import React, { useState, useEffect } from 'react';
import {
  withStyles,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Typography,
  Grid,
  Checkbox,
  InputAdornment,
  MenuItem,
  FormControl,
  Select,
  LinearProgress,
  Chip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import directus from '../../services/directus';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { useStylesEmployees, AddButton } from './style.js';
import useStateRef from "react-usestateref";
import { pageOptions } from 'src/_utils/pageOptions';
import { useSnackbar } from 'notistack';
import ErrorMessage from 'src/views/Components/ErrorMessage';

import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import EventEmitter from 'src/utils/EventEmitter';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import linq from "linq";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { removeCache, setCache } from "../../utils/KeyValueCache";
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'red',
      backgroundColor: 'white',
      borderRadius: '6px',
      height: '53.63px'
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
      marginTop: '8px'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: "translate(14px, -12px) scale(0.75)"
    }
  },
})(TextField);

const SubmitButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
})(Button);

const CancelButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

export const SearchButton = withStyles({
  root: {
    marginRight: '22px',
    borderRadius: '24px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '25px',
    width: '100px',
    minWidth: '50px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
})(Button);

let page = 1;

var searchTimeout;
const Suppliers = (props) => {
  /* eslint-disable no-unused-vars*/
  const classes = useStylesEmployees();
  const [initialized, setInitialized] = useState(false);
  const [suppliers, setSuppliers] = useState(null);

  const [totalPage, setTotalPage] = useState('');
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(100);

  const [loadingPagination, setLoadingPagination] = useState(false);
  const [dataSupplier, setDataSupplier, dataSupplierRef] = useStateRef({
    id: '', code: '', name: '', email: '', phone: '', status: 'published',
    customers: [],
    legal_name: '', abn_number: '', address_line_1: '', address_line_2: '', city: '', state: '', postcode: ''
  })

  const [selectedStatus, setSelectedStatus, selectedStatusRef] = useStateRef("all");

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [mode, setMode] = useState('');

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const supplierStatus = ['published', 'draft', 'inactive'];

  const [search, setSearch] = React.useState('');
  const [searchingLoading, setSearchingLoading] = useState(false);
  const [searchingLoading2, setSearchingLoading2] = useState(false);

  const [sortField, setSortField, sortFieldRef] = useStateRef({
    "code": { type: "desc", value: false },
    "name": { type: "desc", value: false },
    "email": { type: "desc", value: false },
    "phone": { type: "desc", value: false },
    "status": { type: "desc", value: false }
  });

  const [sortLoading, setSortLoading, sortLoadingRef] = useStateRef(false);
  const [loadingExport, setLoadingExport] = React.useState(false);

  const [customers, setCustomers, customersRef] = useStateRef(null);
  const [selectedCustomer, setSelectedCustomer, selectedCustomerRef] = useStateRef([]);

  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadFirst();
      loadCustomers();
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadFirst = () => {
    var sort_data = localStorage.sort_data;
    if (sort_data) {
      sort_data = JSON.parse(sort_data);
      if (sort_data.suppliers) {
        setSortField(sort_data.suppliers)
      }
    }
    loadSuppliers();
  }

  let disable = dataSupplierRef.current.code === '' || dataSupplierRef.current.name === '';

  const loadSuppliers = async (data) => {
    var status = { in: ['published', 'draft', 'inactive'] };

    if (selectedStatusRef.current !== "all") {
      status = { in: [selectedStatusRef.current] }
    }
    var code_sort = sortFieldRef.current.code;
    var name_sort = sortFieldRef.current.name;
    var email_sort = sortFieldRef.current.email;
    var phone_sort = sortFieldRef.current.phone;
    var status_sort = sortFieldRef.current.status;

    var orderByField = "suppliers.code";

    if (code_sort.value) {
      if (code_sort.type === "asc") { orderByField = "suppliers.code"; }
      else if (code_sort.type === "desc") { orderByField = "-suppliers.code"; }
    }

    if (name_sort.value) {
      if (name_sort.type === "asc") { orderByField = "suppliers.name"; }
      else if (name_sort.type === "desc") { orderByField = "-suppliers.name"; }
    }

    if (email_sort.value) {
      if (email_sort.type === "asc") { orderByField = "suppliers.email"; }
      else if (email_sort.type === "desc") { orderByField = "-suppliers.email"; }
    }

    if (phone_sort.value) {
      if (phone_sort.type === "asc") { orderByField = "suppliers.phone_number"; }
      else if (phone_sort.type === "desc") { orderByField = "-suppliers.phone_number"; }
    }

    if (status_sort.value) {
      if (status_sort.type === "asc") { orderByField = "suppliers.status"; }
      else if (status_sort.type === "desc") { orderByField = "-suppliers.status"; }
    }

    var EmployeesResults = null;

    var fields = `*, customers.id, customers.status,
    customers.customer.id, customers.customer.code, customers.customer.name`;

    if (search) {
      EmployeesResults = await directus.api.get('/custom/settings/suppliers', {
        fields: fields,
        filter: {
          'suppliers.status': status
        },
        q: search,
        meta: "filter_count",
        limit: pageNumberRef.current,
        page: page,
        sort: orderByField
      });
    } else {
      EmployeesResults = await directus.api.get('/custom/settings/suppliers', {
        fields: fields,
        filter: {
          'suppliers.status': status
        },
        meta: "filter_count",
        limit: pageNumberRef.current,
        page: page,
        sort: orderByField
      });
    }

    setSuppliers(EmployeesResults.data);
    setTotalPage(EmployeesResults.meta.filter_count);

    let SortOrder;
    let sort_type = '';
    let sort_by_data = '';
    let changeUpperCase = false;

    if (code_sort.value) {
      sort_by_data = 'code';
      sort_type = code_sort.type;
    } else if (name_sort.value) {
      sort_by_data = 'name';
      sort_type = name_sort.type;
    } else if (email_sort.value) {
      sort_by_data = 'email';
      sort_type = email_sort.type;
      changeUpperCase = true;
    } else if (phone_sort.value) {
      sort_by_data = 'phone_number';
      sort_type = phone_sort.type;
    } else if (status_sort.value) {
      sort_by_data = 'status';
      sort_type = status_sort.type;
    } else {
      sort_by_data = 'code';
      sort_type = 'asc';
    }

    SortOrder = EmployeesResults.data;

    SortOrder = linq.from(SortOrder)
      .orderBy(o => o.code)
      .toArray();

    if (sort_by_data) {
      if (sort_type === "desc") {
        SortOrder.sort(function (a, b) {
          let nameA = a[sort_by_data] ? a[sort_by_data] : '';
          let nameB = b[sort_by_data] ? b[sort_by_data] : '';
          if (changeUpperCase) {
            nameA = nameA ? nameA.toUpperCase() : nameA; // ignore upper and lowercase
            nameB = nameB ? nameB.toUpperCase() : nameB; // ignore upper and lowercase
          }

          if (nameA > nameB) { return -1; }
          if (nameA < nameB) { return 1; }
          return 0; // names must be equal
        });
      } else {
        SortOrder.sort(function (a, b) {
          let nameA = a[sort_by_data] ? a[sort_by_data] : '';
          let nameB = b[sort_by_data] ? b[sort_by_data] : '';
          if (changeUpperCase) {
            nameA = nameA ? nameA.toUpperCase() : nameA; // ignore upper and lowercase
            nameB = nameB ? nameB.toUpperCase() : nameB; // ignore upper and lowercase
          }

          if (nameA < nameB) { return -1; }
          if (nameA > nameB) { return 1; }
          return 0; // names must be equal
        });
      }
    }

    if (SortOrder) {
      setSuppliers(SortOrder);
    }

    setLoadingPagination(false);
    setSearchingLoading(false);
    setSearchingLoading2(false);
    setSortLoading(false);
  };

  const loadCustomers = async () => {
    try {
      var results = await directus.getItems('customers', {
        fields: 'id, code, name',
        limit: -1,
        page: page,
        filter: { status: { eq: 'published' } },
        sort: "code"
      });

      setCustomers(results.data);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
  };

  const handleClickOpen = (supplier, mode) => {
    setOpen(true);
    if (mode === "add") {
      setMode('add');
      setTitle('Add Supplier');
      setText('');
      setSelectedCustomer([]);
    }
    else if (mode === "edit") {
      setDataSupplier({
        id: supplier.id, code: supplier.code, name: supplier.name, email: supplier.email, phone: supplier.phone_number, status: supplier.status,
        legal_name: supplier.legal_name, abn_number: supplier.abn_number, address_line_1: supplier.address_line_1, address_line_2: supplier.address_line_2, city: supplier.city, state: supplier.state, postcode: supplier.postcode,
        customers: supplier.customers
      })
      setMode('edit');
      setTitle('Edit Supplier');
      setText('');

      if (supplier.customers.length > 0) {
        let result = [];
        supplier.customers.map(data => {
          result = [...result, data.customer];
        })

        setSelectedCustomer(result);
      }
      else { setSelectedCustomer([]); }

    } else {
      setDataSupplier({
        id: supplier.id, code: supplier.code, name: supplier.name, email: supplier.email, phone: supplier.phone_number, status: supplier.status,
        legal_name: supplier.legal_name, abn_number: supplier.abn_number, address_line_1: supplier.address_line_1, address_line_2: supplier.address_line_2, city: supplier.city, state: supplier.state, postcode: supplier.postcode,
        customers: supplier.customers
      })
      setMode('delete');
      setTitle('Delete Supplier');
      setText('Are you sure?');
    }
  }

  const handleClose = () => {
    setOpen(false);
    setDataSupplier({
      id: '', code: '', name: '', email: '', phone: '', status: 'published',
      legal_name: '', abn_number: '', address_line_1: '', address_line_2: '', city: '', state: '', postcode: '',
      customers: []
    })
  };

  const handleChangeInput = (e, type) => {
    if (type === "status") {
      setDataSupplier({ ...dataSupplierRef.current, status: e })
    } else if (type === "email") {
      const { name, value } = e.target;
      var check_value = null;
      if (value) {
        if (value === " ") {
          check_value = "";
        } else {
          check_value = value.split(/[ ,]+/).join(';');
          check_value = check_value.split(/[;;,]+/).join(';');
        }
      }
      setDataSupplier({ ...dataSupplierRef.current, [name]: check_value })
    } else {
      const { name, value } = e.target;
      setDataSupplier({ ...dataSupplierRef.current, [name]: value })
    }
  }

  const SaveData = async () => {
    setLoading(true);
    try {
      if (mode === 'edit') {
        var new_data_edit = dataSupplierRef.current;


        // Customers - Suppliers - Start ===============================================================================================================
        var createData = [];
        var merge = '';


        // Link Customer to Supplier ===============================================================================================================
        selectedCustomerRef.current.map(data => {
          const filterInput = linq.from(new_data_edit.customers)
            .where(x => x.customer.id === data.id).toArray();
          if (filterInput.length === 0) {
            createData = [...createData, { supplier: new_data_edit.id, customer: data.id, status: 'published' }];
          }
        })

        if (createData.length > 0) {
          try {
            await directus.createItems('customers_suppliers', createData);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
          }
        }

        // Unlink Supplier from Customer ===============================================================================================================
        let deleteCustomerData = '';

        if (new_data_edit.customers) {
          new_data_edit.customers.map(data_supplier => {
            const filterInput = linq.from(selectedCustomerRef.current)
              .where(x => x.id === data_supplier.customer.id).toArray();
            if (filterInput.length === 0) {
              merge = [...merge, data_supplier.customer.id];
              if (deleteCustomerData === '') {
                deleteCustomerData = data_supplier.customer.id;
              }
              else {
                deleteCustomerData = data_supplier.customer.id + ',' + deleteCustomerData;
              }
            }
          })
        }


        let deleteCustomersInput = [];
        var CustomerSupplier;

        if (deleteCustomerData !== '') {
          try {
            CustomerSupplier = await directus.getItems('customers_suppliers', {
              fields: '*',
              filter: {
                supplier: { eq: new_data_edit.id },
                customer: { in: deleteCustomerData }
              }
            });
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
          }

          CustomerSupplier.data.map(data => {
            deleteCustomersInput = [...deleteCustomersInput, data.id]
          })


          try {
            await directus.deleteItems('customers_suppliers', deleteCustomersInput);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
          }
        }

        // Customers - Suppliers - End ===============================================================================================================


        var body_edit = {
          id: new_data_edit.id,
          code: new_data_edit.code,
          name: new_data_edit.name,
          email: new_data_edit.email,
          phone_number: new_data_edit.phone,
          status: new_data_edit.status,
          legal_name: new_data_edit.legal_name,
          abn_number: new_data_edit.abn_number,
          address_line_1: new_data_edit.address_line_1,
          address_line_2: new_data_edit.address_line_2,
          city: new_data_edit.city,
          state: new_data_edit.state,
          postcode: new_data_edit.postcode,
        }
        await directus.updateItem('suppliers', body_edit.id, body_edit);
        EventEmitter.emit("loadSupplier", { value: true });
        enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
      }
      else if (mode === 'add') {
        var new_data = dataSupplierRef.current;
        var body = {
          code: new_data.code,
          name: new_data.name,
          email: new_data.email,
          phone_number: new_data.phone,
          status: new_data.status,
          legal_name: new_data.legal_name,
          abn_number: new_data.abn_number,
          address_line_1: new_data.address_line_1,
          address_line_2: new_data.address_line_2,
          city: new_data.city,
          state: new_data.state,
          postcode: new_data.postcode
        }
        var addSupplier = await directus.createItem('suppliers', body);

        //================================== Link Supplier to Customer ===================================//
        let createDataCustomers = [];
        if (selectedCustomerRef.current.length > 0) {
          selectedCustomerRef.current.map(data => {
            const filterInput = linq.from(selectedCustomerRef.current.approvers_level2)
              .where(x => x.supplier.id === data.id).toArray();
            if (filterInput.length === 0) {
              createDataCustomers = [...createDataCustomers, { customer: data.id, supplier: addSupplier.data.id, status: 'published' }];
            }
          })

          if (createDataCustomers.length > 0) {
            try {
              await directus.createItems('customers_suppliers', createDataCustomers);
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
            }

          }
        }
        EventEmitter.emit("loadSupplier", { value: true });
        enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });

      }
      else {
        // Unlink customers from supplier ===============================================================================================================
        let deleteCustomersData = '';

        dataSupplierRef.current.customers.map(customer => {
          const filterInput = linq.from(selectedCustomerRef.current)
            .where(x => x.id === customer.customer.id).toArray();
          if (filterInput.length === 0) {
            if (deleteCustomersData === '') {
              deleteCustomersData = customer.customer.id;
            }
            else {
              deleteCustomersData = customer.customer.id + ',' + deleteCustomersData;
            }
          }
        })

        let deleteCustomerInput = [];
        var CustomerSuppliersData;

        if (deleteCustomersData !== '') {
          try {
            CustomerSuppliersData = await directus.getItems('customers_suppliers', {
              fields: '*',
              filter: {
                supplier: { eq: dataSupplierRef.current.id },
                customer: { in: deleteCustomersData }
              }
            });
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
          }

          CustomerSuppliersData.data.map(data => {
            deleteCustomerInput = [...deleteCustomerInput, data.id]
          })

          try {
            await directus.deleteItems('customers_suppliers', deleteCustomerInput);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
          }
        }

        await directus.deleteItem('suppliers', dataSupplierRef.current.id);
        EventEmitter.emit("loadSupplier", { value: true });
        enqueueSnackbar("Data deleted successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
      }

      // clear suppliers cache
      removeCache('suppliers')

      loadSuppliers();
      setLoading(false);
      handleClose();
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      setLoading(false);
    }
  }


  const pageChange = (value) => {
    setLoadingPagination(true);
    page = page + value;
    loadSuppliers();
  }

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    page = 1;
    loadSuppliers();
  };

  const handleChangeSelectedStatus = async (e) => {
    setSelectedStatus(e.target.value);
    setSearchingLoading2(true);
    setSortLoading(true);
    await loadSuppliers();
    setSearchingLoading2(false);
  }

  const handleChangeSearching = (e) => {
    setSearch(e.target.value);
  }

  const searchSupplier = (event) => {
    setSearchingLoading(true);
    if (event.key === "Enter") {
      loadSuppliers();
    }
    else {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        page = 1;
        loadSuppliers();
      }, 500)
    }
  }

  const sortData = (val) => {
    setSortLoading(true);
    var sort_field_data = {
      "code": { type: "desc", value: false },
      "name": { type: "desc", value: false },
      "email": { type: "desc", value: false },
      "phone": { type: "desc", value: false },
      "status": { type: "desc", value: false }
    }

    var sort_data = sortFieldRef.current[val];
    if (sort_data) {
      var value = sort_data.value;
      if (sort_data.value === false) {
        value = true;
      }

      if (value === true) {

        var sort_type = "";
        if (sort_data.type === "desc") {
          sort_type = "asc";
        } else {
          sort_type = "desc";
        }

        sort_field_data[val].value = true;
        sort_field_data[val].type = sort_type;

        var sort_data = localStorage.sort_data;
        if (sort_data) {
          sort_data = JSON.parse(sort_data);
          sort_data.suppliers = sort_field_data;
        } else {
          sort_data = { suppliers: sort_field_data };
        }
        localStorage.setItem("sort_data", JSON.stringify(sort_data))
        setSortField(sort_field_data);
      }
    }

    loadSuppliers();
  }

  const exportData = async () => {
    setLoadingExport(true);
    setSortLoading(true);
    let dataURL = '';

    let sort_type = '';
    let sort_by_data = '';

    var code_sort = sortFieldRef.current.code;
    var name_sort = sortFieldRef.current.name;
    var email_sort = sortFieldRef.current.email;
    var phone_sort = sortFieldRef.current.phone;
    var status_sort = sortFieldRef.current.status;

    if (code_sort.value) {
      sort_by_data = 'code';
      sort_type = code_sort.type;
    } else if (name_sort.value) {
      sort_by_data = 'name';
      sort_type = name_sort.type;
    } else if (email_sort.value) {
      sort_by_data = 'email';
      sort_type = email_sort.type;
    } else if (phone_sort.value) {
      sort_by_data = 'phone_number';
      sort_type = phone_sort.type;
    } else if (status_sort.value) {
      sort_by_data = 'status';
      sort_type = status_sort.type;
    } else {
      sort_by_data = 'code';
      sort_type = 'asc';
    }

    var status = "";

    if (selectedStatusRef.current !== "all") {
      status = selectedStatusRef.current
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + directus.api.auth.config.token },
      body: JSON.stringify({
        custom_sort: { sort_by_data: sort_by_data, sort_type: sort_type },
        status: status,
        search_by_text: search,
      })
    };

    var react_app_url = process.env.REACT_APP_URL;
    if (window.location.hostname.search('interax') !== -1) {
      react_app_url = process.env.REACT_APP_URL_INTERAX;
    }

    dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/export_settings/suppliers`;
    await fetch(dataURL, requestOptions)
      .then(resp => resp.blob())
      .then(async blob => {
        var file = await blob.text();
        if (file.includes("error")) {
          alert('Connection error. Please try again.');
        } else if (file.includes("no_template")) {
          alert('No Template');
        } else {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          var file_name = 'Suppliers-All-Export';
          file_name += '.xlsx';
          a.download = file_name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      })
    setLoadingExport(false)
    setSortLoading(false);
  }

  return (
    <div>
      <div style={{ width: "1200px", display: "flex", alignItems: "center", height: "60px", marginTop: "10px", marginBottom: "10px" }}>
        <div style={{ padding: "0px 20px 0 22px", lineHeight: "60px", background: "white", height: "100%", borderRadius: "8px 0 0 8px" }}>
          <Typography style={{ lineHeight: "60px" }}><b>Filter:</b></Typography>
        </div>
        <div style={{ lineHeight: "60px", width: "300px", background: "white", height: "100%", paddingRight: "10px" }}>
          <InputBase
            id="search_order"
            name="searchOrder"
            variant="outlined"
            placeholder="Search"
            size="small"
            onChange={handleChangeSearching}
            onKeyUp={(e) => searchSupplier(e)}
            className={classes.searchbox}
            value={search}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon className={classes.IconSearch} />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <div>{searchingLoading ? <CircularProgress style={{ width: "20px", height: "20px", marginTop: "4px" }} className={classes.circular2} /> : null}</div>
              </InputAdornment>
            }
          />

        </div>
        <div style={{ lineHeight: "60px", width: "235px", background: "white", height: "100%", paddingRight: "10px", paddingLeft: "10px", borderRadius: "0 8px 8px 0" }}>
          <FormControl fullWidth>
            <Select
              className={classes.selectSupplierStatus}
              style={{ height: "36px", marginTop: "11px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedStatusRef.current}
              onChange={handleChangeSelectedStatus}
              variant='outlined'
              disabled={searchingLoading2}
            >
              <MenuItem value="all">All Status</MenuItem>
              <MenuItem value="published">Published</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ lineHeight: "60px", width: "150px", background: "white", height: "100%", paddingRight: "20px", paddingLeft: "10px", borderRadius: "0 8px 8px 0" }}>
          {loadingExport ?
            <span><CircularProgress style={{ marginLeft: "7px", color: "#5bfa17" }} size={20} /></span>
            :
            <IconButton className={classes.export} TouchRippleProps={{ style: { width: "115px" } }} style={{ width: "115px", borderRadius: "10px", backgroundColor: "#82cb43", height: "36px", boxShadow: "0 0 1px 0 rgb(0 0 0 / 31%), 0 2px 2px -2px rgb(0 0 0 / 25%)", padding: "7px", marginTop: "-7px", color: "white" }}
              onClick={(e) => exportData()}
            >
              <FileUploadOutlinedIcon style={{ borderRadius: "14px", marginLeft: "2px" }}
                fontSize="10px"
              />
              <span style={{ fontSize: "15px", fontWeight: "600", marginLeft: "3px", marginRight: "8px" }}>EXPORT</span>
            </IconButton>
          }
        </div>
      </div>

      {suppliers ?
        <div >
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: '#f2f2f6' }}>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={1} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("code")}>
                        <Typography noWrap variant="h5">Code</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['code'].value === true ?
                            sortFieldRef.current['code'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("name")}>
                        <Typography noWrap variant="h5">Name</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['name'].value === true ?
                            sortFieldRef.current['name'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("email")}>
                        <Typography noWrap variant="h5">Email</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['email'].value === true ?
                            sortFieldRef.current['email'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("phone")}>
                        <Typography noWrap variant="h5">Phone</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['phone'].value === true ?
                            sortFieldRef.current['phone'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={1} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("status")}>
                        <Typography noWrap variant="h5">Status</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['status'].value === true ?
                            sortFieldRef.current['status'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>

                  </Grid>
                }
              />
              <Grid item xs={2} style={{ textAlign: 'right' }}>
                {props.supplier_settings === true ?
                  <AddButton id="add_btn" size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                    <AddIcon />
                  </AddButton>
                  : ''}
              </Grid>

            </ListItem>

          </List>
          {sortLoadingRef.current ?
            <Grid item xs={12}>
              <div className={classes.linearProgress} style={{ marginLeft: "7px", marginRight: "7px" }}><LinearProgress style={{ height: "2px" }} /></div>
            </Grid>
            : ''}
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ marginBottom: '20px' }} >

            {suppliers.map((supplier, index) => {
              return (
                <div key={index}>
                  <ListItem style={{ backgroundColor: supplier.status === "draft" ? "#EBE9E9" : supplier.status === "inactive" ? "#cdd0d1" : supplier.status === "deleted" ? "#fee1e0a1" : "white" }}>
                    <ListItemText
                      primary={
                        <Grid container key={index}>
                          <Grid item xs={1} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{supplier.code ? supplier.code : ''}</Typography>
                          </Grid>
                          <Grid item xs={4} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{supplier.name ? supplier.name : ''}</Typography>
                          </Grid>
                          <Grid item xs={4} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{supplier.email ? supplier.email : ''}</Typography>
                          </Grid>
                          <Grid item xs={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{supplier.phone_number}</Typography>
                          </Grid>
                          <Grid item xs={1} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6" style={{ textTransform: "capitalize" }}>{supplier.status}</Typography>
                          </Grid>
                        </Grid>
                      }
                    />

                    {props.supplier_settings === true ?
                      <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <IconButton id={`edit_btn_${index}`} aria-label="edit" onClick={() => handleClickOpen(supplier, 'edit')} >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton id={`delete_btn_${index}`} aria-label="delete" onClick={() => handleClickOpen(supplier, 'delete')} >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                      : <Grid item xs={2} style={{ textAlign: 'right' }}></Grid>}
                    {/* <IconButton aria-label="edit" 
                          // onClick={() => handleClickOpen(supplier)}
                         >
                          <EditIcon fontSize="small" />
                        </IconButton> */}

                  </ListItem>

                  {supplier.customers ? supplier.customers.length > 0 ?
                    <ListItem>
                      <ListItemText
                        id={`customer_supplier_list_${index}`}
                        // secondaryTypographyProps={{ component: "div" }}
                        primary={
                          <div>
                            <Grid container>
                              {supplier.customers.length > 0 ? <Grid item xs={1} style={{ maxWidth: '8.333333% !important' }}><Typography variant="h6">Customers: &nbsp;</Typography></Grid> : ''}
                              <Grid item xs={11}>
                                {/* eslint-disable-next-line array-callback-return */}
                                {supplier.customers.map((item, index) => {
                                  if (item.status === "published" && item.customer !== null) {
                                    return (
                                      <Chip key={index} variant="outlined" size="small" label={<Typography variant="h6" id={`suppliers_${index}`}>{item.customer ? item.customer.name : ''}</Typography>} color="secondary" />
                                    )
                                  }
                                })}
                              </Grid>
                            </Grid>
                          </div>
                        }
                      />
                    </ListItem>
                    : '' : ''}
                  {suppliers.length - 1 > index ? <Divider /> : ''}
                </div>
              );
            })}

            <Dialog
              id="supplier_dialog"
              isopen={`${open}`}
              open={open}
              fullWidth={true}
              onClose={handleClose}
            >
              <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="supplier_dialog_title" component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
              <DialogContent>
                <Typography variant="h6">{text ? text : ''}</Typography>
                {mode !== 'delete' ?
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          id="code_text"
                          label="Code"
                          variant="outlined"
                          fullWidth={true}
                          name="code"
                          value={dataSupplierRef.current.code}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="name_text"
                          label="Name"
                          variant="outlined"
                          fullWidth={true}
                          name="name"
                          value={dataSupplierRef.current.name}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="email_text"
                          label="Email"
                          variant="outlined"
                          fullWidth={true}
                          name="email"
                          value={dataSupplierRef.current.email}
                          onChange={(e) => handleChangeInput(e, "email")}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="phone_text"
                          label="Phone"
                          variant="outlined"
                          fullWidth={true}
                          name="phone"
                          value={dataSupplierRef.current.phone}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="abn_number_text"
                          label="ABN Number"
                          variant="outlined"
                          fullWidth={true}
                          name="abn_number"
                          value={dataSupplierRef.current.abn_number}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={mode === "edit" ? 6 : 12}>
                        <TextField
                          id="legal_name_text"
                          label="Legal Name"
                          variant="outlined"
                          fullWidth={true}
                          name="legal_name"
                          value={dataSupplierRef.current.legal_name}
                          onChange={handleChangeInput}
                        />
                      </Grid>

                      {mode === "edit" ?
                        <Grid item xs={6}>
                          <Autocomplete
                            id="size-small-standard"
                            size="small"
                            options={supplierStatus}
                            disableClearable={true}
                            // getOptionLabel={(option) => option.name}
                            // style={{ marginTop: '15px' }}
                            value={dataSupplierRef.current.status}
                            onChange={(event, newValue) => {
                              handleChangeInput(newValue, "status");
                            }}
                            renderInput={(params) =>
                              <CssTextField
                                // helperText={list.supplier.name}
                                {...params}
                                variant="outlined"
                                name="status"
                                label="Select Supplier Status"
                              />}
                          />
                        </Grid>
                        : null}


                      <Grid item xs={12} style={{ marginTop: "15px" }}><div className={classes.header}><span>Customers</span></div></Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          id="checkboxes-tags-demo"
                          options={customersRef.current}
                          disableCloseOnSelect
                          fullWidth={true}
                          getOptionLabel={(option) => (option ? option.code : '') + " - " + (option ? option.name : '')}
                          getOptionSelected={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            setSelectedCustomer(newValue)
                          }}
                          value={selectedCustomerRef.current ? selectedCustomerRef.current : null}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option ? option.code : ''} - {option ? option.name : ''}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Select Customers" placeholder="Select..." />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: "15px" }}><div className={classes.header}><span>Address</span></div></Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="address_line_1_text"
                          label="Address Line 1"
                          variant="outlined"
                          fullWidth={true}
                          name="address_line_1"
                          value={dataSupplierRef.current.address_line_1}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="address_line_2_text"
                          label="Address Line 2"
                          variant="outlined"
                          fullWidth={true}
                          name="address_line_2"
                          value={dataSupplierRef.current.address_line_2}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="city_text"
                          label="City"
                          variant="outlined"
                          fullWidth={true}
                          name="city"
                          value={dataSupplierRef.current.city}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="state_text"
                          label="State"
                          variant="outlined"
                          fullWidth={true}
                          name="state"
                          value={dataSupplierRef.current.state}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="postcode_text"
                          label="Postcode"
                          variant="outlined"
                          fullWidth={true}
                          name="postcode"
                          value={dataSupplierRef.current.postcode}
                          onChange={handleChangeInput}
                        />
                      </Grid>

                    </Grid>
                  </div>
                  : ''}
              </DialogContent>
              <DialogActions style={{ height: "60px" }}>
                {loading ?
                  <CircularProgress className={classes.circular} />
                  :
                  <div>
                    <CancelButton
                      id="cancel_btn"
                      size="small"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Cancel
                    </CancelButton>
                    <SubmitButton
                      id="save_btn"
                      disabled={mode === 'delete' ? false : disable}
                      size="small"
                      variant="contained"
                      onClick={() => SaveData()}
                    >
                      {mode === 'delete' ? 'Delete' : 'Save'}
                    </SubmitButton>
                  </div>
                }

              </DialogActions>
            </Dialog>
          </List>
        </div>
        :
        <CircularProgress className={classes.circular} />
      }

      {suppliers ?
        <Grid
          item xs={12}
          style={{ textAlign: 'right', marginBottom: '10px', fontFamily: 'Manrope' }}
        >
          <span style={{ paddingRight: '20px' }}>
            Rows per page:
          </span>
          <span style={{ paddingRight: '20px' }}>
            <TextField
              id="standard-select-currency"
              select
              value={pageNumber}
              onChange={handleChange}
            >
              {pageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>

          <span style={{ paddingRight: '20px' }}>
            {(pageNumberRef.current * (page - 1)) + 1} - {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
          </span>

          <Button
            variant="outlined"
            size="small"
            disabled={page === 1 || loadingPagination}
            className={classes.buttonLeft}
            onClick={() => pageChange(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Button
            variant="outlined"
            size="small"
            className={classes.buttonRight}
            disabled={page >= Math.ceil(totalPage / pageNumberRef.current) || loadingPagination}
            onClick={() => pageChange(1)}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Grid>
        :
        " "}
    </div>
  );
}

export default Suppliers;
