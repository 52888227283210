import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import RegisterView from 'src/views/auth/RegisterView';
import PasswordReset from 'src/views/auth/ResetPasswordPage';
import AllOrder from 'src/views/AllOrder';
import Invoices from 'src/views/Invoice';
import Setting from 'src/views/setting';
import MatchInvoice from 'src/views/MatchInvoice/index';
import SupplierRequest from 'src/views/SupplierRequests/index';
import * as permission from 'src/utils/Permission';

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: <AllOrder /> },
      { path: 'orders', element: <AllOrder /> },
      { path: 'invoices', element: permission.POCreatorPermission() && permission.ShowInvoiceTab() ? <Invoices /> : <Navigate to="/" /> },
      { path: 'invoice/:id', element: permission.POCreatorPermission() && permission.ShowInvoiceTab() ? <MatchInvoice /> : <Navigate to="/" /> },
      { path: 'settings', element: permission.AdminPermission() ? <Setting /> : <Navigate to="/" /> },
      { path: 'supplier_requests', element: <SupplierRequest /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'passwordreset', element: <PasswordReset /> },
      { path: 'sign-in', element: <LoginView /> },
      { path: 'sign-up', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/approvals" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
