import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  withStyles,
  TextareaAutosize,
  List,
  ListItem,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip
} from '@material-ui/core';
import './style.css';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import directus from '../../services/directus';
import useStateRef from 'react-usestateref';
import { v4 as uuidv4 } from 'uuid';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import useStyles3 from './style';
import { useStyles as useStyles2, SubmitButton, CancelButton } from 'src/views/_styles/style1';
import Enumerable from 'linq';
import ErrorMessage from 'src/components/ErrorMessage';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { POCreatorPermission, POApproverPermission, AdminPermission, InvoiceAdminPermission } from 'src/utils/Permission';
import { getEmployee } from 'src/utils/sessions';
import EventEmitter from 'src/utils/EventEmitter';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Divider from '@mui/material/Divider';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import SendIcon from '@mui/icons-material/Send';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { waitFor } from '@testing-library/react';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'red',
      backgroundColor: 'white',
      borderRadius: '6px',
      marginBottom: '6px',
      marginRight: '6px',
      height: '30px'
    },
    '& .MuiFormLabel-root': {
      // lineHeight: "8px"
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '80%',
      marginTop: '-3px',
      height: '100%'
    },
    '& .MuiInputBase-input.Mui-disabled': {
      cursor: 'not-allowed'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 0px) scale(0.75)'
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      marginTop: '-3.5px'
    }
  }
})(TextField);

const AddOrder = props => {
  /* eslint-disable no-unused-vars*/
  const [initialized, setInitialized] = useState(false);
  const [customers, setCustomers, customersRef] = useStateRef(null);
  const [customers2, setCustomers2, customersRef2] = useStateRef(null);
  const [suppliers, setSuppliers, suppliersRef] = useStateRef(null);
  const [suppliers2, setSuppliers2, suppliersRef2] = useStateRef(null);
  const [supplierLoading, setSupplierLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [items, setItems, itemsRef] = useStateRef([]);
  const [totalOrderItem, setTotalOrderItem, totalOrderItemRef] = useStateRef(0);
  const [category_type, setCategory_type] = useState(null);
  const [categoryDescription, setCategoryDescription, categoryDescriptionRef] = useStateRef(null);

  const [itemCheck, setItemCheck] = useState(false);
  const [itemSaveAsDraftCheck, setItemSaveAsDraftCheck] = useState(false);
  const [itemCount, setItemCount, itemCountRef] = useStateRef(false);
  const categoryTypeLoading = props.categoryTypeLoading;
  const categoryType = props.categoryType;
  const collection = props.collection;
  const collectionData = props.collectionData;

  const classes = useStyles2();
  const classes2 = useStyles3();
  const [note, setNote] = useState('');
  const [deliveryInstructions, setDeliveryInstructions] = useState('');
  const [email, setEmail] = useState('');
  const [supplier, setSupplier, supplierRef] = useStateRef(null);
  const [customer, setCustomer, customerRef] = useStateRef(null);
  const [list, setList, listRef] = useStateRef('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedDate, handleDateChange] = useState(new Date());

  const [anchorElSupplier, setAnchorElSupplier] = React.useState(null);
  const open2 = Boolean(anchorElSupplier);
  const supplierListRef = React.useRef();
  const filterRef = React.useRef();

  const [anchorElCustomer, setAnchorElCustomer] = React.useState(null);
  const open3 = Boolean(anchorElCustomer);
  const customerListRef = React.useRef();
  const filter2Ref = React.useRef();

  const [isClickFavourite, setIsClickFavourite, isClickFavouriteRef] = useStateRef(false);

  const [isCustomEmail, setIsCustomEmail, isCustomEmailRef] = useStateRef(false);
  const [customEmailLoading, setCustomEmailLoading] = React.useState(false);
  const [dataCustomEmail, setDataCustomEmail, dataCustomEmailRef] = useStateRef({ send_to_email: null, cc_email: null });

  const [categoryTypeData, setCategoryTypeData, categoryTypeDataRef] = useStateRef(categoryType);
  const [categoryTypeData2, setCategoryTypeData2, categoryTypeDataRef2] = useStateRef(categoryType);
  const [anchorElCategory, setAnchorElCategory] = React.useState(null);
  const open4 = Boolean(anchorElCategory);
  let categoryListRef = React.useRef([]);
  const filter3Ref = React.useRef();

  const [anchorElCategory2, setAnchorElCategory2] = React.useState(null);
  const open5 = Boolean(anchorElCategory2);
  const categoryListRef2 = React.useRef();
  const filter4Ref = React.useRef();

  const [selectedListItem, setSelectedListItem, selectedListItemRef] = useStateRef(null);

  const [anchorElSettings, setAnchorElSettings] = React.useState(null);
  const openSettings = Boolean(anchorElSettings);
  const [loadingFile, setLoadingFile] = React.useState(false);

  const [uploadFiles, setUploadFiles, uploadFilesRef] = useStateRef([]);

  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadItems();
      loadCustomer();
      loadSupplier();
      loadCategoryType();
    }
  });

  useEffect(() => {
    loadCategoryType();
  }, [props.categoryType]);
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadCustomer = async () => {
    setCustomerLoading(true);
    try {
      // var employee_id = await getEmployee();
      var Customers = null;
      if (POCreatorPermission()) {
        var customer_filter = { status: 'published' };
        // if(!POApproverPermission()){
        //   customer_filter['selectable'] = 1;
        // }

        var fields = `id, code, name, selectable, access_level, accounting_system, legal_name, 
        suppliers.id, suppliers.status, suppliers.supplier.*`;

        Customers = await directus.getItems('customers', {
          fields: fields,
          sort: 'name',
          filter: customer_filter
        });

        Customers = Customers.data;
      }
      // } else {
      //   Customers = await directus.getItems('customers_approvers_level1', {
      //     fields: 'id, customer.id, customer.code, customer.name',
      //     filter: {
      //       "employee": { "in": employee_id.id },
      //       "customer.id": { "nnull": null },
      //       "customer.status": "published",
      //     }
      //   })

      //   if (Customers.data.length > 0) {
      //     var result = Enumerable.from(Customers.data)
      //       .select(data => data.customer)
      //       .toArray();
      //     Customers = result;
      //   } else {
      //     Customers = [];
      //   }

      //   var Customers2 = await directus.getItems('customers_approvers_level2', {
      //     fields: 'id, customer.id, customer.code, customer.name',
      //     filter: {
      //       "employee": { "in": employee_id.id },
      //       "customer.id": { "nnull": null },
      //       "customer.status": "published",
      //     }
      //   });

      //   if (Customers2.data.length > 0) {
      //     var result = Enumerable.from(Customers2.data)
      //       .select(data => data.customer)
      //       .toArray();

      //     Customers2 = result;
      //   } else {
      //     Customers2 = [];
      //   }

      //   if (Customers.length === 0) {
      //     Customers = Customers2;
      //   } else {
      //     if (Customers2.length !== 0) {
      //       var Customers1_id = Enumerable.from(Customers)
      //         .select(data => data.id)
      //         .toArray();

      //       Customers2 = Enumerable.from(Customers2)
      //         .where(w => !Enumerable.from(Customers1_id).contains(w.id))
      //         .toArray();
      //       Customers = [...Customers, ...Customers2]

      //     }
      //   }

      //   if (Customers.length > 0) {
      //     Customers = Enumerable.from(Customers)
      //       .orderBy(o => o.name)
      //       .toArray();
      //   }
      // }

      var customers_data = Customers;
      var fav_customers = [];

      if (localStorage.directus_employee) {
        var directus_employee = JSON.parse(localStorage.directus_employee);
        if (directus_employee.favourites_customers_po) {
          fav_customers = directus_employee.favourites_customers_po;
        }
      }

      customers_data.map((value, index) => {
        if (fav_customers.indexOf(value.id) >= 0) {
          customers_data[index].isFavourite = true;
        } else {
          customers_data[index].isFavourite = false;
        }
      });

      customers_data = Enumerable.from(customers_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      setCustomers(customers_data);
      setCustomers2(customers_data);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
    setCustomerLoading(false);
  };

  const loadSupplier = async () => {
    setSupplierLoading(true);
    try {
      var Suppliers = await directus.getItems('suppliers', {
        fields: '*',
        sort: 'name',
        limit: -1,
        filter: {
          status: 'published'
        }
      });

      var suppliers_data = Suppliers.data;
      var fav_suppliers = [];

      if (localStorage.directus_employee) {
        var directus_employee = JSON.parse(localStorage.directus_employee);
        if (directus_employee.favourites_suppliers_po) {
          fav_suppliers = directus_employee.favourites_suppliers_po;
        }
      }

      suppliers_data.map((value, index) => {
        if (fav_suppliers.indexOf(value.id) >= 0) {
          suppliers_data[index].isFavourite = true;
        } else {
          suppliers_data[index].isFavourite = false;
        }
      });

      suppliers_data = Enumerable.from(suppliers_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      setSuppliers(suppliers_data);
      setSuppliers2(suppliers_data);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
    setSupplierLoading(false);
  };

  const loadCategoryType = async () => {
    var categories_data = props.categoryType;
    var fav_categories = [];

    if (localStorage.directus_employee) {
      var directus_employee = JSON.parse(localStorage.directus_employee);
      if (directus_employee.favourites_categories_po) {
        fav_categories = directus_employee.favourites_categories_po;
      }
    }

    if (categories_data) {
      categories_data.map((value, index) => {
        if (fav_categories.indexOf(value.id) >= 0) {
          categories_data[index].isFavourite = true;
        } else {
          categories_data[index].isFavourite = false;
        }
      });

      categories_data = Enumerable.from(categories_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      setCategoryTypeData(categories_data);
      setCategoryTypeData2(categories_data);
    }
  };

  const loadItems = input => {
    // let itemData = [];
    // addItems(null);
  };

  const addItems = itemVar => {
    if (itemVar === null) {
      let data = [];
      data.id = uuidv4();
      data.statusValue = 1;
      data.count = 0;
      data.category_type = null;
      data.category_description = null;
      setItems([data]);
      setItemCount(data.count);
    } else {
      let data = [];
      data.id = uuidv4();
      data.statusValue = 1;
      if (itemsRef.current.length > 0) {
        data.count = itemsRef.current[itemsRef.current.length - 1].count + 1;
        if (category_type) {
          data.category_type = category_type;
          data.category_description = categoryDescriptionRef.current;
        }
        setItems([...itemsRef.current, data]);
        setItemCount(data.count);
      } else {
        let data = [];
        data.id = uuidv4();
        data.statusValue = 1;
        data.count = 0;
        if (category_type) {
          data.category_type = category_type;
          data.category_description = categoryDescriptionRef.current;
        } else {
          data.category_type = null;
          data.category_description = null;
        }
        setItems([data]);
        setItemCount(data.count);
      }
    }

    addItemsCheck(itemCountRef.current);
  };

  const changeItems = (e, oldData, newData, checkCategory = false) => {
    var name;
    var value;
    if (e) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = newData.name;
      value = newData.value;
    }

    if (checkCategory) {
      oldData = selectedListItemRef.current;
    }

    if (name === 'category_type') {
      setItems(
        itemsRef.current.map(dataList =>
          dataList.id === oldData.id
            ? dataList.statusValue === 1
              ? { ...oldData, statusValue: 1, category_description: null, [name]: value }
              : { ...oldData, statusValue: 2, category_description: null, [name]: value }
            : dataList
        )
      );
    } else {
      setItems(
        itemsRef.current.map(dataList =>
          dataList.id === oldData.id
            ? dataList.statusValue === 1
              ? { ...oldData, statusValue: 1, [name]: value }
              : { ...oldData, statusValue: 2, [name]: value }
            : dataList
        )
      );

    }

    countTotal();
    addItemsCheck(itemCountRef.current);
  };

  const deleteItems = itemData => {
    if (itemData.statusValue === 1) {
      setItems(itemsRef.current.filter(data => data.count !== itemData.count));
    } else {
      setItems(itemsRef.current.map(data => (data.count === itemData.count ? { ...data, statusValue: 3 } : data)));
    }

    deleteItemsCheck(itemData);
    addItemsCheck(itemCountRef.current);
    countTotal();
  };

  const countTotal = () => {
    let total = 0;
    //eslint-disable-next-line array-callback-return
    itemsRef.current.map(data => {
      if (data.statusValue !== 3) {
        if (data.unit_price && data.quantity) {
          total = total + parseFloat(data.unit_price * data.quantity);
        }
      }
    });
    setTotalOrderItem(total.toFixed(2));
  };

  const deleteItemsCheck = itemData => {
    let items = [];
    items = itemsRef.current.filter(data => data.count !== itemData.count);

    /* eslint-disable eqeqeq*/
    if (
      items.quantity == '' ||
      items.quantity === 0 ||
      items.quantity === '0' ||
      items.description == '' ||
      items.quantity == undefined ||
      items.quantity < 0 ||
      items.description == undefined ||
      items.category_type === undefined ||
      items.category_type === '' ||
      items.category_type === null
    ) {
      setItemCheck(false);
    } else {
      if (
        (items.category_type.show_category || items.category_type.show_as_textbox) &&
        items.category_type.required &&
        (items.category_description === undefined || items.category_description === '' || items.category_description === null)
      ) {
        setItemCheck(false);
      } else {
        setItemCheck(true);
      }
    }

    if (
      items.quantity == '' ||
      items.quantity === 0 ||
      items.quantity === '0' ||
      items.description == '' ||
      items.quantity == undefined ||
      items.description == undefined ||
      items.quantity < 0
    ) {
      setItemSaveAsDraftCheck(false);
    } else {
      setItemSaveAsDraftCheck(true);
    }
    /* eslint-enable eqeqeq*/
  };

  const addItemsCheck = itemCount => {
    let saveItems = [];
    //eslint-disable-next-line array-callback-return
    itemsRef.current.map(data => {
      if (data.statusValue !== 3) {
        saveItems = itemsRef.current;
        if (itemCount === 0) {
          if (
            data.quantity === undefined ||
            data.quantity === '' ||
            data.quantity === 0 ||
            data.quantity === '0' ||
            data.quantity < 0 ||
            data.description === undefined ||
            data.description === '' ||
            data.category_type === undefined ||
            data.category_type === '' ||
            data.category_type === null
          ) {
            setItemCheck(false);
          } else {
            if (
              (data.category_type.show_category || data.category_type.show_as_textbox) &&
              data.category_type.required &&
              (data.category_description === undefined || data.category_description === '' || data.category_description === null)
            ) {
              setItemCheck(false);
            } else {
              setItemCheck(true);
            }
          }

          if (
            data.quantity === undefined ||
            data.quantity === '' ||
            data.quantity === 0 ||
            data.quantity === '0' ||
            data.quantity < 0 ||
            data.description === undefined ||
            data.description === ''
          ) {
            setItemSaveAsDraftCheck(false);
          } else {
            setItemSaveAsDraftCheck(true);
          }
        } else {
          if (
            data.quantity === undefined ||
            data.quantity === '' ||
            data.quantity === 0 ||
            data.quantity === '0' ||
            data.quantity < 0 ||
            data.description === undefined ||
            data.description === '' ||
            data.category_type === undefined ||
            data.category_type === '' ||
            data.category_type === null
          ) {
            setItemCheck(false);
          } else {
            if (
              (data.category_type.show_category || data.category_type.show_as_textbox) &&
              data.category_type.required &&
              (data.category_description === undefined || data.category_description === '' || data.category_description === null)
            ) {
              setItemCheck(false);
            } else {
              setItemCheck(true);
            }
          }


          if (
            data.quantity === undefined ||
            data.quantity === '' ||
            data.quantity === 0 ||
            data.quantity === '0' ||
            data.quantity < 0 ||
            data.description === undefined ||
            data.description === ''
          ) {
            setItemSaveAsDraftCheck(false);
          } else {
            setItemSaveAsDraftCheck(true);
          }
        }
      }
    });

    //eslint-disable-next-line array-callback-return
    saveItems.map(data => {
      if (
        data.quantity === undefined ||
        data.quantity === '' ||
        data.quantity === 0 ||
        data.quantity === '0' ||
        data.quantity < 0 ||
        data.description === undefined ||
        data.description === '' ||
        data.category_type === undefined ||
        data.category_type === '' ||
        data.category_type === null
      ) {
        setItemCheck(false);
      } else {
        if (
          (data.category_type.show_category || data.category_type.show_as_textbox) &&
          data.category_type.required &&
          (data.category_description === undefined || data.category_description === '' || data.category_description === null)
        ) {
          setItemCheck(false);
        }
      }

      if (
        data.quantity === undefined ||
        data.quantity === '' ||
        data.quantity === 0 ||
        data.quantity === '0' ||
        data.quantity < 0 ||
        data.description === undefined ||
        data.description === ''
      ) {
        setItemSaveAsDraftCheck(false);
      }
    });
  };

  let status = { backgroundColor: '#D4E3F2' };

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'note') {
      setNote(value);
    } else if (name === 'email') {
      setEmail(value);
    } else if (name === 'delivery_instructions') {
      setDeliveryInstructions(value);
    }
  };

  const changeCategory = newValue => {
    setCategoryDescription(null);
    setCategory_type(newValue);
  };

  const changeCategoryDescription = newValue => {
    setCategoryDescription(newValue);
  };

  const editSubmit = async (id, type, send_supplier = false, custom_send = false) => {
    var directusUser = JSON.parse(window.localStorage.getItem('directus_employee'));
    const date = moment().format('YYYY-MM-DD HH:mm:ss');
    setLoadingSubmit(true);

    var orderResult = null;

    if (custom_send) {
      orderResult = await directus.api.post(
        '/items/orders',
        {
          employee: directusUser.id,
          status: type,
          order_date: date,
          notes: note,
          delivery_instructions: deliveryInstructions,
          cc_email: email,
          supplier: supplierRef.current.id,
          farm_code: customerRef.current.id,
          category_type: category_type ? category_type.id : "",
          category_description: categoryDescription ? (categoryDescription.id ? categoryDescription.id : categoryDescription) : categoryDescription,
          due_date: selectedDate ? moment(new Date(selectedDate)).format('YYYY-MM-DD') : null,
          send_to_email: dataCustomEmailRef.current.send_to_email,
          cc_email: dataCustomEmailRef.current.cc_email
        },
        { send_supplier: send_supplier, custom_send: custom_send }
      );
    } else {
      orderResult = await directus.api.post(
        '/items/orders',
        {
          employee: directusUser.id,
          status: type,
          order_date: date,
          notes: note,
          delivery_instructions: deliveryInstructions,
          cc_email: email,
          supplier: supplierRef.current.id,
          farm_code: customerRef.current.id,
          category_type: category_type ? category_type.id : null,
          category_description: categoryDescription ? (categoryDescription.id ? categoryDescription.id : categoryDescription) : categoryDescription,
          due_date: selectedDate ? moment(new Date(selectedDate)).format('YYYY-MM-DD') : null
        },
        { send_supplier: send_supplier }
      );
    }

    //--------------------------------------------------------------------------------------------------------
    let addItems = [];
    let editItems = [];
    let delItems = [];
    let delItems2 = [];

    //eslint-disable-next-line array-callback-return
    itemsRef.current.map((data, index) => {
      if (data.statusValue === 3) {
        delItems = [...delItems, data.orders_items_id];
        delItems2 = [...delItems2, data.id];
      }
      if (data.statusValue === 2) {
        editItems = [
          ...editItems,
          {
            id: data.id,
            description: data.description,
            quantity: data.quantity,
            unit_price: data.unit_price,
            cc_email: data.cc_email,
            note: data.note,
            category_type: data.category_type ? data.category_type.id : null,
            category_description: data.category_description
              ? data.category_description.id
                ? data.category_description.id
                : data.category_description
              : data.category_description,
            sort: index,
            supplier_code: data.supplier_code
          }
        ];
      }
      if (data.statusValue === 1 && data.description) {
        // addItems = [...addItems, { id: uuidv4(), order: { id: orderResult.data.id} , order_item: { id: data.id, description: data.description, quantity: data.quantity, unit_price: data.unit_price, internal_code: '' } }];
        addItems = [
          ...addItems,
          {
            id: uuidv4(),
            order: { id: orderResult.data.id },
            order_item: {
              id: data.id,
              description: data.description,
              quantity: data.quantity,
              unit_price: data.unit_price,
              category_type: data.category_type ? data.category_type.id : null,
              category_description: data.category_description
                ? data.category_description.id
                  ? data.category_description.id
                  : data.category_description
                : data.category_description,
              sort: index,
              supplier_code: data.supplier_code
            }
          }
        ];
      }
    });

    if (delItems.length > 0) {
      await directus.deleteItems('orders_items', delItems);
      await directus.deleteItems('order_items', delItems2);
    }

    if (editItems.length > 0) {
      await directus.updateItems('order_items', editItems);
    }

    if (addItems.length > 0) {
      var addItemsBody = addItems;

      for (let index = 0; index < addItemsBody.length; index++) {
        var addItem = addItemsBody[index];
        delete addItem.id;
        delete addItem.order_item.id;

        var orderItemResult = await directus.createItems('order_items', addItem.order_item);
        addItem.order_item = { id: orderItemResult.data.id };

        addItemsBody[index] = addItem;
      }

      await directus.createItems('orders_items', addItemsBody);
    }

    if (uploadFilesRef.current.length > 0) {
      const promises = uploadFilesRef.current.map(async value => {
        if (value.formData) {
          let updateData = await directus.uploadFiles(value.formData, onUploadProgress);

          await directus.createItem('order_files', {
            status: 'published',
            order_id: orderResult.data.id,
            directus_file_id: updateData.data.id
          });
        }
      });

      await Promise.all(promises);
    }

    props.changeAdd(false);
    setLoadingSubmit(false);

    // props.loadOrders();
    EventEmitter.emit('load_orders', { value: true });
    EventEmitter.emit('setIsEdit', { value: false });
    var message = type === 'draft' ? 'Order has been saved as a draft' : 'Order has been sent';
    enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });

    if (custom_send) {
      setCustomEmailLoading(false);
      closeCustomEmail();
    }
  };

  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + '% Done');
  }

  const formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  const NumResultsHeader = ({ children, dataItem, collection_data, ...other }) => {
    const headerRef = React.useRef();
    const countRef = React.useRef();
    const paperRef = React.useRef();
    React.useEffect(() => {
      const numOptions = paperRef.current.querySelectorAll('li[data-option-index]').length;
      countRef.current.innerHTML = numOptions;
      if (numOptions > 0) {
        headerRef.current.style.display = 'block';
      } else {
        headerRef.current.style.display = 'none';
      }
    });

    var total_number = 0;

    if (collection_data) {
      total_number = collection_data.length;
    }

    return (
      <div style={{ background: 'white', borderRadius: '0 0 5px 5px' }}>
        <Paper {...other} ref={paperRef}>
          {children}
        </Paper>
        <div ref={headerRef} style={{ display: 'none', textAlign: 'right', padding: '5px' }}>
          Showing <span ref={countRef}></span> of {total_number}
        </div>
      </div>
    );
  };

  const getDescription = value => {
    var desc = [];
    var disableStatus = false;
    if (collection.length > 0) {
      //eslint-disable-next-line array-callback-return
      collection.map((category_value, index) => {
        if (category_value.settings.name === value.name) {
          if (category_value.settings.show_category && !category_value.settings.show_as_textbox) {
            var label_text =
              category_value.settings.name === 'Activity'
                ? 'Select Activity'
                : category_value.settings.name === 'R&M'
                  ? 'Select Equipment'
                  : 'Select';
            var requiredCategory = false;
            if (!categoryDescription) {
              if (value) {
                if (value.required) {
                  requiredCategory = true;
                }
              }
            } else if (!categoryDescription.id) {
              requiredCategory = true;
            }

            var collection_data =
              category_value.collection_index !== null
                ? collectionData[category_value.collection_index]
                  ? collectionData[category_value.collection_index].data
                  : []
                : [];
            desc.push(
              <Autocomplete
                key={index}
                id="size-small-standard"
                size="small"
                className={requiredCategory ? classes.requiredAutoComplete : ''}
                options={collection_data}
                getOptionLabel={option => option.name}
                style={{ marginRight: '10px' }}
                onChange={(event, newValue) => {
                  changeCategoryDescription(newValue);
                }}
                value={categoryDescription ? (categoryDescription.id ? categoryDescription : null) : null}
                // PaperComponent={NumResultsHeader}
                PaperComponent={({ children }) => (
                  <div>{NumResultsHeader({ children: children, className: 'MuiAutocomplete-paper', type: 'category_type', collection_data })}</div>
                )}
                renderOption={(props, option) => {
                  const matches = match(props.name, option.inputValue);
                  const parts = parse(props.name, matches);

                  return (
                    <div style={{ overflowWrap: 'anywhere' }}>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  );
                }}
                renderInput={params => <CssTextField {...params} variant="outlined" name="category_description" placeholder={label_text} />}
              />
            );
          } else if (category_value.settings.show_as_textbox) {
            var requiredCategory2 = false;
            if (!categoryDescription) {
              if (value) {
                if (value.required) {
                  requiredCategory2 = true;
                }
              }
            } else {
              if (categoryDescription.id) {
                requiredCategory2 = true;
              }
            }

            desc.push(
              <CssTextField
                key={index}
                fullWidth
                className={requiredCategory2 ? classes.textFieldRequired : classes.textField}
                name="category_description"
                variant="outlined"
                autoComplete="none"
                style={{ paddingRight: '10px' }}
                placeholder="Write Description"
                onChange={e => changeCategoryDescription(e.target.value)}
                value={categoryDescription ? (categoryDescription.id ? '' : categoryDescription) : ''}
              />
            );
          } else {
            disableStatus = true;
          }
        }
      });
    } else {
      disableStatus = true;
    }

    if (disableStatus) {
      // desc.push(
      //   <CssTextField
      //     key={"none"}
      //     disabled
      //     fullWidth
      //     name="category_description"
      //     variant="outlined"
      //     autoComplete="none"
      //     style={{ paddingRight: "10px" }}
      //   />
      // )
    }

    return <div>{desc}</div>;
  };

  const getItemDescription = dataItem => {
    var desc = [];
    var disableStatus = false;

    if (dataItem.category_type) {
      if (collection.length > 0) {
        //eslint-disable-next-line array-callback-return
        collection.map((category_value, index) => {
          if (category_value.settings.name === dataItem.category_type.name) {
            if (category_value.settings.show_category && !category_value.settings.show_as_textbox) {
              var label_text =
                category_value.settings.name === 'Activity'
                  ? 'Select Activity'
                  : category_value.settings.name === 'R&M'
                    ? 'Select Equipment'
                    : 'Select';
              var requiredCategory = false;
              if (!categoryDescription) {
                if (dataItem) {
                  if (dataItem.category_type) {
                    requiredCategory = dataItem.category_type.required;
                  }
                }
              }

              var collection_data =
                category_value.collection_index !== null
                  ? collectionData[category_value.collection_index]
                    ? collectionData[category_value.collection_index].data
                    : []
                  : [];
              desc.push(
                <Autocomplete
                  key={index}
                  id="size-small-standard"
                  size="small"
                  className={requiredCategory ? classes.requiredAutoComplete : ''}
                  options={collection_data}
                  getOptionLabel={option => option.name}
                  onChange={(event, newValue) => {
                    changeItems(null, dataItem, { name: 'category_description', value: newValue });
                  }}
                  value={dataItem.category_description ? (dataItem.category_description.id ? dataItem.category_description : null) : null}
                  PaperComponent={({ children }) => (
                    <div>
                      {NumResultsHeader({
                        children: children,
                        className: 'MuiAutocomplete-paper',
                        type: 'category_description',
                        dataItem,
                        collection_data
                      })}
                    </div>
                  )}
                  renderInput={params => (
                    <CssTextField
                      {...params}
                      // onChange={(e) => searchDescription(e, category_value, index)}
                      variant="outlined"
                      name="category_type"
                      placeholder={label_text}
                    />
                  )}
                  renderOption={(props, option) => {
                    const matches = match(props.name, option.inputValue);
                    const parts = parse(props.name, matches);

                    return (
                      <div style={{ overflowWrap: 'anywhere' }}>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    );
                  }}
                />
              );
            } else if (category_value.settings.show_as_textbox) {
              var requiredCategory2 = false;
              if (!dataItem.category_description) {
                if (dataItem.category_type) {
                  if (dataItem.category_type.required) {
                    requiredCategory2 = true;
                  }
                }
              } else {
                if (dataItem.category_description.id) {
                  requiredCategory2 = true;
                }
              }

              desc.push(
                <CssTextField
                  key={index}
                  fullWidth
                  className={requiredCategory2 ? classes.textFieldRequired : ''}
                  name="category_description"
                  variant="outlined"
                  autoComplete="none"
                  placeholder="Write Description"
                  onChange={e => changeItems(null, dataItem, { name: 'category_description', value: e.target.value })}
                  value={dataItem.category_description ? (dataItem.category_description.id ? null : dataItem.category_description) : null}
                />
              );
            } else {
              disableStatus = true;
            }
          }
        });
      } else {
        disableStatus = true;
      }
    } else {
      disableStatus = true;
    }

    return <div>{desc}</div>;
  };

  var disabledCheck = supplierRef.current === null || customerRef.current === null || category_type === null || itemCheck === false;
  if (category_type) {
    if (category_type.show_category || category_type.show_as_textbox) {
      if (category_type.required) {
        if (categoryDescription === null || categoryDescription === '') {
          disabledCheck = true;
        }
      }
    }
  }

  var disabledCheck_save_as_draft = supplierRef.current === null || customerRef.current === null || itemSaveAsDraftCheck === false;

  const stopPropagation = e => {
    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Home':
      case 'End':
        break;
      default:
        e.stopPropagation();
    }
  };

  const saveSupplier = e => {
    if (e.key === 'Enter') {
      if (suppliersRef.current && suppliersRef2.current) {
        if (suppliersRef.current.length > 0) {
          if (suppliersRef.current.length != suppliersRef2.current.length || suppliersRef2.current.length === 1) {
            setSupplier(suppliersRef.current[0]);
            setAnchorElSupplier(null);
            setSuppliers(suppliersRef2.current);
          }
        }
      }
    }
  };

  const saveCustomer = e => {
    if (e.key === 'Enter') {
      if (customersRef.current && customersRef2.current) {
        if (customersRef.current.length > 0) {
          if (customersRef.current.length != customersRef2.current.length || customersRef2.current.length === 1) {
            clickCustomer(customersRef.current[0]);
            setCustomers(customersRef2.current);
          }
        }
      }
    }
  };

  const saveCategory = e => {
    if (e.key === 'Enter') {
      if (categoryTypeDataRef.current && categoryTypeDataRef2.current) {
        if (categoryTypeDataRef.current.length > 0) {
          if (categoryTypeDataRef.current.length != categoryTypeDataRef2.current.length || categoryTypeDataRef2.current.length === 1) {
            changeCategory(categoryTypeDataRef.current[0]);
            setAnchorElCategory2(null);
            setCategoryTypeData(categoryTypeDataRef2.current);
          }
        }
      }
    }
  };

  const saveCategoryOrder = (e, dataItem) => {
    if (e.key === 'Enter') {
      if (categoryTypeDataRef.current && categoryTypeDataRef2.current) {
        if (categoryTypeDataRef.current.length > 0) {
          if (categoryTypeDataRef.current.length != categoryTypeDataRef2.current.length || categoryTypeDataRef2.current.length === 1) {
            changeItems(null, dataItem, { name: 'category_type', value: categoryTypeDataRef.current[0] }, true);
            setAnchorElCategory(null);
            setCategoryTypeData(categoryTypeDataRef2.current);
          }
        }
      }
    }
  };

  const checkKeyDown = (e, type) => {
    if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') {
      if (type === 1) {
        filterRef.current.focus();
      } else if (type === 2) {
        filter2Ref.current.focus();
      } else if (type === 3) {
        filter3Ref.current.focus();
      } else if (type === 4) {
        filter4Ref.current.focus();
      }
    }
  };

  const moveFocusToInput = (e, type) => {
    if (e.key === 'Tab' || e.key === 'ArrowRight') {
      e.stopPropagation();
      e.preventDefault();
      if (type === 1) {
        filterRef.current.focus();
      } else if (type === 2) {
        filter2Ref.current.focus();
      } else if (type === 3) {
        filter3Ref.current.focus();
      } else if (type === 4) {
        filter4Ref.current.focus();
      }
    }
  };

  const clickSupplierFavourite = data => {
    setIsClickFavourite(true);
    if (suppliersRef.current) {
      let suppliers_data = suppliersRef.current;
      const index = suppliers_data.map(o => o.id).indexOf(data.id);
      if (index >= 0) {
        var isFavourite = suppliers_data[index].isFavourite;
        suppliers_data[index].isFavourite = !isFavourite;
      }

      suppliers_data = Enumerable.from(suppliers_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      setSuppliers(suppliers_data);
    }
  };

  const searchSupplier = e => {
    var value = e.target.value.toLowerCase();
    if (value) {
      var result = Enumerable.from(suppliersRef2.current)
        .where(
          w =>
            (w.name ? w.name.toLowerCase().includes(value) : ''.toLowerCase().includes(value)) ||
            (w.code ? w.code.toLowerCase().includes(value) : ''.toLowerCase().includes(value))
        )
        .toArray();

      setSuppliers(result);
    } else {
      setSuppliers(suppliersRef2.current);
    }
  };

  const resetSupplier = async e => {
    setAnchorElSupplier(null);
    try {
      let suppliers_data2 = suppliersRef2.current;
      suppliers_data2 = Enumerable.from(suppliers_data2)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();
      setSuppliers(suppliers_data2);

      if (isClickFavouriteRef.current) {
        var fav_suppliers_data = Enumerable.from(suppliers_data2)
          .where(w => w.isFavourite === true)
          .select(data => data.id)
          .toArray();

        var fav_suppliers = [];
        var directus_employee = null;
        if (localStorage.directus_employee) {
          directus_employee = JSON.parse(localStorage.directus_employee);
          if (directus_employee.favourites_suppliers_po) {
            fav_suppliers = directus_employee.favourites_suppliers_po;
          }
        }

        var isUpdateFavourite = false;
        if (fav_suppliers.length === 0 && fav_suppliers_data.length === 0) {
          isUpdateFavourite = true;
        } else if (fav_suppliers.length !== fav_suppliers_data.length) {
          isUpdateFavourite = true;
        } else {
          isUpdateFavourite = true;
          fav_suppliers_data.map(value => {
            if (fav_suppliers.indexOf(value) >= 0) {
              isUpdateFavourite = false;
            }
          });

          if (!isUpdateFavourite) {
            isUpdateFavourite = true;
            fav_suppliers.map(value => {
              if (fav_suppliers_data.indexOf(value) >= 0) {
                isUpdateFavourite = false;
              }
            });
          }
        }

        if (isUpdateFavourite) {
          directus_employee.favourites_suppliers_po = fav_suppliers_data;
          localStorage.directus_employee = JSON.stringify(directus_employee);
          await directus.updateItem('employees', directus_employee.id, { favourites_suppliers_po: fav_suppliers_data });
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
    setIsClickFavourite(false);
  };

  const clickCustomerFavourite = async data => {
    var enable_click = false;
    if (data.selectable) {
      enable_click = true;
    }

    if (data.access_level) {
      if (data.access_level === 'po_creator') {
        if (!POCreatorPermission()) {
          enable_click = false;
        }
      } else if (data.access_level === 'po_approver') {
        if (!POApproverPermission()) {
          enable_click = false;
        }
      } else if (data.access_level === 'invoice_admin') {
        if (!InvoiceAdminPermission()) {
          enable_click = false;
        }
      } else if (data.access_level === 'invoice_admin') {
        if (!AdminPermission()) {
          enable_click = false;
        }
      }
    }

    if (AdminPermission()) {
      enable_click = true;
    }

    if (enable_click) {
      setIsClickFavourite(true);
      if (customersRef.current) {
        let customers_data = customersRef.current;
        const index = customers_data.map(o => o.id).indexOf(data.id);
        if (index >= 0) {
          var isFavourite = customers_data[index].isFavourite;
          customers_data[index].isFavourite = !isFavourite;
        }

        customers_data = Enumerable.from(customers_data)
          .orderBy(item => item.name)
          .orderByDescending(item => item.isFavourite)
          .toArray();

        setCustomers(customers_data);
      }
    }
  };

  const searchCustomer = e => {
    var value = e.target.value.toLowerCase();
    if (value) {
      var result = Enumerable.from(customersRef2.current)
        .where(
          w =>
            (w.name ? w.name.toLowerCase().includes(value) : ''.toLowerCase().includes(value)) ||
            (w.code ? w.code.toLowerCase().includes(value) : ''.toLowerCase().includes(value))
        )
        .toArray();

      setCustomers(result);
    } else {
      setCustomers(customersRef2.current);
    }
  };

  const resetCustomer = async e => {
    setAnchorElCustomer(null);
    try {
      let customers_data2 = customersRef2.current;
      customers_data2 = Enumerable.from(customers_data2)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();
      setCustomers(customers_data2);

      if (isClickFavouriteRef.current) {
        var fav_customers_data = Enumerable.from(customers_data2)
          .where(w => w.isFavourite === true)
          .select(data => data.id)
          .toArray();

        var fav_customers = [];
        var directus_employee = null;
        if (localStorage.directus_employee) {
          directus_employee = JSON.parse(localStorage.directus_employee);
          if (directus_employee.favourites_customers_po) {
            fav_customers = directus_employee.favourites_customers_po;
          }
        }

        var isUpdateFavourite = false;
        if (fav_customers.length === 0 && fav_customers_data.length === 0) {
          isUpdateFavourite = true;
        } else if (fav_customers.length !== fav_customers_data.length) {
          isUpdateFavourite = true;
        } else {
          isUpdateFavourite = true;
          fav_customers_data.map(value => {
            if (fav_customers.indexOf(value) >= 0) {
              isUpdateFavourite = false;
            }
          });

          if (!isUpdateFavourite) {
            isUpdateFavourite = true;
            fav_customers.map(value => {
              if (fav_customers_data.indexOf(value) >= 0) {
                isUpdateFavourite = false;
              }
            });
          }
        }

        if (isUpdateFavourite) {
          directus_employee.favourites_customers_po = fav_customers_data;
          localStorage.directus_employee = JSON.stringify(directus_employee);
          await directus.updateItem('employees', directus_employee.id, { favourites_customers_po: fav_customers_data });
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
    setIsClickFavourite(false);
  };

  // category favourite
  const clickCategoryFavourite = data => {
    setIsClickFavourite(true);
    if (categoryTypeDataRef.current) {
      let category_data = categoryTypeDataRef.current;
      const index = category_data.map(o => o.id).indexOf(data.id);
      if (index >= 0) {
        var isFavourite = category_data[index].isFavourite;
        category_data[index].isFavourite = !isFavourite;
      }

      category_data = Enumerable.from(category_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      setCategoryTypeData(category_data);
    }
  };

  const searchCategory = e => {
    var value = e.target.value.toLowerCase();
    if (value) {
      var result = Enumerable.from(categoryTypeDataRef2.current)
        .where(
          w =>
            (w.name ? w.name.toLowerCase().includes(value) : ''.toLowerCase().includes(value)) ||
            (w.code ? w.code.toLowerCase().includes(value) : ''.toLowerCase().includes(value))
        )
        .toArray();

      setCategoryTypeData(result);
    } else {
      setCategoryTypeData(categoryTypeDataRef2.current);
    }
  };

  const resetCategory = async e => {
    setAnchorElCategory(null);
    setAnchorElCategory2(null);
    try {
      let category_data2 = categoryTypeDataRef2.current;
      category_data2 = Enumerable.from(category_data2)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();
      setCategoryTypeData(category_data2);

      if (isClickFavouriteRef.current) {
        var fav_category_data = Enumerable.from(category_data2)
          .where(w => w.isFavourite === true)
          .select(data => data.id)
          .toArray();

        var fav_categories = [];
        var directus_employee = null;
        if (localStorage.directus_employee) {
          directus_employee = JSON.parse(localStorage.directus_employee);
          if (directus_employee.favourites_categories_po) {
            fav_categories = directus_employee.favourites_categories_po;
          }
        }

        var isUpdateFavourite = false;
        if (fav_categories.length === 0 && fav_category_data.length === 0) {
          isUpdateFavourite = true;
        } else if (fav_categories.length !== fav_category_data.length) {
          isUpdateFavourite = true;
        } else {
          isUpdateFavourite = true;
          fav_category_data.map(value => {
            if (fav_categories.indexOf(value) >= 0) {
              isUpdateFavourite = false;
            }
          });

          if (!isUpdateFavourite) {
            isUpdateFavourite = true;
            fav_categories.map(value => {
              if (fav_category_data.indexOf(value) >= 0) {
                isUpdateFavourite = false;
              }
            });
          }
        }

        if (isUpdateFavourite) {
          directus_employee.favourites_categories_po = fav_category_data;
          localStorage.directus_employee = JSON.stringify(directus_employee);
          await directus.updateItem('employees', directus_employee.id, { favourites_categories_po: fav_category_data });
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
    setIsClickFavourite(false);
  };

  const openCustomEmail = async () => {
    setIsCustomEmail(true);

    var send_to_email = '';
    var cc_email = '';

    if (supplierRef.current) {
      if (supplierRef.current.email) {
        send_to_email = supplierRef.current.email;
      }
    }

    var employee_data = await getEmployee();
    if (employee_data.email) {
      cc_email = employee_data.email;
    } else if (employee_data.user.email) {
      cc_email = employee_data.user.email;
    }

    setDataCustomEmail({ send_to_email: send_to_email, cc_email: cc_email });
  };

  const closeCustomEmail = () => {
    setIsCustomEmail(false);
    setDataCustomEmail({ send_to_email: null, cc_email: null });
  };

  const handleChangeInput = (e, type) => {
    const { name, value } = e.target;
    var check_value = null;
    if (value) {
      if (value === ' ') {
        check_value = '';
      } else {
        check_value = value.split(/[ ,]+/).join(';');
        check_value = check_value.split(/[;;,]+/).join(';');
      }
    }
    setDataCustomEmail({ ...dataCustomEmailRef.current, [name]: check_value });
  };

  const sendEmail = () => {
    setCustomEmailLoading(true);
    var cc_email = dataCustomEmailRef.current.cc_email;
    var send_to_email = dataCustomEmailRef.current.send_to_email;
    var cc_email_array = cc_email.split(';');
    var send_to_email_array = send_to_email.split(';');

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    var invalid_email = [];
    var invalid_email_count = 0;
    var invalid_email_text = '';
    /* eslint-disable array-callback-return*/
    cc_email_array.map(value => {
      if (value) {
        if (!value.match(mailformat)) {
          invalid_email.push(value);
          invalid_email_count++;
        }
      }
    });
    /* eslint-disable array-callback-return*/
    send_to_email_array.map(value => {
      if (value) {
        if (!value.match(mailformat)) {
          invalid_email.push(value);
          invalid_email_count++;
        }
      }
    });
    /* eslint-disable array-callback-return*/
    invalid_email.map((value, index) => {
      invalid_email_text += value;
      if (index < invalid_email.length - 1) {
        invalid_email_text += ', ';
      }
    });

    if (invalid_email_count > 0) {
      var error_message = 'Invalid email address' + (invalid_email_count > 1 ? 'es' : '');
      error_message += '\n' + invalid_email_text + '';
      enqueueSnackbar(error_message, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        style: { width: '300px', whiteSpace: 'pre-line' },
        autoHideDuration: 4200,
        action: CloseSnackbarAction
      });
      setCustomEmailLoading(false);
    } else {
      editSubmit(null, 'sent', false, true);
    }
  };

  const addToRefs = el => {
    if (el && !categoryListRef.current.includes(el)) {
      categoryListRef.current.push(el);
    }
  };

  const clickCustomer = async option => {
    var enable_click = false;
    if (option.selectable) {
      enable_click = true;
    }

    if (option.access_level) {
      if (option.access_level === 'po_creator') {
        if (!POCreatorPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'po_approver') {
        if (!POApproverPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'invoice_admin') {
        if (!InvoiceAdminPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'invoice_admin') {
        if (!AdminPermission()) {
          enable_click = false;
        }
      }
    }

    if (AdminPermission()) {
      enable_click = true;
    }

    if (enable_click) {
      var isSelected = true;
      if (supplierRef.current && option.suppliers && option.suppliers.length > 0) {
        var selected_supplier = Enumerable.from(option.suppliers)
          .where(x => x.supplier && x.supplier.id == supplierRef.current.id)
          .toArray();

        if (selected_supplier.length == 0) {
          isSelected = false;
          window.alert("Customer is not available for the supplier");
        }
      }

      if (isSelected) {
        setCustomer(option);
        setAnchorElCustomer(null);
      }
    }
  };

  const getCustomerMenuItem = (option, index) => {
    var enable_click = false;
    if (option.selectable) {
      enable_click = true;
    }

    if (option.access_level) {
      if (option.access_level === 'po_creator') {
        if (!POCreatorPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'po_approver') {
        if (!POApproverPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'invoice_admin') {
        if (!InvoiceAdminPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'invoice_admin') {
        if (!AdminPermission()) {
          enable_click = false;
        }
      }
    }

    if (AdminPermission()) {
      enable_click = true;
    }

    return (
      <MenuItem
        key={index}
        style={{
          height: 'auto',
          minHeight: '30px',
          paddingLeft: '10px',
          paddingRight: '10px',
          fontSize: '12px',
          width: '100%',
          borderTop:
            index !== 0
              ? customersRef.current[index].isFavourite === false && customersRef.current[index - 1].isFavourite === true
                ? '1px solid black'
                : '0px solid black'
              : '0px solid black'
        }}
        className={`menuitem_background ${enable_click === true ? '' : 'disabled'}`}
        selected={customerRef.current ? customerRef.current.id === option.id : false}
        onClick={e => {
          clickCustomer(option);
          setCustomers(customersRef2.current);
        }}
      >
        <div style={{ whiteSpace: 'break-spaces', display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <div
            style={{ width: 'calc(100% - 25px)', fontSize: '14px' }}
            onClick={e => {
              // clickCustomer(option);
              setCustomers(customersRef2.current);
            }}
          >
            {option.name}
          </div>
          <div style={{ width: '25px', alignSelf: 'center', textAlign: 'right' }} onClick={e => clickCustomerFavourite(option)}>
            {option.isFavourite ? <StarRoundedIcon fontSize="small" /> : <StarBorderRoundedIcon fontSize="small" />}
          </div>
        </div>
      </MenuItem>
    );
  };

  const onListKeyDown = (e, type, category_item_index, category_item_dataItem) => {
    if (e.key === 'Enter') {
      if (type === 'supplier') {
        setAnchorElSupplier(supplierListRef.current);
      } else if (type === 'customer') {
        setAnchorElCustomer(customerListRef.current);
      } else if (type === 'category_default') {
        setAnchorElCategory2(categoryListRef2.current);
      } else if (type === 'category_item') {
        setAnchorElCategory(categoryListRef.current[category_item_index]);
        setSelectedListItem(category_item_dataItem);
      }
    }
  };

  const handleSubmission = async (e, order) => {
    try {
      setLoadingFile(true);
      e.preventDefault();

      var file_data = document.getElementById('fileInput');
      const form = document.querySelector('#my-form');
      const data = new FormData(form);

      var current_files = uploadFilesRef.current;
      var new_upload_files = [];
      new_upload_files = [
        ...current_files.concat({
          name: file_data.files.item(0).name,
          formData: data
        })
      ];

      setUploadFiles(new_upload_files);
      // let updateData = await directus.uploadFiles(data, onUploadProgress);

      // await directus.createItem('order_files', {
      //   status: "published",
      //   order_id: order.id,
      //   directus_file_id: updateData.data.id
      // });

      // loadOrders();
      // setLoadingFile(false);
      // var message = 'File uploaded successfully';
      // enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200 });
    } catch (e) {
      setLoadingFile(false);
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
  };

  const clickSettings = e => {
    setAnchorElSettings(e.currentTarget);
  };

  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };

  const handleFileDelete = index => {
    var current_files = uploadFilesRef.current;
    var new_upload_files = [];

    current_files.map((value, file_index) => {
      if (file_index != index) {
        new_upload_files.push(value);
      }
    });

    setUploadFiles(new_upload_files);
  };

  const openSupplierPopup = () => {
    if (suppliersRef.current && customerRef.current) {
      if (suppliersRef.current.length > 0 && customerRef.current.suppliers) {
        if (customerRef.current.suppliers.length > 0) {
          var selected_supplier = Enumerable.from(customerRef.current.suppliers)
            .select(x => x.supplier.id).toArray();

          var new_selected_supplier = Enumerable.from(suppliersRef.current)
            .where(x => selected_supplier.includes(x.id))
            .toArray();

          setSuppliers(new_selected_supplier);
        }
      }
    }
    setAnchorElSupplier(supplierListRef.current);
  }

  return (
    <div>
      <div>
        <Paper elevation={0} className={classes.sentOrders}>
          <Grid container spacing={0} justifyContent="space-between" className={classes.orderlist}>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Typography style={{ fontWeight: 'bold', paddingTop: '5px' }}>Draft</Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Typography style={{ paddingTop: '5px' }}> {moment().format('DD/MM/YYYY')} </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              {supplierLoading ? (
                <div className={classes.linearProgress} style={{ marginRight: '10px', marginTop: '6px' }}>
                  <LinearProgress />
                </div>
              ) : (
                <div>
                  <List style={{ padding: '0px' }} component="nav" sx={{ bgcolor: 'background.paper' }}>
                    <ListItem
                      className={classes.selectDate}
                      style={{ padding: '0 4px 0 0' }}
                      aria-haspopup="listbox"
                      aria-controls="lock-menu"
                      aria-expanded={open2 ? 'true' : undefined}
                    >
                      <div
                        tabIndex={0}
                        ref={supplierListRef}
                        className={classes2.textInput}
                        onClick={e => openSupplierPopup()}
                        onKeyDown={e => onListKeyDown(e, 'supplier')}
                      >
                        <div
                          className={classes2.textInputValue}
                          style={{ color: supplierRef.current ? (supplierRef.current.name ? '#263238' : '#bdc1c3') : '#bdc1c3' }}
                        >
                          {supplierRef.current ? supplierRef.current.name ?? 'Select Supplier' : 'Select Supplier'}
                        </div>
                        <div style={{ height: '100%' }}>
                          <ArrowDropDownIcon className={classes2.arrowDropDown} />
                        </div>
                      </div>
                    </ListItem>
                  </List>
                  <Menu
                    id="lock-menu"
                    anchorEl={anchorElSupplier}
                    open={open2}
                    onClose={() => {
                      resetSupplier();
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'lock-button',
                      role: 'listbox',
                      style: { paddingTop: '0px' }
                    }}
                    PaperProps={{
                      style: {
                        maxWidth: '350px',
                        width: '350px',
                        maxHeight: '300px'
                      }
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    onKeyDown={e => checkKeyDown(e, 1)}
                  >
                    {suppliersRef.current ? (
                      <div
                        style={{ position: 'sticky', top: '0', zIndex: '9999', background: 'white', height: '40px' }}
                        onKeyDown={e => moveFocusToInput(e, 1)}
                      >
                        <TextField
                          autoFocus
                          inputRef={filterRef}
                          onKeyDown={e => {
                            stopPropagation(e);
                            saveSupplier(e);
                          }}
                          style={{ width: '100%', padding: '5px 10px 0', height: '45px' }}
                          onChange={e => searchSupplier(e)}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    {suppliersRef.current
                      ? suppliersRef.current.map((option, index) => (
                        <MenuItem
                          key={index}
                          style={{
                            height: 'auto',
                            minHeight: '30px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            fontSize: '12px',
                            width: '100%',
                            borderTop:
                              index !== 0
                                ? suppliersRef.current[index].isFavourite === false && suppliersRef.current[index - 1].isFavourite === true
                                  ? '1px solid black'
                                  : '0px solid black'
                                : '0px solid black'
                          }}
                          selected={supplierRef.current ? supplierRef.current.id === option.id : false}
                          onClick={e => {
                            setSupplier(option);
                            setAnchorElSupplier(null);
                            setSuppliers(suppliersRef2.current);
                          }}
                        >
                          <div style={{ whiteSpace: 'break-spaces', display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <div
                              style={{ width: 'calc(100% - 25px)', fontSize: '14px' }}
                              onClick={e => {
                                setSupplier(option);
                                setAnchorElSupplier(null);
                                setSuppliers(suppliersRef2.current);
                              }}
                            >
                              {option.name}
                            </div>
                            <div style={{ width: '25px', alignSelf: 'center', textAlign: 'right' }} onClick={e => clickSupplierFavourite(option)}>
                              {option.isFavourite ? <StarRoundedIcon fontSize="small" /> : <StarBorderRoundedIcon fontSize="small" />}
                            </div>
                          </div>
                        </MenuItem>
                      ))
                      : null}
                  </Menu>
                </div>
              )}
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              {customerLoading ? (
                <div className={classes.linearProgress} style={{ marginRight: '10px', marginTop: '6px' }}>
                  <LinearProgress />
                </div>
              ) : (
                <div>
                  <List style={{ padding: '0px' }} component="nav" sx={{ bgcolor: 'background.paper' }}>
                    <ListItem
                      className={classes.selectDate}
                      style={{ padding: '0 4px 0 0' }}
                      aria-haspopup="listbox"
                      aria-controls="lock-menu"
                      aria-expanded={open2 ? 'true' : undefined}
                    >
                      <div
                        tabIndex={0}
                        ref={customerListRef}
                        className={classes2.textInput}
                        onClick={e => setAnchorElCustomer(customerListRef.current)}
                        onKeyDown={e => onListKeyDown(e, 'customer')}
                      >
                        <div
                          className={classes2.textInputValue}
                          style={{ color: customerRef.current ? (customerRef.current.name ? '#263238' : '#bdc1c3') : '#bdc1c3' }}
                        >
                          {customerRef.current ? customerRef.current.name ?? 'Select Customer' : 'Select Customer'}
                        </div>
                        <div style={{ height: '100%' }}>
                          <ArrowDropDownIcon className={classes2.arrowDropDown} />
                        </div>
                      </div>
                    </ListItem>
                  </List>
                  <Menu
                    id="lock-menu"
                    anchorEl={anchorElCustomer}
                    open={open3}
                    onClose={() => {
                      resetCustomer();
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'lock-button',
                      role: 'listbox',
                      style: { paddingTop: '0px' }
                    }}
                    PaperProps={{
                      style: {
                        maxWidth: '350px',
                        width: '350px',
                        maxHeight: '300px'
                      }
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    onKeyDown={e => checkKeyDown(e, 2)}
                  >
                    {customersRef.current ? (
                      <div
                        style={{ position: 'sticky', top: '0', zIndex: '9999', background: 'white', height: '40px' }}
                        onKeyDown={e => moveFocusToInput(e, 2)}
                      >
                        <TextField
                          autoFocus
                          inputRef={filter2Ref}
                          onKeyDown={e => {
                            stopPropagation(e);
                            saveCustomer(e);
                          }}
                          style={{ width: '100%', padding: '5px 10px 0', height: '45px' }}
                          onChange={e => searchCustomer(e)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {customersRef.current ? customersRef.current.map((option, index) => getCustomerMenuItem(option, index)) : null}
                  </Menu>

                  {customerRef.current ?
                    customerRef.current.name ? customerRef.current.legal_name ?
                      <Typography id="order_customer" style={{ paddingRight: "5px", marginTop: "10px" }}>
                        <b>Invoice To:</b> {customerRef.current.legal_name}
                      </Typography>
                      : '' : '' : ''}
                </div>
              )}
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <Paper elevation={0} style={status} className={classes.statusarea}>
                <Typography>Draft</Typography>
              </Paper>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} style={{ display: 'inline-flex' }}>
              <Paper elevation={0} style={status} className={classes.amountarea}>
                <Paper elevation={0} className={classes.amountdetail}>
                  <Typography style={{ color: '#333', fontWeight: '600' }}>
                    {isNaN(totalOrderItemRef.current) ? 0 : '$' + formatNumber(totalOrderItemRef.current)}
                  </Typography>
                </Paper>
              </Paper>
              <IconButton size="medium" className={classes.KeyboardArrowDownIcon} onClick={() => props.changeAdd(false)}>
                <CancelIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            justifyContent="space-between"
            style={{ backgroundColor: '#8AA9CA', height: '40px' }}
            className={classes.categorydetail}
          >
            <Grid className={classes.internalcategory} style={{ width: '16%' }}>
              <Typography>INTERNAL</Typography>
            </Grid>
            <Grid className={classes.internalcategory} style={{ width: '12%' }}>
              <Typography>Category</Typography>
            </Grid>
            <Grid item style={{ width: '30%' }}>
              {categoryTypeLoading ? (
                <div className={classes.linearProgress} style={{ marginRight: '10px', marginTop: '6px' }}>
                  <LinearProgress />
                </div>
              ) : (
                <div>
                  <List style={{ padding: '0px' }} component="nav" sx={{ bgcolor: 'background.paper' }}>
                    <ListItem
                      className={classes.selectDate}
                      style={{ padding: '0 4px 0 0' }}
                      aria-haspopup="listbox"
                      aria-controls="lock-menu"
                      aria-expanded={open4 ? 'true' : undefined}
                    >
                      <div
                        tabIndex={0}
                        ref={categoryListRef2}
                        className={classes2.textInput2}
                        style={{ borderColor: category_type ? (category_type.name ? '' : 'red') : 'red' }}
                        onClick={e => setAnchorElCategory2(categoryListRef2.current)}
                        onKeyDown={e => onListKeyDown(e, 'category_default')}
                      >
                        <div
                          className={classes2.textInputValue}
                          style={{ color: categoryTypeDataRef.current ? (categoryTypeDataRef.current.name ? '#263238' : '#bdc1c3') : '#bdc1c3' }}
                        >
                          {category_type ? (category_type.name ? category_type.name : 'Select Category') : 'Select Category'}
                        </div>
                        <div style={{ height: '100%' }}>
                          <ArrowDropDownIcon className={classes2.arrowDropDown} />
                        </div>
                      </div>
                    </ListItem>
                  </List>

                  <Menu
                    id="lock-menu"
                    anchorEl={anchorElCategory2}
                    open={open5}
                    onClose={() => {
                      resetCategory();
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'lock-button',
                      role: 'listbox',
                      style: { paddingTop: '0px' }
                    }}
                    PaperProps={{
                      style: {
                        maxWidth: '350px',
                        width: '350px',
                        maxHeight: '300px'
                      }
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    onKeyDown={e => checkKeyDown(e, 4)}
                  >
                    {categoryTypeDataRef.current ? (
                      <div
                        style={{ position: 'sticky', top: '0', zIndex: '9999', background: 'white', height: '40px' }}
                        onKeyDown={e => moveFocusToInput(e, 4)}
                      >
                        <TextField
                          autoFocus
                          inputRef={filter4Ref}
                          onKeyDown={e => {
                            stopPropagation(e);
                            saveCategory(e);
                          }}
                          style={{ width: '100%', padding: '5px 10px 0', height: '45px' }}
                          onChange={e => searchCategory(e)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {categoryTypeDataRef.current
                      ? categoryTypeDataRef.current.map((option, index) => (
                        <MenuItem
                          key={index}
                          style={{
                            height: 'auto',
                            minHeight: '30px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            fontSize: '12px',
                            width: '100%',
                            borderTop:
                              index !== 0
                                ? categoryTypeDataRef.current[index].isFavourite === false &&
                                  categoryTypeDataRef.current[index - 1].isFavourite === true
                                  ? '1px solid black'
                                  : '0px solid black'
                                : '0px solid black'
                          }}
                          selected={category_type ? category_type.id === option.id : false}
                          onClick={e => {
                            changeCategory(option);
                            setAnchorElCategory2(null);
                            setCategoryTypeData(categoryTypeDataRef2.current);
                          }}
                        >
                          <div style={{ whiteSpace: 'break-spaces', display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <div
                              style={{ width: 'calc(100% - 25px)', fontSize: '14px' }}
                              onClick={e => {
                                changeCategory(option);
                                setAnchorElCategory2(null);
                                setCategoryTypeData(categoryTypeDataRef2.current);
                              }}
                            >
                              {option.name}
                            </div>
                            <div style={{ width: '25px', alignSelf: 'center', textAlign: 'right' }} onClick={e => clickCategoryFavourite(option)}>
                              {option.isFavourite ? <StarRoundedIcon fontSize="small" /> : <StarBorderRoundedIcon fontSize="small" />}
                            </div>
                          </div>
                        </MenuItem>
                      ))
                      : null}
                  </Menu>
                </div>
              )}
            </Grid>
            <Grid className={classes.internalcategory} style={{ width: '12%' }}>
              <Typography>Details</Typography>
            </Grid>
            <Grid item style={{ width: '30%' }}>
              {categoryTypeLoading ? (
                <div className={classes.linearProgress} style={{ marginRight: '10px', marginTop: '6px' }}>
                  <LinearProgress />
                </div>
              ) : category_type ? (
                getDescription(category_type)
              ) : null}
            </Grid>
          </Grid>

          <Grid container spacing={0} justifyContent="space-between" style={status} className={classes.orderitemdetail}>
            <Grid item className={classes.orderitem_header} style={{ width: '10%' }}>
              <Typography className={classes.orderitem_header_left} style={{ marginLeft: '18px' }}>
                Qty
              </Typography>
            </Grid>
            <Grid item className={classes.orderitem_header} style={{ width: '12%' }}>
              <Typography className={classes.orderitem_header_left} style={{ marginLeft: '12px' }}>
                Supplier Code
              </Typography>
            </Grid>
            <Grid item className={classes.orderitem_header} style={{ width: '28%' }}>
              <Typography className={classes.orderitem_header_left} style={{ marginLeft: '12px' }}>
                Description
              </Typography>
            </Grid>
            <Grid item className={classes.orderitem_header} style={{ width: '12%' }}>
              <Typography className={classes.orderitem_header_left} style={{ marginRight: '18px', textAlign: 'right' }}>
                Unit Price
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.itemcategory_header}
              style={{ borderBottomLeftRadius: itemsRef.current ? (itemsRef.current.length > 0 ? '0px' : '8px') : '8px' }}
            >
              <Typography className={classes.orderitem_header_right}>Item Category</Typography>
            </Grid>
            <Grid
              item
              className={classes.itemdetails_header}
              style={{ borderBottomRightRadius: itemsRef.current ? (itemsRef.current.length > 0 ? '0px' : '8px') : '8px' }}
            >
              <Typography className={classes.orderitem_header_right}>Item Details</Typography>
            </Grid>
            <Grid item className={classes.itemclose}></Grid>
          </Grid>

          {itemsRef.current
            ? //eslint-disable-next-line array-callback-return
            itemsRef.current.map((dataItem, index) => {
              if (dataItem.statusValue !== 3) {
                return (
                  <div key={index}>
                    <Grid
                      container
                      spacing={0}
                      justifyContent="space-around"
                      style={{ backgroundColor: '#D4E3F2' }}
                      className={classes.itemarea}
                      key={index}
                    >
                      <Grid item style={{ width: '10%', paddingLeft: '6px' }}>
                        <Paper elevation={0} className={classes.itemcolumn} style={{ marginRight: '6px' }}>
                          <CssTextField
                            className={
                              dataItem.quantity && dataItem.quantity !== '0' && dataItem.quantity !== '' && dataItem.quantity > 0
                                ? classes.textField
                                : classes.textFieldRequired
                            }
                            fullWidth
                            type="number"
                            variant="outlined"
                            name="quantity"
                            autoComplete="none"
                            value={dataItem.quantity ? dataItem.quantity : ''}
                            onChange={event => changeItems(event, dataItem)}
                          />
                        </Paper>
                      </Grid>
                      <Grid item style={{ width: '12%' }}>
                        <Paper elevation={0} className={classes.itemcolumn} style={{ marginRight: '6px' }}>
                          <CssTextField
                            className={classes.textField}
                            fullWidth
                            name="supplier_code"
                            variant="outlined"
                            autoComplete="none"
                            value={dataItem.supplier_code ? dataItem.supplier_code : ''}
                            onChange={event => changeItems(event, dataItem)}
                          />
                        </Paper>
                      </Grid>
                      <Grid item style={{ width: '28%' }}>
                        <Paper elevation={0} className={classes.itemcolumn} style={{ marginRight: '6px' }}>
                          <CssTextField
                            className={dataItem.description ? classes.textField : classes.textFieldRequired}
                            fullWidth
                            name="description"
                            variant="outlined"
                            autoComplete="none"
                            value={dataItem.description ? dataItem.description : ''}
                            onChange={event => changeItems(event, dataItem)}
                          />
                        </Paper>
                      </Grid>
                      <Grid item style={{ width: '12%' }}>
                        <Paper elevation={0} style={status} className={classes.unitpricecolumn}>
                          <CssTextField
                            className={`${classes.unitprice_input} ${classes.textField}`}
                            fullWidth
                            variant="outlined"
                            value={dataItem.unit_price ? parseFloat(dataItem.unit_price).toFixed(2) : ''}
                            onChange={event => changeItems(event, dataItem)}
                            name="unit_price"
                            autoComplete="none"
                            id="formatted-numberformat-input"
                            InputProps={{
                              inputComponent: NumberFormatCustom
                            }}
                          />
                        </Paper>
                      </Grid>
                      <Grid item style={{ width: '17%' }}>
                        <Paper elevation={0} className={classes.itemcolumn}>
                          <div
                            className={classes.itemcategory_content}
                            style={{ borderBottomLeftRadius: index === itemsRef.current.length - 1 ? '6px' : '0px' }}
                          >
                            {categoryTypeLoading ? (
                              <div className={classes.linearProgress} style={{ marginRight: '10px', marginTop: '6px' }}>
                                <LinearProgress />
                              </div>
                            ) : (
                              <div>
                                <List style={{ padding: '0px' }} component="nav" sx={{ bgcolor: 'background.paper' }}>
                                  <ListItem
                                    className={classes.selectDate}
                                    style={{ padding: '0 4px 0 0' }}
                                    aria-haspopup="listbox"
                                    aria-controls="lock-menu"
                                    aria-expanded={open4 ? 'true' : undefined}
                                  >
                                    <div
                                      key={index}
                                      tabIndex={0}
                                      ref={addToRefs}
                                      className={classes2.textInput2}
                                      style={{
                                        borderColor: dataItem.category_type ? (dataItem.category_type.name ? '' : 'red') : 'red',
                                        marginRight: '0px'
                                      }}
                                      onClick={e => {
                                        setAnchorElCategory(categoryListRef.current[index]);
                                        setSelectedListItem(dataItem);
                                      }}
                                      onKeyDown={e => onListKeyDown(e, 'category_item', index, dataItem)}
                                    >
                                      <div
                                        className={classes2.textInputValue}
                                        style={{
                                          color: categoryTypeDataRef.current
                                            ? categoryTypeDataRef.current.name
                                              ? '#263238'
                                              : '#bdc1c3'
                                            : '#bdc1c3'
                                        }}
                                      >
                                        {dataItem.category_type ? dataItem.category_type.name : 'Select Category'}
                                      </div>
                                      <div style={{ height: '100%' }}>
                                        <ArrowDropDownIcon className={classes2.arrowDropDown} />
                                      </div>
                                    </div>
                                  </ListItem>
                                </List>

                                <Menu
                                  id="lock-menu"
                                  anchorEl={anchorElCategory}
                                  open={open4}
                                  onClose={() => {
                                    resetCategory();
                                  }}
                                  MenuListProps={{
                                    'aria-labelledby': 'lock-button',
                                    role: 'listbox',
                                    style: { paddingTop: '0px' }
                                  }}
                                  PaperProps={{
                                    style: {
                                      maxWidth: '350px',
                                      width: '350px',
                                      maxHeight: '300px'
                                    }
                                  }}
                                  getContentAnchorEl={null}
                                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                  onKeyDown={e => checkKeyDown(e, 3)}
                                >
                                  {categoryTypeDataRef.current ? (
                                    <div
                                      style={{ position: 'sticky', top: '0', zIndex: '9999', background: 'white', height: '40px' }}
                                      onKeyDown={e => moveFocusToInput(e, 3)}
                                    >
                                      <TextField
                                        autoFocus
                                        inputRef={filter3Ref}
                                        onKeyDown={e => {
                                          stopPropagation(e);
                                          saveCategoryOrder(e, dataItem);
                                        }}
                                        style={{ width: '100%', padding: '5px 10px 0', height: '45px' }}
                                        onChange={e => searchCategory(e)}
                                      />
                                    </div>
                                  ) : (
                                    ''
                                  )}

                                  {categoryTypeDataRef.current
                                    ? categoryTypeDataRef.current.map((option, index) => (
                                      <MenuItem
                                        key={index}
                                        style={{
                                          height: 'auto',
                                          minHeight: '30px',
                                          paddingLeft: '10px',
                                          paddingRight: '10px',
                                          fontSize: '12px',
                                          width: '100%',
                                          borderTop:
                                            index !== 0
                                              ? categoryTypeDataRef.current[index].isFavourite === false &&
                                                categoryTypeDataRef.current[index - 1].isFavourite === true
                                                ? '1px solid black'
                                                : '0px solid black'
                                              : '0px solid black'
                                        }}
                                        selected={dataItem.category_type ? dataItem.category_type.id === option.id : false}
                                        onClick={e => {
                                          changeItems(null, dataItem, { name: 'category_type', value: option }, true);
                                          setAnchorElCategory(null);
                                          setCategoryTypeData(categoryTypeDataRef2.current);
                                        }}
                                      >
                                        <div
                                          style={{ whiteSpace: 'break-spaces', display: 'flex', width: '100%', justifyContent: 'space-between' }}
                                        >
                                          <div
                                            style={{ width: 'calc(100% - 25px)', fontSize: '14px' }}
                                            onClick={e => {
                                              changeItems(null, dataItem, { name: 'category_type', value: option }, true);
                                              setAnchorElCategory(null);
                                              setCategoryTypeData(categoryTypeDataRef2.current);
                                            }}
                                          >
                                            {option.name}
                                          </div>
                                          <div
                                            style={{ width: '25px', alignSelf: 'center', textAlign: 'right' }}
                                            onClick={e => clickCategoryFavourite(option)}
                                          >
                                            {option.isFavourite ? (
                                              <StarRoundedIcon fontSize="small" />
                                            ) : (
                                              <StarBorderRoundedIcon fontSize="small" />
                                            )}
                                          </div>
                                        </div>
                                      </MenuItem>
                                    ))
                                    : null}
                                </Menu>
                              </div>
                            )}
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item className={classes.itemdetails}>
                        <div
                          className={classes.itemdetails_content}
                          style={{ borderBottomRightRadius: index === itemsRef.current.length - 1 ? '6px' : '0px' }}
                        >
                          {categoryTypeLoading ? (
                            <div className={classes.linearProgress} style={{ marginRight: '10px', marginTop: '6px' }}>
                              <LinearProgress />
                            </div>
                          ) : dataItem.category_type ? (
                            getItemDescription(dataItem)
                          ) : null}
                        </div>
                      </Grid>
                      <Grid item className={classes.itemclose}>
                        <IconButton size="medium" className={classes.CancelIconButton} onClick={() => deleteItems(dataItem)}>
                          <CancelIcon id="iconButton" style={{ fontSize: 30 }} className={classes.CancelIcon} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                );
              }
            })
            : null
            // : <span><CircularProgress size={20} /></span>
          }

          <Grid item xs={12} style={{ backgroundColor: '#D4E3F2', borderRadius: '0px 0px 8px 8px' }} className={classes.itemarealast}>
            <IconButton className={classes.add} onClick={addItems} style={{ marginLeft: '15px' }}>
              <AddCircleIcon fontSize="small" className={classes.iconAdd} style={{ fontSize: 36 }} />
            </IconButton>
          </Grid>

          <Grid container spacing={1} justifyContent="space-between" className={classes.orderFooter}>
            <Grid item xs={2} md={2} lg={2}>
              {/* <CssTextField
              className={classes.cc_email}
              variant="outlined"
              name="email"
              placeholder="Delivery by: Select Date"
              onChange={handleChange}
              autoComplete='none'
              fullWidth
              type={email}
              value={list.cc_email}
            /> */}
              <div style={{ display: 'flex', border: '1px solid rgba(142,142,147,0.3)', borderRadius: '10px', height: '35px' }}>
                <div
                  style={{
                    marginRight: '10px',
                    fontFamily: 'Manrope',
                    lineHeight: '35px',
                    marginLeft: '10.82px',
                    fontSize: '14px',
                    overflow: 'hidden'
                  }}
                >
                  Delivery by:
                </div>
                <div style={{ width: 'calc(100% - 100px)' }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id="date_picker"
                      style={{ marginTop: '4px' }}
                      format="dd/MM/yyyy"
                      placeholder="dd/MM/yyyy"
                      value={moment(selectedDate).format()}
                      onChange={date => handleDateChange(date)}
                    // margin="normal"
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <TextareaAutosize
                minRows={2}
                maxRows={2}
                variant="outlined"
                name="note"
                placeholder="Notes: Jot an internal note here..."
                onChange={handleChange}
                style={{
                  maxWidth: '100%',
                  minWidth: '100%',
                  maxHeight: '54%',
                  minHeight: '35px',
                  borderRadius: '8px',
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                  fontFamily: 'Manrope',
                  padding: '7px 14px',
                  height: '32px',
                  fontSize: '14px'
                }}
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <TextareaAutosize
                minRows={2}
                maxRows={2}
                variant="outlined"
                name="delivery_instructions"
                placeholder="Delivery Instructions: Jot something done"
                onChange={handleChange}
                style={{
                  maxWidth: '100%',
                  minWidth: '100%',
                  maxHeight: '54%',
                  minHeight: '35px',
                  borderRadius: '8px',
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                  fontFamily: 'Manrope',
                  padding: '7px 14px',
                  height: '32px',
                  fontSize: '14px'
                }}
              />
            </Grid>
            <Grid item xs={5} md={4} lg={4}>
              <div
                style={{
                  textAlign: 'end',
                  justifyContent: 'flex-end',
                  display: 'flex',
                  flexWrap: 'wrap',
                  rowGap: '5px',
                  maxWidth: 'fit-content',
                  marginLeft: 'auto'
                }}
              >
                {loadingSubmit ? (
                  <span display="inline-flex" style={{ marginTop: '5px' }}>
                    <CircularProgress size={20} />
                  </span>
                ) : (
                  <>
                    <form id="my-form" className={classes.form}>
                      <input type="file" name="file" id="fileInput" onChange={e => handleSubmission(e, list)} className={classes.input} />
                      <label htmlFor="fileInput">
                        <Button
                          id="attach_file_btn"
                          name="file"
                          variant="contained"
                          className={classes.buttonadditem2}
                          // disabled={disabledCheck}
                          component="span"
                        >
                          ATTACH FILE
                        </Button>
                      </label>
                    </form>

                    <Button
                      variant="contained"
                      className={classes.buttonadditem2}
                      onClick={() => editSubmit(list.id, 'draft')}
                      disabled={disabledCheck_save_as_draft}
                    >
                      SAVE AS DRAFT
                    </Button>

                    <Button
                      variant="contained"
                      className={classes.buttonadditem2}
                      onClick={() => editSubmit(list.id, 'sent')}
                      disabled={disabledCheck}
                    >
                      SUBMIT
                    </Button>
                    {supplierRef.current ? (
                      supplierRef.current.id ? (
                        supplierRef.current.email ? (
                          <Button
                            id="submit_btn"
                            variant="contained"
                            className={classes.buttonadditem2}
                            onClick={() => editSubmit(list.id, 'sent', true)}
                            disabled={disabledCheck}
                          >
                            SUBMIT AND SEND
                          </Button>
                        ) : null
                      ) : list.supplier ? (
                        list.supplier.email ? (
                          <Button
                            id="submit_btn"
                            variant="contained"
                            className={classes.buttonadditem2}
                            onClick={() => editSubmit(list.id, 'sent', true)}
                            disabled={disabledCheck}
                          >
                            SUBMIT AND SEND
                          </Button>
                        ) : null
                      ) : null
                    ) : null}

                    {supplierRef.current ? (
                      supplierRef.current.id ? (
                        supplierRef.current.email ? (
                          <Button
                            id="submit_btn"
                            variant="contained"
                            className={classes.buttonadditem2}
                            onClick={() => openCustomEmail()}
                            disabled={disabledCheck}
                          >
                            SUBMIT AND CUSTOM SEND
                          </Button>
                        ) : null
                      ) : list.supplier ? (
                        list.supplier.email ? (
                          <Button
                            id="submit_btn"
                            variant="contained"
                            className={classes.buttonadditem2}
                            onClick={() => openCustomEmail()}
                            disabled={disabledCheck}
                          >
                            SUBMIT AND CUSTOM SEND
                          </Button>
                        ) : null
                      ) : null
                    ) : null}

                    {/* <Button
                      id="demo-customized-button"
                      className={classes.buttonadditem2}
                      aria-controls={openSettings ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openSettings ? 'true' : undefined}
                      variant="contained"
                      disableElevation
                      onClick={clickSettings}
                      endIcon={<KeyboardArrowDownIcon />}
                      disabled={disabledCheck}
                    >
                      Options
                    </Button> */}
                  </>
                )}

                {/* <Menu
                  anchorEl={anchorElSettings}
                  id="account-menu"
                  open={openSettings}
                  onClose={handleCloseSettings}
                  onClick={handleCloseSettings}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={() => editSubmit(list.id, 'draft')}>
                    <div style={{ display: "flex" }}>
                      <div style={{ minWidth: "25px", maxWidth: "25px" }}>
                        <SaveAsIcon style={{ fontSize: "18px" }} />
                      </div>
                      Save as Draft
                    </div>
                  </MenuItem>

                  <Divider />
                  <MenuItem onClick={() => editSubmit(list.id, 'sent')}>
                    <div style={{ display: "flex" }}>
                      <div style={{ minWidth: "25px", maxWidth: "25px" }}>
                        <SendIcon style={{ fontSize: "17px" }} />
                      </div>
                      Submit
                    </div>
                  </MenuItem>

                  {supplierRef.current ? supplierRef.current.id ? supplierRef.current.email ?
                    <MenuItem onClick={() => editSubmit(list.id, 'sent', true)}>
                      <div style={{ display: "flex" }}>
                        <div style={{ minWidth: "25px", maxWidth: "25px" }}>
                          <SendIcon style={{ fontSize: "17px" }} />
                        </div>
                        Submit and Send
                      </div>
                    </MenuItem>
                    : null : list.supplier ? list.supplier.email ?
                      <MenuItem onClick={() => editSubmit(list.id, 'sent', true)}>
                        <div style={{ display: "flex" }}>
                          <div style={{ minWidth: "25px", maxWidth: "25px" }}>
                            <SendIcon style={{ fontSize: "17px" }} />
                          </div>
                          Submit and Send
                        </div>
                      </MenuItem>
                      : null : null : null
                  }
                  {supplierRef.current ? supplierRef.current.id ? supplierRef.current.email ?
                    <MenuItem onClick={() => openCustomEmail()}>
                      <div style={{ display: "flex" }}>
                        <div style={{ minWidth: "25px", maxWidth: "25px" }}>
                          <SendIcon style={{ fontSize: "17px" }} />
                        </div>
                        Submit and Custom Send
                      </div>
                    </MenuItem>
                    : null : list.supplier ? list.supplier.email ?
                      <MenuItem onClick={() => openCustomEmail()}>
                        <div style={{ display: "flex" }}>
                          <div style={{ minWidth: "25px", maxWidth: "25px" }}>
                            <SendIcon style={{ fontSize: "17px" }} />
                          </div>
                          Submit and Custom Send
                        </div>
                      </MenuItem>
                      : null : null : null
                  }
                </Menu> */}

                {/* {loadingSubmit ?
                  <span><CircularProgress size={20} /></span>
                  :
                  <div style={{ textAlign: 'end', justifyContent: "flex-end", display: "flex", flexWrap: "wrap", rowGap: "5px" }}>

                    <Button
                      variant="contained"
                      className={classes.buttonadditem2}
                      onClick={() => editSubmit(list.id, 'draft')}
                      disabled={disabledCheck}
                    >
                      SAVE AS DRAFT
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.buttonadditem2}
                      onClick={() => editSubmit(list.id, 'sent')}
                      disabled={disabledCheck}
                    >
                      SUBMIT
                    </Button>
                    {supplierRef.current ? supplierRef.current.id ? supplierRef.current.email ?
                      <Button
                        id="submit_btn"
                        variant="contained"
                        className={classes.buttonadditem2}
                        onClick={() => editSubmit(list.id, 'sent', true)}
                        disabled={disabledCheck}
                      >
                        SUBMIT AND SEND
                      </Button>
                      : null : list.supplier ? list.supplier.email ?
                        <Button
                          id="submit_btn"
                          variant="contained"
                          className={classes.buttonadditem2}
                          onClick={() => editSubmit(list.id, 'sent', true)}
                          disabled={disabledCheck}
                        >
                          SUBMIT AND SEND
                        </Button>
                        : null : null : null
                    }

                    {supplierRef.current ? supplierRef.current.id ? supplierRef.current.email ?
                      <Button
                        id="submit_btn"
                        variant="contained"
                        className={classes.buttonadditem2}
                        onClick={() => openCustomEmail()}
                        disabled={disabledCheck}
                      >
                        SUBMIT AND CUSTOM SEND
                      </Button>
                      : null : list.supplier ? list.supplier.email ?
                        <Button
                          id="submit_btn"
                          variant="contained"
                          className={classes.buttonadditem2}
                          onClick={() => openCustomEmail()}
                          disabled={disabledCheck}
                        >
                          SUBMIT AND CUSTOM SEND
                        </Button>
                        : null : null : null
                    }

                  </div>
                } */}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', paddingTop: '5px' }}>
              <Grid container style={{ flex: '60%' }}>
                {uploadFilesRef.current.length > 0
                  ? uploadFilesRef.current.map((file, index) => {
                    return (
                      <Grid key={index} span="true" style={{ display: 'flex' }}>
                        <Chip
                          icon={<InsertDriveFileIcon />}
                          label={file.name}
                          // onClick={(id) => handleClick(file.directus_file_id.data.full_url)}
                          onDelete={() => handleFileDelete(index)}
                          variant="outlined"
                          style={{ marginRight: '5px' }}
                        />
                      </Grid>
                    );
                  })
                  : ''}
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Dialog open={isCustomEmailRef.current} onClose={closeCustomEmail}>
          <DialogTitle disableTypography={true} id="form-dialog-title">
            <Typography component="div" variant="h6">
              <h2>Submit and Custom Send</h2>
            </Typography>
          </DialogTitle>
          <DialogContent style={{ width: '450px' }}>
            <TextField
              id="email_text"
              label="Email"
              variant="outlined"
              fullWidth={true}
              name="send_to_email"
              value={dataCustomEmailRef.current.send_to_email}
              placeholder={'user1@gmail.com;user2@gmail.com'}
              onChange={handleChangeInput}
            />
            <br />
            <br />
            <TextField
              id="cc_email_text"
              label="CC Email"
              variant="outlined"
              fullWidth={true}
              name="cc_email"
              value={dataCustomEmailRef.current.cc_email}
              placeholder={'user1@gmail.com;user2@gmail.com'}
              onChange={handleChangeInput}
            />
          </DialogContent>

          <DialogActions style={{ height: '60px', marginRight: '5px' }}>
            {customEmailLoading ? (
              <CircularProgress className={classes.circular} />
            ) : (
              <div>
                <CancelButton id="cancel_btn" size="small" variant="contained" onClick={closeCustomEmail}>
                  Cancel
                </CancelButton>
                <SubmitButton
                  id="save_btn"
                  size="small"
                  variant="contained"
                  onClick={() => sendEmail()}
                  disabled={!dataCustomEmailRef.current.send_to_email}
                >
                  Save
                </SubmitButton>
              </div>
            )}
          </DialogActions>
        </Dialog>
        <div style={{ height: 12 + 'px' }}></div>
      </div>
    </div>
  );
};

export default AddOrder;
