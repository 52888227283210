//export const Permission = (user.role.name === 'Administrator' || user.role.name === 'DigiTime Admin' || user.role.name === 'Company Admin'); 
const user = JSON.parse(window.localStorage.getItem('directus_user'));
const employee = JSON.parse(window.localStorage.getItem('directus_employee'));
const configuration = JSON.parse(window.localStorage.getItem('configuration'));

export function AdminPermission() {
  if (user && employee) {
    if (user.role) {
      return (employee.user.role.name === "Super Admin");
    }
  }
  return false;
}

export function InvoiceAdminPermission() {
  if (user) {
    return (employee.user.role.name === "Invoice Admin" || AdminPermission());
  }
  else {
    return false;
  }
}

export function POApproverPermission() {
  if (user) {
    return (employee.user.role.name === "PO Approver" || InvoiceAdminPermission() || AdminPermission());
  }
  else {
    return false;
  }
}

export function POApproverPermissionOnly() {
  if (user) {
    return (employee.user.role.name === "PO Approver" && !InvoiceAdminPermission());
  }
  else {
    return false;
  }
}

export function POCreatorPermission() {
  if (user) {
    return (employee.user.role.name === "PO Creator" || POApproverPermission || InvoiceAdminPermission() || AdminPermission());
  }
  else {
    return false;
  }
}

export function ShowInvoiceTab() {
  if (configuration) {
    return (configuration.show_invoices === true ? true : false);
  }
  else {
    return false;
  }
}