import React, { useState, useEffect } from 'react';
import {
  withStyles,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Divider,
  Button,
  Typography,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Chip,
  Checkbox,
  InputAdornment,
  LinearProgress,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import directus from '../../services/directus';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import EditIcon from '@material-ui/icons/Edit';
import EventEmitter from 'src/utils/EventEmitter';
import useStateRef from "react-usestateref";
import { useStylesEmployees, AddButton } from './style.js';
import linq from "linq";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { removeCache } from "../../utils/KeyValueCache";
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'red',
      backgroundColor: 'white',
      borderRadius: '6px',
      height: '53.63px'
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
      marginTop: '8px'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: "translate(14px, -12px) scale(0.75)"
    }
  },
})(TextField);

const SubmitButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
})(Button);

const CancelButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

const pageOptions = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
  },
];

let page = 1;

var searchTimeout;
const Customers = (props) => {
  /* eslint-disable no-unused-vars*/
  const classes = useStylesEmployees();
  const [initialized, setInitialized] = useState(false);
  const [Customers, setCustomers] = useState(null);

  const [employees, setEmployees] = useState(null);
  const [approversLevel1, setApproversLevel1, approversLevel1Ref] = useStateRef([]);
  const [approversLevel2, setApproversLevel2, approversLevel2Ref] = useStateRef([]);

  const [suppliers, setSuppliers, suppliersRef] = useStateRef(null);
  const [selectedSupplier, setSelectedSupplier, selectedSupplierRef] = useStateRef([]);

  const [searchCus, setSearchCus] = useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = React.useState(null);
  const [totalPage, setTotalPage] = useState('');
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(100);

  const [dataCustomer, setDataCustomer, dataCustomerRef] = useStateRef({
    id: '', code: '', name: '', accounting_system: '', account_code: '', abn_number: '', status: 'published',
    approvers_level1: [], approvers_level2: [], bypass_approval: false,
    suppliers: [],
    delivery_address_line_1: '', delivery_address_line_2: '', delivery_city: '', delivery_state: '', delivery_post_code: '',
    not_synced: false, access_level: ''
  })

  const [mode, setMode] = useState('');
  const [accountSystem, setAccountSystem] = React.useState('');
  const [accessLevel, setAccessLevel] = React.useState('');

  const [accountingSystemData, setAccountingSystemData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [search, setSearch] = React.useState('');
  const [searchingLoading, setSearchingLoading] = useState(false);

  let disable = dataCustomerRef.current.name === '' || dataCustomerRef.current.code === '';

  const [sortField, setSortField, sortFieldRef] = useStateRef({
    "name": { type: "desc", value: false },
    "email": { type: "desc", value: false },
    "acc_system": { type: "desc", value: false },
    "acc_code": { type: "desc", value: false },
    "abn_number": { type: "desc", value: false }
  });

  const [sortLoading, setSortLoading, sortLoadingRef] = useStateRef(false);
  const [loadingExport, setLoadingExport] = React.useState(false);

  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadAccountingSystem();
      loadFirst();
      loadEmployee();
      loadSuppliers();
    }

    const searchCustomer = (eventData) => {
      page = 1;
      setSearchCus(eventData.text);
      loadCustomers(eventData.text);
    }

    const listener = EventEmitter.addListener('searchCustomer', searchCustomer);

    return () => {
      listener.remove();
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadFirst = () => {
    var sort_data = localStorage.sort_data;
    if (sort_data) {
      sort_data = JSON.parse(sort_data);
      if (sort_data.customers) {
        setSortField(sort_data.customers)
      }
    }
    loadSuppliers();
  }

  const loadAccountingSystem = async () => {
    try {
      var account_system = await directus.getItems('accounting_systems');
      setAccountingSystemData(account_system.data);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
  };

  /* eslint-disable array-callback-return*/
  const loadCustomers = async (data) => {
    let filterData;

    var name_sort = sortFieldRef.current.name;
    var email_sort = sortFieldRef.current.email;
    var acc_code_sort = sortFieldRef.current.acc_code;
    var abn_number_sort = sortFieldRef.current.abn_number;
    var acc_system_sort = sortFieldRef.current.acc_system;

    var orderByFieldCustom = 'customers.name';

    if (name_sort.value) {
      if (name_sort.type === "asc") { orderByFieldCustom = "customers.name"; }
      else if (name_sort.type === "desc") { orderByFieldCustom = "-customers.name"; }
    }

    if (email_sort.value) {
      if (email_sort.type === "asc") { orderByFieldCustom = "customers.email"; }
      else if (email_sort.type === "desc") { orderByFieldCustom = "-customers.email"; }
    }

    if (acc_code_sort.value) {
      if (acc_code_sort.type === "asc") { orderByFieldCustom = "customers.account_code"; }
      else if (acc_code_sort.type === "desc") { orderByFieldCustom = "-customers.account_code"; }
    }

    if (abn_number_sort.value) {
      if (abn_number_sort.type === "asc") { orderByFieldCustom = "customers.abn_number"; }
      else if (abn_number_sort.type === "desc") { orderByFieldCustom = "-customers.abn_number"; }
    }

    if (acc_system_sort.value) {
      if (acc_system_sort.type === "asc") { orderByFieldCustom = "accounting_systems.name"; }
      else if (acc_system_sort.type === "desc") { orderByFieldCustom = "-accounting_systems.name"; }
    }

    var fields = `*, 
    approvers_level1.employee.user.*, approvers_level1.employee.*, approvers_level1.*, 
    approvers_level2.employee.user.*, approvers_level2.employee.*, approvers_level2.*, 
    accounting_system.*, 
    suppliers.id, suppliers.status, 
    suppliers.supplier.id, suppliers.supplier.code, suppliers.supplier.name`;

    if (search) {
      filterData = {
        fields: fields,
        meta: "filter_count",
        limit: pageNumberRef.current,
        q: search,
        page: page,
        sort: orderByFieldCustom
      }
    }
    else {
      filterData = {
        fields: fields,
        meta: "filter_count",
        limit: pageNumberRef.current,
        page: page,
        sort: orderByFieldCustom
      };
    }
    try {
      var CustomersResults = await directus.api.get('/custom/settings/customers', filterData);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }

    CustomersResults.data.map((customerResult, x) => {
      customerResult.approvers_level1.map((approversLevel1Result, y) => {
        if (approversLevel1Result.employee === null) {
          CustomersResults.data[x].approvers_level1.splice(y, 1);
        }
      })

      customerResult.approvers_level2.map((approversLevel2Result, y) => {
        if (approversLevel2Result.employee === null) {
          CustomersResults.data[x].approvers_level2.splice(y, 1);
        }
      })
    })

    setCustomers(CustomersResults.data);
    setTotalPage(CustomersResults.meta.filter_count);


    let SortOrder;
    let sort_type = '';
    let sort_by_data = '';
    let changeUpperCase = false;

    if (name_sort.value) {
      sort_by_data = 'name';
      sort_type = name_sort.type;
      changeUpperCase = true;
    } else if (email_sort.value) {
      sort_by_data = 'email';
      sort_type = email_sort.type;
      changeUpperCase = true;
    } else if (acc_code_sort.value) {
      sort_by_data = 'account_code';
      sort_type = acc_code_sort.type;
      changeUpperCase = true;
    } else if (abn_number_sort.value) {
      sort_by_data = 'abn_number';
      sort_type = abn_number_sort.type;
      changeUpperCase = true;
    } else if (acc_system_sort.value) {
      sort_by_data = 'accounting_system_name';
      sort_type = acc_system_sort.type;
      changeUpperCase = true;
    } else {
      sort_by_data = 'name';
      sort_type = 'asc';
      changeUpperCase = true;
    }


    SortOrder = CustomersResults.data;
    SortOrder.map((value, index) => {
      var accounting_system_name = "";
      if (value.accounting_system !== null) {
        if (value.accounting_system.name) {
          accounting_system_name = value.accounting_system.name;
        }
      }
      SortOrder[index].accounting_system_name = accounting_system_name;
    })

    SortOrder = linq.from(SortOrder)
      .orderBy(o => o.name)
      .toArray();

    if (sort_by_data) {
      if (sort_type === "desc") {
        SortOrder.sort(function (a, b) {
          let nameA = a[sort_by_data] ? a[sort_by_data] : '';
          let nameB = b[sort_by_data] ? b[sort_by_data] : '';
          if (changeUpperCase) {
            nameA = nameA ? nameA.toUpperCase() : nameA; // ignore upper and lowercase
            nameB = nameB ? nameB.toUpperCase() : nameB; // ignore upper and lowercase
          }

          if (nameA > nameB) { return -1; }
          if (nameA < nameB) { return 1; }
          return 0; // names must be equal
        });
      } else {
        SortOrder.sort(function (a, b) {
          let nameA = a[sort_by_data] ? a[sort_by_data] : '';
          let nameB = b[sort_by_data] ? b[sort_by_data] : '';
          if (changeUpperCase) {
            nameA = nameA ? nameA.toUpperCase() : nameA; // ignore upper and lowercase
            nameB = nameB ? nameB.toUpperCase() : nameB; // ignore upper and lowercase
          }

          if (nameA < nameB) { return -1; }
          if (nameA > nameB) { return 1; }
          return 0; // names must be equal
        });
      }
    }

    if (SortOrder) {
      setCustomers(SortOrder);
    }

    setSearchingLoading(false);
    setSortLoading(false);
  };

  const loadEmployee = async () => {
    try {
      var EmployeesResults = await directus.getItems('employees', {
        fields: 'user.*, *',
        limit: -1,
        page: page,
        filter: { status: { eq: 'published' }, type: { neq: null } },
        sort: "code"
      });

      setEmployees(EmployeesResults.data);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
  };

  const loadSuppliers = async () => {
    try {
      var results = await directus.getItems('suppliers', {
        fields: 'id, code, name',
        limit: -1,
        page: page,
        filter: { status: { eq: 'published' } },
        sort: "code"
      });

      setSuppliers(results.data);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }

    loadCustomers();
  };

  const pageChange = (value) => {
    page = page + value;
    loadCustomers(searchCus);
  }

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    page = 1;
    loadCustomers(searchCus);
  };

  const handleClickOpen = (Customer, mode) => {
    setOpen(true);

    if (mode === 'edit') {
      setMode('edit');
      setDataCustomer({
        id: Customer.id, code: Customer.code, name: Customer.name, email: Customer.email, accounting_system: Customer.accounting_system, account_code: Customer.account_code, abn_number: Customer.abn_number, status: Customer.status,
        approvers_level1: Customer.approvers_level1, approvers_level2: Customer.approvers_level2, bypass_approval: Customer.bypass_approval,
        suppliers: Customer.suppliers,
        delivery_address_line_1: Customer.delivery_address_line_1, delivery_address_line_2: Customer.delivery_address_line_2, delivery_city: Customer.delivery_city, delivery_state: Customer.delivery_state, delivery_post_code: Customer.delivery_post_code, legal_name: Customer.legal_name,
        not_synced: Customer.not_synced
      })
      setAccountSystem(Customer.accounting_system);

      if (Customer.access_level === "po_creator") {
        setAccessLevel({ value: "po_creator", name: "PO Creator" })
      } else if (Customer.access_level === "po_approver") {
        setAccessLevel({ value: "po_approver", name: "PO Approver" })
      } else if (Customer.access_level === "invoice_admin") {
        setAccessLevel({ value: "invoice_admin", name: "Invoice Admin" })
      } else if (Customer.access_level === "super_admin") {
        setAccessLevel({ value: "super_admin", name: "Super Admin" })
      }




      if (Customer.approvers_level1.length > 0) {
        let result = [];
        Customer.approvers_level1.map(data => {
          result = [...result, data.employee];
        })

        setApproversLevel1(result);
      }
      else { setApproversLevel1([]); }

      if (Customer.approvers_level2.length > 0) {
        let result = [];
        Customer.approvers_level2.map(data => {
          result = [...result, data.employee];
        })

        setApproversLevel2(result);
      }
      else { setApproversLevel2([]); }

      if (Customer.suppliers.length > 0) {
        let result = [];
        Customer.suppliers.map(data => {
          result = [...result, data.supplier];
        })

        setSelectedSupplier(result);
      }
      else { setSelectedSupplier([]); }


      setTitle(Customer.name);
    }
    else if (mode === 'add') {
      setMode('add');
      setTitle('Add Customer');
      setApproversLevel1([]);
      setApproversLevel2([]);
      setSelectedSupplier([]);
      setDataCustomer({
        id: '', code: '', name: '', email: '', accounting_system: '', account_code: '', abn_number: '', status: 'published',
        approvers_level1: [], approvers_level2: [], bypass_approval: false,
        suppliers: [],
        delivery_address_line_1: '', delivery_address_line_2: '', delivery_city: '', delivery_state: '', delivery_post_code: '', legal_name: ''
      })
      setAccessLevel(null);
    }
    else {
      setMode('delete');
      setDataCustomer({
        id: Customer.id, code: Customer.code, name: Customer.name, email: Customer.email, accounting_system: Customer.accounting_system, account_code: Customer.account_code, abn_number: Customer.abn_number, status: Customer.status,
        approvers_level1: Customer.approvers_level1, approvers_level2: Customer.approvers_level2, bypass_approval: Customer.bypass_approval,
        suppliers: Customer.suppliers,
        delivery_address_line_1: Customer.delivery_address_line_1, delivery_address_line_2: Customer.delivery_address_line_2, delivery_city: Customer.delivery_city, delivery_state: Customer.delivery_state, delivery_post_code: Customer.delivery_post_code, legal_name: Customer.legal_name
      })
      setTitle('Delete ' + Customer.name);
    }

  };

  const handleClose = () => {
    setOpen(false);
    setAccountSystem('');
    setDataCustomer({
      id: '', code: '', name: '', email: '', accounting_system: '', account_code: '', abn_number: '', status: 'published',
      approvers_level1: [], approvers_level2: [], bypass_approval: false,
      suppliers: [],
      delivery_address_line_1: '', delivery_address_line_2: '', delivery_city: '', delivery_state: '', delivery_post_code: '', legal_name: ''
    })
    setAccessLevel(null);
  };


  const handleChangeInput = (e, type) => {
    if (type === "checkbox") {
      const { name, checked } = e.target;
      setDataCustomer({ ...dataCustomerRef.current, [name]: checked })
    } else if (type === "accounting_system") {
      setAccountSystem(e);
    } else if (type === "access_level") {
      setAccessLevel(e);
    } else if (type === "email") {
      const { name, value } = e.target;
      var check_value = null;
      if (value) {
        if (value === " ") {
          check_value = "";
        } else {
          check_value = value.split(/[ ,]+/).join(';');
          check_value = check_value.split(/[;;,]+/).join(';');
        }
      }
      setDataCustomer({ ...dataCustomerRef.current, [name]: check_value })
    } else {
      const { name, value } = e.target;
      setDataCustomer({ ...dataCustomerRef.current, [name]: value })
    }
  }

  const SaveCustomer = async () => {
    setLoading(true);

    let createData = [];
    let merge = '';
    if (mode === 'edit') {
      var data_customer = dataCustomerRef.current;
      // Add Approvers Level 1 ===============================================================================================================
      approversLevel1Ref.current.map(data => {
        const filterInput = linq.from(data_customer.approvers_level1)
          .where(x => x.employee.id === data.id).toArray();
        if (filterInput.length === 0) {
          merge = [...merge, data.id];
          createData = [...createData, { employee: data.id, customer: data_customer.id, status: 'published' }];
        }
      })

      if (createData.length > 0) {
        try {
          await directus.createItems('customers_approvers_level1', createData);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }

      // Delete Approvers Level 1 ===============================================================================================================
      let deleteApproversLevel1Data = '';

      data_customer.approvers_level1.map(customer => {
        const filterInput = linq.from(approversLevel1Ref.current)
          .where(x => x.id === customer.employee.id).toArray();
        if (filterInput.length === 0) {
          merge = [...merge, customer.employee.id];
          if (deleteApproversLevel1Data === '') {
            deleteApproversLevel1Data = customer.employee.id;
          }
          else {
            deleteApproversLevel1Data = customer.employee.id + ',' + deleteApproversLevel1Data;
          }
        }
      })

      let deleteApproversLevel1Input = [];
      var CustomerApproversLevel1;

      if (deleteApproversLevel1Data !== '') {
        try {
          CustomerApproversLevel1 = await directus.getItems('customers_approvers_level1', {
            fields: '*',
            filter: {
              customer: { eq: data_customer.id },
              employee: { in: deleteApproversLevel1Data }
            }
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }

        CustomerApproversLevel1.data.map(data => {
          deleteApproversLevel1Input = [...deleteApproversLevel1Input, data.id]
        })

        try {
          await directus.deleteItems('customers_approvers_level1', deleteApproversLevel1Input);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }



      createData = [];
      merge = '';

      // Add Approvers Level 2 ===============================================================================================================
      approversLevel2Ref.current.map(data => {
        const filterInput = linq.from(data_customer.approvers_level2)
          .where(x => x.employee.id === data.id).toArray();
        if (filterInput.length === 0) {
          merge = [...merge, data.id];
          createData = [...createData, { employee: data.id, customer: data_customer.id, status: 'published' }];
        }
      })

      if (createData.length > 0) {
        try {
          await directus.createItems('customers_approvers_level2', createData);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }

      // Delete Approvers Level 2 ===============================================================================================================
      let deleteApproversLevel2Data = '';

      data_customer.approvers_level2.map(customer => {
        const filterInput = linq.from(approversLevel2Ref.current)
          .where(x => x.id === customer.employee.id).toArray();
        if (filterInput.length === 0) {
          merge = [...merge, customer.employee.id];
          if (deleteApproversLevel2Data === '') {
            deleteApproversLevel2Data = customer.employee.id;
          }
          else {
            deleteApproversLevel2Data = customer.employee.id + ',' + deleteApproversLevel2Data;
          }
        }
      })

      let deleteApproversLevel2Input = [];
      var CustomerApproversLevel2;

      if (deleteApproversLevel2Data !== '') {
        try {
          CustomerApproversLevel2 = await directus.getItems('customers_approvers_level2', {
            fields: '*',
            filter: {
              customer: { eq: data_customer.id },
              employee: { in: deleteApproversLevel2Data }
            }
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }

        CustomerApproversLevel2.data.map(data => {
          deleteApproversLevel2Input = [...deleteApproversLevel2Input, data.id]
        })

        try {
          await directus.deleteItems('customers_approvers_level2', deleteApproversLevel2Input);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }


      try {
        var checkEmployee = await directus.getItems('employees', {
          fields: 'user.*,user.role.*,*',
          filter: { id: { in: merge } },
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }

      changeUserRole(checkEmployee.data, 0);


      // Customers - Suppliers - Start ===============================================================================================================
      createData = [];
      merge = '';


      // Link Supplier to Customer ===============================================================================================================
      selectedSupplierRef.current.map(data => {
        const filterInput = linq.from(data_customer.suppliers)
          .where(x => x.supplier.id === data.id).toArray();
        if (filterInput.length === 0) {
          createData = [...createData, { supplier: data.id, customer: data_customer.id, status: 'published' }];
        }
      })

      if (createData.length > 0) {
        try {
          await directus.createItems('customers_suppliers', createData);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }

      // Unlink Supplier from Customer ===============================================================================================================
      let deleteSupplierData = '';

      if (data_customer.suppliers) {
        data_customer.suppliers.map(customer => {
          const filterInput = linq.from(selectedSupplierRef.current)
            .where(x => x.id === customer.supplier.id).toArray();
          if (filterInput.length === 0) {
            merge = [...merge, customer.supplier.id];
            if (deleteSupplierData === '') {
              deleteSupplierData = customer.supplier.id;
            }
            else {
              deleteSupplierData = customer.supplier.id + ',' + deleteSupplierData;
            }
          }
        })
      }


      let deleteSuppliersInput = [];
      var CustomerSupplier;

      if (deleteSupplierData !== '') {
        try {
          CustomerSupplier = await directus.getItems('customers_suppliers', {
            fields: '*',
            filter: {
              customer: { eq: data_customer.id },
              supplier: { in: deleteSupplierData }
            }
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }

        CustomerSupplier.data.map(data => {
          deleteSuppliersInput = [...deleteSuppliersInput, data.id]
        })


        try {
          await directus.deleteItems('customers_suppliers', deleteSuppliersInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }

      // Customers - Suppliers - End ===============================================================================================================

      var access_level = null;
      if (accessLevel) {
        if (accessLevel.value) {
          access_level = accessLevel.value;
        }
      }
      try {
        await directus.updateItem('customers', data_customer.id,
          {
            name: data_customer.name, email: data_customer.email, code: data_customer.code, accounting_system: accountSystem ? accountSystem.id : '', account_code: data_customer.account_code, abn_number: data_customer.abn_number,
            delivery_address_line_1: data_customer.delivery_address_line_1,
            delivery_address_line_2: data_customer.delivery_address_line_2,
            delivery_city: data_customer.delivery_city,
            delivery_post_code: data_customer.delivery_post_code,
            delivery_state: data_customer.delivery_state,
            legal_name: data_customer.legal_name,
            bypass_approval: data_customer.bypass_approval,
            access_level: access_level
          }
        );
        EventEmitter.emit("loadCustomer", { value: true });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }

    }
    else if (mode === 'add') {
      var data_customer = dataCustomerRef.current;
      try {
        var access_level = null;
        if (accessLevel) {
          if (accessLevel.value) {
            access_level = accessLevel.value;
          }
        }

        var addCustomers = await directus.createItems('customers', {
          code: data_customer.code,
          name: data_customer.name,
          email: data_customer.email,
          accounting_system: accountSystem.id,
          account_code: data_customer.account_code,
          abn_number: data_customer.abn_number,
          delivery_address_line_1: data_customer.delivery_address_line_1,
          delivery_address_line_2: data_customer.delivery_address_line_2,
          delivery_city: data_customer.delivery_city,
          delivery_post_code: data_customer.delivery_post_code,
          delivery_state: data_customer.delivery_state,
          legal_name: data_customer.legal_name,
          bypass_approval: data_customer.bypass_approval,
          not_synced: true,
          access_level: access_level,
          status: 'published'
        });
        EventEmitter.emit("loadCustomer", { value: true });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }


      //================================== Add Customers Approvers Level 1 Relations ===================================//
      let createDataApproversLevel1 = [];
      if (approversLevel1Ref.current.length > 0) {
        approversLevel1Ref.current.map(data => {
          const filterInput = linq.from(dataCustomerRef.current.approvers_level1)
            .where(x => x.employee.id === data.id).toArray();
          if (filterInput.length === 0) {
            createDataApproversLevel1 = [...createDataApproversLevel1, { employee: data.id, customer: addCustomers.data.id, status: 'published' }];
          }
        })

        if (createDataApproversLevel1.length > 0) {
          try {
            await directus.createItems('customers_approvers_level1', createDataApproversLevel1);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
          }

        }
      }

      //================================== Add Customers Approvers Level 2 Relations ===================================//
      let createDataApproversLevel2 = [];
      if (approversLevel2Ref.current.length > 0) {
        approversLevel2Ref.current.map(data => {
          const filterInput = linq.from(dataCustomerRef.current.approvers_level2)
            .where(x => x.employee.id === data.id).toArray();
          if (filterInput.length === 0) {
            createDataApproversLevel2 = [...createDataApproversLevel2, { employee: data.id, customer: addCustomers.data.id, status: 'published' }];
          }
        })

        if (createDataApproversLevel2.length > 0) {
          try {
            await directus.createItems('customers_approvers_level2', createDataApproversLevel2);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
          }

        }
      }

      //================================== Link Supplier to Customer ===================================//
      let createDataSuppliers = [];
      if (selectedSupplierRef.current.length > 0) {
        selectedSupplierRef.current.map(data => {
          const filterInput = linq.from(selectedSupplierRef.current.approvers_level2)
            .where(x => x.supplier.id === data.id).toArray();
          if (filterInput.length === 0) {
            createDataSuppliers = [...createDataSuppliers, { supplier: data.id, customer: addCustomers.data.id, status: 'published' }];
          }
        })

        if (createDataSuppliers.length > 0) {
          try {
            await directus.createItems('customers_suppliers', createDataSuppliers);
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
          }

        }
      }


    }
    else {
      //================================== Delete Approvers Level 1 ===================================//
      let deleteApproversLevel1Data = '';

      dataCustomerRef.current.approvers_level1.map(customer => {
        const filterInput = linq.from(approversLevel1Ref.current)
          .where(x => x.id === customer.employee.id).toArray();
        if (filterInput.length === 0) {
          if (deleteApproversLevel1Data === '') {
            deleteApproversLevel1Data = customer.employee.id;
          }
          else {
            deleteApproversLevel1Data = customer.employee.id + ',' + deleteApproversLevel1Data;
          }
        }
      })

      let deleteApproversLevel1Input = [];
      var CustomerApproversLevel1;

      if (deleteApproversLevel1Data !== '') {
        try {
          CustomerApproversLevel1 = await directus.getItems('customers_approvers_level1', {
            fields: '*',
            filter: {
              customer: { eq: dataCustomerRef.current.id },
              employee: { in: deleteApproversLevel1Data }
            }
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }

        CustomerApproversLevel1.data.map(data => {
          deleteApproversLevel1Input = [...deleteApproversLevel1Input, data.id]
        })

        try {
          await directus.deleteItems('customers_approvers_level1', deleteApproversLevel1Input);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }

      // Delete Approvers Level 2 ===============================================================================================================
      let deleteApproversLevel2Data = '';

      dataCustomerRef.current.approvers_level2.map(customer => {
        const filterInput = linq.from(approversLevel2Ref.current)
          .where(x => x.id === customer.employee.id).toArray();
        if (filterInput.length === 0) {
          if (deleteApproversLevel2Data === '') {
            deleteApproversLevel2Data = customer.employee.id;
          }
          else {
            deleteApproversLevel2Data = customer.employee.id + ',' + deleteApproversLevel2Data;
          }
        }
      })

      let deleteApproversLevel2Input = [];
      var CustomerApproversLevel2;

      if (deleteApproversLevel2Data !== '') {
        try {
          CustomerApproversLevel2 = await directus.getItems('customers_approvers_level2', {
            fields: '*',
            filter: {
              customer: { eq: dataCustomerRef.current.id },
              employee: { in: deleteApproversLevel2Data }
            }
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }

        CustomerApproversLevel2.data.map(data => {
          deleteApproversLevel2Input = [...deleteApproversLevel2Input, data.id]
        })

        try {
          await directus.deleteItems('customers_approvers_level2', deleteApproversLevel2Input);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }

      // Unlink customers from supplier ===============================================================================================================
      let deleteSuppliersData = '';

      dataCustomerRef.current.suppliers.map(supplier => {
        const filterInput = linq.from(selectedSupplierRef.current)
          .where(x => x.id === supplier.supplier.id).toArray();
        if (filterInput.length === 0) {
          if (deleteSuppliersData === '') {
            deleteSuppliersData = supplier.supplier.id;
          }
          else {
            deleteSuppliersData = supplier.supplier.id + ',' + deleteSuppliersData;
          }
        }
      })

      let deleteCustomerInput = [];
      var CustomerSuppliersData;

      if (deleteSuppliersData !== '') {
        try {
          CustomerSuppliersData = await directus.getItems('customers_suppliers', {
            fields: '*',
            filter: {
              customer: { eq: dataCustomerRef.current.id },
              supplier: { in: deleteSuppliersData }
            }
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }

        CustomerSuppliersData.data.map(data => {
          deleteCustomerInput = [...deleteCustomerInput, data.id]
        })

        try {
          await directus.deleteItems('customers_suppliers', deleteCustomerInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }

      try {
        await directus.deleteItem('customers', dataCustomerRef.current.id);
        EventEmitter.emit("loadCustomer", { value: true });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }
    }

    // Remove customers cache
    removeCache('customers');

    loadCustomers(searchCus ? searchCus : '');
    setLoading(false);
    handleClose();

    var message = 'Data updated successfully';
    enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
  }

  const changeUserRole = async (data, count) => {
    let start = count;
    if (count < data.length) {
      if (data[count].customers && data[count].customers.length === 0 && data[count].user.role.id === 4) {
        try {
          await directus.updateUser(data[count].user.id, {
            role: 3
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }
      else if (data[count].customers && data[count].customers.length > 0 && data[count].user.role.id === 3) {
        try {
          await directus.updateUser(data[count].user.id, {
            role: 4
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }

      }

      changeUserRole(data, start + 1);
    }
    else {
      loadCustomers(searchCus ? searchCus : '');
      setLoading(false);
      handleClose();
    }
  }
  /* eslint-enable array-callback-return*/

  const handleChangeSearching = (e) => {
    setSearch(e.target.value);
  }

  const searchCustomer = (event) => {
    setSearchingLoading(true);
    if (event.key === "Enter") {
      loadCustomers();
    }
    else {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        page = 1;
        loadCustomers();
      }, 500)
    }
  }

  const sortData = (val) => {
    setSortLoading(true);
    var sort_field_data = {
      "name": { type: "desc", value: false },
      "email": { type: "desc", value: false },
      "acc_system": { type: "desc", value: false },
      "acc_code": { type: "desc", value: false },
      "abn_number": { type: "desc", value: false }
    }

    var sort_data = sortFieldRef.current[val];
    if (sort_data) {
      var value = sort_data.value;
      if (sort_data.value === false) {
        value = true;
      }

      if (value === true) {

        var sort_type = "";
        if (sort_data.type === "desc") {
          sort_type = "asc";
        } else {
          sort_type = "desc";
        }

        sort_field_data[val].value = true;
        sort_field_data[val].type = sort_type;

        var sort_data = localStorage.sort_data;
        if (sort_data) {
          sort_data = JSON.parse(sort_data);
          sort_data.customers = sort_field_data;
        } else {
          sort_data = { customers: sort_field_data };
        }
        localStorage.setItem("sort_data", JSON.stringify(sort_data))
        setSortField(sort_field_data);
      }
    }

    loadCustomers();
  }

  const exportData = async () => {
    setLoadingExport(true);
    setSortLoading(true);
    let dataURL = '';

    let sort_type = '';
    let sort_by_data = '';

    var name_sort = sortFieldRef.current.name;
    var acc_code_sort = sortFieldRef.current.acc_code;
    var abn_number_sort = sortFieldRef.current.abn_number;
    var acc_system_sort = sortFieldRef.current.acc_system;

    if (name_sort.value) {
      sort_by_data = 'name';
      sort_type = name_sort.type;
    } else if (acc_code_sort.value) {
      sort_by_data = 'account_code';
      sort_type = acc_code_sort.type;
    } else if (abn_number_sort.value) {
      sort_by_data = 'abn_number';
      sort_type = abn_number_sort.type;
    } else if (acc_system_sort.value) {
      sort_by_data = 'accounting_system_name';
      sort_type = acc_system_sort.type;
    } else {
      sort_by_data = 'name';
      sort_type = 'asc';
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + directus.api.auth.config.token },
      body: JSON.stringify({
        custom_sort: { sort_by_data: sort_by_data, sort_type: sort_type },
        search_by_text: search,
      })
    };

    var react_app_url = process.env.REACT_APP_URL;
    if (window.location.hostname.search('interax') !== -1) {
      react_app_url = process.env.REACT_APP_URL_INTERAX;
    }

    dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/export_settings/customers`;
    await fetch(dataURL, requestOptions)
      .then(resp => resp.blob())
      .then(async blob => {
        var file = await blob.text();
        if (file.includes("error")) {
          alert('Connection error. Please try again.');
        } else if (file.includes("no_template")) {
          alert('No Template');
        } else {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          var file_name = 'Customers-All-Export';
          file_name += '.xlsx';
          a.download = file_name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      })
    setLoadingExport(false)
    setSortLoading(false);
  }

  return (
    <div>
      <div style={{ width: "1200px", display: "flex", alignItems: "center", height: "60px", marginTop: "10px", marginBottom: "10px" }}>
        <div style={{ padding: "0px 20px 0 22px", lineHeight: "60px", background: "white", height: "100%", borderRadius: "8px 0 0 8px" }}>
          <Typography style={{ lineHeight: "60px" }}><b>Filter:</b></Typography>
        </div>
        <div style={{ lineHeight: "60px", width: "310px", background: "white", height: "100%", paddingRight: "10px", borderRadius: "0 8px 8px 0" }}>
          <InputBase
            id="search_order"
            name="searchOrder"
            variant="outlined"
            placeholder="Search"
            size="small"
            onChange={handleChangeSearching}
            onKeyUp={(e) => searchCustomer(e)}
            className={classes.searchbox}
            value={search}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon className={classes.IconSearch} />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <div>{searchingLoading ? <CircularProgress style={{ width: "20px", height: "20px", marginTop: "4px" }} className={classes.circular2} /> : null}</div>
              </InputAdornment>
            }
          />
        </div>
        <div style={{ lineHeight: "60px", width: "150px", background: "white", height: "100%", paddingRight: "20px", paddingLeft: "10px", borderRadius: "0 8px 8px 0" }}>
          {loadingExport ?
            <span><CircularProgress style={{ marginLeft: "7px", color: "#5bfa17" }} size={20} /></span>
            :
            <IconButton className={classes.export} TouchRippleProps={{ style: { width: "115px" } }} style={{ width: "115px", borderRadius: "10px", backgroundColor: "#82cb43", height: "36px", boxShadow: "0 0 1px 0 rgb(0 0 0 / 31%), 0 2px 2px -2px rgb(0 0 0 / 25%)", padding: "7px", marginTop: "-7px", color: "white" }}
              onClick={(e) => exportData()}
            >
              <FileUploadOutlinedIcon style={{ borderRadius: "14px", marginLeft: "2px" }}
                fontSize="10px"
              />
              <span style={{ fontSize: "15px", fontWeight: "600", marginLeft: "3px", marginRight: "8px" }}>EXPORT</span>
            </IconButton>
          }
        </div>
      </div>

      {Customers ?
        <div style={{ marginBottom: '20px' }}>
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: '#f2f2f6' }}>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("name")}>
                        <Typography noWrap variant="h5">Name</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['name'].value === true ?
                            sortFieldRef.current['name'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("email")}>
                        <Typography noWrap variant="h5">Email</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['email'].value === true ?
                            sortFieldRef.current['email'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("acc_system")}>
                        <Typography noWrap variant="h5">Accounting System</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['acc_system'].value === true ?
                            sortFieldRef.current['acc_system'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("acc_code")}>
                        <Typography noWrap variant="h5">Account Code</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['acc_code'].value === true ?
                            sortFieldRef.current['acc_code'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("abn_number")}>
                        <Typography noWrap variant="h5">ABN Number</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['abn_number'].value === true ?
                            sortFieldRef.current['abn_number'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                }
              />
              <Grid item xs={2} style={{ textAlign: 'right', paddingRight: '40px' }}>
                {/* {props.customer_settings === true ? */}
                <AddButton size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                  <AddIcon />
                </AddButton>
                {/* : ''} */}
              </Grid>
            </ListItem>
          </List>
          {sortLoadingRef.current ?
            <Grid item xs={12}>
              <div className={classes.linearProgress} style={{ marginLeft: "7px", marginRight: "7px" }}><LinearProgress style={{ height: "2px" }} /></div>
            </Grid>
            : ''}
          <List component="nav" aria-label="main" className={classes.root} dense={true}>
            {Customers.map((Customer, index) => {
              return (
                <div key={index} style={{ backgroundColor: Customer.status === "deleted" ? "#fee1e0a1" : "white" }}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Grid container key={index}>
                          <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6" id={`customer_name_${index}`} >{Customer.name}</Typography>
                          </Grid>
                          <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{Customer.email}</Typography>
                          </Grid>
                          <Grid item xs={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word" }}>
                            <Typography variant="h6">{Customer.accounting_system ? Customer.accounting_system.name : ''}</Typography>
                          </Grid>
                          <Grid item xs={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word" }}>
                            <Typography variant="h6">{Customer.account_code}</Typography>
                          </Grid>
                          <Grid item xs={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word" }}>
                            <Typography variant="h6">{Customer.abn_number}</Typography>
                          </Grid>

                        </Grid>
                      }
                    />
                    {props.customer_settings === true || Customer.not_synced ?

                      <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <IconButton id={`edit_customer_${index}`} aria-label="edit" onClick={() => handleClickOpen(Customer, 'edit')}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton id={`delete_customer_${index}`} aria-label="delete" onClick={() => handleClickOpen(Customer, 'delete')} >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Grid>

                      :

                      <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <IconButton id={`edit_customer_${index}`} aria-label="edit" onClick={() => handleClickOpen(Customer, 'edit')}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Grid>

                    }
                  </ListItem>

                  {Customer.approvers_level1.length > 0 ?
                    <ListItem>
                      <ListItemText
                        id={`customer_approvers_level1_list_${index}`}
                        // secondaryTypographyProps={{ component: "div" }}
                        primary={
                          <div>
                            <Grid container>
                              {Customer.approvers_level1.length > 0 ? <Grid item xs={1} style={{ maxWidth: '8.333333% !important' }}><Typography variant="h6">Approvers Level 1: &nbsp;</Typography></Grid> : ''}
                              <Grid item xs={11}>
                                {/* eslint-disable-next-line array-callback-return */}
                                {Customer.approvers_level1.map((item, index) => {
                                  if (item.status === 'published' && item.employee !== null) {
                                    return (
                                      <Chip key={index} variant="outlined" size="small" label={<Typography variant="h6" id={`approvers_level1_${index}`}>{item.employee ? item.employee.user.first_name : ''} {item.employee ? item.employee.user.last_name : ''}</Typography>} color="secondary" />
                                    )
                                  }
                                })}
                              </Grid>
                            </Grid>
                          </div>
                        }
                      />
                    </ListItem>
                    : ''}

                  {Customer.approvers_level2.length > 0 ?
                    <ListItem>
                      <ListItemText
                        id={`customer_approvers_level2_list_${index}`}
                        // secondaryTypographyProps={{ component: "div" }}
                        primary={
                          <div>
                            <Grid container>
                              {Customer.approvers_level2.length > 0 ? <Grid item xs={1} style={{ maxWidth: '8.333333% !important' }}><Typography variant="h6">Approvers Level 2: &nbsp;</Typography></Grid> : ''}
                              <Grid item xs={11}>
                                {/* eslint-disable-next-line array-callback-return */}
                                {Customer.approvers_level2.map((item, index) => {
                                  if (item.status === 'published' && item.employee !== null) {
                                    return (
                                      <Chip key={index} variant="outlined" size="small" label={<Typography variant="h6" id={`approvers_level2_${index}`}>{item.employee ? item.employee.user.first_name : ''} {item.employee ? item.employee.user.last_name : ''}</Typography>} color="secondary" />
                                    )
                                  }
                                })}
                              </Grid>
                            </Grid>
                          </div>
                        }
                      />
                    </ListItem>
                    : ''}


                  {Customer.suppliers ? Customer.suppliers.length > 0 ?
                    <ListItem>
                      <ListItemText
                        id={`customer_supplier_list_${index}`}
                        // secondaryTypographyProps={{ component: "div" }}
                        primary={
                          <div>
                            <Grid container>
                              {Customer.suppliers.length > 0 ? <Grid item xs={1} style={{ maxWidth: '8.333333% !important' }}><Typography variant="h6">Suppliers: &nbsp;</Typography></Grid> : ''}
                              <Grid item xs={11}>
                                {/* eslint-disable-next-line array-callback-return */}
                                {Customer.suppliers.map((item, index) => {
                                  if (item.status === "published" && item.supplier !== null) {
                                    return (
                                      <Chip key={index} variant="outlined" size="small" label={<Typography variant="h6" id={`suppliers_${index}`}>{item.supplier ? item.supplier.name : ''}</Typography>} color="secondary" />
                                    )
                                  }
                                })}
                              </Grid>
                            </Grid>
                          </div>
                        }
                      />
                    </ListItem>
                    : '' : ''}

                  {Customers.length - 1 > index ? <Divider /> : ''}
                </div>
              );
            })}
            <Dialog
              id="customers_dialog"
              isopen={`${open}`}
              open={open}
              fullWidth={true}
              onClose={handleClose}
            >
              <DialogTitle id="form-dialog-title"><Typography id="customers_dialog_title" component="div" variant="h5">{title}</Typography></DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  {mode === 'add' ?
                    <>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Code"
                          name="code"
                          variant="outlined"
                          id="Code"
                          value={dataCustomerRef.current.code ? dataCustomerRef.current.code : ''}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Name"
                          name="name"
                          variant="outlined"
                          id="Name"
                          value={dataCustomerRef.current.name ? dataCustomerRef.current.name : ''}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                    </>
                    :
                    mode === 'edit' ?
                      props.customer_settings === true || dataCustomerRef.current.not_synced ?
                        <>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Code"
                              name="code"
                              variant="outlined"
                              id="Code"
                              value={dataCustomerRef.current.code ? dataCustomerRef.current.code : ''}
                              onChange={handleChangeInput}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Name"
                              name="name"
                              variant="outlined"
                              id="Name"
                              value={dataCustomerRef.current.name ? dataCustomerRef.current.name : ''}
                              onChange={handleChangeInput}
                            />
                          </Grid>
                        </>
                        : ''
                      : ''
                  }


                  {mode !== 'delete' ?
                    <>
                      <Grid item xs={6}>
                        <Autocomplete
                          id="size-small-standard"
                          size="small"
                          options={accountingSystemData ? accountingSystemData : []}
                          getOptionLabel={(option) => option.name}
                          // style={{ marginTop: '10px' }}
                          value={accountSystem ? accountSystem : null}
                          onChange={(event, newValue) => {
                            handleChangeInput(newValue, "accounting_system");
                          }}
                          renderInput={(params) =>
                            <CssTextField
                              // helperText={list.supplier.name}
                              {...params}
                              variant="outlined"
                              name="accounting_system"
                              label="Select Accounting System"
                            />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Account Code"
                          name="account_code"
                          variant="outlined"
                          id="account_code"
                          value={dataCustomerRef.current.account_code ? dataCustomerRef.current.account_code : ''}
                          onChange={handleChangeInput}
                        />
                        <br />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="ABN Number"
                          name="abn_number"
                          variant="outlined"
                          id="abn_number"
                          value={dataCustomerRef.current.abn_number ? dataCustomerRef.current.abn_number : ''}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Legal Name"
                          name="legal_name"
                          variant="outlined"
                          id="legal_name"
                          value={dataCustomerRef.current.legal_name ? dataCustomerRef.current.legal_name : ''}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Email"
                          name="email"
                          variant="outlined"
                          id="email"
                          value={dataCustomerRef.current.email ? dataCustomerRef.current.email : ''}
                          onChange={(e) => handleChangeInput(e, "email")}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          id="size-small-standard"
                          size="small"
                          options={[{ value: "super_admin", name: "Super Admin" }, { value: "invoice_admin", name: "Invoice Admin" }, { value: "po_approver", name: "PO Approver" }, { value: "po_creator", name: "PO Creator" },]}
                          getOptionLabel={(option) => option.name}
                          // style={{ marginTop: '10px' }}
                          value={accessLevel ? accessLevel : null}
                          onChange={(event, newValue) => {
                            handleChangeInput(newValue, "access_level");
                          }}
                          renderInput={(params) =>
                            <CssTextField
                              // helperText={list.supplier.name}
                              {...params}
                              variant="outlined"
                              name="access_level"
                              label="Select Access Level"
                            />}
                        />
                      </Grid>

                      {employees ?
                        <>
                          <Grid item xs={12} style={{ marginTop: "15px" }}><div className={classes.header}><span>Approvers</span></div></Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              multiple
                              id="checkboxes-tags-demo"
                              options={employees}
                              disableCloseOnSelect
                              fullWidth={true}
                              getOptionLabel={(option) => (option ? option.code : '') + " - " + (option ? option.user ? `${option.user.first_name} ${option.user.last_name}` : '' : '')}
                              getOptionSelected={(option, value) => option.id === value.id}
                              onChange={(event, newValue) => {
                                setApproversLevel1(newValue)
                              }}
                              value={approversLevel1Ref.current ? approversLevel1Ref.current : null}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option ? option.code : ''} - {option ? option.user ? `${option.user.first_name} ${option.user.last_name}` : '' : ''}
                                </React.Fragment>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Select Approvers Level 1" placeholder="Select..." />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              multiple
                              id="checkboxes-tags-demo"
                              options={employees}
                              disableCloseOnSelect
                              fullWidth={true}
                              getOptionLabel={(option) => (option ? option.code : '') + " - " + (option ? option.user ? `${option.user.first_name} ${option.user.last_name}` : '' : '')}
                              getOptionSelected={(option, value) => option.id === value.id}
                              onChange={(event, newValue) => {
                                setApproversLevel2(newValue)
                              }}
                              value={approversLevel2Ref.current ? approversLevel2Ref.current : null}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option ? option.code : ''} - {option ? option.user ? `${option.user.first_name} ${option.user.last_name}` : '' : ''}
                                </React.Fragment>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Select Approvers Level 2" placeholder="Select..." />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              style={{ marginRight: "25px" }}
                              control={
                                <Switch name="bypass_approval" checked={dataCustomerRef.current.bypass_approval === true ? true : false} onClick={(e) => handleChangeInput(e, "checkbox")} />
                              }
                              label="Bypass Approval"
                            />
                          </Grid>
                        </>
                        :
                        <CircularProgress className={classes.circular} size={20} />
                      }


                      {employees ?
                        <>
                          <Grid item xs={12} style={{ marginTop: "15px" }}><div className={classes.header}><span>Suppliers</span></div></Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              multiple
                              id="checkboxes-tags-demo"
                              options={suppliersRef.current}
                              disableCloseOnSelect
                              fullWidth={true}
                              getOptionLabel={(option) => (option ? option.code : '') + " - " + (option ? option.name : '')}
                              getOptionSelected={(option, value) => option.id === value.id}
                              onChange={(event, newValue) => {
                                setSelectedSupplier(newValue)
                              }}
                              value={selectedSupplierRef.current ? selectedSupplierRef.current : null}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option ? option.code : ''} - {option ? option.name : ''}
                                </React.Fragment>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Select Suppliers" placeholder="Select..." />
                              )}
                            />
                          </Grid>
                        </>
                        :
                        <CircularProgress className={classes.circular} size={20} />
                      }

                      <Grid item xs={12} style={{ marginTop: "15px" }}><div className={classes.header}><span>Delivery Address</span></div></Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="delivery_address_line_1_text"
                          label="Delivery Address Line 1"
                          variant="outlined"
                          fullWidth={true}
                          name="delivery_address_line_1"
                          value={dataCustomerRef.current.delivery_address_line_1}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="delivery_address_line_2_text"
                          label="Delivery Address Line 2"
                          variant="outlined"
                          fullWidth={true}
                          name="delivery_address_line_2"
                          value={dataCustomerRef.current.delivery_address_line_2}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="delivery_city_text"
                          label="Delivery City"
                          variant="outlined"
                          fullWidth={true}
                          name="delivery_city"
                          value={dataCustomerRef.current.delivery_city}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="delivery_state_text"
                          label="Delivery State"
                          variant="outlined"
                          fullWidth={true}
                          name="delivery_state"
                          value={dataCustomerRef.current.delivery_state}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="delivery_post_code_text"
                          label="Delivery Postcode"
                          variant="outlined"
                          fullWidth={true}
                          name="delivery_post_code"
                          value={dataCustomerRef.current.delivery_post_code}
                          onChange={handleChangeInput}
                        />
                      </Grid>
                    </> : ""}
                </Grid>

              </DialogContent>
              <DialogActions style={{ height: "60px" }}>
                {loading ?
                  <CircularProgress className={classes.circular} />
                  :
                  <div>
                    <CancelButton
                      id="cancel_btn"
                      size="small"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Cancel
                    </CancelButton>
                    <SubmitButton
                      id={`${mode === 'delete' ? 'delete' : 'save'}_btn`}
                      disabled={mode === 'delete' ? false : disable}
                      size="small"
                      variant="contained"
                      onClick={() => SaveCustomer()}
                    >
                      {mode === 'delete' ? 'Delete' : 'Save'}
                    </SubmitButton>
                  </div>
                }

              </DialogActions>
            </Dialog>
          </List>
        </div>
        :
        <CircularProgress className={classes.circular} />
      }

      {Customers ?
        <Grid
          item xs={12}
          style={{ textAlign: 'right', marginBottom: '10px', fontFamily: 'Manrope' }}
        >
          <span style={{ paddingRight: '20px' }}>
            Rows per page:
          </span>
          <span style={{ paddingRight: '20px' }}>
            <TextField
              select
              value={pageNumber}
              onChange={handleChange}
            >
              {pageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>

          <span id="page_number" style={{ paddingRight: '20px' }}>
            {(pageNumberRef.current * (page - 1)) + 1} - {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
          </span>

          <Button
            id="prev_btn"
            variant="outlined"
            size="small"
            disabled={page === 1}
            className={classes.buttonLeft}
            onClick={() => pageChange(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Button
            id="next_btn"
            variant="outlined"
            size="small"
            className={classes.buttonRight}
            disabled={page >= Math.ceil(totalPage / pageNumberRef.current)}
            onClick={() => pageChange(1)}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Grid>
        :
        " "}
    </div>
  );
}

export default Customers;
