// import DoneAllIcon from '@material-ui/icons/DoneAll';
// import DateRangeIcon from '@material-ui/icons/DateRange';
// import SettingsIcon from '@material-ui/icons/Settings';

import { AdminPermission, InvoiceAdminPermission, POApproverPermission, POCreatorPermission } from 'src/utils/Permission';
import SettingsIcon from '@material-ui/icons/Settings';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';

export function items() {
  let menu_items;

  if (AdminPermission()) {
    menu_items = [
      {
        href: '/',
        icon: FormatListBulletedIcon,
        title: 'Orders',
        show: true,
        number: 0,
        dividerValue: 1,
        features: {
          search: true,
          add: true,
          addNavigate: true,
          sort: true,
          list: true,
          filter: true,
          searchByCustomer: true,
          export: true
        },
        listStyle: {
          paddingLeft: '15px',
          borderRadius: '8px 8px 0 0'
        }
      },
      {
        href: '/orders',
        icon: FormatListBulletedIcon,
        title: 'Orders',
        show: false,
        number: 1,
        dividerValue: 1,
        features: {
          search: true,
          add: true,
          addNavigate: true,
          sort: true,
          list: true,
          filter: true,
          searchByCustomer: true,
          export: true
        },
        listStyle: {
          paddingLeft: '15px',
          borderRadius: '8px 8px 0 0'
        }
      },
      {
        href: '/invoices',
        icon: AllInboxIcon,
        title: 'Invoices',
        show: true,
        number: 2,
        dividerValue: 1,
        features: {
          search: true,
          add: false,
          addNavigate: false,
          sort: false,
          list: true,
          filter: true,
          searchByCustomer: false,
          export: true
        },
        listStyle: {
          paddingLeft: '15px',
          borderRadius: '0',
        }
      },
      {
        href: '/settings',
        icon: SettingsIcon,
        title: 'Settings',
        number: 4,
        dividerValue: 0,
        features: {
          search: false,
          add: false,
          addNavigate: false,
          sort: false,
          list: false,
          filter: false,
          searchByCustomer: false,
          export: false
        },
        listStyle: {
          paddingLeft: '15px',
          borderRadius: '0 0 8px 8px'
        }
      }
    ];
  }else if (InvoiceAdminPermission() || POApproverPermission()) {
    menu_items = [
      {
        href: '/',
        icon: FormatListBulletedIcon,
        title: 'Orders',
        show: true,
        number: 0,
        dividerValue: 1,
        features: {
          search: true,
          add: true,
          addNavigate: true,
          sort: true,
          list: true,
          filter: true,
          searchByCustomer: true,
          export: true
        },
        listStyle: {
          paddingLeft: '15px',
          borderRadius: '8px 8px 0 0'
        }
      },
      {
        href: '/orders',
        icon: FormatListBulletedIcon,
        title: 'Orders',
        show: false,
        number: 1,
        dividerValue: 1,
        features: {
          search: true,
          add: true,
          addNavigate: true,
          sort: true,
          list: true,
          filter: true,
          searchByCustomer: true,
          export: true
        },
        listStyle: {
          paddingLeft: '15px',
          borderRadius: '8px 8px 0 0'
        }
      },
      {
        href: '/invoices',
        icon: AllInboxIcon,
        title: 'Invoices',
        show: true,
        number: 2,
        dividerValue: 1,
        features: {
          search: true,
          add: false,
          addNavigate: false,
          sort: false,
          list: true,
          filter: true,
          searchByCustomer: false,
          export: true
        },
        listStyle: {
          paddingLeft: '15px',
          borderRadius: '0',
        }
      }
    ];
  }else if (POCreatorPermission()) {
    menu_items = [
      {
        href: '/',
        icon: FormatListBulletedIcon,
        title: 'Orders',
        show: true,
        number: 0,
        dividerValue: 1,
        features: {
          search: true,
          add: true,
          addNavigate: true,
          sort: true,
          list: true,
          filter: true,
          searchByCustomer: true,
          export: true
        },
        listStyle: {
          paddingLeft: '15px',
          borderRadius: '8px 8px 0 0'
        }
      },
      {
        href: '/orders',
        icon: FormatListBulletedIcon,
        title: 'Orders',
        show: false,
        number: 1,
        dividerValue: 1,
        features: {
          search: true,
          add: true,
          addNavigate: true,
          sort: true,
          list: true,
          filter: true,
          searchByCustomer: true,
          export: true
        },
        listStyle: {
          paddingLeft: '15px',
          borderRadius: '8px 8px 0 0'
        }
      },
    ];
  }

  // if (AdminPermission()) {
  //   menu_items = [
  //     {
  //       href: '/',
  //       icon: FormatListBulletedIcon,
  //       title: 'My Orders',
  //       number: 0,
  //       dividerValue: 1,
  //       features: {
  //         search: true,
  //         add: true,
  //         addNavigate: true,
  //         sort: true,
  //         list: true,
  //         filter: true,
  //         searchByCustomer: true,
  //         export: true
  //       },
  //       listStyle: {
  //         paddingLeft: '15px',
  //         borderRadius: '8px 8px 0 0',
  //       }
  //     },
  //     {
  //       href: '/all_orders',
  //       icon: AllInboxIcon,
  //       title: 'All Orders',
  //       number: 1,
  //       dividerValue: 1,
  //       features: {
  //         search: true,
  //         add: true,
  //         addNavigate: true,
  //         sort: true,
  //         list: true,
  //         filter: true,
  //         searchByCustomer: true,
  //         export: true
  //       },
  //       listStyle: {
  //         paddingLeft: '15px',
  //         borderRadius: '0 0 0 0'
  //       }
  //     },
  //     {
  //       href: '/draft_orders',
  //       icon: InsertDriveFileOutlinedIcon,
  //       title: 'Draft Orders',
  //       number: 2,
  //       dividerValue: 1,
  //       features: {
  //         search: true,
  //         add: true,
  //         addNavigate: false,
  //         sort: true,
  //         list: true,
  //         filter: false,
  //         searchByCustomer: true,
  //         export: true
  //       },
  //       listStyle: {
  //         paddingLeft: '15px',
  //         borderRadius: '0 0 8px 8px',
  //       }
  //     },
  //   ];
  // }
  // else {
  //   menu_items = [
  //     {
  //       href: '/',
  //       icon: FormatListBulletedIcon,
  //       title: 'My Orders',
  //       number: 0,
  //       dividerValue: 1,
  //       features: {
  //         search: true,
  //         add: true,
  //         addNavigate: true,
  //         sort: true,
  //         list: true,
  //         filter: true,
  //         searchByCustomer: true,
  //         export: true
  //       },
  //       listStyle: {
  //         paddingLeft: '15px',
  //         borderRadius: '8px 8px 0 0'
  //       }
  //     },
  //     {
  //       href: '/draft_orders',
  //       icon: InsertDriveFileOutlinedIcon,
  //       title: 'Draft Orders',
  //       number: 2,
  //       dividerValue: 1,
  //       features: {
  //         search: true,
  //         add: true,
  //         addNavigate: false,
  //         sort: true,
  //         list: true,
  //         filter: false,
  //         searchByCustomer: true,
  //         export: true
  //       },
  //       listStyle: {
  //         paddingLeft: '15px',
  //         borderRadius: '0 0 8px 8px',
  //       }
  //     }
  //   ];
  // }

  return menu_items;
}


export function itemsInvoice() {
  let menu_items = [];

  if (AdminPermission()) {
    menu_items = [
      {
        href: '/invoices/all',
        icon: AllInboxIcon,
        title: 'All Invoices',
        number: 4,
        dividerValue: 1,
        features: {
          search: true,
          add: false,
          addNavigate: false,
          sort: false,
          list: true,
          filter: false,
          searchByCustomer: false,
          export: false
        },
        listStyle: {
          paddingLeft: '15px',
          borderRadius: '8px 8px 0 0',
        }
      },
      {
        href: '/invoices/unmatched',
        icon: InsertDriveFileOutlinedIcon,
        title: 'Unmatched Invoices',
        number: 5,
        dividerValue: 1,
        features: {
          search: false,
          add: false,
          addNavigate: false,
          sort: false,
          list: true,
          filter: false,
          searchByCustomer: false,
          export: false
        },
        listStyle: {
          paddingLeft: '15px',
          borderRadius: '0 0 0 0'
        }
      },
      {
        href: '/invoices/matched',
        icon: DescriptionOutlined,
        title: 'Matched Invoices',
        number: 6,
        dividerValue: 1,
        features: {
          search: true,
          add: false,
          addNavigate: false,
          sort: false,
          list: true,
          filter: false,
          searchByCustomer: false,
          export: false
        },
        listStyle: {
          paddingLeft: '15px',
          borderRadius: '0 0 0 0'
        }
      },
      {
        href: '/invoices/approved',
        icon: TaskOutlinedIcon,
        title: 'Approved Invoices',
        number: 7,
        dividerValue: 1,
        features: {
          search: true,
          add: false,
          addNavigate: false,
          sort: false,
          list: true,
          filter: false,
          searchByCustomer: false,
          export: false
        },
        listStyle: {
          paddingLeft: '15px',
          borderRadius: '0 0 8px 8px',
        }
      },
    ];
  }

  return menu_items;
}

export function itemsBottom() {
  let menu_items = [];

  if (AdminPermission()) {
    menu_items = [
      {
        href: '/settings',
        icon: SettingsIcon,
        title: 'Settings',
        number: 8,
        dividerValue: 0,
        features: {
          search: false,
          add: false,
          addNavigate: false,
          sort: false,
          list: false,
          filter: false,
          searchByCustomer: false,
          export: false
        },
        listStyle: {
          paddingLeft: '15px',
          borderRadius: '8px'
        }
      }
    ];
  }

  return menu_items;
}
