import { makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Tooltip,
} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6),
    marginRight: '28px',
    marginLeft: '28px',
  },

  buttonLeft: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '24px 0 0 24px',
    borderRight: 0,
    boxShadow: '0 0 black',
    height: 30,
    border: '0 !important',
  },
  buttonRight: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '0 24px 24px 0',
    boxShadow: '0 0 black',
    height: 30,
    border: '0 !important',
  },
  tablehead: {
    borderRadius: '8px',
    height: '38px',
    padding: '8px 6px 6px 22px',
    marginBottom: '10px',
    position: 'sticky',
    top: '12px',
  },
  circular: {
    color: '#5bfa17',
    marginLeft: '45%'
  },
  invoiceslistfalse: {
    borderRadius: '8px',
    height: '46px',
    width: '980px',
  },
  invoiceslisttrue: {
    borderRadius: '8px',
    height: '46px',
    width: 'auto',
  },
  iconbuttonarea: {
    borderRadius: '10px',
    marginRight: '6px',
    paddingRight: '6px',
    width: '36px',
    height: '36px'
  },
  autocomplete: {
    width: '336px',
    display: 'inline-block',
    marginRight: '6px',
    '& .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth ':
    {
      marginTop: '-5px !important',
      height: '36px'
    }
  },
  button: {
    backgroundColor: 'rgba(142,142,147,0.12)',
    borderRadius: '10px',
    height: '36px',
    width: '204px',
    display: 'inline-grid',
  },
  buttonOCR: {
    backgroundColor: 'rgba(142,142,147,0.12)',
    borderRadius: '10px',
    height: '36px',
    width: '150px',
    display: 'inline-grid',
  },
  buttonProcessWithoutPO: {
    backgroundColor: '#FFFF',
    borderRadius: '10px',
    height: '36px',
    width: '200px',
    display: 'inline-grid',
    border: "1px solid #FD2418",
    color: "#FD2418",
    '&:hover': {
      background: "#EDEDED"
    },
    marginRight: "10px"
  },
  orderhead: {
    borderRadius: '8px',
    padding: '8px 6px 6px 22px',

  },
  statusarea: {
    height: '36px',
    paddingLeft: '10px',
    marginLeft: '-10px',
    paddingTop: '7px',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    textTransform: 'capitalize'
  },

  amountarea: {
    width: '80%',
    height: '36px',
    paddingTop: '6px',
    paddingRight: '3px',
    paddingLeft: '3px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  amountdetail: {
    height: '30px',
    marginTop: '-3px',
    textAlign: 'right',
    paddingRight: '15px',
    paddingTop: '4px',
  },

  orderitemdetail: {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    padding: '6px 0px 6px 16px',
    marginTop: '6px'
  },

  itemareafirst: {
    paddingLeft: '6px',
    paddingBottom: '3px',
    paddingTop: '3px',
  },
  itemarealast: {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    paddingLeft: '6px',
    paddingBottom: '3px',
    paddingTop: '3px',
  },
  itemcolumn: {
    height: '30px',
    borderRadius: '8px',
    marginRight: '6px',
    backgroundColor: 'transparent',
  },
  unitpricecolumn: {
    height: '30px',
    borderRadius: '8px',
    marginRight: '6px',
    display: 'flex',
    backgroundColor: 'transparent'
  },
  matched_invoice_info_header: {
    fontSize: "14px",
    fontWeight: "700",
    overflowX: "hidden",
    overflowWrap: "break-word"
  },
  matched_invoice_info_content: {
    fontSize: "12px",
    overflowX: "hidden",
    overflowWrap: "break-word"
  },
  iconbuttonarea: {
    borderRadius: '10px',
    width: '36px',
    height: '36px',
    marginRight: '8px'
  },
  autocomplete2: {
    '& .MuiInputBase-root input': {
      fontSize: "12px"
    },
    marginRight: "10px"
  },
  textfield2: {
    '& .MuiInputBase-root input': {
      fontSize: "12px"
    },
    paddingRight: "10px"
  },
  textfieldDuplicate: {
    '& .MuiInputBase-root input': {
      fontSize: "12px",
      color: "red",
      fontWeight: "700"
    },
    paddingRight: "10px"
  },
  invoiceTabs: {
    background: "white",
    border: "1px solid #bbbcbdab",
    borderRadius: "15px",
    margin: "3px",
    color: "#263238",
    fontFamily: 'Manrope',
    fontWeight: "600",
    padding: "10px 15px",
    fontSize: "14px",
    cursor: "pointer",
    '&:hover': {
      background: "#e7e7e7"
    }
  },
  selectedInvoiceTabs: {
    fontWeight: "700",
    background: "rgba(214, 238, 193, 0.5)",
    border: "1px solid rgba(214, 238, 193, 0.5)",
    "&:hover": {
      color: "#82CB43",
      border: "1px solid #82CB43",
      background: "rgba(214, 238, 193, 0.5)"
    }
  },

  orderTabs: {
    background: "white",
    border: "1px solid #bbbcbdab",
    borderRadius: "15px",
    margin: "3px",
    color: "#263238",
    fontFamily: 'Manrope',
    fontWeight: "600",
    padding: "8px 10px",
    fontSize: "14px",
    cursor: "pointer",
    '&:hover': {
      background: "#e7e7e7"
    }
  },
  selectedOrderTabs: {
    fontWeight: "700",
    background: "rgba(214, 238, 193, 0.5)",
    border: "1px solid rgba(214, 238, 193, 0.5)",
    "&:hover": {
      color: "#82CB43",
      border: "1px solid #82CB43",
      background: "rgba(214, 238, 193, 0.5)"
    }
  },

  iconbuttonarea2: {
    borderRadius: '10px',
    width: '36px',
    height: '36px',
    marginLeft: '6px',
    marginTop: '0px',
  },
  split_screen: {
    position: "absolute",
    marginTop: "8px",
    fontSize: "20px !important",
    color: "#616161",
    "&:hover": {
      color: "#82CB43"
    }
  },
  iconAddNewMatch: {
    height: "30px",
    width: "30px",
    color: "white",
    background: "#82CB43",
    "&:hover": {
      color: "white",
      background: "rgba(214, 238, 193, 0.5)",
    }
  },
  textInput: {
    border: "1px solid #cfc6c6",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    borderRadius: "6px",
    fontSize: "12px",
    cursor: "pointer",
    height: "35px",
    paddingLeft: "10px",
    paddingRight: "6px",
    alignItems: "center",
    "&:hover": {
      border: "1px solid black",
    },
    "&:hover #close_multiselect": {
      display: "block"
    },
    "& #close_multiselect": {
      display: "none"
    },
    background: "white"
  },
  textInputValue: {
    overflowY: "hidden",
    width: "calc(100% - 20px)",
    fontWeight: "400",
    textOverflow: "ellipsis",
    overflowX: "hidden",
    whiteSpace: "nowrap"
  },
  arrowDropDown: {
    height: "24px !important",
    width: "24px !important",
    color: "rgba(0, 0, 0, 0.54)",
    marginTop: "4.5px",
    "&:hover": {
      borderRadius: "50%",
      background: "#a19c9c1a"
    }
  },
  closeIcon: {
    height: "24px !important",
    width: "24px !important",
    color: "rgba(0, 0, 0, 0.54)",
    marginTop: "4.5px",
    padding: "3.5px",
    "&:hover": {
      borderRadius: "50%",
      background: "#a19c9c1a"
    }
  },
  firstnote: {
    borderRadius: '8px',
    marginTop: '6px',
    padding: '6px 0px 6px 16px',
    margin: "0px 6px"
  },
}));

export const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

export const useStyles2 = makeStyles((theme) => ({
  root: {
    paddingRight: '10px',
    paddingLeft: '10px',
    position: 'relative',
    overflow: 'auto',
    // maxHeight: window.innerHeight - 250,
    borderRadius: '10px'
  },
  match_order_number: {
    '& .MuiFormLabel-root': {
      marginTop: "-3px"
    },
  },
  match_order_number_disabled: {
    verticalAlign: 'middle !important',
    '& .MuiOutlinedInput-root': {
      marginBottom: '0px !important',
    },
  },
  buttonEditInvoice: {
    height: "30px",
    borderRadius: "10px",
    marginRight: "5px"
  },
  date_picker: {
    '& .MuiOutlinedInput-input': {
      padding: "10px 0 10px 10px"
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: "0px"
    },
    '& .MuiOutlinedInput-adornedEnd #date_picker': {
      fontSize: "12px"
    },
    '& .MuiInputBase-root': {
      background: "#FFFFFF"
    },
    '& .MuiIconButton-root': {
      padding: "10px"
    },
    '& .MuiInputBase-root .MuiButtonBase-root .MuiIconButton-label .MuiSvgIcon-root': {
      height: "20px",
      width: "20px"
    },
  },
  editInvoiceDialog: {
    '& .MuiDialog-paperFullWidth': {
      maxWidth: "650px"
    }
  }

}));


export const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "12px",
    maxWidth: 500,
    backgroundColor: "grey"
  },
})(Tooltip);

export const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'red',
      backgroundColor: 'white',
      borderRadius: '6px',
      marginBottom: '6px',
      marginRight: '6px',
      height: '30px'
    },
  },
})(TextField);

export const CssTextField2 = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'red',
      backgroundColor: 'white',
      borderRadius: '6px',
      height: '35px'
    },
  },
})(TextField);

export const CssTextFieldQuantity = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'red',
      backgroundColor: 'white',
      borderRadius: '6px',
      height: '30px'
    },
  },
})(TextField);


export const SubmitButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
})(Button);

export const CancelButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

export const RejectButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#FFDBD9'),
    backgroundColor: '#FFDBD9',
    borderRadius: '10px',
    height: '36px',
    width: '151px',
    display: 'inline-grid',
    marginRight: '6px',
    '&:hover': {
      backgroundColor: '#FFDBD9',
      borderRadius: '10px',
      height: '36px',
      width: '151px',
      display: 'inline-grid',
      marginRight: '6px',
    },
  },
}))(Button);

export const MatchButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#D0F2D4'),
    backgroundColor: '#D0F2D4',
    borderRadius: '10px',
    height: '36px',
    width: '151px',
    display: 'inline-grid',
    marginRight: '6px',
    '&:hover': {
      backgroundColor: '#D0F2D4',
      borderRadius: '10px',
      height: '36px',
      width: '151px',
      display: 'inline-grid',
      marginRight: '6px',
    },
  },
}))(Button);

export const UndoButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#D0F2D4'),
    backgroundColor: 'rgb(255 240 221)',
    borderRadius: '10px',
    height: '36px',
    width: '144px',
    display: 'inline-grid',
    marginRight: '6px',
    '&:hover': {
      backgroundColor: 'rgb(255 240 221)',
      borderRadius: '10px',
      height: '36px',
      width: '144px',
      display: 'inline-grid',
      marginRight: '6px',
    },
  },
}))(Button);

export const ArrowButton = withStyles((theme) => ({
  root: {
    color: '#6d6d6d',
    backgroundColor: 'white',
    border: "1px solid #EDEDED",
    borderRadius: '10px',
    height: '36px',
    display: 'inline-grid',
    marginRight: '6px',
    '&:hover': {
      backgroundColor: '#EDEDED'
    },
  },
}))(Button);

export const ApprovedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#D0F2D4'),
    backgroundColor: '#D0F2D4',
    borderRadius: '10px',
    height: '36px',
    width: '144px',
    display: 'inline-grid',
    marginRight: '6px',
    '&:hover': {
      backgroundColor: '#D0F2D4',
      borderRadius: '10px',
      height: '36px',
      width: '144px',
      display: 'inline-grid',
      marginRight: '6px',
    },
  },
}))(Button);

export const MatchedPOButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#D0F2D4'),
    backgroundColor: '#d4e3f2',
    borderRadius: '10px',
    height: '36px',
    width: '210px',
    display: 'inline-grid',
    marginRight: '6px',
    '&:hover': {
      backgroundColor: '#d4e3f2',
      borderRadius: '10px',
      height: '36px',
      width: '210px',
      display: 'inline-grid',
      marginRight: '6px',
    },
  },
}))(Button);

export const CancelMatchButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#D0F2D4'),
    backgroundColor: '#bdbdbdde',
    borderRadius: '10px',
    height: '36px',
    width: '95px',
    display: 'inline-grid',
    marginRight: '6px',
    '&:hover': {
      backgroundColor: '#d5d5d5',
      borderRadius: '10px',
      height: '36px',
      width: '95px',
      display: 'inline-grid',
      marginRight: '6px',
    },
  },
}))(Button);