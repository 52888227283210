import React, { useEffect, useState } from 'react';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  TextareaAutosize,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import './style.css';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import directus from 'src/services/directus';
import useStateRef from 'react-usestateref';
import { v4 as uuidv4 } from 'uuid';
import EventEmitter from 'src/utils/EventEmitter';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useSnackbar } from 'notistack';
import {
  CancelButton,
  CancelledButton,
  CompletedButton,
  DraftButton,
  SentButton,
  SubmitButton,
  useStyles as useStyles2
} from 'src/views/_styles/style1';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Enumerable from 'linq';
import linq from 'linq';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { AdminPermission, InvoiceAdminPermission, POApproverPermission, POCreatorPermission } from 'src/utils/Permission';
import { getEmployee } from 'src/utils/sessions';
import ErrorMessage from 'src/components/ErrorMessage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import useStyles3 from 'src/views/AllOrder/style.js';

import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Divider from '@mui/material/Divider';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { CustomTooltip } from '../setting/style';
import { getCache, setCache } from 'src/utils/KeyValueCache';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import InfoIcon from '@material-ui/icons/Info';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'red',
      backgroundColor: 'white',
      borderRadius: '6px',
      marginBottom: '6px',
      marginRight: '6px',
      height: '30px'
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '80%',
      marginTop: '-3px',
      height: '100%'
    },
    '& .MuiInputBase-input.Mui-disabled': {
      cursor: 'not-allowed'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 0px) scale(0.75)'
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      marginTop: '-3.5px'
    }
  }
})(TextField);

const DataStatusDraft = props => {
  /* eslint-disable no-unused-vars*/
  const [initialized, setInitialized] = useState(false);
  const [customers, setCustomers, customersRef] = useStateRef(null);
  const [customers2, setCustomers2, customersRef2] = useStateRef(null);
  const [suppliers, setSuppliers, suppliersRef] = useStateRef(null);
  const [suppliers2, setSuppliers2, suppliersRef2] = useStateRef(null);
  const [supplierLoading, setSupplierLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [items, setItems, itemsRef] = useStateRef(null);
  const [totalOrderItem, setTotalOrderItem, totalOrderItemRef] = useStateRef(props.totalOrder);
  // const [isEdit, setIsEdit, isEditRef] = useStateRef(props.isEdit);
  const [add, setAdd] = useState(false);

  const [category_type, setCategory_type] = useState(props.data.category_type);
  const [categoryDescription, setCategoryDescription, categoryDescriptionRef] = useStateRef(props.data.category_description);

  const [itemCheck, setItemCheck] = useState(true);
  const [itemCount, setItemCount, itemCountRef] = useStateRef(false);
  const [loadingItems, setLoadingItems, loadingItemsRef] = useStateRef(false);
  const [maxSort, setMaxSort, maxSortRef] = useStateRef(false);

  const data = props.data;
  const categoryTypeLoading = props.categoryTypeLoading;
  const categoryType = props.categoryType;
  const loadOrders = props.loadOrders;
  const collection = props.collection;
  const collectionData = props.collectionData;
  const orderEventType = props.orderEventType;
  const editMode = props.editMode;
  const goToFirstPage = props.goToFirstPage;
  const setIsEdit = props.setIsEdit;

  const classes = useStyles2();
  const classes2 = useStyles3();
  const [note, setNote] = React.useState(data.notes);
  const [email, setEmail] = React.useState(data.cc_email);
  const [deliveryInstructions, setDeliveryInstructions] = useState(data.delivery_instructions);
  const [supplier, setSupplier, supplierRef] = useStateRef(data.supplier ? data.supplier : null);
  const [customer, setCustomer, customerRef] = useStateRef(data.farm_code ? data.farm_code : null);
  const [list, setList, listRef] = useStateRef(data);
  const [loadingSubmit, setLoadingSubmit] = React.useState(false);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  let status = { backgroundColor: '#D4E3F2' };
  const { enqueueSnackbar } = useSnackbar();
  const [showChangeLog, setShowChangeLog] = React.useState(false);
  const [logData, setLogData] = React.useState([]);

  const [selectedDate, handleDateChange] = useState(data.due_date ? moment(data.due_date).format() : new Date());

  const [openDeleteOrderDialog, setOpenDeleteOrderDialog] = React.useState(false);
  const [loadingDeleteDialog, setLoadingDeleteDialog] = useState(false);

  const [matchedInvoiceData, setMatchedInvoiceData, matchedInvoiceDataRef] = useStateRef(null);
  const [open, setOpen] = React.useState(false);
  const [loadingStatusCompleted, setLoadingStatusCompleted] = React.useState(false);
  const navigate = useNavigate();

  const [anchorElSupplier, setAnchorElSupplier] = React.useState(null);
  const open2 = Boolean(anchorElSupplier);
  const supplierListRef = React.useRef();
  const filterRef = React.useRef();

  const [anchorElCustomer, setAnchorElCustomer] = React.useState(null);
  const open3 = Boolean(anchorElCustomer);
  const customerListRef = React.useRef();
  const filter2Ref = React.useRef();

  const [isClickFavourite, setIsClickFavourite, isClickFavouriteRef] = useStateRef(false);
  const [loadingFile, setLoadingFile] = React.useState(false);

  const [isCustomEmail, setIsCustomEmail, isCustomEmailRef] = useStateRef(false);
  const [customEmailLoading, setCustomEmailLoading] = React.useState(false);
  const [dataCustomEmail, setDataCustomEmail, dataCustomEmailRef] = useStateRef({
    send_to_email: null,
    cc_email: null
  });

  const [categoryTypeData, setCategoryTypeData, categoryTypeDataRef] = useStateRef(categoryType);
  const [categoryTypeData2, setCategoryTypeData2, categoryTypeDataRef2] = useStateRef(categoryType);
  const [anchorElCategory, setAnchorElCategory] = React.useState(null);
  const open4 = Boolean(anchorElCategory);
  let categoryListRef = React.useRef([]);
  const filter3Ref = React.useRef();

  const [anchorElCategory2, setAnchorElCategory2] = React.useState(null);
  const open5 = Boolean(anchorElCategory2);
  const categoryListRef2 = React.useRef();
  const filter4Ref = React.useRef();

  const [selectedListItem, setSelectedListItem, selectedListItemRef] = useStateRef(null);

  const [employeeID, setEmployeeID, employeeIDRef] = useStateRef(null);

  const [openUpdateCategoryDialog, setOpenUpdateCategoryDialog] = React.useState(false);
  const [updateCategory, setUpdateCategory] = React.useState('category');
  const [isUpdateCategoryDescription, setIsUpdateCategoryDescription, isUpdateCategoryDescriptionRef] = useStateRef(false);

  const [anchorElSettings, setAnchorElSettings] = React.useState(null);
  const openSettings = Boolean(anchorElSettings);

  const [openGenerateInvoiceDialog, setOpenGenerateInvoiceDialog] = React.useState(false);
  const [loadingGenerateInvoice, setLoadingGenerateInvoice] = useState(false);
  const [selectedAttachment, setSelectedAttachment, selectedAttachmentRef] = useStateRef(null);

  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadItems();
      loadCustomer();
      loadSupplier();
      loadCategoryType();
      loadEmployeeData();
    }

    const add = eventData => {
      setAdd(true);
    };
    const listener = EventEmitter.addListener('add', add);

    const onLoadOrders = async eventData => {
      loadItems();
    };
    const listener2 = EventEmitter.addListener('reload_order_item', onLoadOrders);

    return () => {
      listener.remove();
      listener2.remove();
    };
  });

  useEffect(() => {
    loadCategoryType();
  }, [props.categoryType]);
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadEmployeeData = async () => {
    var employee_data = await getEmployee();

    if (employee_data) {
      if (employee_data.user) {
        if (employee_data.user.id) {
          setEmployeeID(employee_data.user.id);
        }
      }
    }
  };

  const loadCustomer = async () => {
    setCustomerLoading(true);
    try {
      // var employee_id = await getEmployee();
      var customer_filter = { status: 'published' };
      // if (!POApproverPermission()) {
      //   customer_filter['selectable'] = 1;
      // }
      var Customers = null;
      // if (AdminPermission()) {

      var fields = `id, code, name, selectable, access_level, accounting_system, legal_name, 
      suppliers.id, suppliers.status, suppliers.supplier.*`;

      var customerCache = getCache('customers');
      if (!customerCache) {
        Customers = await directus.getItems('customers', {
          fields: fields,
          sort: 'name',
          filter: customer_filter
        });

        setCache('customers', Customers.data);
      } else {
        Customers = { data: customerCache };
      }

      Customers = Customers.data;

      // } else {
      //   Customers = await directus.getItems('po_customer_employees', {
      //     fields: 'customer.po_managers.*, customer.*,employee.*,*',
      //     filter: {
      //       "employee": { "in": employee_id.id },
      //       "customer.id": { "nnull": null },
      //       "customer.status": "published",
      //     }
      //   });

      //   if (Customers.data.length > 0) {
      //     var result = Enumerable.from(Customers.data)
      //       .select(data => data.customer)
      //       .toArray();

      //     result = Enumerable.from(result)
      //       .orderBy(o => o.name)
      //       .toArray();
      //     Customers = result;
      //   }

      // }
      var customers_data = Customers;
      var fav_customers = [];

      if (localStorage.directus_employee) {
        var directus_employee = JSON.parse(localStorage.directus_employee);
        if (directus_employee.favourites_customers_po) {
          fav_customers = directus_employee.favourites_customers_po;
        }
      }

      customers_data.map((value, index) => {
        if (fav_customers.indexOf(value.id) >= 0) {
          customers_data[index].isFavourite = true;
        } else {
          customers_data[index].isFavourite = false;
        }
      });

      customers_data = Enumerable.from(customers_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      setCustomers(customers_data);
      setCustomers2(customers_data);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setCustomerLoading(false);
  };

  const loadSupplier = async () => {
    setSupplierLoading(true);
    try {
      var supplierCache = getCache('suppliers');
      var Suppliers = { data: [] };

      if (!supplierCache) {
        var Suppliers = await directus.getItems('suppliers', {
          fields: '*',
          sort: 'name',
          limit: -1,
          filter: {
            status: 'published'
          }
        });

        setCache('suppliers', Suppliers.data);
      } else {
        Suppliers = { data: supplierCache };
      }

      var suppliers_data = Suppliers.data;
      var fav_suppliers = [];

      if (localStorage.directus_employee) {
        var directus_employee = JSON.parse(localStorage.directus_employee);
        if (directus_employee.favourites_suppliers_po) {
          fav_suppliers = directus_employee.favourites_suppliers_po;
        }
      }

      suppliers_data.map((value, index) => {
        if (fav_suppliers.indexOf(value.id) >= 0) {
          suppliers_data[index].isFavourite = true;
        } else {
          suppliers_data[index].isFavourite = false;
        }
      });

      suppliers_data = Enumerable.from(suppliers_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      setSuppliers(suppliers_data);
      setSuppliers2(suppliers_data);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setSupplierLoading(false);
  };

  const loadCategoryType = async () => {
    var categories_data = props.categoryType;
    var fav_categories = [];

    if (localStorage.directus_employee) {
      var directus_employee = JSON.parse(localStorage.directus_employee);
      if (directus_employee.favourites_categories_po) {
        fav_categories = directus_employee.favourites_categories_po;
      }
    }

    categories_data.map((value, index) => {
      if (fav_categories.indexOf(value.id) >= 0) {
        categories_data[index].isFavourite = true;
      } else {
        categories_data[index].isFavourite = false;
      }
    });

    categories_data = Enumerable.from(categories_data)
      .orderBy(item => item.name)
      .orderByDescending(item => item.isFavourite)
      .toArray();

    setCategoryTypeData(categories_data);
    setCategoryTypeData2(categories_data);
  };

  const loadItems = input => {
    setLoadingItems(true);
    let itemData = [];

    if (props.data.order_items.length === 0) {
    } else {
      //eslint-disable-next-line array-callback-return
      props.data.order_items.map((data, index) => {
        itemData[index] = { ...data.order_item, statusValue: 0, count: index, orders_items_id: data.order_item.id };
      });
      setItems(itemData);
      setItemCount(itemData.length);
      var maxSort = linq.from(props.data.order_items).maxBy(s => s.order_item.sort);
      if (maxSort) {
        setMaxSort(maxSort.order_item.sort);
      }
    }
    setLoadingItems(false);
  };

  const addItems = itemVar => {
    if (itemVar === null) {
      let data = [];
      data.id = uuidv4();
      data.statusValue = 1;
      data.count = 0;
      data.category_type = null;
      data.category_description = null;
      if (category_type) {
        data.category_type = category_type;
        data.category_description = categoryDescriptionRef.current;
      }
      setItems([data]);
      setItemCount(data.count);
    } else {
      let data = [];
      data.id = uuidv4();
      data.statusValue = 1;
      data.count = itemsRef.current[itemsRef.current.length - 1].count + 1;
      if (category_type) {
        data.category_type = category_type;
        data.category_description = categoryDescriptionRef.current;
        data.sort = maxSort + 1;
        setMaxSort(data.sort);
      }
      setItems([...itemsRef.current, data]);
      setItemCount(data.count);
    }

    addItemsCheck(itemCountRef.current);
  };

  const changeItems = (e, oldData, newData, checkCategory = false) => {
    var name;
    var value;
    if (e) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = newData.name;
      value = newData.value;
    }

    if (checkCategory) {
      oldData = selectedListItemRef.current;
    }

    if (name === 'category_type') {
      //eslint-disable-next-line array-callback-return
      setItems(
        items.map(dataList =>
          dataList.id === oldData.id
            ? dataList.statusValue === 1
              ? { ...oldData, statusValue: 1, category_description: null, [name]: value }
              : { ...oldData, statusValue: 2, category_description: null, [name]: value }
            : dataList
        )
      );
    } else {
      setItems(
        items.map(dataList =>
          dataList.id === oldData.id
            ? dataList.statusValue === 1
              ? { ...oldData, statusValue: 1, [name]: value }
              : { ...oldData, statusValue: 2, [name]: value }
            : dataList
        )
      );
    }

    countTotal();
    addItemsCheck(itemCountRef.current);
  };

  const deleteItems = itemData => {
    if (itemData.statusValue === 1) {
      setItems(itemsRef.current.filter(data => data.count !== itemData.count));
    } else {
      setItems(itemsRef.current.map(data => (data.count === itemData.count ? { ...data, statusValue: 3 } : data)));
    }
    if (itemData.sort !== null && itemData.sort !== undefined) {
      if (itemData.sort === maxSortRef.current) {
        var maxSort = linq.from(itemsRef.current).maxBy(s => s.sort);
        if (maxSort) {
          setMaxSort(maxSort.sort);
        }
      }
    }
    deleteItemsCheck(itemData);
    addItemsCheck(itemCountRef.current);
    countTotal();
  };

  const countTotal = () => {
    let total = 0;

    //eslint-disable-next-line array-callback-return
    itemsRef.current.map(data => {
      if (data.statusValue !== 3) {
        if (data.unit_price && data.quantity) {
          total = total + parseFloat(data.unit_price * data.quantity);
        }
      }
    });
    setTotalOrderItem(total.toFixed(2));
  };

  const deleteItemsCheck = itemData => {
    let items = [];
    items = itemsRef.current.filter(data => data.count !== itemData.count);

    /* eslint-disable eqeqeq*/
    if (
      items.quantity == '' ||
      items.quantity === 0 ||
      items.quantity === '0' ||
      items.description == '' ||
      items.quantity == undefined ||
      items.quantity < 0 ||
      items.description == undefined ||
      items.category_type === undefined ||
      items.category_type === '' ||
      items.category_type === null
    ) {
      setItemCheck(false);
    } else {
      if (
        (items.category_type.show_category || items.category_type.show_as_textbox) &&
        items.category_type.required &&
        (items.category_description === undefined || items.category_description === '' || items.category_description === null)
      ) {
        setItemCheck(false);
      } else {
        setItemCheck(true);
      }
    }
    /* eslint-enable eqeqeq*/
  };

  const addItemsCheck = itemCount => {
    let saveItems = [];
    //eslint-disable-next-line array-callback-return
    itemsRef.current.map(data => {
      if (data.statusValue !== 3) {
        saveItems = itemsRef.current;
        if (itemCount === 0) {
          if (
            data.quantity === undefined ||
            data.quantity === '' ||
            data.quantity === 0 ||
            data.quantity === '0' ||
            data.quantity < 0 ||
            data.description === undefined ||
            data.description === '' ||
            data.category_type === undefined ||
            data.category_type === '' ||
            data.category_type === null
          ) {
            setItemCheck(false);
          } else {
            if (
              (data.category_type.show_category || data.category_type.show_as_textbox) &&
              data.category_type.required &&
              (data.category_description === undefined || data.category_description === '' || data.category_description === null)
            ) {
              setItemCheck(false);
            } else {
              setItemCheck(true);
            }
          }
        } else {
          if (
            data.quantity === undefined ||
            data.quantity === '' ||
            data.quantity === 0 ||
            data.quantity === '0' ||
            data.quantity < 0 ||
            data.description === undefined ||
            data.description === '' ||
            data.category_type === undefined ||
            data.category_type === '' ||
            data.category_type === null
          ) {
            setItemCheck(false);
          } else {
            if (
              (data.category_type.show_category || data.category_type.show_as_textbox) &&
              data.category_type.required &&
              (data.category_description === undefined || data.category_description === '' || data.category_description === null)
            ) {
              setItemCheck(false);
            } else {
              setItemCheck(true);
            }
          }
        }
      }
    });

    //eslint-disable-next-line array-callback-return
    saveItems.map(data => {
      if (
        data.quantity === undefined ||
        data.quantity === '' ||
        data.quantity === 0 ||
        data.quantity === '0' ||
        data.quantity < 0 ||
        data.description === undefined ||
        data.description === '' ||
        data.category_type === undefined ||
        data.category_type === '' ||
        data.category_type === null
      ) {
        setItemCheck(false);
      } else {
        if (
          (data.category_type.show_category || data.category_type.show_as_textbox) &&
          data.category_type.required &&
          (data.category_description === undefined || data.category_description === '' || data.category_description === null)
        ) {
          setItemCheck(false);
        }
      }
    });
  };

  /* eslint-enable no-unused-vars*/
  useEffect(() => {
    if (data.invoices) {
      var matched_invoice_data = [];
      data.invoices.map(value => {
        let invoice_data = value.invoices_id;
        let totalOrder = 0;

        if (invoice_data) {
          invoice_data.orders.map(order_value => {
            var order_data = order_value.orders_id;
            if (order_data) {
              order_data.order_items.map(item => {
                if (item.order_item) {
                  totalOrder = totalOrder + item.order_item.unit_price * item.order_item.quantity;
                }
              });
            }
          });

          if (!totalOrder) {
            totalOrder = invoice_data.total_amount;
          }

          matched_invoice_data.push({
            date: invoice_data.date,
            number: invoice_data.number,
            invoice_amount: totalOrder,
            date_matched: value.date_matched,
            id: invoice_data.id
          });
        }
      });

      if (matched_invoice_data.length > 0) {
        matched_invoice_data = linq
          .from(matched_invoice_data)
          .orderBy(o => o.date)
          .toArray();
      }

      setMatchedInvoiceData(matched_invoice_data);
    }
  }, []);

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'note') {
      setNote(value);
    } else if (name === 'email') {
      setEmail(value);
    } else if (name === 'delivery_instructions') {
      setDeliveryInstructions(value);
    }
  };

  const changeSupplier = newValue => {
    setSupplier(newValue);
  };

  const changeCustomer = newValue => {
    setCustomer(newValue);
  };

  const changeCategory = newValue => {
    var showWarning = true;
    if (category_type && newValue) {
      if (category_type.id === newValue.id) {
        showWarning = false;
      }
    }

    setCategoryDescription(null);
    setCategory_type(newValue);

    if (itemsRef.current) {
      if (itemsRef.current.length > 0) {
        if (showWarning) {
          setUpdateCategory('category');
          handleOpenUpdateCategoryDialog();
        }
      }
    }
  };

  const handleCloseUpdateCategoryDialog = () => {
    setOpenUpdateCategoryDialog(false);
  };

  const handleOpenUpdateCategoryDialog = () => {
    setOpenUpdateCategoryDialog(true);
  };

  const updateAllCategory = () => {
    var new_items = [];

    if (itemsRef.current) {
      if (updateCategory === 'category') {
        itemsRef.current.map(value => {
          value.category_type = category_type;
          value.category_description = '';
          if (value.statusValue != 3 && value.statusValue != 1) {
            value.statusValue = 2;
          }
          new_items.push(value);
        });
      } else {
        itemsRef.current.map(value => {
          value.category_description = categoryDescriptionRef.current;
          if (value.statusValue != 3 && value.statusValue != 1) {
            value.statusValue = 2;
          }
          new_items.push(value);
        });
      }
    }

    setItems(new_items);
    countTotal();
    addItemsCheck(itemCountRef.current);
    handleCloseUpdateCategoryDialog();
  };

  const changeCategoryDescription = (newValue, isText) => {
    if (!isText) {
      var showWarning = true;
      if (categoryDescriptionRef.current && newValue) {
        if (categoryDescriptionRef.current.id === newValue.id) {
          showWarning = false;
        }
      }

      setCategoryDescription(newValue);
      if (itemsRef.current) {
        if (itemsRef.current.length > 0) {
          if (showWarning) {
            setUpdateCategory('description');
            handleOpenUpdateCategoryDialog();
          }
        }
      }
    } else {
      if (newValue !== categoryDescriptionRef.current) {
        setCategoryDescription(newValue);
        setIsUpdateCategoryDescription(true);
      } else {
        setCategoryDescription(newValue);
        // setIsUpdateCategoryDescription(false);
      }
    }
  };

  const checkOrderDescription = () => {
    if (itemsRef.current) {
      if (itemsRef.current.length > 0) {
        if (isUpdateCategoryDescriptionRef.current) {
          setUpdateCategory('description');
          handleOpenUpdateCategoryDialog();
        }
      }
    }
    setIsUpdateCategoryDescription(false);
  };

  const handleClickShowChangeLog = async id => {
    if (!showChangeLog) {
      var OrderEvent = await directus.getItems('order_event', {
        fields: '*.*',
        filter: {
          order: id
        },
        sort: 'created_on'
      });

      setLogData(OrderEvent.data);
    }
    setShowChangeLog(!showChangeLog);
  };

  const handleClickExportActionLog = async id => {
    const react_app_url = process.env.REACT_APP_URL;
    downloadFile(`${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/export_order/action_log/${id}`);
  };

  const downloadFile = url => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop();
    link.click();
  };

  /* eslint-disable array-callback-return*/
  const editSubmit = async (id, type, send_supplier = false, custom_send = false) => {
    setLoadingSubmit(true);

    let addItems = [];
    let editItems = [];
    let delItems = [];
    let delItems2 = [];

    var sort = maxSort + 1;
    if (items) {
      items.map((data, index) => {
        if (data.statusValue === 3) {
          delItems = [...delItems, data.orders_items_id];
          delItems2 = [...delItems2, data.id];
        }
        if (data.statusValue === 2) {
          if (data.sort !== null && data.sort !== undefined && data.sort !== '') {
            editItems = [
              ...editItems,
              {
                id: data.id,
                description: data.description,
                quantity: data.quantity,
                unit_price: data.unit_price,
                cc_email: data.cc_email,
                note: data.note,
                category_type: data.category_type.id,
                category_description: data.category_description
                  ? data.category_description.id
                    ? data.category_description.id
                    : data.category_description
                  : data.category_description,
                supplier_code: data.supplier_code,
                sort: data.sort
              }
            ];
          } else {
            editItems = [
              ...editItems,
              {
                id: data.id,
                description: data.description,
                quantity: data.quantity,
                unit_price: data.unit_price,
                cc_email: data.cc_email,
                note: data.note,
                category_type: data.category_type.id,
                category_description: data.category_description
                  ? data.category_description.id
                    ? data.category_description.id
                    : data.category_description
                  : data.category_description,
                supplier_code: data.supplier_code
              }
            ];
          }
        }

        if (data.statusValue === 1 && data.description) {
          var defaultSort = sort;
          if (data.sort !== null && data.sort !== undefined) {
            defaultSort = data.sort;
          }
          addItems = [
            ...addItems,
            {
              order: { id: id },
              order_item: {
                id: data.id,
                description: data.description,
                quantity: data.quantity,
                unit_price: data.unit_price,
                category_type: data.category_type.id,
                category_description: data.category_description
                  ? data.category_description.id
                    ? data.category_description.id
                    : data.category_description
                  : data.category_description,
                sort: defaultSort,
                supplier_code: data.supplier_code
              }
            }
          ];
          sort++;
        }
      });
    }

    if (delItems.length > 0) {
      await directus.deleteItems('orders_items', delItems);
      await directus.deleteItems('order_items', delItems2);
    }

    if (editItems.length > 0) {
      await directus.updateItems('order_items', editItems);
    }

    if (addItems.length > 0) {
      for (let index = 0; index < addItems.length; index++) {
        var addItem = addItems[index];
        delete addItem.order_item.id;

        var orderItemResult = await directus.createItem('order_items', addItem.order_item);
        addItems[index].order_item = { id: orderItemResult.data.id };
      }

      await directus.createItems('orders_items', addItems);
    }

    if (custom_send) {
      await directus.updateItem(
        'orders',
        id,
        {
          notes: note,
          delivery_instructions: deliveryInstructions,
          // cc_email: email,
          supplier: supplier.id,
          farm_code: customer.id,
          category_type: category_type.id,
          category_description: categoryDescription ? (categoryDescription.id ? categoryDescription.id : categoryDescription) : categoryDescription,
          due_date: selectedDate ? moment(new Date(selectedDate)).format('YYYY-MM-DD') : null,
          status: type,
          send_to_email: dataCustomEmailRef.current.send_to_email,
          cc_email: dataCustomEmailRef.current.cc_email
        },
        { send_supplier: send_supplier, custom_send: custom_send }
      );
    } else {
      await directus.updateItem(
        'orders',
        id,
        {
          notes: note,
          delivery_instructions: deliveryInstructions,
          cc_email: email,
          supplier: supplier.id,
          farm_code: customer.id,
          category_type: category_type.id,
          category_description: categoryDescription ? (categoryDescription.id ? categoryDescription.id : categoryDescription) : categoryDescription,
          due_date: selectedDate ? moment(new Date(selectedDate)).format('YYYY-MM-DD') : null,
          status: type
        },
        { send_supplier: send_supplier }
      );
    }

    // await loadOrders();
    EventEmitter.emit('load_orders', { value: true });

    setLoadingSubmit(false);
    var message = type === 'draft' ? 'Order has been saved as a draft' : 'Order has been sent';
    enqueueSnackbar(message, {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      variant: 'success',
      autoHideDuration: 3200,
      action: CloseSnackbarAction
    });

    if (custom_send) {
      setCustomEmailLoading(false);
      closeCustomEmail();
    }
  };

  const formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  const NumResultsHeader = ({ children, dataItem, collection_data, ...other }) => {
    const headerRef = React.useRef();
    const countRef = React.useRef();
    const paperRef = React.useRef();
    React.useEffect(() => {
      const numOptions = paperRef.current.querySelectorAll('li[data-option-index]').length;
      countRef.current.innerHTML = numOptions;
      if (numOptions > 0) {
        headerRef.current.style.display = 'block';
      } else {
        headerRef.current.style.display = 'none';
      }
    });

    var total_number = 0;

    if (collection_data) {
      total_number = collection_data.length;
    }

    return (
      <div style={{ background: 'white', borderRadius: '0 0 5px 5px' }}>
        <Paper {...other} ref={paperRef}>
          {children}
        </Paper>
        <div ref={headerRef} style={{ display: 'none', textAlign: 'right', padding: '5px' }}>
          Showing <span ref={countRef}></span> of {total_number}
        </div>
      </div>
    );
  };

  const getDescription = value => {
    var desc = [];
    var disableStatus = false;
    if (collection.length > 0) {
      collection.map((category_value, index) => {
        if (category_value.settings.name === value.name) {
          if (category_value.settings.show_category && !category_value.settings.show_as_textbox) {
            var label_text =
              category_value.settings.name === 'Activity'
                ? 'Select Activity'
                : category_value.settings.name === 'R&M'
                  ? 'Select Equipment'
                  : 'Select';
            var requiredCategory = false;
            if (!categoryDescription) {
              if (value) {
                if (value.required) {
                  requiredCategory = true;
                }
              }
            } else if (!categoryDescription.id) {
              requiredCategory = true;
            }

            var collection_data =
              category_value.collection_index !== null
                ? collectionData[category_value.collection_index]
                  ? collectionData[category_value.collection_index].data
                  : []
                : [];
            desc.push(
              <Autocomplete
                key={index}
                id="order_category_description"
                size="small"
                className={requiredCategory ? classes.requiredAutoComplete : ''}
                options={collection_data}
                getOptionLabel={option => option.name}
                style={{ marginRight: '10px' }}
                onChange={(event, newValue) => {
                  changeCategoryDescription(newValue);
                }}
                // getOptionSelected={(option, value) => option.id === value.id}
                // value={categoryDescription ? categoryDescription.id ? categoryDescription : null : null}
                PaperComponent={({ children }) => (
                  <div>
                    {NumResultsHeader({
                      children: children,
                      className: 'MuiAutocomplete-paper',
                      type: 'category_type',
                      collection_data
                    })}
                  </div>
                )}
                renderOption={(props, option) => {
                  const matches = match(props.name, option.inputValue);
                  const parts = parse(props.name, matches);

                  return (
                    <div style={{ overflowWrap: 'anywhere' }}>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  );
                }}
                renderInput={params => (
                  <CssTextField
                    {...params}
                    variant="outlined"
                    name="category_description"
                    // value={categoryDescription ? categoryDescription.id ? categoryDescription.name : null : null}
                    placeholder={categoryDescription ? (categoryDescription.id ? categoryDescription.name : label_text) : label_text}
                  />
                )}
              />
            );
          } else if (category_value.settings.show_as_textbox) {
            var requiredCategory2 = false;
            if (!categoryDescription) {
              if (value) {
                if (value.required) {
                  requiredCategory2 = true;
                }
              }
            } else {
              if (categoryDescription.id) {
                requiredCategory2 = true;
              }
            }
            desc.push(
              <CssTextField
                key={index}
                id="order_category_description"
                className={requiredCategory2 ? classes.textFieldRequired : classes.textField}
                fullWidth
                name="category_description"
                variant="outlined"
                autoComplete="none"
                style={{ paddingRight: '10px' }}
                placeholder="Write Description"
                onChange={e => changeCategoryDescription(e.target.value, true)}
                onBlur={e => checkOrderDescription()}
                value={categoryDescription ? (categoryDescription.id ? '' : categoryDescription) : ''}
              />
            );
          } else {
            disableStatus = true;
          }
        }
      });
    } else {
      disableStatus = true;
    }

    if (disableStatus) {
      // desc.push(
      //   <CssTextField
      //     key={"none"}
      //     disabled
      //     fullWidth
      //     name="category_description"
      //     variant="outlined"
      //     autoComplete="none"
      //     style={{ paddingRight: "10px" }}
      //   />
      // )
    }

    return <div>{desc}</div>;
  };

  const getItemDescription = (dataItem, ind) => {
    var desc = [];
    var disableStatus = false;
    if (dataItem.category_type) {
      if (collection.length > 0) {
        collection.map((category_value, index) => {
          if (category_value.settings.name === dataItem.category_type.name) {
            if (category_value.settings.show_category && !category_value.settings.show_as_textbox) {
              var label_text =
                category_value.settings.name === 'Activity'
                  ? 'Select Activity'
                  : category_value.settings.name === 'R&M'
                    ? 'Select Equipment'
                    : 'Select';
              var requiredCategory = false;
              if (!dataItem.category_description) {
                if (dataItem) {
                  if (dataItem.category_type) {
                    requiredCategory = dataItem.category_type.required;
                  }
                }
              }

              var collection_data =
                category_value.collection_index !== null
                  ? collectionData[category_value.collection_index]
                    ? collectionData[category_value.collection_index].data
                    : []
                  : [];

              desc.push(
                <Autocomplete
                  key={index}
                  id={`order_item_category_desc_${ind}`}
                  size="small"
                  className={requiredCategory ? classes.requiredAutoComplete : ''}
                  options={collection_data}
                  getOptionLabel={option => (option ? (option.name ? option.name : '') : '')}
                  onChange={(event, newValue) => {
                    changeItems(null, dataItem, { name: 'category_description', value: newValue });
                  }}
                  // getOptionSelected={(option) => option.id === dataItem.category_description ? dataItem.category_description.id ? dataItem.category_description : null : null}
                  // value={dataItem.category_description ? dataItem.category_description.id ? dataItem.category_description : null : null}
                  PaperComponent={({ children }) => (
                    <div>
                      {NumResultsHeader({
                        children: children,
                        className: 'MuiAutocomplete-paper',
                        type: 'category_description',
                        dataItem,
                        collection_data
                      })}
                    </div>
                  )}
                  value={dataItem.category_description ? (dataItem.category_description.id ? dataItem.category_description.name : null) : null}
                  renderInput={params => (
                    <CssTextField
                      {...params}
                      variant="outlined"
                      name="category_type"
                      placeholder={
                        dataItem.category_description
                          ? dataItem.category_description.id
                            ? dataItem.category_description.name
                            : label_text
                          : label_text
                      }
                    // placeholder={listRef.current.order_items[ind] ? listRef.current.order_items[ind].order_item.category_description ? listRef.current.order_items[ind].order_item.category_description.id ? listRef.current.order_items[ind].order_item.category_description.name : label_text : label_text : label_text}
                    />
                  )}
                  renderOption={(props, option) => {
                    const matches = match(props.name, option.inputValue);
                    const parts = parse(props.name, matches);

                    return (
                      <div style={{ overflowWrap: 'anywhere' }}>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    );
                  }}
                />
              );
            } else if (category_value.settings.show_as_textbox) {
              var requiredCategory2 = false;
              if (!dataItem.category_description) {
                if (dataItem.category_type) {
                  if (dataItem.category_type.required) {
                    requiredCategory2 = true;
                  }
                }
              } else {
                if (dataItem.category_description.id) {
                  requiredCategory2 = true;
                }
              }

              desc.push(
                <CssTextField
                  key={index}
                  id={`order_item_category_desc_${ind}`}
                  className={requiredCategory2 ? classes.textFieldRequired : classes.textField}
                  fullWidth
                  name="category_description"
                  variant="outlined"
                  autoComplete="none"
                  placeholder="Write Description"
                  onChange={e => changeItems(null, dataItem, { name: 'category_description', value: e.target.value })}
                  value={dataItem.category_description ? (dataItem.category_description.id ? '' : dataItem.category_description) : ''}
                />
              );
            } else {
              disableStatus = true;
            }
          }
        });
      } else {
        disableStatus = true;
      }
    } else {
      disableStatus = true;
    }

    return <div style={{ width: '100%' }}>{desc}</div>;
  };

  var disabledCheck = supplier === null || customer === null || category_type === null || itemCheck === false;
  if (category_type) {
    if (category_type.show_category || category_type.show_as_textbox) {
      if (category_type.required) {
        if (categoryDescription === null || categoryDescription === '') {
          disabledCheck = true;
        }
      }
    }
  }

  const navigateToInvoice = value => {
    EventEmitter.emit('hideFilter', { value: true });
    EventEmitter.emit('reload_item', { value: `/invoice/${value.id}` });
    navigate(`/invoice/${value.id}`, { state: { id: '', arrow: '', prevLocation: window.location.pathname } });
  };

  const duplicateData = async value => {
    setLoadingDuplicate(true);
    var directusUser = JSON.parse(window.localStorage.getItem('directus_employee'));
    const date = moment().format('YYYY-MM-DD HH:mm:ss');
    var order_body = {
      employee: directusUser.id,
      status: 'draft',
      order_date: date,
      notes: value.notes,
      cc_email: value.cc_email,
      supplier: value.supplier.id,
      farm_code: value.farm_code.id,
      category_type: value.category_type.id,
      category_description: value.category_description
        ? value.category_description.id
          ? value.category_description.id
          : value.category_description
        : value.category_description
      // due_date: value.due_date
    };

    const orderResult = await directus.createItems('orders', order_body);

    var order_items = value.order_items;
    var order_list = [];
    if (order_items) {
      order_items.map(data => {
        order_list = [
          ...order_list,
          {
            order: { id: orderResult.data.id },
            order_item: {
              description: data.order_item.description,
              quantity: data.order_item.quantity,
              unit_price: data.order_item.unit_price,
              category_type: data.order_item.category_type.id,
              category_description: data.order_item.category_description
                ? data.order_item.category_description.id
                  ? data.order_item.category_description.id
                  : data.order_item.category_description
                : '',
              sort: data.order_item.sort,
              supplier_code: data.order_item.supplier_code
            }
          }
        ];
      });

      for (let index = 0; index < order_list.length; index++) {
        let order_item_list = order_list[index];
        let order_item = order_item_list.order_item;
        delete order_item.id;
        var orderItemResult = await directus.createItems('order_items', order_item);
        if (orderItemResult && orderItemResult.data) {
          order_item_list.order_item = { id: orderItemResult.data.id };
          order_list[index] = order_item_list;
        }
      }

      await directus.createItems('orders_items', order_list);
    }

    await props.loadOrders();

    goToFirstPage();

    if (orderResult.data) {
      editMode(orderResult.data.id, true);
    }

    var message = 'Order has been duplicated';
    enqueueSnackbar(message, {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      variant: 'success',
      autoHideDuration: 3200,
      action: CloseSnackbarAction
    });
    setLoadingDuplicate(false);
  };
  /* eslint-enable array-callback-return*/

  const handleCloseDeleteOrderDialog = () => {
    setOpenDeleteOrderDialog(false);
  };

  const handleOpenDeleteOrderDialog = () => {
    setOpenDeleteOrderDialog(true);
  };

  const deleteOrder = async () => {
    setLoadingDeleteDialog(true);
    try {
      var orders = await directus.getItems('orders', {
        fields: 'id,order_items.*,files.*',
        filter: {
          id: list.id
        }
      });

      let delItems = [];
      let delItems2 = [];
      let delFiles = [];

      if (orders.data[0]) {
        var deleted_order = orders.data[0];
        if (deleted_order.order_items) {
          if (deleted_order.order_items.length > 0) {
            //eslint-disable-next-line array-callback-return
            deleted_order.order_items.map(value => {
              delItems.push(value.id);
              delItems2.push(value.order_item);
            });
          }
        }

        if (deleted_order.files) {
          if (deleted_order.files.length > 0) {
            deleted_order.files.map(value => {
              delFiles.push(value.id);
            });
          }
        }
      }

      if (delItems.length > 0) {
        await directus.deleteItems('orders_items', delItems);
        await directus.deleteItems('order_items', delItems2);
      }

      if (delFiles.length > 0) {
        await directus.deleteItems('order_files', delFiles);
      }

      await directus.deleteItem('orders', list.id);
      enqueueSnackbar('Data deleted successfully', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'success',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setLoadingDeleteDialog(false);
    handleCloseDeleteOrderDialog();

    loadOrders();
    setIsEdit(false);
  };

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const updateStatus = async (id, status) => {
    setLoadingStatusCompleted(true);
    handleCloseDialog();

    await directus.updateItem('orders', id, {
      status: status
    });

    loadOrders();
    setLoadingStatusCompleted(false);

    var message = 'Status has changed to ' + status;
    enqueueSnackbar(message, {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      variant: 'success',
      autoHideDuration: 3200,
      action: CloseSnackbarAction
    });
  };

  const navigateToOtherInvoice = id => {
    navigate(`/invoice/${id}`, { state: { prevLocation: window.location.pathname } });
  };

  const stopPropagation = e => {
    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Home':
      case 'End':
        break;
      default:
        e.stopPropagation();
    }
  };

  const saveSupplier = e => {
    if (e.key === 'Enter') {
      if (suppliersRef.current && suppliersRef2.current) {
        if (suppliersRef.current.length > 0) {
          if (suppliersRef.current.length != suppliersRef2.current.length || suppliersRef2.current.length === 1) {
            setSupplier(suppliersRef.current[0]);
            setAnchorElSupplier(null);
            setSuppliers(suppliersRef2.current);
          }
        }
      }
    }
  };

  const saveCustomer = e => {
    if (e.key === 'Enter') {
      if (customersRef.current && customersRef2.current) {
        if (customersRef.current.length > 0) {
          if (customersRef.current.length != customersRef2.current.length || customersRef2.current.length === 1) {
            clickCustomer(customersRef.current[0]);
            setCustomers(customersRef2.current);
          }
        }
      }
    }
  };

  const saveCategory = e => {
    if (e.key === 'Enter') {
      if (categoryTypeDataRef.current && categoryTypeDataRef2.current) {
        if (categoryTypeDataRef.current.length > 0) {
          if (categoryTypeDataRef.current.length != categoryTypeDataRef2.current.length || categoryTypeDataRef2.current.length === 1) {
            changeCategory(categoryTypeDataRef.current[0]);
            setAnchorElCategory2(null);
            setCategoryTypeData(categoryTypeDataRef2.current);
          }
        }
      }
    }
  };

  const saveCategoryOrder = (e, dataItem) => {
    if (e.key === 'Enter') {
      if (categoryTypeDataRef.current && categoryTypeDataRef2.current) {
        if (categoryTypeDataRef.current.length > 0) {
          if (categoryTypeDataRef.current.length != categoryTypeDataRef2.current.length || categoryTypeDataRef2.current.length === 1) {
            changeItems(null, dataItem, { name: 'category_type', value: categoryTypeDataRef.current[0] }, true);
            setAnchorElCategory(null);
            setCategoryTypeData(categoryTypeDataRef2.current);
          }
        }
      }
    }
  };

  const checkKeyDown = (e, type) => {
    if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') {
      if (type === 1) {
        filterRef.current.focus();
      } else if (type === 2) {
        filter2Ref.current.focus();
      } else if (type === 3) {
        filter3Ref.current.focus();
      } else if (type === 4) {
        filter4Ref.current.focus();
      }
    }
  };

  const moveFocusToInput = (e, type) => {
    if (e.key === 'Tab' || e.key === 'ArrowRight') {
      e.stopPropagation();
      e.preventDefault();
      if (type === 1) {
        filterRef.current.focus();
      } else if (type === 2) {
        filter2Ref.current.focus();
      } else if (type === 3) {
        filter3Ref.current.focus();
      } else if (type === 4) {
        filter4Ref.current.focus();
      }
    }
  };

  // supplier favourite
  const clickSupplierFavourite = data => {
    setIsClickFavourite(true);
    if (suppliersRef.current) {
      let suppliers_data = suppliersRef.current;
      const index = suppliers_data.map(o => o.id).indexOf(data.id);
      if (index >= 0) {
        var isFavourite = suppliers_data[index].isFavourite;
        suppliers_data[index].isFavourite = !isFavourite;
      }

      suppliers_data = Enumerable.from(suppliers_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      setSuppliers(suppliers_data);
    }
  };

  const searchSupplier = e => {
    filterRef.current.focus();
    var value = e.target.value.toLowerCase();
    if (value) {
      var result = Enumerable.from(suppliersRef2.current)
        .where(
          w =>
            (w.name ? w.name.toLowerCase().includes(value) : ''.toLowerCase().includes(value)) ||
            (w.code ? w.code.toLowerCase().includes(value) : ''.toLowerCase().includes(value))
        )
        .toArray();

      setSuppliers(result);
    } else {
      setSuppliers(suppliersRef2.current);
    }
  };

  const resetSupplier = async e => {
    setAnchorElSupplier(null);
    try {
      let suppliers_data2 = suppliersRef2.current;
      suppliers_data2 = Enumerable.from(suppliers_data2)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();
      setSuppliers(suppliers_data2);

      if (isClickFavouriteRef.current) {
        var fav_suppliers_data = Enumerable.from(suppliers_data2)
          .where(w => w.isFavourite === true)
          .select(data => data.id)
          .toArray();

        var fav_suppliers = [];
        var directus_employee = null;
        if (localStorage.directus_employee) {
          directus_employee = JSON.parse(localStorage.directus_employee);
          if (directus_employee.favourites_suppliers_po) {
            fav_suppliers = directus_employee.favourites_suppliers_po;
          }
        }

        var isUpdateFavourite = false;
        if (fav_suppliers.length === 0 && fav_suppliers_data.length === 0) {
          isUpdateFavourite = true;
        } else if (fav_suppliers.length !== fav_suppliers_data.length) {
          isUpdateFavourite = true;
        } else {
          isUpdateFavourite = true;
          fav_suppliers_data.map(value => {
            if (fav_suppliers.indexOf(value) >= 0) {
              isUpdateFavourite = false;
            }
          });

          if (!isUpdateFavourite) {
            isUpdateFavourite = true;
            fav_suppliers.map(value => {
              if (fav_suppliers_data.indexOf(value) >= 0) {
                isUpdateFavourite = false;
              }
            });
          }
        }

        if (isUpdateFavourite) {
          directus_employee.favourites_suppliers_po = fav_suppliers_data;
          localStorage.directus_employee = JSON.stringify(directus_employee);
          await directus.updateItem('employees', directus_employee.id, { favourites_suppliers_po: fav_suppliers_data });
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setIsClickFavourite(false);
  };

  // customer favourite
  const clickCustomerFavourite = data => {
    var enable_click = false;
    if (data.selectable) {
      enable_click = true;
    }

    if (data.access_level) {
      if (data.access_level === 'po_creator') {
        if (!POCreatorPermission()) {
          enable_click = false;
        }
      } else if (data.access_level === 'po_approver') {
        if (!POApproverPermission()) {
          enable_click = false;
        }
      } else if (data.access_level === 'invoice_admin') {
        if (!InvoiceAdminPermission()) {
          enable_click = false;
        }
      } else if (data.access_level === 'invoice_admin') {
        if (!AdminPermission()) {
          enable_click = false;
        }
      }
    }

    if (AdminPermission()) {
      enable_click = true;
    }

    if (enable_click) {
      setIsClickFavourite(true);
      if (customersRef.current) {
        let customers_data = customersRef.current;
        const index = customers_data.map(o => o.id).indexOf(data.id);
        if (index >= 0) {
          var isFavourite = customers_data[index].isFavourite;
          customers_data[index].isFavourite = !isFavourite;
        }

        customers_data = Enumerable.from(customers_data)
          .orderBy(item => item.name)
          .orderByDescending(item => item.isFavourite)
          .toArray();

        setCustomers(customers_data);
      }
    }
  };

  const searchCustomer = e => {
    var value = e.target.value.toLowerCase();
    if (value) {
      var result = Enumerable.from(customersRef2.current)
        .where(
          w =>
            (w.name ? w.name.toLowerCase().includes(value) : ''.toLowerCase().includes(value)) ||
            (w.code ? w.code.toLowerCase().includes(value) : ''.toLowerCase().includes(value))
        )
        .toArray();

      setCustomers(result);
    } else {
      setCustomers(customersRef2.current);
    }
  };

  const resetCustomer = async e => {
    setAnchorElCustomer(null);
    try {
      let customers_data2 = customersRef2.current;
      customers_data2 = Enumerable.from(customers_data2)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();
      setCustomers(customers_data2);

      if (isClickFavouriteRef.current) {
        var fav_customers_data = Enumerable.from(customers_data2)
          .where(w => w.isFavourite === true)
          .select(data => data.id)
          .toArray();

        var fav_customers = [];
        var directus_employee = null;
        if (localStorage.directus_employee) {
          directus_employee = JSON.parse(localStorage.directus_employee);
          if (directus_employee.favourites_customers_po) {
            fav_customers = directus_employee.favourites_customers_po;
          }
        }

        var isUpdateFavourite = false;
        if (fav_customers.length === 0 && fav_customers_data.length === 0) {
          isUpdateFavourite = true;
        } else if (fav_customers.length !== fav_customers_data.length) {
          isUpdateFavourite = true;
        } else {
          isUpdateFavourite = true;
          fav_customers_data.map(value => {
            if (fav_customers.indexOf(value) >= 0) {
              isUpdateFavourite = false;
            }
          });

          if (!isUpdateFavourite) {
            isUpdateFavourite = true;
            fav_customers.map(value => {
              if (fav_customers_data.indexOf(value) >= 0) {
                isUpdateFavourite = false;
              }
            });
          }
        }

        if (isUpdateFavourite) {
          directus_employee.favourites_customers_po = fav_customers_data;
          localStorage.directus_employee = JSON.stringify(directus_employee);
          await directus.updateItem('employees', directus_employee.id, { favourites_customers_po: fav_customers_data });
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setIsClickFavourite(false);
  };

  // category favourite
  const clickCategoryFavourite = data => {
    setIsClickFavourite(true);
    if (categoryTypeDataRef.current) {
      let category_data = categoryTypeDataRef.current;
      const index = category_data.map(o => o.id).indexOf(data.id);
      if (index >= 0) {
        var isFavourite = category_data[index].isFavourite;
        category_data[index].isFavourite = !isFavourite;
      }

      category_data = Enumerable.from(category_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      setCategoryTypeData(category_data);
    }
  };

  const searchCategory = e => {
    var value = e.target.value.toLowerCase();
    if (value) {
      var result = Enumerable.from(categoryTypeDataRef2.current)
        .where(
          w =>
            (w.name ? w.name.toLowerCase().includes(value) : ''.toLowerCase().includes(value)) ||
            (w.code ? w.code.toLowerCase().includes(value) : ''.toLowerCase().includes(value))
        )
        .toArray();

      setCategoryTypeData(result);
    } else {
      setCategoryTypeData(categoryTypeDataRef2.current);
    }
  };

  const resetCategory = async e => {
    setAnchorElCategory(null);
    setAnchorElCategory2(null);
    try {
      let category_data2 = categoryTypeDataRef2.current;
      category_data2 = Enumerable.from(category_data2)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();
      setCategoryTypeData(category_data2);

      if (isClickFavouriteRef.current) {
        var fav_category_data = Enumerable.from(category_data2)
          .where(w => w.isFavourite === true)
          .select(data => data.id)
          .toArray();

        var fav_categories = [];
        var directus_employee = null;
        if (localStorage.directus_employee) {
          directus_employee = JSON.parse(localStorage.directus_employee);
          if (directus_employee.favourites_categories_po) {
            fav_categories = directus_employee.favourites_categories_po;
          }
        }

        var isUpdateFavourite = false;
        if (fav_categories.length === 0 && fav_category_data.length === 0) {
          isUpdateFavourite = true;
        } else if (fav_categories.length !== fav_category_data.length) {
          isUpdateFavourite = true;
        } else {
          isUpdateFavourite = true;
          fav_category_data.map(value => {
            if (fav_categories.indexOf(value) >= 0) {
              isUpdateFavourite = false;
            }
          });

          if (!isUpdateFavourite) {
            isUpdateFavourite = true;
            fav_categories.map(value => {
              if (fav_category_data.indexOf(value) >= 0) {
                isUpdateFavourite = false;
              }
            });
          }
        }

        if (isUpdateFavourite) {
          directus_employee.favourites_categories_po = fav_category_data;
          localStorage.directus_employee = JSON.stringify(directus_employee);
          await directus.updateItem('employees', directus_employee.id, { favourites_categories_po: fav_category_data });
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setIsClickFavourite(false);
  };

  const handleSubmission = async (e, order) => {
    try {
      setLoadingFile(true);
      e.preventDefault();

      const form = document.querySelector('#my-form');
      const data = new FormData(form);

      let updateData = await directus.uploadFiles(data, onUploadProgress);

      await directus.createItem('order_files', {
        status: 'published',
        order_id: order.id,
        directus_file_id: updateData.data.id
      });

      loadOrders();
      setLoadingFile(false);
      var message = 'File uploaded successfully';
      enqueueSnackbar(message, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'success',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    } catch (e) {
      setLoadingFile(false);
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + '% Done');
  }

  const handleClick = id => {
    window.open(id);
  };

  const handleDelete = async id => {
    let delItems = [];
    delItems = [...delItems, id];

    if (delItems.length > 0) {
      await directus.deleteItems('order_files', delItems);
    }

    loadOrders();
    var message = 'File has been deleted';
    enqueueSnackbar(message, {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      variant: 'success',
      autoHideDuration: 3200,
      action: CloseSnackbarAction
    });
  };

  const openCustomEmail = () => {
    setIsCustomEmail(true);

    var send_to_email = '';
    var cc_email = '';

    if (list.supplier) {
      if (list.supplier.email) {
        send_to_email = list.supplier.email;
      }
    }
    if (list.employee.email) {
      cc_email = list.employee.email;
    } else if (list.employee.user.email) {
      cc_email = list.employee.user.email;
    }

    setDataCustomEmail({ send_to_email: send_to_email, cc_email: cc_email });
  };

  const closeCustomEmail = () => {
    setIsCustomEmail(false);
    setDataCustomEmail({ send_to_email: null, cc_email: null });
  };

  const handleChangeInput = (e, type) => {
    const { name, value } = e.target;
    var check_value = null;
    if (value) {
      if (value === ' ') {
        check_value = '';
      } else {
        check_value = value.split(/[ ,]+/).join(';');
        check_value = check_value.split(/[;;,]+/).join(';');
      }
    }
    setDataCustomEmail({ ...dataCustomEmailRef.current, [name]: check_value });
  };

  const sendEmail = () => {
    setCustomEmailLoading(true);
    var cc_email = dataCustomEmailRef.current.cc_email;
    var send_to_email = dataCustomEmailRef.current.send_to_email;
    var cc_email_array = cc_email.split(';');
    var send_to_email_array = send_to_email.split(';');

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    var invalid_email = [];
    var invalid_email_count = 0;
    var invalid_email_text = '';
    /* eslint-disable array-callback-return*/
    cc_email_array.map(value => {
      if (value) {
        if (!value.match(mailformat)) {
          invalid_email.push(value);
          invalid_email_count++;
        }
      }
    });
    /* eslint-disable array-callback-return*/
    send_to_email_array.map(value => {
      if (value) {
        if (!value.match(mailformat)) {
          invalid_email.push(value);
          invalid_email_count++;
        }
      }
    });
    /* eslint-disable array-callback-return*/
    invalid_email.map((value, index) => {
      invalid_email_text += value;
      if (index < invalid_email.length - 1) {
        invalid_email_text += ', ';
      }
    });

    if (invalid_email_count > 0) {
      var error_message = 'Invalid email address' + (invalid_email_count > 1 ? 'es' : '');
      error_message += '\n' + invalid_email_text + '';
      enqueueSnackbar(error_message, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        style: { width: '300px', whiteSpace: 'pre-line' },
        autoHideDuration: 4200,
        action: CloseSnackbarAction
      });
      setCustomEmailLoading(false);
    } else {
      editSubmit(list.id, 'sent', false, true);
    }
  };

  const addToRefs = el => {
    if (el && !categoryListRef.current.includes(el)) {
      categoryListRef.current.push(el);
    }
  };

  const clickCustomer = option => {
    var enable_click = false;
    if (option.selectable) {
      enable_click = true;
    }

    if (option.access_level) {
      if (option.access_level === 'po_creator') {
        if (!POCreatorPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'po_approver') {
        if (!POApproverPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'invoice_admin') {
        if (!InvoiceAdminPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'invoice_admin') {
        if (!AdminPermission()) {
          enable_click = false;
        }
      }
    }

    if (AdminPermission()) {
      enable_click = true;
    }

    if (enable_click) {
      setCustomer(option);
      setAnchorElCustomer(null);
    }
  };

  const getCustomerMenuItem = (option, index) => {
    var enable_click = false;
    if (option.selectable) {
      enable_click = true;
    }

    if (option.access_level) {
      if (option.access_level === 'po_creator') {
        if (!POCreatorPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'po_approver') {
        if (!POApproverPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'invoice_admin') {
        if (!InvoiceAdminPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'invoice_admin') {
        if (!AdminPermission()) {
          enable_click = false;
        }
      }
    }

    if (AdminPermission()) {
      enable_click = true;
    }

    return (
      <MenuItem
        key={index}
        style={{
          height: 'auto',
          minHeight: '30px',
          paddingLeft: '10px',
          paddingRight: '10px',
          fontSize: '12px',
          width: '100%',
          borderTop:
            index !== 0
              ? customersRef.current[index].isFavourite === false && customersRef.current[index - 1].isFavourite === true
                ? '1px solid black'
                : '0px solid black'
              : '0px solid black'
        }}
        className={`menuitem_background ${enable_click === true ? '' : 'disabled'}`}
        selected={customerRef.current ? customerRef.current.id === option.id : false}
        onClick={e => {
          clickCustomer(option);
          setCustomers(customersRef2.current);
        }}
      >
        <div style={{ whiteSpace: 'break-spaces', display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <div
            style={{ width: 'calc(100% - 25px)', fontSize: '14px' }}
            onClick={e => {
              clickCustomer(option);
              setCustomers(customersRef2.current);
            }}
          >
            {option.name}
          </div>
          <div style={{ width: '25px', alignSelf: 'center', textAlign: 'right' }} onClick={e => clickCustomerFavourite(option)}>
            {option.isFavourite ? <StarRoundedIcon fontSize="small" /> : <StarBorderRoundedIcon fontSize="small" />}
          </div>
        </div>
      </MenuItem>
    );
  };

  const onListKeyDown = (e, type, category_item_index, category_item_dataItem) => {
    if (e.key === 'Enter') {
      if (type === 'supplier') {
        setAnchorElSupplier(supplierListRef.current);
      } else if (type === 'customer') {
        setAnchorElCustomer(customerListRef.current);
      } else if (type === 'category_default') {
        setAnchorElCategory2(categoryListRef2.current);
      } else if (type === 'category_item') {
        setAnchorElCategory(categoryListRef.current[category_item_index]);
        setSelectedListItem(category_item_dataItem);
      }
    }
  };

  const clickSettings = e => {
    setAnchorElSettings(e.currentTarget);
  };

  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };

  //generate invoice
  const handleOpenGenerateInvoiceDialog = value => {
    setOpenGenerateInvoiceDialog(true);
    setSelectedAttachment(value);
  };

  const handleCloseGenerateInvoiceDialog = () => {
    setOpenGenerateInvoiceDialog(false);
    setSelectedAttachment(null);
  };

  const generateInvoice = async (isMatchedPO = false) => {
    try {
      setLoadingGenerateInvoice(true);
      const requestOptions = {
        method: 'GET'
      };

      var react_app_url = process.env.REACT_APP_URL;
      if (window.location.hostname.search('interax') !== -1) {
        react_app_url = process.env.REACT_APP_URL_INTERAX;
      }
      await fetch(`${react_app_url}${selectedAttachmentRef.current.directus_file_id.data.asset_url}`, requestOptions)
        .then(res => res.blob()) // Gets the response and returns it as a blob
        .then(async blob => {
          var file_name = selectedAttachmentRef.current.directus_file_id.filename_download;
          const data = new FormData();
          data.append('blob', blob, `PO-${list.order_number}-${file_name}`);

          let updateData = await directus.api.post('/custom/emails/upload-order-invoice', data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          if (!updateData.error_message) {
            if (isMatchedPO) {
              await directus.createItem('invoices_orders', {
                id: uuidv4(),
                date_matched: moment().format('YYYY-MM-DD'),
                invoices_id: updateData.invoice_id,
                orders_id: list.id
              });
            }
            if (updateData.invoice_id) {
              EventEmitter.emit('hideFilter', { value: true });
              EventEmitter.emit('reload_item', { value: `/invoice/${updateData.invoice_id}` });
              navigate(`/invoice/${updateData.invoice_id}`);
              var message = 'A new invoice has been created.';
              enqueueSnackbar(message, {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'success',
                autoHideDuration: 3200,
                action: CloseSnackbarAction
              });
            }
          } else {
            enqueueSnackbar(updateData.error_message, {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: 'error',
              autoHideDuration: 3200,
              action: CloseSnackbarAction
            });
            setLoadingGenerateInvoice(false);
          }
        });
    } catch (e) {
      if (e.message.includes('Error! The image')) {
        enqueueSnackbar('This PO attachment cannot be generated into invoice.', {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
      } else {
        enqueueSnackbar(ErrorMessage(e), {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
      }
    }
    setLoadingGenerateInvoice(false);
  };

  const getMatchInvoiceIconColor = (invoice_values) => {
    var color = '#13E000';
    if (invoice_values.length > 0) {
      var invoice_status_list = [];
      invoice_values.map((invoice_value) => {
        if (invoice_value.invoices_id) {
          if (invoice_value.invoices_id.invoice_status) {
            invoice_status_list.push(invoice_value.invoices_id.invoice_status);
          }
        }
      })

      if (invoice_status_list.length > 0) {
        if (invoice_status_list.includes("unmatched")) color = '#FFB251';
        else if (invoice_status_list.includes("disputed")) color = '#FF453B';
        else if (invoice_status_list.includes("matched")) color = '#188BFF';
        else if (invoice_status_list.includes("awaiting_approval_level_2")) color = '#188BFF';
        else if (invoice_status_list.includes("approved")) color = '#13E000';
      }

    }
    return color;
  }

  const openSupplierPopup = () => {
    if (suppliersRef.current && customerRef.current) {
      if (suppliersRef.current.length > 0 && customerRef.current.suppliers) {
        if (customerRef.current.suppliers.length > 0) {
          var selected_supplier = Enumerable.from(customerRef.current.suppliers)
            .select(x => x.supplier.id).toArray();

          var new_selected_supplier = Enumerable.from(suppliersRef.current)
            .where(x => selected_supplier.includes(x.id))
            .toArray();

          setSuppliers(new_selected_supplier);
        }
      }
    }
    setAnchorElSupplier(supplierListRef.current);
  }


  return (
    <div id={`order_draft_${props.id}`} key={props.id}>
      <div>
        <Paper elevation={0} className={classes.sentOrders}>
          <Grid container spacing={0} justifyContent="space-between" className={classes.orderlist}>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Typography id="order_number" style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                Draft
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Typography noWrap id="order_date" style={{ paddingTop: '5px' }}>
                {data.order_date ? moment(data.order_date).format('DD/MM/YYYY') : ''}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              {supplierLoading ? (
                <div className={classes.linearProgress} style={{ marginRight: '10px', marginTop: '6px' }}>
                  <LinearProgress />
                </div>
              ) : (
                <div>
                  <List style={{ padding: '0px' }} component="nav" sx={{ bgcolor: 'background.paper' }}>
                    <ListItem
                      className={classes.selectDate}
                      style={{ padding: '0 4px 0 0' }}
                      aria-haspopup="listbox"
                      aria-controls="lock-menu"
                      aria-expanded={open2 ? 'true' : undefined}
                    >
                      <div
                        tabIndex={0}
                        ref={supplierListRef}
                        className={classes2.textInput}
                        onClick={e => openSupplierPopup()}
                        onKeyDown={e => onListKeyDown(e, 'supplier')}
                      >
                        <div
                          className={classes2.textInputValue}
                          style={{ color: supplierRef.current ? (supplierRef.current.name ? '#263238' : '#bdc1c3') : '#bdc1c3' }}
                        >
                          {supplierRef.current
                            ? supplierRef.current.name
                            : list.supplier
                              ? list.supplier.name ?? 'Select Supplier'
                              : 'Select Supplier'}
                        </div>
                        <div style={{ height: '100%' }}>
                          <ArrowDropDownIcon className={classes2.arrowDropDown} />
                        </div>
                      </div>
                    </ListItem>
                  </List>
                  <Menu
                    id="lock-menu"
                    anchorEl={anchorElSupplier}
                    open={open2}
                    onClose={() => {
                      resetSupplier();
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'lock-button',
                      role: 'listbox',
                      style: { paddingTop: '0px' }
                    }}
                    PaperProps={{
                      style: {
                        maxWidth: '350px',
                        width: '350px',
                        maxHeight: '300px'
                      }
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    onKeyDown={e => checkKeyDown(e, 1)}
                  >
                    {suppliersRef.current ? (
                      <div
                        style={{ position: 'sticky', top: '0', zIndex: '9999', background: 'white', height: '40px' }}
                        onKeyDown={e => moveFocusToInput(e, 1)}
                      >
                        <TextField
                          inputRef={filterRef}
                          autoFocus
                          onKeyDown={e => {
                            stopPropagation(e);
                            saveSupplier(e);
                          }}
                          style={{ width: '100%', padding: '5px 10px 0', height: '45px' }}
                          onChange={e => searchSupplier(e)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {suppliersRef.current
                      ? suppliersRef.current.map((option, index) => (
                        <MenuItem
                          key={index}
                          style={{
                            height: 'auto',
                            minHeight: '30px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            fontSize: '12px',
                            width: '100%',
                            borderTop:
                              index !== 0
                                ? suppliersRef.current[index].isFavourite === false && suppliersRef.current[index - 1].isFavourite === true
                                  ? '1px solid black'
                                  : '0px solid black'
                                : '0px solid black'
                          }}
                          selected={supplierRef.current ? supplierRef.current.id === option.id : false}
                          onClick={e => {
                            setSupplier(option);
                            setAnchorElSupplier(null);
                            setSuppliers(suppliersRef2.current);
                          }}
                        >
                          <div
                            style={{
                              whiteSpace: 'break-spaces',
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'space-between'
                            }}
                          >
                            <div
                              style={{ width: 'calc(100% - 25px)', fontSize: '14px' }}
                              onClick={e => {
                                setSupplier(option);
                                setAnchorElSupplier(null);
                                setSuppliers(suppliersRef2.current);
                              }}
                            >
                              {option.name}
                            </div>
                            <div style={{ width: '25px', alignSelf: 'center', textAlign: 'right' }} onClick={e => clickSupplierFavourite(option)}>
                              {option.isFavourite ? <StarRoundedIcon fontSize="small" /> : <StarBorderRoundedIcon fontSize="small" />}
                            </div>
                          </div>
                        </MenuItem>
                      ))
                      : null}
                  </Menu>
                </div>
              )}
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              {customerLoading ? (
                <div className={classes.linearProgress} style={{ marginRight: '10px', marginTop: '6px' }}>
                  <LinearProgress />
                </div>
              ) : (
                <div>
                  <List style={{ padding: '0px' }} component="nav" sx={{ bgcolor: 'background.paper' }}>
                    <ListItem
                      className={classes.selectDate}
                      style={{ padding: '0 4px 0 0' }}
                      aria-haspopup="listbox"
                      aria-controls="lock-menu"
                      aria-expanded={open2 ? 'true' : undefined}
                    >
                      <div style={{ display: "flex", width: "100%" }}>
                        <div
                          tabIndex={0}
                          ref={customerListRef}
                          className={classes2.textInput}
                          onClick={e => setAnchorElCustomer(customerListRef.current)}
                          onKeyDown={e => onListKeyDown(e, 'customer')}
                        >
                          <div
                            className={classes2.textInputValue}
                            style={{ color: customerRef.current ? (customerRef.current.name ? '#263238' : '#bdc1c3') : '#bdc1c3' }}
                          >
                            {customerRef.current
                              ?
                              <div style={{ display: "flex" }}>
                                <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{customerRef.current.name}</div>
                                {customerRef.current.name ? customerRef.current.legal_name ?
                                  <CustomTooltip title={<Typography variant="h6"> {customerRef.current.legal_name}</Typography>}>
                                    <InfoIcon
                                      fontSize="small"
                                      style={{ cursor: 'pointer', height: '13px', marginRight: "5px", marginTop: "2px", marginLeft: "3px" }}
                                    />
                                  </CustomTooltip>
                                  : '' : ''}
                              </div>
                              : list.farm_code
                                ?
                                <div style={{ display: "flex" }}>
                                  <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{customerRef.current.name}</div>
                                  {list.farm_code.name ? list.farm_code.legal_name ?
                                    <CustomTooltip title={<Typography variant="h6"> {list.farm_code.legal_name}</Typography>}>
                                      <InfoIcon
                                        fontSize="small"
                                        style={{ cursor: 'pointer', height: '13px', marginRight: "5px", marginTop: "2px", marginLeft: "3px" }}
                                      />
                                    </CustomTooltip>
                                    : '' : ''}
                                </div>
                                ?? 'Select Customer'
                                : 'Select Customer'}
                          </div>
                          <div style={{ height: '100%' }}>
                            <ArrowDropDownIcon className={classes2.arrowDropDown} />
                          </div>
                        </div>
                      </div>
                    </ListItem>
                  </List>
                  <Menu
                    id="lock-menu"
                    anchorEl={anchorElCustomer}
                    open={open3}
                    onClose={() => {
                      resetCustomer();
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'lock-button',
                      role: 'listbox',
                      style: { paddingTop: '0px' }
                    }}
                    PaperProps={{
                      style: {
                        maxWidth: '350px',
                        width: '350px',
                        maxHeight: '300px'
                      }
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    onKeyDown={e => checkKeyDown(e, 2)}
                  >
                    {customersRef.current ? (
                      <div
                        style={{ position: 'sticky', top: '0', zIndex: '9999', background: 'white', height: '40px' }}
                        onKeyDown={e => moveFocusToInput(e, 2)}
                      >
                        <TextField
                          autoFocus
                          inputRef={filter2Ref}
                          onKeyDown={e => {
                            stopPropagation(e);
                            saveCustomer(e);
                          }}
                          style={{ width: '100%', padding: '5px 10px 0', height: '45px' }}
                          onChange={e => searchCustomer(e)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {customersRef.current ? customersRef.current.map((option, index) => getCustomerMenuItem(option, index)) : null}
                  </Menu>
                </div>
              )}

              {customerRef.current
                ?
                customerRef.current.name ? customerRef.current.legal_name ?
                  <Typography id="order_customer" style={{ paddingRight: "5px", marginTop: "10px" }}>
                    <b>Invoice To:</b> {customerRef.current.legal_name}
                  </Typography>
                  : '' : ''
                : list.farm_code
                  ?
                  list.farm_code.name ? list.farm_code.legal_name ?
                    <Typography id="order_customer" style={{ paddingRight: "5px", marginTop: "10px" }}>
                      <b>Invoice To:</b> {list.farm_code.legal_name}
                    </Typography>
                    : '' : ''
                  ?? ''
                  : ''}

            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <Paper elevation={0} style={status} className={classes.statusarea}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '80%' }}>
                    <Typography id="order_status">{listRef.current.status}</Typography>
                  </div>
                  <div style={{ width: '20%' }}>
                    {data.invoices && props.showInvoiceTab ? (
                      data.invoices.length > 0 ? (
                        <CheckCircleRoundedIcon style={{
                          color: getMatchInvoiceIconColor(data.invoices),
                          fontSize: '20px', cursor: 'pointer'
                        }} />
                      ) : null
                    ) : null}
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} style={{ display: 'inline-flex' }}>
              <Paper elevation={0} style={status} className={classes.amountarea}>
                <Paper elevation={0} className={classes.amountdetail}>
                  <Typography noWrap id="order_amount" style={{ color: '#333', fontWeight: '600' }}>
                    {isNaN(totalOrderItemRef.current) ? 0 : '$' + formatNumber((Math.round(totalOrderItemRef.current * 100) / 100).toFixed(2))}
                  </Typography>
                </Paper>
              </Paper>
              <IconButton
                id={`order_close_edit_btn`}
                size="medium"
                className={classes.KeyboardArrowDownIcon}
                onClick={() => props.editMode(props.id, false)}
              >
                <KeyboardArrowDownIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            justifyContent="space-between"
            style={{ backgroundColor: '#8AA9CA', height: '40px' }}
            className={classes.categorydetail}
          >
            <Grid className={classes.internalcategory} style={{ width: '16%' }}>
              <Typography>INTERNAL</Typography>
            </Grid>
            <Grid className={classes.internalcategory} style={{ width: '12%' }}>
              <Typography>Category</Typography>
            </Grid>
            <Grid item style={{ width: '30%' }}>
              {categoryTypeLoading ? (
                <div className={classes.linearProgress} style={{ marginRight: '10px', marginTop: '6px' }}>
                  <LinearProgress />
                </div>
              ) : (
                <div>
                  <List style={{ padding: '0px' }} component="nav" sx={{ bgcolor: 'background.paper' }}>
                    <ListItem
                      className={classes.selectDate}
                      style={{ padding: '0 4px 0 0' }}
                      aria-haspopup="listbox"
                      aria-controls="lock-menu"
                      aria-expanded={open4 ? 'true' : undefined}
                    >
                      <div
                        tabIndex={0}
                        ref={categoryListRef2}
                        className={classes2.textInput2}
                        style={{ borderColor: category_type ? (category_type.name ? '' : 'red') : 'red' }}
                        onClick={e => setAnchorElCategory2(categoryListRef2.current)}
                        onKeyDown={e => onListKeyDown(e, 'category_default')}
                      >
                        <div
                          className={classes2.textInputValue}
                          style={{ color: categoryTypeDataRef.current ? (categoryTypeDataRef.current.name ? '#263238' : '#bdc1c3') : '#bdc1c3' }}
                        >
                          {category_type ? (category_type.name ? category_type.name : 'Select Category') : 'Select Category'}
                        </div>
                        <div style={{ height: '100%' }}>
                          <ArrowDropDownIcon className={classes2.arrowDropDown} />
                        </div>
                      </div>
                    </ListItem>
                  </List>

                  <Menu
                    id="lock-menu"
                    anchorEl={anchorElCategory2}
                    open={open5}
                    onClose={() => {
                      resetCategory();
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'lock-button',
                      role: 'listbox',
                      style: { paddingTop: '0px' }
                    }}
                    PaperProps={{
                      style: {
                        maxWidth: '350px',
                        width: '350px',
                        maxHeight: '300px'
                      }
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    onKeyDown={e => checkKeyDown(e, 4)}
                  >
                    {categoryTypeDataRef.current ? (
                      <div
                        style={{ position: 'sticky', top: '0', zIndex: '9999', background: 'white', height: '40px' }}
                        onKeyDown={e => moveFocusToInput(e, 4)}
                      >
                        <TextField
                          autoFocus
                          inputRef={filter4Ref}
                          onKeyDown={e => {
                            stopPropagation(e);
                            saveCategory(e);
                          }}
                          style={{ width: '100%', padding: '5px 10px 0', height: '45px' }}
                          onChange={e => searchCategory(e)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {categoryTypeDataRef.current
                      ? categoryTypeDataRef.current.map((option, index) => (
                        <MenuItem
                          key={index}
                          style={{
                            height: 'auto',
                            minHeight: '30px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            fontSize: '12px',
                            width: '100%',
                            borderTop:
                              index !== 0
                                ? categoryTypeDataRef.current[index].isFavourite === false &&
                                  categoryTypeDataRef.current[index - 1].isFavourite === true
                                  ? '1px solid black'
                                  : '0px solid black'
                                : '0px solid black'
                          }}
                          selected={category_type ? category_type.id === option.id : false}
                          onClick={e => {
                            changeCategory(option);
                            setAnchorElCategory2(null);
                            setCategoryTypeData(categoryTypeDataRef2.current);
                          }}
                        >
                          <div
                            style={{
                              whiteSpace: 'break-spaces',
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'space-between'
                            }}
                          >
                            <div
                              style={{ width: 'calc(100% - 25px)', fontSize: '14px' }}
                              onClick={e => {
                                changeCategory(option);
                                setAnchorElCategory2(null);
                                setCategoryTypeData(categoryTypeDataRef2.current);
                              }}
                            >
                              {option.name}
                            </div>
                            <div style={{ width: '25px', alignSelf: 'center', textAlign: 'right' }} onClick={e => clickCategoryFavourite(option)}>
                              {option.isFavourite ? <StarRoundedIcon fontSize="small" /> : <StarBorderRoundedIcon fontSize="small" />}
                            </div>
                          </div>
                        </MenuItem>
                      ))
                      : null}
                  </Menu>
                </div>
              )}
            </Grid>
            <Grid className={classes.internalcategory} style={{ width: '12%' }}>
              <Typography>Details</Typography>
            </Grid>
            <Grid item style={{ width: '30%' }}>
              {categoryTypeLoading ? (
                <div className={classes.linearProgress} style={{ marginRight: '10px', marginTop: '6px' }}>
                  <LinearProgress />
                </div>
              ) : category_type ? (
                getDescription(category_type)
              ) : null}
            </Grid>
          </Grid>

          <Grid container spacing={0} justifyContent="space-between" style={status} className={classes.orderitemdetail}>
            <Grid item className={classes.orderitem_header} style={{ width: '10%' }}>
              <Typography className={classes.orderitem_header_left} style={{ marginLeft: '18px' }}>
                Qty
              </Typography>
            </Grid>
            <Grid item className={classes.orderitem_header} style={{ width: '12%' }}>
              <Typography className={classes.orderitem_header_left} style={{ marginLeft: '12px' }}>
                Supplier Code
              </Typography>
            </Grid>
            <Grid item className={classes.orderitem_header} style={{ width: '28%' }}>
              <Typography className={classes.orderitem_header_left} style={{ marginLeft: '12px' }}>
                Description
              </Typography>
            </Grid>
            <Grid item className={classes.orderitem_header} style={{ width: '12%' }}>
              <Typography className={classes.orderitem_header_left} style={{ marginRight: '18px', textAlign: 'right' }}>
                Unit Price
              </Typography>
            </Grid>
            <Grid item className={classes.itemcategory_header}>
              <Typography className={classes.orderitem_header_right}>Item Category</Typography>
            </Grid>
            <Grid item className={classes.itemdetails_header}>
              <Typography className={classes.orderitem_header_right}>Item Details</Typography>
            </Grid>
            <Grid item className={classes.itemclose}></Grid>
          </Grid>

          {/* eslint-disable array-callback-return */}
          {itemsRef.current ? (
            itemsRef.current.map((dataItem, index) => {
              if (dataItem.statusValue !== 3) {
                return (
                  <div id={`order_item_detail_${index}`} key={index}>
                    <Grid
                      container
                      spacing={0}
                      justifyContent="space-around"
                      style={{ backgroundColor: '#D4E3F2' }}
                      className={classes.itemarea}
                      key={index}
                    >
                      <Grid item style={{ width: '10%', paddingLeft: '6px' }}>
                        <Paper elevation={0} className={classes.itemcolumn} style={{ marginRight: '6px' }}>
                          <CssTextField
                            id={`order_item_quantity_${index}`}
                            className={
                              dataItem.quantity && dataItem.quantity !== '0' && dataItem.quantity !== '' && dataItem.quantity > 0
                                ? classes.textField
                                : classes.textFieldRequired
                            }
                            fullWidth
                            variant="outlined"
                            name="quantity"
                            value={dataItem.quantity ? dataItem.quantity : ''}
                            onChange={event => changeItems(event, dataItem)}
                          />
                        </Paper>
                      </Grid>
                      <Grid item style={{ width: '12%' }}>
                        <Paper elevation={0} className={classes.itemcolumn} style={{ marginRight: '6px' }}>
                          <CssTextField
                            id={`order_item_description_${index}`}
                            className={classes.textField}
                            fullWidth
                            name="supplier_code"
                            variant="outlined"
                            value={dataItem.supplier_code ? dataItem.supplier_code : ''}
                            onChange={event => changeItems(event, dataItem)}
                          />
                        </Paper>
                      </Grid>
                      <Grid item style={{ width: '28%' }}>
                        <Paper elevation={0} className={classes.itemcolumn} style={{ marginRight: '6px' }}>
                          <CssTextField
                            id={`order_item_description_${index}`}
                            className={dataItem.description ? classes.textField : classes.textFieldRequired}
                            fullWidth
                            name="description"
                            variant="outlined"
                            value={dataItem.description ? dataItem.description : ''}
                            onChange={event => changeItems(event, dataItem)}
                          />
                        </Paper>
                      </Grid>
                      <Grid item style={{ width: '12%' }}>
                        <Paper elevation={0} style={status} className={classes.unitpricecolumn}>
                          <CssTextField
                            id={`order_item_unit_price_${index}`}
                            className={`${classes.unitprice_input} ${classes.textField}`}
                            fullWidth
                            name="unit_price"
                            variant="outlined"
                            value={dataItem.unit_price ? parseFloat(dataItem.unit_price).toFixed(2) : parseFloat(0).toFixed(2)}
                            onChange={event => changeItems(event, dataItem)}
                            InputProps={{
                              inputComponent: NumberFormatCustom
                            }}
                          />
                        </Paper>
                      </Grid>
                      <Grid item style={{ width: '17%' }}>
                        <Paper elevation={0} className={classes.itemcolumn}>
                          <div
                            className={classes.itemcategory_content}
                            style={{ borderBottomLeftRadius: index === items.length - 1 ? '6px' : '0px' }}
                          >
                            {categoryTypeLoading ? (
                              <div className={classes.linearProgress} style={{ marginRight: '10px', marginTop: '6px' }}>
                                <LinearProgress />
                              </div>
                            ) : (
                              <div>
                                <List style={{ padding: '0px' }} component="nav" sx={{ bgcolor: 'background.paper' }}>
                                  <ListItem
                                    className={classes.selectDate}
                                    style={{ padding: '0 4px 0 0' }}
                                    aria-haspopup="listbox"
                                    aria-controls="lock-menu"
                                    aria-expanded={open4 ? 'true' : undefined}
                                  >
                                    <div
                                      tabIndex={0}
                                      key={index}
                                      ref={addToRefs}
                                      className={classes2.textInput2}
                                      style={{
                                        borderColor: dataItem.category_type ? (dataItem.category_type.name ? '' : 'red') : 'red',
                                        marginRight: '0px'
                                      }}
                                      onClick={e => {
                                        setAnchorElCategory(categoryListRef.current[index]);
                                        setSelectedListItem(dataItem);
                                      }}
                                      onKeyDown={e => onListKeyDown(e, 'category_item', index, dataItem)}
                                    >
                                      <div
                                        className={classes2.textInputValue}
                                        style={{
                                          color: categoryTypeDataRef.current ? (categoryTypeDataRef.current.name ? '#263238' : '#bdc1c3') : '#bdc1c3'
                                        }}
                                      >
                                        {dataItem.category_type ? dataItem.category_type.name : 'Select Category'}
                                      </div>
                                      <div style={{ height: '100%' }}>
                                        <ArrowDropDownIcon className={classes2.arrowDropDown} />
                                      </div>
                                    </div>
                                  </ListItem>
                                </List>

                                <Menu
                                  id="lock-menu"
                                  anchorEl={anchorElCategory}
                                  open={open4}
                                  onClose={() => {
                                    resetCategory();
                                  }}
                                  MenuListProps={{
                                    'aria-labelledby': 'lock-button',
                                    role: 'listbox',
                                    style: { paddingTop: '0px' }
                                  }}
                                  PaperProps={{
                                    style: {
                                      maxWidth: '350px',
                                      width: '350px',
                                      maxHeight: '300px'
                                    }
                                  }}
                                  getContentAnchorEl={null}
                                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                  onKeyDown={e => checkKeyDown(e, 3)}
                                >
                                  {categoryTypeDataRef.current ? (
                                    <div
                                      style={{
                                        position: 'sticky',
                                        top: '0',
                                        zIndex: '9999',
                                        background: 'white',
                                        height: '40px'
                                      }}
                                      onKeyDown={e => moveFocusToInput(e, 3)}
                                    >
                                      <TextField
                                        autoFocus
                                        inputRef={filter3Ref}
                                        onKeyDown={e => {
                                          stopPropagation(e);
                                          saveCategoryOrder(e, dataItem);
                                        }}
                                        style={{ width: '100%', padding: '5px 10px 0', height: '45px' }}
                                        onChange={e => searchCategory(e)}
                                      />
                                    </div>
                                  ) : (
                                    ''
                                  )}

                                  {categoryTypeDataRef.current
                                    ? categoryTypeDataRef.current.map((option, index) => (
                                      <MenuItem
                                        key={index}
                                        style={{
                                          height: 'auto',
                                          minHeight: '30px',
                                          paddingLeft: '10px',
                                          paddingRight: '10px',
                                          fontSize: '12px',
                                          width: '100%',
                                          borderTop:
                                            index !== 0
                                              ? categoryTypeDataRef.current[index].isFavourite === false &&
                                                categoryTypeDataRef.current[index - 1].isFavourite === true
                                                ? '1px solid black'
                                                : '0px solid black'
                                              : '0px solid black'
                                        }}
                                        selected={dataItem.category_type ? dataItem.category_type.id === option.id : false}
                                        onClick={e => {
                                          changeItems(null, dataItem, { name: 'category_type', value: option }, true);
                                          setAnchorElCategory(null);
                                          setCategoryTypeData(categoryTypeDataRef2.current);
                                        }}
                                      >
                                        <div
                                          style={{
                                            whiteSpace: 'break-spaces',
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'space-between'
                                          }}
                                        >
                                          <div
                                            style={{ width: 'calc(100% - 25px)', fontSize: '14px' }}
                                            onClick={e => {
                                              changeItems(
                                                null,
                                                dataItem,
                                                {
                                                  name: 'category_type',
                                                  value: option
                                                },
                                                true
                                              );
                                              setAnchorElCategory(null);
                                              setCategoryTypeData(categoryTypeDataRef2.current);
                                            }}
                                          >
                                            {option.name}
                                          </div>
                                          <div
                                            style={{ width: '25px', alignSelf: 'center', textAlign: 'right' }}
                                            onClick={e => clickCategoryFavourite(option)}
                                          >
                                            {option.isFavourite ? <StarRoundedIcon fontSize="small" /> : <StarBorderRoundedIcon fontSize="small" />}
                                          </div>
                                        </div>
                                      </MenuItem>
                                    ))
                                    : null}
                                </Menu>
                              </div>
                            )}
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item className={classes.itemdetails}>
                        <div className={classes.itemdetails_content} style={{ borderBottomRightRadius: index === items.length - 1 ? '6px' : '0px' }}>
                          {categoryTypeLoading ? (
                            <div className={classes.linearProgress} style={{ marginRight: '10px', marginTop: '6px' }}>
                              <LinearProgress />
                            </div>
                          ) : dataItem.category_type ? (
                            getItemDescription(dataItem, index)
                          ) : null}
                        </div>
                      </Grid>
                      <Grid item className={classes.itemclose}>
                        <IconButton
                          id={`order_item_cancel_btn_${index}`}
                          size="medium"
                          className={classes.CancelIconButton}
                          onClick={() => deleteItems(dataItem)}
                        >
                          <CancelIcon id="iconButton" style={{ fontSize: 30 }} className={classes.CancelIcon} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                );
              }
            })
          ) : loadingItemsRef.current === true ? (
            <span>
              <CircularProgress size={20} />
            </span>
          ) : (
            ''
          )}
          {/* eslint-enable array-callback-return */}

          <Grid item xs={12} style={{ backgroundColor: '#D4E3F2', borderRadius: '0px 0px 8px 8px' }} className={classes.itemarealast}>
            <IconButton id="order_item_add_btn" style={{ marginLeft: '15px' }} className={classes.add} onClick={() => addItems(items ? items : null)}>
              <AddCircleIcon fontSize="small" className={classes.iconAdd} style={{ fontSize: 36 }} />
            </IconButton>
          </Grid>

          <Grid container spacing={1} justifyContent="space-between" style={{ paddingTop: '6px' }}>
            <Grid item xs={2} md={2} lg={2}>
              <div
                style={{
                  display: 'flex',
                  border: '1px solid rgba(142,142,147,0.3)',
                  borderRadius: '10px',
                  height: '35px'
                }}
              >
                <div
                  style={{
                    marginRight: '10px',
                    fontFamily: 'Manrope',
                    lineHeight: '35px',
                    marginLeft: '10.82px',
                    fontSize: '14px',
                    overflow: 'hidden'
                  }}
                >
                  Delivery by:
                </div>
                <div style={{ width: 'calc(100% - 100px)' }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id="date_picker"
                      style={{ marginTop: '4px' }}
                      format="dd/MM/yyyy"
                      placeholder="dd/MM/yyyy"
                      value={moment(selectedDate).format()}
                      onChange={date => handleDateChange(date)}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <TextareaAutosize
                id="order_notes"
                minRows={2}
                maxRows={2}
                variant="outlined"
                name="note"
                placeholder="Notes: Jot an internal note here..."
                value={note ? note : ''}
                onChange={handleChange}
                style={{
                  maxWidth: '100%',
                  minWidth: '100%',
                  maxHeight: '54%',
                  minHeight: '35px',
                  borderRadius: '8px',
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                  fontFamily: 'Manrope',
                  padding: '7px 14px',
                  height: '32px',
                  fontSize: '14px'
                }}
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <TextareaAutosize
                minRows={2}
                maxRows={2}
                variant="outlined"
                name="delivery_instructions"
                placeholder="Delivery Instructions: Jot something done"
                value={deliveryInstructions ? deliveryInstructions : ''}
                onChange={handleChange}
                style={{
                  maxWidth: '100%',
                  minWidth: '100%',
                  maxHeight: '54%',
                  minHeight: '35px',
                  borderRadius: '8px',
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                  fontFamily: 'Manrope',
                  padding: '7px 14px',
                  height: '32px',
                  fontSize: '14px'
                }}
              />
            </Grid>
            <Grid item xs={5} md={4} lg={4}>
              <div
                style={{
                  textAlign: 'end',
                  justifyContent: 'flex-end',
                  display: 'flex',
                  flexWrap: 'wrap',
                  rowGap: '5px',
                  maxWidth: 'fit-content',
                  marginLeft: 'auto'
                }}
              >
                {loadingFile || loadingSubmit || loadingDuplicate || loadingStatusCompleted ? (
                  <span display="inline-flex" style={{ marginTop: '5px' }}>
                    <CircularProgress size={20} />
                  </span>
                ) : (
                  <>
                    <form id="my-form" className={classes.form}>
                      <input type="file" name="file" onChange={e => handleSubmission(e, list)} id="contained-button-file" className={classes.input} />
                      <label htmlFor="contained-button-file">
                        <Button
                          id="attach_file_btn"
                          name="file"
                          variant="contained"
                          className={classes.buttonadditem2}
                          disabled={disabledCheck}
                          component="span"
                        >
                          ATTACH FILE
                        </Button>
                      </label>
                    </form>

                    <Button
                      id="submit_btn"
                      variant="contained"
                      className={classes.buttonadditem2}
                      onClick={() => editSubmit(list.id, 'sent')}
                      disabled={disabledCheck || loadingDuplicate || loadingStatusCompleted || loadingFile}
                    >
                      SUBMIT
                    </Button>

                    {supplier ? (
                      supplier.id ? (
                        supplier.email ? (
                          <Button
                            id="submit_btn"
                            variant="contained"
                            className={classes.buttonadditem2}
                            onClick={() => editSubmit(list.id, 'sent', true)}
                            disabled={disabledCheck || loadingDuplicate || loadingStatusCompleted || loadingFile}
                          >
                            SUBMIT AND SEND
                          </Button>
                        ) : null
                      ) : list.supplier ? (
                        list.supplier.email ? (
                          <Button
                            id="submit_btn"
                            variant="contained"
                            className={classes.buttonadditem2}
                            onClick={() => editSubmit(list.id, 'sent', true)}
                            disabled={disabledCheck || loadingDuplicate || loadingStatusCompleted || loadingFile}
                          >
                            SUBMIT AND SEND
                          </Button>
                        ) : null
                      ) : null
                    ) : null}

                    {supplier ? (
                      supplier.id ? (
                        supplier.email ? (
                          <Button
                            id="submit_btn"
                            variant="contained"
                            className={classes.buttonadditem2}
                            onClick={() => openCustomEmail()}
                            disabled={disabledCheck || loadingDuplicate || loadingStatusCompleted || loadingFile}
                          >
                            SUBMIT AND CUSTOM SEND
                          </Button>
                        ) : null
                      ) : list.supplier ? (
                        list.supplier.email ? (
                          <Button
                            id="submit_btn"
                            variant="contained"
                            className={classes.buttonadditem2}
                            onClick={() => openCustomEmail()}
                            disabled={disabledCheck || loadingDuplicate || loadingStatusCompleted || loadingFile}
                          >
                            SUBMIT AND CUSTOM SEND
                          </Button>
                        ) : null
                      ) : null
                    ) : null}

                    <Button
                      id="demo-customized-button"
                      className={classes.buttonadditem2}
                      aria-controls={openSettings ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openSettings ? 'true' : undefined}
                      variant="contained"
                      disableElevation
                      onClick={clickSettings}
                      endIcon={<KeyboardArrowDownIcon />}
                      disabled={disabledCheck}
                    >
                      Options
                    </Button>
                  </>
                )}

                <Menu
                  anchorEl={anchorElSettings}
                  id="account-menu"
                  open={openSettings}
                  onClose={handleCloseSettings}
                  onClick={handleCloseSettings}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0
                      }
                    }
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={() => editSubmit(list.id, 'draft')} style={{ minWidth: '150px' }}>
                    <div style={{ display: 'flex' }}>
                      <div style={{ minWidth: '25px', maxWidth: '25px' }}>
                        <SaveAsIcon style={{ fontSize: '18px' }} />
                      </div>
                      Save as Draft
                    </div>
                  </MenuItem>

                  {list.invoices.length === 0 ? (
                    <MenuItem onClick={handleClickOpenDialog}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ minWidth: '25px', maxWidth: '25px' }}>
                          <PublishedWithChangesIcon style={{ fontSize: '17px' }} />
                        </div>
                        Update Status
                      </div>
                    </MenuItem>
                  ) : (
                    ''
                  )}
                  <Divider />
                  {/* <MenuItem onClick={() => editSubmit(list.id, 'sent')}>
                    <div style={{ display: "flex" }}>
                      <div style={{ minWidth: "25px", maxWidth: "25px" }}>
                        <SendIcon style={{ fontSize: "17px" }} />
                      </div>
                      Submit
                    </div>
                  </MenuItem>

                  {supplier ? supplier.id ? supplier.email ?
                    <MenuItem onClick={() => editSubmit(list.id, 'sent', true)}>
                      <div style={{ display: "flex" }}>
                        <div style={{ minWidth: "25px", maxWidth: "25px" }}>
                          <SendIcon style={{ fontSize: "17px" }} />
                        </div>
                        Submit and Send
                      </div>
                    </MenuItem>
                    : null : list.supplier ? list.supplier.email ?
                      <MenuItem onClick={() => editSubmit(list.id, 'sent', true)}>
                        <div style={{ display: "flex" }}>
                          <div style={{ minWidth: "25px", maxWidth: "25px" }}>
                            <SendIcon style={{ fontSize: "17px" }} />
                          </div>
                          Submit and Send
                        </div>
                      </MenuItem>
                      : null : null : null
                  }
                  {supplier ? supplier.id ? supplier.email ?
                    <MenuItem onClick={() => openCustomEmail()}>
                      <div style={{ display: "flex" }}>
                        <div style={{ minWidth: "25px", maxWidth: "25px" }}>
                          <SendIcon style={{ fontSize: "17px" }} />
                        </div>
                        Submit and Custom Send
                      </div>
                    </MenuItem>
                    : null : list.supplier ? list.supplier.email ?
                      <MenuItem onClick={() => openCustomEmail()}>
                        <div style={{ display: "flex" }}>
                          <div style={{ minWidth: "25px", maxWidth: "25px" }}>
                            <SendIcon style={{ fontSize: "17px" }} />
                          </div>
                          Submit and Custom Send
                        </div>
                      </MenuItem>
                      : null : null : null
                  }
                  <Divider /> */}
                  <MenuItem onClick={e => duplicateData(list)}>
                    <div style={{ display: 'flex' }}>
                      <div style={{ minWidth: '25px', maxWidth: '25px' }}>
                        <ContentCopyIcon style={{ fontSize: '18px' }} />
                      </div>
                      Duplicate
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', paddingTop: '5px' }}>
              <Grid container style={{ flex: '60%' }}>
                {data.files.map((file, index) => {
                  if (file.directus_file_id) {
                    return (
                      <Grid key={index} span="true" style={{ display: 'flex' }}>
                        {POApproverPermission() ? (
                          <div style={{ marginTop: '7px', marginLeft: '5px', cursor: 'pointer' }}>
                            <CustomTooltip arrow title={<Typography variant="h6">Generate Invoice</Typography>}>
                              <ContentCopyIcon className={classes.generate_invoice_button} onClick={() => handleOpenGenerateInvoiceDialog(file)} />
                            </CustomTooltip>
                          </div>
                        ) : (
                          ''
                        )}
                        <Chip
                          icon={<InsertDriveFileIcon />}
                          label={file.directus_file_id.filename_download}
                          onClick={id => handleClick(file.directus_file_id.data.full_url)}
                          onDelete={() => handleDelete(file.id)}
                          variant="outlined"
                          style={{ marginRight: '5px' }}
                        />
                      </Grid>
                    );
                  } else {
                    return null;
                  }
                })}
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                style={
                  {
                    /* flex: '40%' */
                  }
                }
              >
                <div style={{ display: 'flex' }}>
                  <div style={{ width: 'calc(100% - 400px)', paddingRight: '5px' }}>
                    <Typography id="order_modified" style={{ fontSize: '13px', Color: 'grey' }}>
                      Last modified: {moment(data.modified_on).format('DD/MM/YYYY')}{' '}
                      {data.modified_by ? (
                        <span>
                          by {data.modified_by.first_name} {data.modified_by.last_name}
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography>
                  </div>
                  <div style={{ width: '550px', height: '30px', display: 'flex' }}>
                    <Button
                      id="show_change_log_btn"
                      variant="contained"
                      className={classes.buttonChangeLog}
                      onClick={() => handleClickShowChangeLog(list.id)}
                      style={{ width: '280px' }}
                    >
                      {!showChangeLog ? 'SHOW' : 'HIDE'} Change Log
                    </Button>
                    <div style={{ width: '290px', height: '30px', paddingLeft: '5px' }}>
                      <Button
                        id="show_change_log_btn"
                        variant="contained"
                        className={classes.buttonChangeLog}
                        onClick={() => handleClickExportActionLog(list.id)}
                      >
                        Export Change Log
                      </Button>
                    </div>
                    {POApproverPermission() ? (
                      <div style={{ width: '168px', height: '30px', marginRight: '7px', marginTop: '-3px' }}>
                        <CancelledButton
                          variant="contained"
                          onClick={() => handleOpenDeleteOrderDialog(list.id)}
                          disabled={disabledCheck || loadingDuplicate || loadingSubmit || loadingFile}
                          style={{ width: '168px', marginLeft: '7px' }}
                        >
                          Delete Order
                        </CancelledButton>
                      </div>
                    ) : list.owner ? (
                      list.owner.id === employeeIDRef.current ? (
                        <div style={{ width: '168px', height: '30px', marginRight: '7px' }}>
                          <CancelledButton
                            variant="contained"
                            onClick={() => handleOpenDeleteOrderDialog(list.id)}
                            disabled={disabledCheck || loadingDuplicate || loadingSubmit || loadingFile}
                            style={{ width: '168px', marginLeft: '7px' }}
                          >
                            Delete Order
                          </CancelledButton>
                        </div>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                  </div>

                  <Dialog isopen={`${openDeleteOrderDialog}`} open={openDeleteOrderDialog} fullWidth={true} onClose={handleCloseDeleteOrderDialog}>
                    <DialogTitle disableTypography={true} id="form-dialog-title">
                      <Typography component="div" variant="h6">
                        <h2>Delete Order</h2>
                      </Typography>
                    </DialogTitle>
                    <DialogContent>
                      <Typography variant="h6">Are you sure?</Typography>
                    </DialogContent>
                    <DialogActions style={{ height: '60px' }}>
                      {loadingDeleteDialog ? (
                        <CircularProgress className={classes.circular} />
                      ) : (
                        <div>
                          <CancelButton id="cancel_btn" size="small" variant="contained" onClick={handleCloseDeleteOrderDialog}>
                            Cancel
                          </CancelButton>
                          <SubmitButton style={{ marginLeft: '10px' }} id="save_btn" size="small" variant="contained" onClick={() => deleteOrder()}>
                            Delete
                          </SubmitButton>
                        </div>
                      )}
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    isopen={`${openGenerateInvoiceDialog}`}
                    open={openGenerateInvoiceDialog}
                    fullWidth={true}
                    onClose={handleCloseGenerateInvoiceDialog}
                  >
                    <DialogTitle disableTypography={true} id="form-dialog-title">
                      <Typography component="div" variant="h6">
                        <h2>Generate Invoice</h2>
                      </Typography>
                    </DialogTitle>
                    <DialogContent>
                      <Typography variant="h6">Are you sure you want to generate a new invoice record from this attachment?</Typography>
                    </DialogContent>
                    <DialogActions style={{ height: '60px' }}>
                      {loadingGenerateInvoice ? (
                        <CircularProgress className={classes.circular} />
                      ) : (
                        <div>
                          <CancelButton id="cancel_btn" size="small" variant="contained" onClick={handleCloseGenerateInvoiceDialog}>
                            Cancel
                          </CancelButton>
                          <DraftButton
                            style={{ marginLeft: '10px', width: '125px' }}
                            id="save_btn"
                            size="small"
                            variant="contained"
                            onClick={() => generateInvoice()}
                          >
                            Generate
                          </DraftButton>
                          <CompletedButton
                            style={{ marginLeft: '10px', width: '225px' }}
                            id="save_btn"
                            size="small"
                            variant="contained"
                            onClick={() => generateInvoice(true)}
                          >
                            Generate and Match PO
                          </CompletedButton>
                        </div>
                      )}
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    isopen={`${openUpdateCategoryDialog}`}
                    open={openUpdateCategoryDialog}
                    fullWidth={true}
                    onClose={handleCloseUpdateCategoryDialog}
                  >
                    <DialogTitle disableTypography={true} id="form-dialog-title">
                      <Typography component="div" variant="h6">
                        <h2>Confirmation</h2>
                      </Typography>
                    </DialogTitle>
                    <DialogContent>
                      <Typography variant="h6">
                        Do you want to apply this {updateCategory === 'category' ? 'category' : 'category description'} to all existing lines?
                      </Typography>
                    </DialogContent>
                    <DialogActions style={{ height: '60px' }}>
                      {loadingDeleteDialog ? (
                        <CircularProgress className={classes.circular} />
                      ) : (
                        <div>
                          <CancelButton id="cancel_btn" size="small" variant="contained" onClick={handleCloseUpdateCategoryDialog}>
                            No
                          </CancelButton>
                          <SubmitButton id="save_btn" size="small" variant="contained" onClick={() => updateAllCategory()}>
                            Yes
                          </SubmitButton>
                        </div>
                      )}
                    </DialogActions>
                  </Dialog>
                </div>
              </Grid>
            </Grid>
          </Grid>
          {matchedInvoiceDataRef.current ? (
            matchedInvoiceDataRef.current.length > 0 ? (
              <Grid
                container
                spacing={0}
                justifyContent="space-around"
                style={{ paddingTop: 'inherit', background: 'white', marginTop: '10px', borderRadius: '4px' }}
              >
                <Grid item xs={12} style={{ marginTop: '8px' }}>
                  <Typography style={{ padding: '10px 16px 10px 16px', fontSize: '16px' }}>
                    <b>Matched Invoices</b>
                  </Typography>
                </Grid>
                <Grid
                  container
                  style={{
                    background: '#daf0dc',
                    padding: '12.58px 16px 6px 16px',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px'
                  }}
                >
                  <Grid item xs={3}>
                    <Typography style={{ fontSize: '14px' }}>
                      <b>Invoice Date</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ fontSize: '14px' }}>
                      <b>Invoice Number</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ fontSize: '14px' }}>
                      <b>Invoice Amount</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ fontSize: '14px' }}>
                      <b>Date Matched</b>
                    </Typography>
                  </Grid>
                </Grid>
                {matchedInvoiceDataRef.current.map((value, index) => {
                  return (
                    <Grid
                      container
                      key={index}
                      style={{
                        background: '#daf0dc',
                        padding: index === matchedInvoiceDataRef.current.length - 1 ? '4px 16px 14px 16px' : '4px 16px 4px 16px',
                        borderBottomLeftRadius: index === matchedInvoiceDataRef.current.length - 1 ? '8px' : '0px',
                        borderBottomRightRadius: index === matchedInvoiceDataRef.current.length - 1 ? '8px' : '0px'
                      }}
                    >
                      <Grid item xs={3} className={classes.logdata_detail}>
                        <Typography className={classes.logdata_detail_txt}>{value.date ? moment(value.date).format('DD/MM/YYYY') : null}</Typography>
                      </Grid>
                      <Grid item xs={3} className={classes.logdata_detail}>
                        <Typography className={classes.logdata_detail_txt}>{value.number}</Typography>
                      </Grid>
                      <Grid item xs={3} className={classes.logdata_detail}>
                        <Typography className={classes.logdata_detail_txt}>
                          {isNaN(value.invoice_amount) ? 0 : '$' + formatNumber((Math.round(value.invoice_amount * 100) / 100).toFixed(2))}
                        </Typography>
                      </Grid>
                      <Grid item xs={POCreatorPermission() ? 2 : 3} className={classes.logdata_detail}>
                        <Typography className={classes.logdata_detail_txt}>
                          {value.date_matched ? moment(value.date_matched).format('DD/MM/YYYY') : null}
                        </Typography>
                      </Grid>
                      {POCreatorPermission() ? (
                        <Grid item xs={1} className={classes.logdata_detail} style={{ textAlign: 'end' }}>
                          <RouterLink to={`/invoice/${value.id}`} onClick={() => navigateToOtherInvoice(value.id)}>
                            <IconButton size="medium" className={classes.iconbuttonarea2} onClick={() => navigateToOtherInvoice(value.id)}>
                              <VisibilityIcon fontSize="small" />
                            </IconButton>
                          </RouterLink>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            ) : null
          ) : null}

          {showChangeLog ? (
            <Grid
              container
              spacing={0}
              justifyContent="space-between"
              style={{ background: '#FFFCD2', borderRadius: '8px', padding: '12px 16px', marginTop: '5px' }}
            >
              <Grid container>
                <Grid item xs={3} lg={3} className={classes.logdata}>
                  <Typography className={classes.orderitem_header_left}>Date & Time</Typography>
                </Grid>
                <Grid item xs={3} lg={3} className={classes.logdata}>
                  <Typography className={classes.orderitem_header_left}>Status Updates</Typography>
                </Grid>
                <Grid item xs={6} lg={6} className={classes.logdata}>
                  <Typography className={classes.orderitem_header_left}>User</Typography>
                </Grid>
              </Grid>
              {logData.length > 0
                ? logData.map((dataLog, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid item xs={3} lg={3} className={classes.logdata_detail}>
                        <Typography className={classes.logdata_detail_txt}>
                          {dataLog.created_on ? moment(dataLog.created_on).format('DD/MM/YYYY, hh:mm a') : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} lg={3} className={classes.logdata_detail}>
                        <Typography className={classes.logdata_detail_txt}>
                          {orderEventType ? orderEventType[dataLog.type] : dataLog.type}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} lg={6} className={classes.logdata_detail}>
                        <Typography className={classes.logdata_detail_txt}>
                          {dataLog.owner ? dataLog.owner.first_name : ''} {dataLog.owner ? dataLog.owner.last_name : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })
                : null}
            </Grid>
          ) : null}
        </Paper>

        <Dialog open={open}>
          <DialogTitle>Update {list.order_number} Status</DialogTitle>
          <DialogActions>
            <CancelButton size="small" variant="contained" onClick={handleCloseDialog}>
              <Typography variant="h6">Back</Typography>
            </CancelButton>

            <SentButton variant="contained" onClick={() => updateStatus(list.id, 'sent')}>
              <Typography variant="h6">Sent</Typography>
            </SentButton>

            {POApproverPermission() ? (
              <CompletedButton variant="contained" onClick={() => updateStatus(list.id, 'completed')}>
                <Typography variant="h6">Completed</Typography>
              </CompletedButton>
            ) : POCreatorPermission() && list.invoices ? (
              list.invoices.length === 0 ? (
                <CompletedButton variant="contained" onClick={() => updateStatus(list.id, 'completed')}>
                  <Typography variant="h6">Completed</Typography>
                </CompletedButton>
              ) : (
                ''
              )
            ) : (
              ''
            )}

            {list.invoices ? (
              list.invoices.length === 0 ? (
                <CancelledButton variant="contained" onClick={() => updateStatus(list.id, 'cancelled')}>
                  <Typography variant="h6">Cancelled</Typography>
                </CancelledButton>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </DialogActions>
        </Dialog>

        <Dialog open={isCustomEmailRef.current} onClose={closeCustomEmail}>
          <DialogTitle disableTypography={true} id="form-dialog-title">
            <Typography component="div" variant="h6">
              <h2>Submit and Custom Send</h2>
            </Typography>
          </DialogTitle>
          <DialogContent style={{ width: '450px' }}>
            <TextField
              id="email_text"
              label="Email"
              variant="outlined"
              fullWidth={true}
              name="send_to_email"
              value={dataCustomEmailRef.current.send_to_email}
              placeholder={'user1@gmail.com;user2@gmail.com'}
              onChange={handleChangeInput}
            />
            <br />
            <br />
            <TextField
              id="cc_email_text"
              label="CC Email"
              variant="outlined"
              fullWidth={true}
              name="cc_email"
              value={dataCustomEmailRef.current.cc_email}
              placeholder={'user1@gmail.com;user2@gmail.com'}
              onChange={handleChangeInput}
            />
          </DialogContent>

          <DialogActions style={{ height: '60px', marginRight: '5px' }}>
            {customEmailLoading ? (
              <CircularProgress className={classes.circular} />
            ) : (
              <div>
                <CancelButton id="cancel_btn" size="small" variant="contained" onClick={closeCustomEmail}>
                  Cancel
                </CancelButton>
                <SubmitButton
                  id="save_btn"
                  size="small"
                  variant="contained"
                  onClick={() => sendEmail()}
                  disabled={!dataCustomEmailRef.current.send_to_email}
                >
                  Save
                </SubmitButton>
              </div>
            )}
          </DialogActions>
        </Dialog>
        <div style={{ height: 12 + 'px' }}></div>
      </div>
    </div>
  );
};
export default DataStatusDraft;
