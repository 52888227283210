import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Container,
  makeStyles,
  CircularProgress,
  Grid,
  Button,
  MenuItem,
  TextField,
  withStyles,
  LinearProgress,
  Tooltip
} from "@material-ui/core";
import Page from "src/components/Page";
import Paper from "@material-ui/core/Paper";
import { enableRipple } from "@syncfusion/ej2-base";
import directus from "../../services/directus";
import { useStyles } from "./style.js";
import OrderDetail from "src/views/Order/OrderDetail";
import useStateRef from "react-usestateref";
import EventEmitter from "src/utils/EventEmitter";
import linq from "linq";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import { pageOptions } from "src/_utils/pageOptions";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { getCache, setCache } from "src/utils/KeyValueCache";

import moment from "moment";
import { POApproverPermission, POCreatorPermission } from "src/utils/Permission";
import { getEmployee } from "src/utils/sessions";
import AddOrder from "./AddOrder";
import SortRoundedIcon from "@material-ui/icons/SortRounded";
import { OverflowTip } from "src/components/OverflowTip";
import { v4 as uuidv4 } from "uuid";
import Enumerable from "linq";

const useStyles2 = makeStyles(theme => ({
  root: {
    marginRight: "28px",
    marginLeft: "28px",
    position: "relative",
    overflow: "auto",
    padding: "0px !important"
  }
}));

export const SearchButton = withStyles({
  root: {
    marginRight: "22px",
    borderRadius: "24px",
    backgroundColor: "#2AC940",
    color: "#ffffff",
    height: "25px",
    width: "100px",
    minWidth: "50px",
    "&:hover": {
      backgroundColor: "#22a033"
    }
  }
})(Button);

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "16px",
    maxWidth: 500,
    backgroundColor: "grey"
  }
})(Tooltip);

let page = 1;

var react_app_url = process.env.REACT_APP_URL;
if (window.location.hostname.search("interax") !== -1) {
  react_app_url = process.env.REACT_APP_URL_INTERAX;
}

const IndexView = () => {
  /* eslint-disable no-unused-vars*/
  const classes = useStyles();
  const classess = useStyles2();
  const [initialized, setInitialized] = useState(false);
  const [orders, setOrders, ordersRef] = useStateRef(null);
  // const [logs, setLogs] = useState([]);
  const { state } = useLocation();

  const [add, setAdd] = useState(state ? state.input : false);

  const [updateStatusToDraft, setUpdateStatusToDraft, updateStatusToDraftRef] = useStateRef(false);

  const [sort, setSort, sortRef] = useStateRef("");
  const [search, setSearch, searchRef] = useStateRef("");
  const [totalPage, setTotalPage, totalPageRef] = useStateRef("");
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(100);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [searchByDateLoading, setSearchByDateLoading] = useState(false);

  const [categoryType, setCategoryType, categoryTypeRef] = useStateRef(null);
  const [collection, setCollection, collectionRef] = useStateRef([]);
  const [collectionName, setCollectionName, collectionNameRef] = useStateRef([]);
  const [collectionData, setCollectionData, collectionDataRef] = useStateRef([]);

  const [categoryTypeLoading, setCategoryTypeLoading] = useState(false);

  const [orderEventType, setOrderEventType] = useState(null);

  const [employeeID, setEmployeeID] = React.useState(null);

  const [filterData, setFilterData, filterDataRef] = useStateRef({
    date: 6,
    date_range: [],
    order: ["all"],
    invoice: ["all"],
    supplier: ["0"],
    customer: ["0"],
    employee: ["0"],
    po_category: ["0"],
    isMyOrder: POApproverPermission() ? false : true,
    isAnyPrice: true,
    price: { min: 0, max: 0 }
  });

  const [customerEmployee, setCustomerEmployee, customerEmployeeRef] = useStateRef(null);

  const [sortField, setSortField, sortFieldRef] = useStateRef({
    order_number: { type: "desc", value: false },
    date: { type: "desc", value: false },
    supplier: { type: "desc", value: false },
    customer: { type: "desc", value: false },
    status: { type: "desc", value: false },
    amount: { type: "desc", value: false },
    match_invoice: { type: "desc", value: false }
  });

  const [sortLoading, setSortLoading, sortLoadingRef] = useStateRef(false);
  const [filterLoading, setFilterLoading, filterLoadingRef] = useStateRef(false);
  const [showInvoiceTab, setShowInvoiceTab, showInvoiceTabsRef] = useStateRef(false);
  /* eslint-enable no-unused-vars*/

  enableRipple(true);

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadFirst();
      loadCategoryType();
      loadOrderEventType();
      // loadConfigPO();
      loadEmployeeID();
      getConfigurationSettings();
    }

    const onSearch = async eventData => {
      setSearch(eventData.text);
      page = 1;
      await loadOrders(eventData.text);
      EventEmitter.emit("searching_loading", {
        value: false
      });
    };
    const listener = EventEmitter.addListener(window.location.pathname, onSearch);

    const onExportOrder = eventData => {
      exportOrder(eventData.text);
    };
    const listener5 = EventEmitter.addListener("export", onExportOrder);

    const add = eventData => {
      setAdd(true);
    };
    const listener6 = EventEmitter.addListener("addOrder", add);

    return () => {
      listener.remove();
      listener5.remove();
      listener6.remove();
    };
  }, []);

  useEffect(() => {
    const onFilterData = async eventData => {
      setFilterData(eventData);
      setFilterLoading(true);
      page = 1;
      await loadOrders();
      EventEmitter.emit("searching_loading", {
        value: false
      });
    };
    const listener1 = EventEmitter.addListener("filter_data", onFilterData);

    const onLoadOrders = async eventData => {
      await loadOrders();
      EventEmitter.emit("reload_order_item", { value: true });
    };
    const listener2 = EventEmitter.addListener("load_orders", onLoadOrders);

    return () => {
      listener1.remove();
      listener2.remove();
    };
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/

  const getConfigurationSettings = async () => {
    var result = await directus.getItems('configuration');
    window.localStorage.setItem('configuration', JSON.stringify(result.data[0]));
    if (result.data.length > 0) {
      var data = result.data[0];
      if (data) {
        if (data.show_invoices === true) {
          setShowInvoiceTab(true);
        }
        
        if (data.update_status_to_draft === true) {
          setUpdateStatusToDraft(true);
        }
      }
    }
  }

  const findNestedObj = (entireObj, keyToFind) => {
    //eslint-disable-next-line no-unused-vars
    let foundObj;
    var list = [];
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (nestedValue) {
        if (nestedValue && nestedValue[keyToFind]) {
          if (list.indexOf(nestedValue[keyToFind]) === -1) {
            foundObj = nestedValue;
            list.push(nestedValue[keyToFind]);
          }
        }
      }
      return nestedValue;
    });
    return list;
  };

  var findNestedObjByKey = (theObject, keyToFind) => {
    var result = [];
    if (theObject instanceof Array) {
      for (let index = 0; index < theObject.length; index++) {
        const obj = theObject[index];

        for (const [key, value] of Object.entries(obj)) {
          if (value && key === keyToFind && result.indexOf(value) === -1) {
            result.push(value);
          } else if (value instanceof Object || value instanceof Array) {
            result = result.concat(findNestedObjByKey(value, keyToFind));
          }
        }
      }
    } else if (theObject instanceof Object) {
      for (const [key, value] of Object.entries(theObject)) {
        if (value && key === keyToFind && result.indexOf(value) === -1) {
          result.push(value);
        } else if (value instanceof Object || value instanceof Array) {
          result = result.concat(findNestedObjByKey(value, keyToFind));
        }
      }
    }

    return result;
  };

  function findNestedObj2(entireObj, keyToFind, valToFind, newData) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind] == valToFind) {
        nestedValue[keyToFind] = newData;
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    return foundObj;
  }

  const getNewOrder = async Orders => {
    var findCategoryType = Orders.data;
    var result5 = findNestedObjByKey(findCategoryType, "category_description");
    var result6 = Enumerable.from(findNestedObj(findCategoryType, "category_type"))
      .groupBy(x => x.id)
      .select(x => x.first().id)
      .toArray();

    if (result5.length > 0) {
      var new_data = await directus.api.post("/custom/mobile/timesheets/batch-data", {
        activities: {
          filter: {
            // "status": "published",
            id: { in: result5 }
          }
        },
        resources: {
          filter: {
            // "status": "published",
            id: { in: result5 }
          }
        },
        order_category_type: {
          filter: {
            id: { in: result6 }
          }
        }
      });

      if (new_data.data.activities.length > 0) {
        //eslint-disable-next-line array-callback-return
        new_data.data.activities.map((value, index) => {
          findCategoryType = findCategoryType.map(u => {
            if (!u.category_type) return u;

            let categoryType = Enumerable.from(new_data.data.order_category_type).firstOrDefault(x => x.id == u.category_type.id);
            if (categoryType && categoryType.collection == "activities") {
              return u.category_description != value.id ? u : replaceCategoryDescription(u, value);
            } else {
              return u;
            }
          });
        });
      }

      if (new_data.data.resources.length > 0) {
        //eslint-disable-next-line array-callback-return
        new_data.data.resources.map((value, index) => {
          findCategoryType = findCategoryType.map(u => {
            if (!u.category_type) return u;

            let categoryType = Enumerable.from(new_data.data.order_category_type).firstOrDefault(x => x.id == u.category_type.id);
            if (categoryType && categoryType.collection == "resources") {
              return u.category_description != value.id ? u : replaceCategoryDescription(u, value);
            } else {
              return u;
            }
          });
        });
      }

      findCategoryType = findCategoryType.map(order => {
        return replaceOrderItemsCategoryDescription(order, new_data.data);
      });
    }

    return findCategoryType;
  };

  const replaceOrderItemsCategoryDescription = (order, categories_data) => {
    if (order.order_items && order.order_items.length > 0) {
      order.order_items = order.order_items.map((order_item, index) => {
        if (!order_item.order_item) return order_item;
        if (!order_item.order_item.category_type) return order_item;

        let categoryType = Enumerable.from(categories_data.order_category_type).firstOrDefault(x => x.id == order_item.order_item.category_type.id);
        if (categoryType && categoryType.collection == "activities") {
          order_item.order_item.category_description = categories_data.activities.find(x => x.id == order_item.order_item.category_description);
        } else if (categoryType && categoryType.collection == "resources") {
          order_item.order_item.category_description = categories_data.resources.find(x => x.id == order_item.order_item.category_description);
        }

        return order_item;
      });
    }

    return order;
  };

  const replaceCategoryDescription = (oldData, newData) => {
    oldData.category_description = newData;
    return oldData;
  };

  const loadFirst = () => {
    var filter_data = localStorage.filter_data;
    if (filter_data) {
      filter_data = JSON.parse(filter_data);
      if (!filter_data.price) {
        filter_data.price = {
          min: 0,
          max: 0
        };
        localStorage.setItem("filter_data", JSON.stringify(filter_data));
      }

      if (!filter_data.employee) {
        filter_data.employee = ["0"];
      }

      if (!filter_data.po_category) {
        filter_data.po_category = ["0"];
      }

      setFilterData(filter_data);
    }

    var sort_data = localStorage.sort_data;
    if (sort_data) {
      sort_data = JSON.parse(sort_data);
      if (sort_data.order) {
        var sort_data_order = sort_data.order;
        if (!sort_data_order.customer) {
          sort_data_order.customer = { type: "desc", value: false };
        }
        if (!sort_data_order.date) {
          sort_data_order.date = { type: "desc", value: false };
        }
        if (!sort_data_order.order_number) {
          sort_data_order.order_number = { type: "desc", value: false };
        }
        if (!sort_data_order.status) {
          sort_data_order.status = { type: "desc", value: false };
        }
        if (!sort_data_order.supplier) {
          sort_data_order.supplier = { type: "desc", value: false };
        }
        if (!sort_data_order.amount) {
          sort_data_order.amount = { type: "desc", value: false };
        }
        if (!sort_data_order.match_invoice) {
          sort_data_order.match_invoice = { type: "desc", value: false };
        }
        setSortField(sort_data_order);
      }
    }
    loadOrders();
  };

  const loadOrders = async (data, firstLoad = false) => {
    setOrdersLoading(true);
    //------------------ sort -------------- //
    var customer_sort = sortFieldRef.current.customer;
    var date_sort = sortFieldRef.current.date;
    var order_number_sort = sortFieldRef.current.order_number;
    var status_sort = sortFieldRef.current.status;
    var supplier_sort = sortFieldRef.current.supplier;
    var amount_sort = sortFieldRef.current.amount;
    var matched_invoice_sort = sortFieldRef.current.match_invoice;

    var orderByFieldCustom = "-orders.order_date";

    if (order_number_sort.value) {
      if (order_number_sort.type === "asc") {
        orderByFieldCustom = "orders.order_number";
      } else if (order_number_sort.type === "desc") {
        orderByFieldCustom = "-orders.order_number";
      }
    }

    if (date_sort.value) {
      if (date_sort.type === "asc") {
        orderByFieldCustom = "orders.order_date";
      } else if (date_sort.type === "desc") {
        orderByFieldCustom = "-orders.order_date";
      }
    }

    if (supplier_sort.value) {
      if (supplier_sort.type === "asc") {
        orderByFieldCustom = "suppliers.name";
      } else if (supplier_sort.type === "desc") {
        orderByFieldCustom = "-suppliers.name";
      }
    }

    if (customer_sort.value) {
      if (customer_sort.type === "asc") {
        orderByFieldCustom = "customers.name";
      } else if (customer_sort.type === "desc") {
        orderByFieldCustom = "-customers.name";
      }
    }

    if (status_sort.value) {
      if (status_sort.type === "asc") {
        orderByFieldCustom = "orders.status";
      } else if (status_sort.type === "desc") {
        orderByFieldCustom = "-orders.status";
      }
    }

    if (amount_sort.value) {
      if (amount_sort.type === "asc") {
        orderByFieldCustom = "amount";
      } else if (amount_sort.type === "desc") {
        orderByFieldCustom = "-amount";
      }
    }

    if (matched_invoice_sort.value) {
      if (matched_invoice_sort.type === "asc") {
        orderByFieldCustom = "matched_invoice";
      } else if (matched_invoice_sort.type === "desc") {
        orderByFieldCustom = "-matched_invoice";
      }
    }

    //------------------ filter -------------- //
    let filterData = {};
    // var employee = await getEmployee();

    if (searchRef.current) {
      data = searchRef.current;
    }
    var Orders = null;
    var order_date_value = { nnull: null };

    //date filter
    var index = filterDataRef.current.date;
    var start_date = moment().format("YYYY-MM-DD 00:00:00");
    var end_date = moment().format("YYYY-MM-DD 23:59:59");
    if (index === 2) {
      start_date = filterDataRef.current.date_range[0];
      end_date = filterDataRef.current.date_range[1];
    } else if (index === 3) {
      start_date = moment()
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    } else if (index === 4) {
      start_date = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    } else if (index === 5) {
      start_date = moment()
        .subtract(29, "days")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment().format("YYYY-MM-DD 23:59:59");
    } else if (index === 6) {
      start_date = moment()
        .subtract(2, "months")
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    }

    if (index !== 1) {
      order_date_value = { between: [start_date, end_date] };
    }

    //order status filter
    var order_index = filterDataRef.current.order;
    var status = { nnull: null };
    if (order_index[0] !== "all") {
      status = { in: order_index };
    }

    //invoice status filter
    var invoice_index = filterDataRef.current.invoice;
    var invoice_status = null;
    if (invoice_index[0] !== "all") {
      invoice_status = { in: invoice_index };
    }

    //supplier filter
    var supplier_index = filterDataRef.current.supplier;
    var supplier = { nnull: null };
    if (supplier_index[0] !== "0") {
      supplier = { in: supplier_index };
    }

    var employee_id = await getEmployee();
    //customer filter
    var customer_index = filterDataRef.current.customer;
    var customer = { nnull: null };
    var showOrder = false;

    //matched with invoice filter
    var matched_invoice_index = filterDataRef.current.matched_invoice;
    var matched_invoice = "";
    if (matched_invoice_index) {
      if (matched_invoice_index[0] !== "all" && matched_invoice_index.length === 1) {
        matched_invoice = matched_invoice_index[0];
      }
    }

    //employee filter
    var employees_index = filterDataRef.current.employee;
    var employees = { nnull: null };
    if (employees_index[0] !== "0") {
      employees = { in: employees_index };
    }


    //po category filter
    var po_category_index = filterDataRef.current.po_category;
    var po_categories = { nnull: null };

    if (po_category_index[0] !== "0") {
      po_categories = { in: po_category_index };
    }

    if (POCreatorPermission()) {
      showOrder = true;
    }
    // if (!AdminPermission()) {

    //   var po_customer_employees = await directus.getItems('customers_approvers_level1', {
    //     fields: 'customer.id',
    //     filter: {
    //       "employee": { "in": employee_id.id },
    //       "customer.id": { "nnull": null },
    //     }
    //   });

    //   var po_customer_employees2 = await directus.getItems('customers_approvers_level2', {
    //     fields: 'customer.id',
    //     filter: {
    //       "employee": { "in": employee_id.id },
    //       "customer.id": { "nnull": null },
    //     }
    //   });

    //   if (po_customer_employees.data.length > 0 || po_customer_employees2.data.length > 0) {
    //     var po_customer_id = [];
    //     var po_customer_id2 = [];
    //     if (po_customer_employees.data.length > 0) {
    //       po_customer_id = Enumerable.from(po_customer_employees.data)
    //         .select(data => data.customer.id)
    //         .toArray();
    //     }

    //     if (po_customer_employees2.data.length > 0) {
    //       po_customer_id2 = Enumerable.from(po_customer_employees2.data)
    //         .where(w => !Enumerable.from(po_customer_id).contains(w.customer.id))
    //         .select(data => data.customer.id)
    //         .toArray();

    //       if (po_customer_id.length > 0) {
    //         po_customer_id = [...po_customer_id, ...po_customer_id2]
    //       }
    //     }
    //     setCustomerEmployee(po_customer_id);
    //     customer = { "in": po_customer_id }
    //   } else {
    //     showOrder = false;
    //   }
    // }

    if (customer_index[0] !== "0") {
      customer = { in: customer_index };
    }

    //isMyOrder filter
    var isMyOrder = filterDataRef.current.isMyOrder;
    var includeArchive = filterDataRef.current.includeArchivedOrders;
    // if (!POApproverPermission()) {
    //   isMyOrder = true;
    // }
    var employee = { nnull: null };

    if (isMyOrder) {
      employee = { eq: employee_id.id };
    }

    if (order_index[0] !== "all") {
      filterData["orders.status"] = status;
    } else {
      filterData = { ["orders.status"]: { nin: ["deleted"] } };
    }

    if (index !== 1) {
      filterData["orders.order_date"] = order_date_value;
    }
    if (isMyOrder) {
      filterData["employee"] = employee;
    }
    if (supplier_index[0] !== "0" && supplier_index.length > 0) {
      filterData["orders.supplier"] = supplier;
    }

    if (POCreatorPermission() && customer_index.length > 0 && customer_index[0] !== "0") {
      filterData["orders.farm_code"] = customer;
    }

    if (invoice_status) {
      filterData["invoices.invoice_status"] = invoice_status;
      filterData["invoices.status"] = "published";
    }

    if (matched_invoice === "matched") {
      filterData["matched_with_invoice"] = "matched";
    } else if (matched_invoice === "unmatched") {
      filterData["matched_with_invoice"] = "unmatched";
    }

    if (!filterDataRef.current.isAnyPrice && filterDataRef.current.price) {
      filterData["total_price"] = [filterDataRef.current.price.min, filterDataRef.current.price.max];
    }

    if (employees_index) {
      if (employees_index[0] !== "0" && employees_index.length > 0) {
        filterData["orders.owner"] = employees;
      }
    }

    if (po_category_index) {
      if (po_category_index[0] !== "0" && po_category_index.length > 0) {
        filterData["orders.category_type"] = po_categories;
      }
    }

    if (filterDataRef.current.po_category_description) {
      filterData["category_type_description"] = filterDataRef.current.po_category_description;
    }

    if (showOrder) {
      var params_filter = [
        "employee.id",
        "employee.owner",
        "employee.position_level",
        "employee.job_title",
        "employee.mobile_number",
        "employee.code",
        "employee.type",
        "employee.email",
        "employee.user.*",
        "supplier.id",
        "supplier.code",
        "supplier.name",
        "supplier.status",
        "supplier.email",
        "farm_code.id",
        "farm_code.code",
        "farm_code.name",
        "farm_code.status",
        "farm_code.legal_name",
        "category_type.*",
        "modified_on",
        "modified_by.*",
        "files.*.*",
        "invoices.*",
        "invoices.orders_id.id",
        "invoices.invoices_id.orders.orders_id.id",
        "invoices.invoices_id.orders.orders_id.order_number",
        "invoices.invoices_id.orders.orders_id.order_items.order_item.id",
        "invoices.invoices_id.orders.orders_id.order_items.order_item.unit_price",
        "invoices.invoices_id.orders.orders_id.order_items.order_item.quantity",
        "invoices.invoices_id.*",
        // "order_items.*.*.*.*",
        "order_items.order_item.category_description",
        "order_items.order_item.category_description_text",
        "order_items.order_item.category_type.*",
        "order_items.order_item.description",
        "order_items.order_item.id",
        "order_items.order_item.internal_code",
        "order_items.order_item.quantity",
        "order_items.order_item.sort",
        "order_items.order_item.supplier_code",
        "order_items.order_item.unit_price",
        "order_items.order_item.invoice_order_item.id",
        "order_items.order_item.invoice_order_item.invoice_id.id",
        "order_items.order_item.invoice_order_item.quantity",
        "order_items.order_item.invoice_order_item.status",
        "id",
        "status",
        "order_date",
        "supplier",
        "cc_email",
        "notes",
        "order_number",
        "farm_code",
        "additional_notes",
        "category_type",
        "category_description",
        "due_date",
        "delivery_instructions",
        "send_to_email",
        "owner.id"
      ];

      var request_header = {
        fields: params_filter,
        meta: "filter_count",
        limit: pageNumberRef.current,
        page: page,
        sort: orderByFieldCustom,
        include_archive: includeArchive
      }

      if (data) {
        request_header['q'] = data;
      }

      var params = Object.entries(request_header).map(e => e.join('=')).join('&');
      Orders = await directus.api.post(`/custom/orders?${params}`, {
        filter: filterData
      });

      var newOrder = await getNewOrder(Orders);
      Orders.data = newOrder;

      setOrders(Orders.data);
      setTotalPage(Orders.meta.filter_count);

      let SortOrder;
      let sort_type = "";
      let sort_by_data = "";
      let changeUpperCase = false;

      if (supplier_sort.value) {
        sort_by_data = "supplier_name";
        sort_type = supplier_sort.type;
        changeUpperCase = true;
      } else if (customer_sort.value) {
        sort_by_data = "customer_name";
        sort_type = customer_sort.type;
        changeUpperCase = true;
      } else if (date_sort.value) {
        sort_by_data = "order_date";
        sort_type = date_sort.type;
      } else if (order_number_sort.value) {
        sort_by_data = "order_number";
        sort_type = order_number_sort.type;
      } else if (status_sort.value) {
        sort_by_data = "status";
        sort_type = status_sort.type;
      } else if (amount_sort.value) {
        sort_by_data = "total_order";
        sort_type = amount_sort.type;
      } else if (matched_invoice_sort.value) {
        sort_by_data = "matched_invoice_flag";
        sort_type = matched_invoice_sort.type;
      } else {
        sort_by_data = "order_date";
        sort_type = "desc";
      }

      SortOrder = Orders.data;

      SortOrder.map((value, index) => {
        var supplier_name = "";
        var customer_name = "";
        var matched_invoice_flag = false;

        if (value.supplier !== null) {
          if (value.supplier.name) {
            supplier_name = value.supplier.name;
          }
        }

        if (value.farm_code !== null) {
          if (value.farm_code.name) {
            customer_name = value.farm_code.name;
          }
        }

        SortOrder[index].supplier_name = supplier_name;
        SortOrder[index].customer_name = customer_name;

        let totalOrder = 0;
        //eslint-disable-next-line array-callback-return
        value.order_items.map(data => {
          if (data.order_item !== null) {
            totalOrder = totalOrder + data.order_item.unit_price * data.order_item.quantity;
          }
        });

        if (value.invoices.length > 0) {
          matched_invoice_flag = true;
        }
        SortOrder[index].total_order = totalOrder;
        SortOrder[index].matched_invoice_flag = matched_invoice_flag;
      });

      SortOrder = linq
        .from(SortOrder)
        .orderByDescending(o => o.date)
        .toArray();

      if (sort_by_data) {
        if (sort_type === "desc") {
          SortOrder.sort(function (a, b) {
            let nameA = a[sort_by_data] ? a[sort_by_data] : "";
            let nameB = b[sort_by_data] ? b[sort_by_data] : "";
            if (changeUpperCase) {
              nameA = nameA ? nameA.toUpperCase() : nameA; // ignore upper and lowercase
              nameB = nameB ? nameB.toUpperCase() : nameB; // ignore upper and lowercase
            }

            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            return 0; // names must be equal
          });
        } else {
          SortOrder.sort(function (a, b) {
            let nameA = a[sort_by_data] ? a[sort_by_data] : "";
            let nameB = b[sort_by_data] ? b[sort_by_data] : "";
            if (changeUpperCase) {
              nameA = nameA ? nameA.toUpperCase() : nameA; // ignore upper and lowercase
              nameB = nameB ? nameB.toUpperCase() : nameB; // ignore upper and lowercase
            }

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0; // names must be equal
          });
        }
      }

      if (SortOrder) {
        setOrders(SortOrder);
      }
    }
    setSortLoading(false);
    setFilterLoading(false);
    setLoadingPagination(false);
    setOrdersLoading(false);
  };

  const loadCategoryType = async () => {
    setCategoryTypeLoading(true);

    var cache = getCache("order_category_type");
    if (!cache) {
      var Category = await directus.getItems("order_category_type", {
        fields: "*",
        sort: "name"
      });

      setCache("order_category_type", Category.data);
      cache = Category.data;
    }

    let data = cache;

    setCategoryType(data);
    loadCollections(data);
  };

  // const loadConfigPO = async () => {
  //   var config_po = getCache("config_po");
  //   if (!config_po) {
  //     var result = await directus.getItems("config_po", {
  //       fields: "*"
  //     });

  //     if (result.data[0]) {
  //       setCache("config_po", result.data[0]);
  //       config_po = result.data[0];
  //     }
  //   }

  //   if (config_po) {
  //     if (config_po.update_status_to_draft) {
  //       setUpdateStatusToDraft(config_po.update_status_to_draft);
  //     }
  //   }
  // };

  const loadEmployeeID = async () => {
    var employee_id = await getEmployee();
    setEmployeeID(employee_id.id);
  };

  const loadCollections = async data => {
    if (data.length > 0) {
      var count = 0;
      var new_collection = [];
      const promises = await data.map(async value => {
        if (value.collection) {
          var custom_filter = value.custom_filter;
          if (!collectionNameRef.current.includes(value.collection)) {
            setCollectionName([...collectionNameRef.current.concat(value.collection)]);

            if (custom_filter) {
              if (!custom_filter["status"]) {
                custom_filter["status"] = { nin: "deleted" };
              }
            } else {
              custom_filter = { status: { nin: "deleted" } };
            }

            var params = {
              fields: "*",
              filter: custom_filter,
              sort: "name",
              meta: "filter_count",
              limit: -1
            };

            var cache_key = `${value.collection}_${JSON.stringify(params).hashCode()}`;
            var cache_data = getCache(cache_key);
            if (!cache_data) {
              var result = await directus.getItems(value.collection, params);
              setCollectionData([...collectionDataRef.current.concat({
                data: result.data,
                filter_count: result.meta.filter_count
              })]);
              setCache(cache_key, result.data);
            } else {
              setCollectionData([...collectionDataRef.current.concat({
                data: cache_data,
                filter_count: cache_data.length
              })]);
            }

            new_collection.push({ settings: value, collection_index: count });
            count++;
          } else {
            var collection_index = collectionNameRef.current.findIndex(obj => obj == value.collection);
            new_collection.push({
              settings: value,
              collection_index: collection_index !== -1 ? collection_index : null
            });
          }
        } else {
          new_collection.push({ settings: value, collection_index: null });
        }
      });
      await Promise.all(promises);
      setCollection(new_collection);
    }

    setCategoryTypeLoading(false);
  };

  const loadOrderEventType = async () => {
    let company = JSON.parse(window.localStorage.getItem("company"));
    let token = JSON.parse(window.localStorage.getItem("directus_data"));

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.token
      }
    };

    var order_event_type = getCache("order_event_type_options_choices");

    if (!order_event_type) {
      fetch(`${react_app_url}${company}/fields/order_event/type`, requestOptions)
        .then(response => response.json())
        .then(async result => {
          if (result.data) {
            setOrderEventType(result.data.options.choices);
            setCache("order_event_type_options_choices", result.data.options.choices);
          }
        });
    } else {
      setOrderEventType(order_event_type);
    }
  };

  const pageChange = value => {
    setLoadingPagination(true);
    page = page + value;
    loadOrders(searchRef.current);
  };

  const goToFirstPage = async () => {
    setLoadingPagination(true);
    page = 1;
    loadOrders(searchRef.current);
  };

  const handleChange = event => {
    setPageNumber(event.target.value);
    page = 1;
    setLoadingPagination(true);
    loadOrders(searchRef.current);
  };

  const exportOrder = async export_data => {
    let dataURL = "";

    var start_date = null;
    var end_date = null;

    var index = filterDataRef.current.date;
    if (index === 2) {
      start_date = filterDataRef.current.date_range[0];
      end_date = filterDataRef.current.date_range[1];
    } else if (index === 3) {
      start_date = moment()
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    } else if (index === 4) {
      start_date = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    } else if (index === 5) {
      start_date = moment()
        .subtract(29, "days")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment().format("YYYY-MM-DD 23:59:59");
    } else if (index === 6) {
      start_date = moment()
        .subtract(2, "months")
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    }

    //order status filter
    var order_index = filterDataRef.current.order;
    var status = null;
    if (order_index[0] !== "all") {
      status = order_index;
    }

    //invoice status filter
    var invoice_index = filterDataRef.current.invoice;
    var invoice_status = null;
    if (invoice_index[0] !== "all") {
      invoice_status = invoice_index;
    }

    //customer filter
    var customer_index = filterDataRef.current.customer;
    var customer = null;

    // if (!POApproverPermission()) {
    //   customer = customerEmployeeRef.current;
    // }
    if (customer_index[0] !== "0") {
      customer = customer_index;
    }

    //supplier filter
    var supplier_index = filterDataRef.current.supplier;
    var supplier = null;
    if (supplier_index[0] !== "0") {
      supplier = supplier_index;
    }

    //isMyOrder filter
    var isMyOrder = filterDataRef.current.isMyOrder;
    // if (!POApproverPermission()) {
    //   isMyOrder = true;
    // }
    var employee = null;
    var employee_id = await getEmployee();

    if (isMyOrder) {
      employee = employee_id.id;
    }

    //matched invoice filter
    var matched_invoice_index = filterDataRef.current.matched_invoice;
    var matched_invoice = null;
    if (matched_invoice_index) {
      if (matched_invoice_index[0] !== "all" && matched_invoice_index.length === 1) {
        matched_invoice = matched_invoice_index[0];
      }
    }

    //employee created by filter
    var created_by_index = filterDataRef.current.employee;
    var created_by = null;
    if (created_by_index) {
      if (created_by_index[0] !== "0") {
        created_by = created_by_index;
      }
    }

    //po category filter
    var po_category_index = filterDataRef.current.po_category;
    var po_category = null;
    if (po_category_index) {
      if (po_category_index[0] !== "0") {
        po_category = po_category_index;
      }
    }

    //------------------ sort -------------- //
    var customer_sort = sortFieldRef.current.customer;
    var date_sort = sortFieldRef.current.date;
    var order_number_sort = sortFieldRef.current.order_number;
    var status_sort = sortFieldRef.current.status;
    var supplier_sort = sortFieldRef.current.supplier;
    var amount_sort = sortFieldRef.current.amount;
    var matched_invoice_sort = sortFieldRef.current.match_invoice;

    let sort_type = "";
    let sort_by_data = "";

    if (supplier_sort.value) {
      sort_by_data = "supplier_name";
      sort_type = supplier_sort.type;
    } else if (customer_sort.value) {
      sort_by_data = "customer_name";
      sort_type = customer_sort.type;
    } else if (date_sort.value) {
      sort_by_data = "order_date";
      sort_type = date_sort.type;
    } else if (order_number_sort.value) {
      sort_by_data = "order_number";
      sort_type = order_number_sort.type;
    } else if (status_sort.value) {
      sort_by_data = "status";
      sort_type = status_sort.type;
    } else if (amount_sort.value) {
      sort_by_data = "amount";
      sort_type = amount_sort.type;
    } else if (matched_invoice_sort.value) {
      sort_by_data = "matched_invoice_flag";
      sort_type = matched_invoice_sort.type;
    } else {
      sort_by_data = "order_date";
      sort_type = "desc";
    }

    if (export_data.link === "all_detailed" || export_data.link === "all_invoice_qty_detailed") {
      var file_name_temp = "temp_order_export-" + uuidv4();
      var request_body_default = {
        start_date: start_date,
        end_date: end_date,
        export_id: export_data.id,
        selected_customer: customer,
        selected_supplier: supplier,
        selected_created_by: created_by,
        selected_po_category: po_category,
        search_by_text: searchRef.current,
        status: status,
        invoice_status: invoice_status,
        employee: employee,
        matched_invoice: matched_invoice,
        custom_sort: { sort_by_data: sort_by_data, sort_type: sort_type },
        isAnyPrice: filterDataRef.current.isAnyPrice,
        total_price: [filterDataRef.current.price.min, filterDataRef.current.price.max]
      };

      var end_index = 0;
      var looping_total = parseInt(totalPageRef.current / 100);
      var total_left = totalPageRef.current % 100;
      if (total_left > 0) {
        looping_total += 1;
      }
      var isLooping = true;

      if (totalPageRef.current !== 0 && totalPageRef.current !== "0") {
        for (var i = 0; i < looping_total; i++) {
          if (isLooping) {
            var request_body = request_body_default;

            var offset = 0;
            if (i === 0) {
              offset = 0;
            } else {
              offset = i * 100;
            }

            request_body["settings"] = {
              offset: offset,
              end_index: end_index,
              file_name: file_name_temp,
              isLastLooping: i === looping_total - 1
            };

            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + directus.api.auth.config.token
              },
              body: JSON.stringify(request_body)
            };

            dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem("company"))}/custom/export_order/` + export_data.link;

            if (i === looping_total - 1) {
              try {
                await fetch(dataURL, requestOptions)
                  .then(resp => resp.blob())
                  .then(async blob => {
                    var file = await blob.text();
                    if (file.includes("error")) {
                      alert("Connection error. Please try again.");
                    } else if (file.includes("no_template")) {
                      alert("No Template");
                    } else {
                      const url = window.URL.createObjectURL(blob);
                      const a = document.createElement("a");
                      a.style.display = "none";
                      a.href = url;
                      // the filename you want
                      var file_name = "Order-Export";
                      if (start_date && end_date) {
                        file_name += `-${moment(start_date).format("YYYY-MM-DD")}-${moment(end_date).format("YYYY-MM-DD")}`;
                      } else {
                        file_name += "-All";
                      }
                      file_name += ".xlsx";
                      a.download = file_name;
                      document.body.appendChild(a);
                      a.click();
                      window.URL.revokeObjectURL(url);
                    }
                  });

                EventEmitter.emit("export_loading", { value: false });
              } catch (error) {
                alert("Connection error. Please try again.");
                EventEmitter.emit("export_loading", { value: false });
              }
            } else {
              try {
                await fetch(dataURL, requestOptions)
                  .then(resp => resp.json())
                  .then(resp => {
                    if (resp.isLooping) {
                      end_index = resp.end_index + 1;
                    } else {
                      isLooping = false;
                      EventEmitter.emit("export_loading", { value: false });
                      alert("Connection error. Please try again.");
                    }
                  });
              } catch (error) {
                alert("Connection error. Please try again.");
                EventEmitter.emit("export_loading", { value: false });
              }
            }
          }
        }
      } else {
        EventEmitter.emit("export_loading", { value: false });
      }
    } else {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + directus.api.auth.config.token },
        body: JSON.stringify({
          start_date: start_date,
          end_date: end_date,
          export_id: export_data.id,
          selected_customer: customer,
          selected_supplier: supplier,
          selected_created_by: created_by,
          selected_po_category: po_category,
          search_by_text: searchRef.current,
          status: status,
          invoice_status: invoice_status,
          employee: employee,
          matched_invoice: matched_invoice,
          custom_sort: { sort_by_data: sort_by_data, sort_type: sort_type },
          isAnyPrice: filterDataRef.current.isAnyPrice,
          total_price: [filterDataRef.current.price.min, filterDataRef.current.price.max]
        })
      };

      dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem("company"))}/custom/export_order/` + export_data.link;

      try {
        await fetch(dataURL, requestOptions)
          .then(resp => resp.blob())
          .then(async blob => {
            var file = await blob.text();
            if (file.includes("error")) {
              alert("Connection error. Please try again.");
            } else if (file.includes("no_template")) {
              alert("No Template");
            } else {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              // the filename you want
              var file_name = "Order-Export";
              if (start_date && end_date) {
                file_name += `-${moment(start_date).format("YYYY-MM-DD")}-${moment(end_date).format("YYYY-MM-DD")}`;
              } else {
                file_name += "-All";
              }
              file_name += ".xlsx";
              a.download = file_name;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            }
          });
      } catch (error) {
        alert("Connection error. Please try again.");
        EventEmitter.emit("export_loading", { value: false });
      }
      EventEmitter.emit("export_loading", { value: false });
    }
    EventEmitter.emit("export_loading", { value: false });
  };

  const changeAdd = data => {
    setAdd(data);
  };

  const sortData = async (val, defaultType) => {
    setSortLoading(true);
    var sort_field_data = {
      order_number: { type: "desc", value: false },
      date: { type: "desc", value: false },
      supplier: { type: "desc", value: false },
      customer: { type: "desc", value: false },
      status: { type: "desc", value: false },
      amount: { type: "desc", value: false },
      match_invoice: { type: "desc", value: false }
    };

    var sort_data = sortFieldRef.current[val];
    if (sort_data) {
      var value = sort_data.value;
      if (sort_data.value === false) {
        value = true;
      }

      if (value === true) {
        var sort_type = "";
        if (defaultType) {
          sort_type = defaultType;
        } else {
          if (sort_data.type === "desc") {
            sort_type = "asc";
          } else {
            sort_type = "desc";
          }
        }

        sort_field_data[val].value = true;
        sort_field_data[val].type = sort_type;

        var sort_data = localStorage.sort_data;
        if (sort_data) {
          sort_data = JSON.parse(sort_data);
          sort_data.order = sort_field_data;
        } else {
          sort_data = { order: sort_field_data };
        }
        localStorage.setItem("sort_data", JSON.stringify(sort_data));
        setSortField(sort_field_data);
      }
    }
    await loadOrders();
  };

  return (
    <Page className={classes.root} title="Orders" style={{ paddingTop: "0px" }}>
      <Container maxWidth={false} style={{ padding: "0px" }}>
        <List className={classess.root} subheader={<li />} style={{ overflow: "hidden" }}>
          <ListSubheader style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Paper elevation={0} className={classes.tablehead}>
              <Grid container spacing={0} justifyContent="space-around"
                style={{ paddingLeft: "22px", paddingRight: "6px" }}>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                  <div className={classes.headerSort} onClick={() => sortData("order_number")}>
                    <Typography noWrap component="div">
                      <b>
                        <OverflowTip>Order No.</OverflowTip>
                      </b>
                    </Typography>
                    <div style={{ position: "relative" }}>
                      {sortFieldRef.current["order_number"].value === true ? (
                        sortFieldRef.current["order_number"].type === "asc" ? (
                          <SortRoundedIcon className={classes.ascSort} />
                        ) : (
                          <SortRoundedIcon className={classes.descSort} />
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                  <div className={classes.headerSort} onClick={() => sortData("date")}>
                    <Typography noWrap component="div">
                      <b>
                        <OverflowTip>Date</OverflowTip>
                      </b>
                    </Typography>
                    <div style={{ position: "relative" }}>
                      {sortFieldRef.current["date"].value === true ? (
                        sortFieldRef.current["date"].type === "asc" ? (
                          <SortRoundedIcon className={classes.ascSort} />
                        ) : (
                          <SortRoundedIcon className={classes.descSort} />
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                  <div className={classes.headerSort} onClick={() => sortData("supplier")}>
                    <Typography noWrap component="div">
                      <b>
                        <OverflowTip>Supplier</OverflowTip>
                      </b>
                    </Typography>
                    <div style={{ position: "relative" }}>
                      {sortFieldRef.current["supplier"].value === true ? (
                        sortFieldRef.current["supplier"].type === "asc" ? (
                          <SortRoundedIcon className={classes.ascSort} />
                        ) : (
                          <SortRoundedIcon className={classes.descSort} />
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                  <div className={classes.headerSort} onClick={() => sortData("customer")}>
                    <Typography noWrap component="div">
                      <b>
                        <OverflowTip>Customer</OverflowTip>
                      </b>
                    </Typography>
                    <div style={{ position: "relative" }}>
                      {sortFieldRef.current["customer"].value === true ? (
                        sortFieldRef.current["customer"].type === "asc" ? (
                          <SortRoundedIcon className={classes.ascSort} />
                        ) : (
                          <SortRoundedIcon className={classes.descSort} />
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} md={2} lg={2}>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "80%" }}>
                      <div className={classes.headerSort} onClick={() => sortData("status")}>
                        <Typography noWrap component="div">
                          <b>
                            <OverflowTip>Status</OverflowTip>
                          </b>
                        </Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current["status"].value === true ? (
                            sortFieldRef.current["status"].type === "asc" ? (
                              <SortRoundedIcon className={classes.ascSort} />
                            ) : (
                              <SortRoundedIcon className={classes.descSort} />
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    {showInvoiceTabsRef.current === true ?
                      <div style={{ width: "20%", height: "20px", marginTop: "-2px" }}>
                        <div className={classes.headerSort} onClick={() => sortData("match_invoice")}>
                          <CustomTooltip arrow title={<Typography variant="h6">Match Invoice</Typography>}>
                            <InsertDriveFileOutlinedIcon />
                          </CustomTooltip>
                          <div style={{ position: "relative", marginTop: "1px" }}>
                            {sortFieldRef.current["match_invoice"].value === true ? (
                              sortFieldRef.current["match_invoice"].type === "asc" ? (
                                <SortRoundedIcon className={classes.ascSort} />
                              ) : (
                                <SortRoundedIcon className={classes.descSort} />
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      : <div style={{ width: "20%", height: "20px", marginTop: "-2px" }}></div>}
                  </div>
                </Grid>
                <Grid item xs={2} md={2} lg={2}>
                  <div className={classes.headerSort} onClick={() => sortData("amount")}>
                    <Typography noWrap component="div">
                      <b>
                        <OverflowTip>Amount</OverflowTip>
                      </b>
                    </Typography>
                    <div style={{ position: "relative" }}>
                      {sortFieldRef.current["amount"].value === true ? (
                        sortFieldRef.current["amount"].type === "asc" ? (
                          <SortRoundedIcon className={classes.ascSort} />
                        ) : (
                          <SortRoundedIcon className={classes.descSort} />
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
              {sortLoadingRef.current ? (
                <Grid item xs={12}>
                  <div className={classes.linearProgress}
                    style={{ marginTop: "7px", marginLeft: "7px", marginRight: "7px" }}>
                    <LinearProgress style={{ height: "2px" }} />
                  </div>
                </Grid>
              ) : (
                ""
              )}
            </Paper>
          </ListSubheader>

          {add ? (
            <div>
              <AddOrder
                categoryType={categoryTypeRef.current}
                collection={collectionRef.current}
                collectionData={collectionDataRef.current}
                categoryTypeLoading={categoryTypeLoading}
                loadOrders={loadOrders}
                orderData={ordersRef.current}
                changeAdd={changeAdd}
              />
            </div>
          ) : (
            <div></div>
          )}

          {orders ? (
            orders.length > 0 ? (
              <div style={{ position: "relative" }}>
                <div>
                  <OrderDetail
                    loadOrders={loadOrders}
                    orderData={ordersRef.current}
                    categoryType={categoryType}
                    collection={collectionRef.current}
                    collectionData={collectionDataRef.current}
                    categoryTypeLoading={categoryTypeLoading}
                    orderEventType={orderEventType}
                    goToFirstPage={goToFirstPage}
                    updateStatusToDraft={updateStatusToDraftRef.current}
                    employeeID={employeeID}
                    showInvoiceTab={showInvoiceTabsRef.current}
                  />
                </div>
                {sortLoadingRef.current || loadingPagination || filterLoadingRef.current ? (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "999",
                      top: 0,
                      height: "calc(100% - 10px)",
                      width: "100%",
                      background: "#6e6e6e05",
                      borderRadius: "10px"
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
            ) : !ordersLoading ? (
              <Grid container direction="column" justifyContent="center" alignItems="center"
                style={{ opacity: "0.2", paddingTop: "6pc" }}>
                <Grid item xs={12} sm={12} lg={12}>
                  <img alt="No Data Found" height="230" width="230" src="/static/images/folder.png?v=0.0.1" />
                  <Typography variant="h3" style={{ textAlign: "end" }}>
                    No Data Found
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              ""
            )
          ) : !ordersLoading ? (
            <Grid container direction="column" justifyContent="center" alignItems="center"
              style={{ opacity: "0.2", paddingTop: "6pc" }}>
              <Grid item xs={12} sm={12} lg={12}>
                <img alt="No Data Found" height="230" width="230" src="/static/images/folder.png?v=0.0.1" />
                <Typography variant="h3" style={{ textAlign: "end" }}>
                  No Data Found
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <CircularProgress className={classes.circular} />
          )}
        </List>

        {orders ? (
          orders.length > 0 ? (
            <Grid item xs={12}
              style={{ textAlign: "right", marginTop: "10px", paddingRight: "20px", fontFamily: "Manrope" }}>
              <span style={{ paddingRight: "20px" }}>Rows per page:</span>
              <span style={{ paddingRight: "20px" }}>
                <TextField id="standard-select-currency" select value={pageNumber} onChange={handleChange}>
                  {pageOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </span>

              <span id="pagination_number" style={{ paddingRight: "20px" }}>
                {pageNumberRef.current * (page - 1) + 1} - {pageNumberRef.current * page > totalPage ? totalPage : pageNumberRef.current * page} of{" "}
                {totalPage}
              </span>

              <Button
                id="prev_btn"
                variant="outlined"
                size="small"
                disabled={page === 1 || loadingPagination}
                className={classes.buttonLeft}
                onClick={() => pageChange(-1)}
              >
                <ArrowBackIosRoundedIcon />
              </Button>

              <Button
                id="next_btn"
                variant="outlined"
                size="small"
                className={classes.buttonRight}
                disabled={page >= Math.ceil(totalPage / pageNumberRef.current) || loadingPagination}
                onClick={() => pageChange(1)}
              >
                <ArrowForwardIosRoundedIcon />
              </Button>
            </Grid>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </Container>
    </Page>
  );
};

export default IndexView;
