import React, { useState, useEffect } from 'react';
import { Grid, Typography, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, TextareaAutosize, Menu, MenuItem } from '@material-ui/core';
import './style.css';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';
import moment from 'moment';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import directus from 'src/services/directus';
import useStateRef from 'react-usestateref';
// import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Box from '@material-ui/core/Box';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Chip from '@material-ui/core/Chip';
import { useSnackbar } from 'notistack';
import { useStyles as useStyles2, CancelButton, CancelledButton, CompletedButton, DraftButton, SubmitButton } from 'src/views/_styles/style1';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import linq from 'linq';
import EventEmitter from 'src/utils/EventEmitter';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { POApproverPermission, POCreatorPermission } from 'src/utils/Permission';
import ErrorMessage from 'src/components/ErrorMessage';
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { CustomTooltip } from '../setting/style';
import Enumerable from 'linq';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import InfoIcon from '@material-ui/icons/Info';
import DescriptionIcon from '@mui/icons-material/Description';

function OrderStatusSent({
  data,
  items,
  props,
  totalOrderItem,
  loadOrders,
  handleChange,
  orderEventType,
  editMode,
  goToFirstPage,
  updateStatusToDraft,
  showInvoiceTab
}) {
  /* eslint-disable no-unused-vars*/
  // const classes = useStyles();
  const classes = useStyles2();
  const [list, setList, listRef] = useStateRef(data);
  const [open, setOpen] = React.useState(false);
  let status = { backgroundColor: '#FFF0DD' };
  const rowLen = items.length;
  const note = data.notes;
  const delivery_instructions = data.delivery_instructions;
  const email = data.cc_email;
  const supplier = data.supplier ? data.supplier.name : null;
  const customer = data.farm_code ? data.farm_code.name : null;

  // const [image, setImage] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingStatusCompleted, setLoadingStatusCompleted] = React.useState(false);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const [loadingFile, setLoadingFile] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showChangeLog, setShowChangeLog] = React.useState(false);
  const [logData, setLogData] = React.useState([]);
  const [loadingExport, setLoadingExport] = React.useState(false);

  const [matchedInvoiceData, setMatchedInvoiceData, matchedInvoiceDataRef] = useStateRef([]);
  const [loadingResendSupplier, setLoadingResendSupplier] = useState(false);

  const [anchorElSettings, setAnchorElSettings] = React.useState(null);
  const openSettings = Boolean(anchorElSettings);

  const navigate = useNavigate();

  const [openGenerateInvoiceDialog, setOpenGenerateInvoiceDialog] = React.useState(false);
  const [loadingGenerateInvoice, setLoadingGenerateInvoice] = useState(false);
  const [selectedAttachment, setSelectedAttachment, selectedAttachmentRef] = useStateRef(null);

  const [orderNote, setOrderNote, orderNoteRef] = useStateRef(list.additional_notes ? list.additional_notes : '');
  /* eslint-enable no-unused-vars*/

  useEffect(() => {
    if (data.invoices) {
      var matched_invoice_data = [];
      data.invoices.map(value => {
        let invoice_data = value.invoices_id;
        let totalOrder = 0;

        if (invoice_data) {
          invoice_data.orders.map(order_value => {
            var order_data = order_value.orders_id;
            if (order_data) {
              order_data.order_items.map(item => {
                if (item.order_item) {
                  totalOrder = totalOrder + item.order_item.unit_price * item.order_item.quantity;
                }
              });
            }
          });

          if (!totalOrder) {
            totalOrder = invoice_data.total_amount;
          }

          matched_invoice_data.push({
            date: invoice_data.date,
            number: invoice_data.number,
            invoice_amount: totalOrder,
            date_matched: value.date_matched,
            id: invoice_data.id
          });
        }
      });

      if (matched_invoice_data.length > 0) {
        matched_invoice_data = linq
          .from(matched_invoice_data)
          .orderBy(o => o.date)
          .toArray();
      }

      setMatchedInvoiceData(matched_invoice_data);
    }
  }, []);

  const updateStatus = async (id, status) => {
    setLoadingSubmit(true);
    setLoadingStatusCompleted(true);
    handleCloseDialog();

    let editItems = [];

    //eslint-disable-next-line array-callback-return
    items.map((data, index) => {
      editItems = [
        ...editItems,
        {
          id: data.order_item.id,
          description: data.order_item.description,
          quantity: data.order_item.quantity,
          unit_price: data.order_item.unit_price,
          cc_email: data.cc_email,
          note: data.note
        }
      ];
    });

    if (editItems.length > 0) {
      await directus.updateItems('order_items', editItems);
    }

    await directus.updateItem('orders', id, {
      notes: note,
      delivery_instructions: delivery_instructions,
      cc_email: email,
      supplier: supplier ? supplier.id : null,
      farm_code: customer ? customer.id : null,
      status: status
    });

    loadOrders();
    setLoadingSubmit(false);
    setLoadingStatusCompleted(false);

    var message = 'Status has changed to ' + status;
    enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
  };

  const handleClickShowChangeLog = async id => {
    if (!showChangeLog) {
      var OrderEvent = await directus.getItems('order_event', {
        fields: '*.*',
        filter: {
          order: id
        },
        sort: 'created_on'
      });

      setLogData(OrderEvent.data);
    }
    setShowChangeLog(!showChangeLog);
  };

  const handleClickExportActionLog = async id => {
    const react_app_url = process.env.REACT_APP_URL;
    downloadFile(`${react_app_url}${JSON.parse(window.localStorage.getItem("company"))}/custom/export_order/action_log/${id}`);
  };

  const downloadFile = url => {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop();
    link.click();
  };

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSubmission = async (e, order) => {
    setLoadingFile(true);
    e.preventDefault();

    const form = document.querySelector('#my-form');
    const data = new FormData(form);

    let updateData = await directus.uploadFiles(data, onUploadProgress);

    await directus.createItem('order_files', {
      status: 'published',
      order_id: order.id,
      directus_file_id: updateData.data.id
    });

    loadOrders();
    setLoadingFile(false);
    var message = 'File uploaded successfully';
    enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
  };

  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + '% Done');
  }

  const handleClick = id => {
    window.open(id);
  };

  const handleDelete = async id => {
    let delItems = [];
    delItems = [...delItems, id];

    if (delItems.length > 0) {
      await directus.deleteItems('order_files', delItems);
    }

    loadOrders();
    var message = 'File has been deleted';
    enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
  };

  const formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  const exportPO = async data => {
    setLoadingExport(true);
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + directus.api.auth.config.token }
      };

      var react_app_url = process.env.REACT_APP_URL;
      if (window.location.hostname.search('interax') !== -1) {
        react_app_url = process.env.REACT_APP_URL_INTERAX;
      }
      await fetch(`${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/export/purchase_order/` + data.id, requestOptions)
        .then(response => response.blob())
        .then(async blob => {
          var file = await blob.text();
          const url = window.URL.createObjectURL(blob);

          if (file.includes('error')) {
            var message = JSON.parse(file).error.message;
            alert(JSON.parse(message).error.message);
          } else {
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            // a.download = 'PO-Export' + '-' + moment(data.order_date).format('YYYY-MM-DD') + '.pdf';
            a.download = `PO-Export-${moment(data.order_date).format('YYYY-MM-DD')}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
        });
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
    setLoadingExport(false);
  };

  const navigateToInvoice = value => {
    EventEmitter.emit('hideFilter', { value: true });
    EventEmitter.emit('reload_item', { value: `/invoice/${value.id}` });
    navigate(`/invoice/${value.id}`, { state: { id: '', arrow: '', prevLocation: window.location.pathname } });
  };

  const duplicateData = async value => {
    setLoadingDuplicate(true);
    var directusUser = JSON.parse(window.localStorage.getItem('directus_employee'));
    const date = moment().format('YYYY-MM-DD HH:mm:ss');
    var order_body = {
      employee: directusUser.id,
      status: 'draft',
      order_date: date,
      notes: value.notes,
      cc_email: value.cc_email,
      supplier: value.supplier ? value.supplier.id : null,
      farm_code: value.farm_code ? value.farm_code.id : null,
      category_type: value.category_type.id,
      category_description: value.category_description
        ? value.category_description.id
          ? value.category_description.id
          : value.category_description
        : value.category_description
      // due_date: value.due_date
    };

    const orderResult = await directus.createItems('orders', order_body);

    var order_items = value.order_items;
    var order_list = [];
    if (order_items) {
      //eslint-disable-next-line array-callback-return
      order_items.map(data => {
        order_list = [
          ...order_list,
          {
            order: { id: orderResult.data.id },
            order_item: {
              description: data.order_item.description,
              quantity: data.order_item.quantity,
              unit_price: data.order_item.unit_price,
              category_type: data.order_item.category_type.id,
              category_description: data.order_item.category_description
                ? data.order_item.category_description.id
                  ? data.order_item.category_description.id
                  : data.order_item.category_description
                : '',
              sort: data.order_item.sort,
              supplier_code: data.order_item.supplier_code
            }
          }
        ];
      });

      for (let index = 0; index < order_list.length; index++) {
        let order_item_list = order_list[index];
        const order_item = order_item_list.order_item;
        var orderItemResult = await directus.createItems('order_items', order_item);
        if (orderItemResult && orderItemResult.data) {
          order_item_list.order_item = { id: orderItemResult.data.id };
          order_list[index] = order_item_list;
        }
      }

      await directus.createItems('orders_items', order_list);
    }

    await props.loadOrders();

    goToFirstPage();

    if (orderResult.data) {
      editMode(orderResult.data.id, true);
    }

    var message = 'Order has been duplicated';
    enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
    setLoadingDuplicate(false);
  };

  const navigateToOtherInvoice = id => {
    navigate(`/invoice/${id}`, { state: { prevLocation: window.location.pathname } });
  };

  const resendToSuplier = async id => {
    setLoadingResendSupplier(true);
    try {
      let result = await directus.api.get(`/custom/orders/send_po_email/${id}`, { resend_supplier: true });
      if (result.data) {
        enqueueSnackbar('Order has been resent to supplier', {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'success',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
    setLoadingResendSupplier(false);
  };

  const clickSettings = e => {
    setAnchorElSettings(e.currentTarget);
  };

  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };

  //generate invoice
  const handleOpenGenerateInvoiceDialog = value => {
    setOpenGenerateInvoiceDialog(true);
    setSelectedAttachment(value);
  };

  const handleCloseGenerateInvoiceDialog = () => {
    setOpenGenerateInvoiceDialog(false);
    setSelectedAttachment(null);
  };

  const generateInvoice = async (isMatchedPO = false) => {
    try {
      setLoadingGenerateInvoice(true);
      const requestOptions = {
        method: 'GET'
      };

      var react_app_url = process.env.REACT_APP_URL;
      if (window.location.hostname.search('interax') !== -1) {
        react_app_url = process.env.REACT_APP_URL_INTERAX;
      }
      await fetch(`${react_app_url}${selectedAttachmentRef.current.directus_file_id.data.asset_url}`, requestOptions)
        .then(res => res.blob()) // Gets the response and returns it as a blob
        .then(async blob => {
          var file_name = selectedAttachmentRef.current.directus_file_id.filename_download;
          const data = new FormData();
          data.append('blob', blob, `PO-${list.order_number}-${file_name}`);

          let updateData = await directus.api.post('/custom/emails/upload-order-invoice', data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          if (!updateData.error_message) {
            if (isMatchedPO) {
              await directus.createItem('invoices_orders', {
                date_matched: moment().format('YYYY-MM-DD'),
                invoices_id: updateData.invoice_id,
                orders_id: list.id
              });
            }
            if (updateData.invoice_id) {
              EventEmitter.emit('hideFilter', { value: true });
              EventEmitter.emit('reload_item', { value: `/invoice/${updateData.invoice_id}` });
              navigate(`/invoice/${updateData.invoice_id}`);
              var message = 'A new invoice has been created.';
              enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
            }
          } else {
            enqueueSnackbar(updateData.error_message, {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: 'error',
              autoHideDuration: 3200,
              action: CloseSnackbarAction
            });
            setLoadingGenerateInvoice(false);
          }
        });
    } catch (e) {
      if (e.message.includes('Error! The image')) {
        enqueueSnackbar('This PO attachment cannot be generated into invoice.', {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
      } else {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }
    }
    setLoadingGenerateInvoice(false);
  };

  // const generateInvoice = async () => {
  //   setLoadingExport(true);
  //   try {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + directus.api.auth.config.token },
  //     };

  //     var react_app_url = process.env.REACT_APP_URL;
  //     if (window.location.hostname.search('interax') !== -1) {
  //       react_app_url = process.env.REACT_APP_URL_INTERAX;
  //     }

  //     await fetch(`${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/export/purchase_order/` + data.id, requestOptions)
  //       .then(response => response.blob())
  //       .then(async blob => {
  //         const data = new FormData();
  //         data.append("blob", blob, `PO-${list.order_number}.pdf`);

  //         let updateData = await directus.uploadFiles(data, onUploadProgress);

  //         var result = await directus.createItem('invoices', {
  //           id: uuidv4(),
  //           status: "published",
  //           date: moment().format("YYYY-MM-DD"),
  //           invoice_status: "unmatched",
  //           raw_po_number: list.order_number,
  //           raw_supplier: list.supplier ? list.supplier.name : null,
  //           raw_customer: list.farm_code ? list.farm_code.name : null,
  //         });

  //         await directus.createItem("invoices_directus_files", {
  //           invoices_id: result.data.id,
  //           directus_files_id: updateData.data.id,
  //           status: "published"
  //         })

  //         var message = 'A new invoice has been created.';
  //         enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200 });

  //         navigate(`/invoice/${result.data.id}`)
  //         EventEmitter.emit('hideFilter', { value: true })
  //         EventEmitter.emit('reload_item', { value: `/invoice/${result.data.id}` })
  //       }
  //       )
  //   } catch (e) {
  //     enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
  //   }
  //   setLoadingExport(false);
  // }

  const getMatchInvoiceIconColor = (invoice_values) => {
    var color = '#13E000';
    if (invoice_values.length > 0) {
      var invoice_status_list = [];
      invoice_values.map((invoice_value) => {
        if (invoice_value.invoices_id) {
          if (invoice_value.invoices_id.invoice_status) {
            invoice_status_list.push(invoice_value.invoices_id.invoice_status);
          }
        }
      })

      if (invoice_status_list.length > 0) {
        if (invoice_status_list.includes("unmatched")) color = '#FFB251';
        else if (invoice_status_list.includes("disputed")) color = '#FF453B';
        else if (invoice_status_list.includes("matched")) color = '#188BFF';
        else if (invoice_status_list.includes("awaiting_approval_level_2")) color = '#188BFF';
        else if (invoice_status_list.includes("approved")) color = '#13E000';
      }

    }
    return color;
  }

  const saveNotes = async (id) => {
    try {
      if (!loadingDuplicate) {
        setLoadingDuplicate(true);
        await directus.updateItem(
          'orders',
          id,
          {
            additional_notes: orderNoteRef.current
          }
        );
        loadOrders();
        setLoadingDuplicate(false);

        var message = 'Notes have been saved';
        enqueueSnackbar(message, {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'success',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
      setLoadingDuplicate(false);
    }
  }

  const handleChangeNotes = e => {
    const { name, value } = e.target;

    if (name === 'note') {
      setOrderNote(value);
    }
  };

  var matched_invoice = false;

  if (data.invoices && showInvoiceTab) {
    if (data.invoices.length > 0) {
      matched_invoice = true;
    }
  }

  const getInvoicedQty = (data_item) => {
    var invoiced_qty = 0;

    if (data_item) {
      if (data_item.order_item.invoice_order_item) {
        if (data_item.order_item.invoice_order_item.length > 0) {
          data_item.order_item.invoice_order_item.map((data_value) => {
            if (data_value.quantity && data_value.status === "published") {
              invoiced_qty += data_value.quantity;
            }
          })
        }
      }
    }

    return invoiced_qty ? invoiced_qty : "0";
  }

  return (
    <div>
      <Paper elevation={0} className={classes.sentOrders}>
        <Grid container spacing={0} justifyContent="space-around" className={classes.orderlist}>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <Typography noWrap id="order_number" style={{ fontWeight: 'bold' }}>
              {list.order_number}
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <Typography noWrap id="order_date">
              {data.order_date ? moment(data.order_date).format('DD/MM/YYYY') : ''}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <Typography id="order_supplier" style={{ paddingRight: '5px' }}>
              {list.supplier ? list.supplier.name : null}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <Typography id="order_customer" style={{ paddingRight: '5px' }}>{list.farm_code ? list.farm_code.name : null}
              {list.farm_code ? list.farm_code.legal_name ?
                <CustomTooltip title={<Typography variant="h6"> {list.farm_code.legal_name}</Typography>}>
                  <InfoIcon
                    fontSize="small"
                    style={{ cursor: 'pointer', height: '13px', marginRight: "10px", marginTop: "3px" }}
                  />
                </CustomTooltip>
                : '' : ''}
            </Typography>
            {list.farm_code ? list.farm_code.legal_name ?
              <Typography id="order_customer" style={{ paddingRight: "5px", marginTop: "20px" }}>
                <b>Invoice To:</b> {list.farm_code.legal_name}
              </Typography>
              : '' : ''
            }
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <Paper elevation={0} style={status} className={classes.statusarea2}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '80%' }}>
                  <Typography id="order_status">{listRef.current.status}</Typography>
                </div>
                <div style={{ width: '20%' }}>
                  {data.invoices && showInvoiceTab ? (
                    data.invoices.length > 0 ? (
                      <CheckCircleRoundedIcon style={{
                        color: getMatchInvoiceIconColor(data.invoices),
                        fontSize: '20px', cursor: 'pointer'
                      }} />
                    ) : null
                  ) : null}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} style={{ display: 'inline-flex' }}>
            <Paper elevation={0} style={status} className={classes.amountarea2}>
              <Paper elevation={0} className={classes.amountdetail}>
                <Typography noWrap id="order_amount" style={{ color: '#333', fontWeight: '600' }}>
                  {isNaN(totalOrderItem) ? 0 : '$' + formatNumber(totalOrderItem.toFixed(2))}
                </Typography>
              </Paper>
            </Paper>
            <IconButton
              id={`order_close_edit_btn`}
              size="medium"
              className={classes.KeyboardArrowDownIcon}
              onClick={() => props.editMode(props.id, false)}
            >
              <KeyboardArrowDownIcon fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container spacing={0} justifyContent="space-between" style={{ backgroundColor: '#8AA9CA' }} className={classes.categorydetail}>
          <Grid className={classes.internalcategory} style={{ width: '16%' }}>
            <Typography>INTERNAL</Typography>
          </Grid>
          <Grid className={classes.internalcategory} style={{ width: '12%' }}>
            <Typography>Category</Typography>
          </Grid>
          <Grid item style={{ width: '30%' }}>
            <Paper elevation={0} className={classes.categorycolumn}>
              <Typography id="order_category" style={{ paddingLeft: '6px' }}>
                {list.category_type ? (list.category_type.name ? list.category_type.name : list.category_type) : list.category_type}
              </Typography>
            </Paper>
          </Grid>
          <Grid className={classes.internalcategory} style={{ width: '12%' }}>
            <Typography>Details</Typography>
          </Grid>
          <Grid item style={{ width: '30%' }}>
            <Paper elevation={0} className={classes.categorycolumn}>
              <Typography id="order_category_description" style={{ paddingLeft: '6px' }}>
                {list.category_description
                  ? list.category_description.name
                    ? list.category_description.name
                    : list.category_description
                  : list.category_description}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {rowLen !== 0 ? (
          <Grid container spacing={0} justifyContent="space-between" style={{ backgroundColor: '#D4E3F2' }} className={classes.orderitemdetail}>
            <Grid item className={classes.orderitem_header} style={{ width: '8%' }}>
              <Typography className={classes.orderitem_header_left} style={{ marginLeft: '18px' }}>
                Qty
              </Typography>
            </Grid>
            <Grid item className={classes.orderitem_header} style={{ width: '12%' }}>
              <Typography className={classes.orderitem_header_left} style={{ marginLeft: '12px' }}>
                Supplier Code
              </Typography>
            </Grid>
            <Grid item className={classes.orderitem_header} style={{ width: matched_invoice ? "24%" : "30%" }}>
              <Typography className={classes.orderitem_header_left} style={{ marginLeft: '12px' }}>
                Description
              </Typography>
            </Grid>
            <Grid item className={classes.orderitem_header} style={{ width: '12%' }}>
              <Typography className={classes.orderitem_header_left} style={{ marginRight: '18px', textAlign: 'right' }}>
                Unit Price
              </Typography>
            </Grid>
            <Grid item className={classes.itemcategory_header}>
              <Typography className={classes.orderitem_header_right}>Item Category</Typography>
            </Grid>
            <Grid item className={classes.itemdetails_header}>
              <Typography className={classes.orderitem_header_right}>Item Details</Typography>
            </Grid>
            {matched_invoice ? (
              <Grid item className={classes.orderitem_header} style={{ width: "6%" }}>
                <Typography className={classes.orderitem_header_left} style={{ marginLeft: "18px", textAlign: "left" }}>
                  Invoiced Qty
                </Typography>
              </Grid>
            ) : null}
            <Grid item className={classes.itemclose}></Grid>
          </Grid>
        ) : (
          ''
        )}

        {items ? (
          items.map((dataItem, index) => {
            return (
              <div key={index}>
                <Grid
                  container
                  spacing={0}
                  justifyContent="space-between"
                  className={index + 1 !== rowLen ? classes.itemarea : classes.itemarealast}
                  style={{ backgroundColor: '#D4E3F2', borderRadius: index === items.length - 1 ? '0px 0px 8px 8px' : '0px' }}
                >
                  <Grid item style={{ width: '8%', paddingLeft: '6px' }}>
                    <Paper elevation={0} className={classes.itemcolumn2}>
                      <Typography id={`order_item_quantity_${index}`} style={{ marginLeft: '2px' }}>
                        {dataItem.order_item.quantity}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item style={{ width: '12%', paddingLeft: '6px' }}>
                    <Paper elevation={0} className={classes.itemcolumn2}>
                      <Typography id={`order_item_supplier_code_${index}`} style={{ marginLeft: '2px' }}>
                        {dataItem.order_item.supplier_code}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item style={{ width: matched_invoice ? "24%" : "30%" }}>
                    <Paper elevation={0} className={classes.itemcolumn2} style={{ paddingBottom: '4px' }}>
                      <Typography id={`order_item_description_${index}`} style={{ marginLeft: '2px' }}>
                        {dataItem.order_item.description}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item style={{ width: '12%' }}>
                    <Paper elevation={0} className={classes.itemcolumn2}>
                      <Typography id={`order_item_unit_price_${index}`} style={{ textAlign: 'right', marginRight: '2px' }}>
                        {/* {isNaN(dataItem.order_item.unit_price) ? 0 : "$" + formatNumber(dataItem.order_item.unit_price.toFixed(2))} */}
                        {isNaN(dataItem.order_item.unit_price) ? 0 : '$' + parseFloat(dataItem.order_item.unit_price).toFixed(2)}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item style={{ width: '17%' }}>
                    <div
                      className={classes.itemcategory_content}
                      style={{ height: '100%', borderBottomLeftRadius: index === items.length - 1 ? '6px' : '0px' }}
                    >
                      <Paper elevation={0} className={classes.itemcolumn2} style={{ marginRight: '0px' }}>
                        <Typography id={`order_item_category_${index}`} style={{ paddingBottom: '4px', marginLeft: '2px' }}>
                          {dataItem.order_item.category_type ? dataItem.order_item.category_type.name : dataItem.order_item.category_type}
                        </Typography>
                      </Paper>
                    </div>
                  </Grid>
                  <Grid item className={classes.itemdetails}>
                    <div
                      className={classes.itemdetails_content}
                      style={{ height: '100%', borderBottomRightRadius: index === items.length - 1 ? '6px' : '0px' }}
                    >
                      <Paper elevation={0} className={classes.itemcolumn2} style={{ marginRight: '0px' }}>
                        <Typography id={`order_item_category_desc_${index}`} style={{ paddingBottom: '4px', marginLeft: '2px' }}>
                          {dataItem.order_item.category_description
                            ? dataItem.order_item.category_description.name
                              ? dataItem.order_item.category_description.name
                              : dataItem.order_item.category_description
                            : dataItem.order_item.category_description}
                        </Typography>
                      </Paper>
                    </div>
                  </Grid>

                  {matched_invoice ? (
                    <Grid item className={classes.orderitem_header} style={{ width: "6%", paddingTop: "0px", paddingBottom: "0px" }}>
                      <Paper elevation={0} className={classes.itemcolumn2} style={{ marginLeft: "6px", marginRight: "0px" }}>
                        <Typography style={{ marginLeft: "2px" }}>{getInvoicedQty(dataItem)}</Typography>
                      </Paper>
                    </Grid>
                  ) : null}

                  <Grid item className={classes.itemclose}></Grid>
                </Grid>
              </div>
            );
          })
        ) : (
          <span>
            <CircularProgress size={20} />
          </span>
        )}

        <Grid container spacing={1} justifyContent="space-between" style={{ paddingTop: '6px' }}>
          <Grid item xs={2} md={2} lg={2}>
            <div style={{ display: 'flex', border: '1px solid rgba(142,142,147,0.3)', borderRadius: '10px', height: '35px' }}>
              <div
                style={{
                  marginRight: '10px',
                  fontFamily: 'Manrope',
                  lineHeight: '35px',
                  marginLeft: '10.82px',
                  fontSize: '14px',
                  overflow: 'hidden'
                }}
              >
                Delivery by: {list.due_date ? moment(new Date(list.due_date)).format('DD/MM/YYYY') : ''}
              </div>
            </div>
          </Grid>
          <Grid item xs={3} md={3} lg={3}>
            <div style={{ display: "flex", flexFlow: "column" }}>
              <TextareaAutosize
                id="order_notes"
                minRows={2}
                maxRows={2}
                variant="outlined"
                name="note"
                placeholder="Notes: Jot an internal note here..."
                onChange={handleChangeNotes}
                value={orderNoteRef.current ? orderNoteRef.current : ''}
                style={{
                  maxWidth: '100%',
                  minWidth: '100%',
                  minHeight: '35px',
                  borderRadius: '8px',
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                  fontFamily: 'Manrope',
                  padding: '7px 14px',
                  height: '32px',
                  fontSize: '14px'
                }}
              />
              <div style={{ textAlign: "right" }}>
                <Button
                  id="submit_btn"
                  variant="contained"
                  className={classes.buttonadditem2}
                  onClick={() => saveNotes(list.id)}
                  disabled={loadingFile || loadingDuplicate}
                  style={{ maxWidth: "70px", marginTop: "6px", fontSize: "12px", height: "30px", marginBottom: "10px" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                  <DescriptionIcon style={{ fontSize: "13px", marginRight: "3px", marginTop: "0px" }} /> <div style={{ height: "13px", lineHeight: "15px" }}>Save</div>
                  </div>
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={3} md={3} lg={3}>
            <TextareaAutosize
              minRows={2}
              maxRows={2}
              variant="outlined"
              name="delivery_instructions"
              placeholder="Delivery Instructions: Jot something done"
              onChange={handleChange}
              value={list.delivery_instructions ? list.delivery_instructions : ''}
              disabled
              style={{
                maxWidth: '100%',
                minWidth: '100%',
                maxHeight: '54%',
                minHeight: '35px',
                borderRadius: '8px',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                fontFamily: 'Manrope',
                padding: '7px 14px',
                height: '32px',
                fontSize: '14px'
              }}
            />
          </Grid>
          <Grid item xs={5} md={4} lg={4}>
            <div
              style={{
                textAlign: 'end',
                justifyContent: 'flex-end',
                display: 'flex',
                flexWrap: 'wrap',
                rowGap: '5px',
                maxWidth: 'fit-content',
                marginLeft: 'auto',
                marginBottom: '5px'
              }}
            >
              {loadingFile || loadingDuplicate || loadingResendSupplier || loadingStatusCompleted || loadingExport ? (
                <span display="inline-flex" style={{ marginTop: '5px' }}>
                  <CircularProgress size={20} />
                </span>
              ) : (
                <>
                  <form id="my-form" className={classes.form}>
                    <input type="file" name="file" onChange={e => handleSubmission(e, list)} id="contained-button-file" className={classes.input} />
                    <label htmlFor="contained-button-file">
                      <Button id="attach_file_btn" name="file" variant="contained" className={classes.buttonadditem2} component="span">
                        ATTACH FILE
                      </Button>
                    </label>
                  </form>

                  {/* <Button
                    id="submit_btn"
                    variant="contained"
                    className={classes.buttonadditem2}
                    onClick={() => saveNotes(list.id)}
                    disabled={loadingFile || loadingDuplicate}
                  >
                    Save Notes
                  </Button> */}

                  <Button
                    id="submit_btn"
                    variant="contained"
                    className={classes.buttonadditem2}
                    onClick={() => resendToSuplier(list.id)}
                    disabled={loadingFile || loadingDuplicate}
                  >
                    RESEND TO SUPPLIER
                  </Button>

                  <Button
                    id="demo-customized-button"
                    className={classes.buttonadditem2}
                    aria-controls={openSettings ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openSettings ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={clickSettings}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    Options
                  </Button>
                </>
              )}

              <Menu
                anchorEl={anchorElSettings}
                id="account-menu"
                open={openSettings}
                onClose={handleCloseSettings}
                onClick={handleCloseSettings}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0
                    }
                  }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {POCreatorPermission() ? (
                  <MenuItem onClick={handleClickOpenDialog}>
                    <div style={{ display: 'flex' }}>
                      <div style={{ minWidth: '25px', maxWidth: '25px' }}>
                        <PublishedWithChangesIcon style={{ fontSize: '17px' }} />
                      </div>
                      Update Status
                    </div>
                  </MenuItem>
                ) : (
                  ''
                )}

                {POCreatorPermission() ? <Divider /> : ''}

                {/* <MenuItem onClick={() => resendToSuplier(list.id)}>
                  <div style={{ display: "flex" }}>
                    <div style={{ minWidth: "25px", maxWidth: "25px" }}>
                      <SendIcon style={{ fontSize: "17px" }} />
                    </div>
                    Resend to Supplier
                  </div>
                </MenuItem>

                <Divider /> */}
                <MenuItem onClick={e => duplicateData(list)} style={{ minWidth: '150px' }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ minWidth: '25px', maxWidth: '25px' }}>
                      <ContentCopyIcon style={{ fontSize: '18px' }} />
                    </div>
                    Duplicate
                  </div>
                </MenuItem>
                <MenuItem onClick={e => exportPO(list)}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ minWidth: '25px', maxWidth: '25px' }}>
                      <FileUploadOutlinedIcon style={{ fontSize: '18px' }} />
                    </div>
                    Export PO
                  </div>
                </MenuItem>
                {/* {InvoiceAdminPermission() ?
                  <MenuItem onClick={(e) => generateInvoice(list)}>
                    <div style={{ display: "flex" }}>
                      <div style={{ minWidth: "25px", maxWidth: "25px" }}>
                        <PostAddOutlinedIcon style={{ fontSize: "18px" }} />
                      </div>
                      Generate a new invoice
                    </div>
                  </MenuItem>
                  : ''} */}
              </Menu>

              {loadingStatusCompleted === false ? (
                <Dialog open={open}>
                  <DialogTitle>Update {list.order_number} Status</DialogTitle>
                  <DialogActions>
                    <CancelButton size="small" variant="contained" onClick={handleCloseDialog}>
                      <Typography variant="h6">Back</Typography>
                    </CancelButton>

                    {matchedInvoiceDataRef.current.length === 0 && updateStatusToDraft ? (
                      <DraftButton variant="contained" onClick={() => updateStatus(list.id, 'draft')}>
                        <Typography variant="h6">Draft</Typography>
                      </DraftButton>
                    ) : null}
                    {POCreatorPermission() && matchedInvoiceDataRef.current.length === 0 && list.invoices ? (
                      list.invoices.length === 0 ? (
                        <CancelledButton variant="contained" onClick={() => updateStatus(list.id, 'cancelled')}>
                          <Typography variant="h6">Cancelled</Typography>
                        </CancelledButton>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}

                    {POCreatorPermission() ? (
                      <CompletedButton variant="contained" onClick={() => updateStatus(list.id, 'completed')}>
                        <Typography variant="h6">Completed</Typography>
                      </CompletedButton>
                    ) : POCreatorPermission() && matchedInvoiceDataRef.current.length === 0 && list.invoices ? (
                      list.invoices.length === 0 ? (
                        <CompletedButton variant="contained" onClick={() => updateStatus(list.id, 'completed')}>
                          <Typography variant="h6">Completed</Typography>
                        </CompletedButton>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                  </DialogActions>
                </Dialog>
              ) : (
                ''
              )}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', paddingTop: '5px' }}>
          <Grid container style={{ flex: '60%' }}>
            {data.files.map((file, index) => {
              if (file.directus_file_id) {
                return (
                  <Grid key={index} span="true" style={{ display: 'flex' }}>
                    {POApproverPermission() ? (
                      <div style={{ marginTop: '7px', marginLeft: '5px', cursor: 'pointer' }}>
                        <CustomTooltip arrow title={<Typography variant="h6">Generate Invoice</Typography>}>
                          <ContentCopyIcon className={classes.generate_invoice_button} onClick={() => handleOpenGenerateInvoiceDialog(file)} />
                        </CustomTooltip>
                      </div>
                    ) : (
                      ''
                    )}
                    <Chip
                      icon={<InsertDriveFileIcon />}
                      label={file.directus_file_id.filename_download}
                      onClick={id => handleClick(file.directus_file_id.data.full_url)}
                      onDelete={() => handleDelete(file.id)}
                      variant="outlined"
                      style={{ marginRight: '5px' }}
                    />
                  </Grid>
                );
              } else {
                return null;
              }
            })}
          </Grid>
          <Grid container justifyContent="flex-end" style={{ flex: '40%' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: 'calc(100% - 200px)', paddingRight: '5px' }}>
                <Typography id="order_modified" style={{ fontSize: '13px', Color: 'grey' }}>
                  Last modified: {moment(data.modified_on).format('DD/MM/YYYY')}{' '}
                  {data.modified_by ? (
                    <span>
                      by {data.modified_by.first_name} {data.modified_by.last_name}
                    </span>
                  ) : (
                    ''
                  )}
                </Typography>
              </div>
              <div style={{ width: '280px', height: '30px' }}>
                <Button
                  id="show_change_log_btn"
                  variant="contained"
                  className={classes.buttonChangeLog}
                  onClick={() => handleClickShowChangeLog(list.id)}
                >
                  {!showChangeLog ? 'SHOW' : 'HIDE'} Change Log
                </Button>
              </div>
              <div style={{ width: '290px', height: '30px' }}>
                <Button
                  id="show_change_log_btn"
                  variant="contained"
                  className={classes.buttonChangeLog}
                  onClick={() => handleClickExportActionLog(list.id)}
                >
                  Export Change Log
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>

        {list.notes !== "" ? (
          <Grid container spacing={0} justifyContent="space-between" style={status} className={classes.firstnote}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              Previous Note:
              <p>{list.notes}</p>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        {matchedInvoiceDataRef.current ? (
          matchedInvoiceDataRef.current.length > 0 ? (
            <Grid
              container
              spacing={0}
              justifyContent="space-around"
              style={{ paddingTop: 'inherit', background: 'white', marginTop: '10px', borderRadius: '4px' }}
            >
              <Grid item xs={12} style={{ marginTop: '8px' }}>
                <Typography style={{ padding: '10px 16px 10px 16px', fontSize: '16px' }}>
                  <b>Matched Invoices</b>
                </Typography>
              </Grid>
              <Grid
                container
                style={{ background: '#daf0dc', padding: '12.58px 16px 6px 16px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
              >
                <Grid item xs={3}>
                  <Typography style={{ fontSize: '14px' }}>
                    <b>Invoice Date</b>
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontSize: '14px' }}>
                    <b>Invoice Number</b>
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontSize: '14px' }}>
                    <b>Invoice Amount</b>
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontSize: '14px' }}>
                    <b>Date Matched</b>
                  </Typography>
                </Grid>
              </Grid>
              {matchedInvoiceDataRef.current.map((value, index) => {
                return (
                  <Grid
                    container
                    key={index}
                    style={{
                      background: '#daf0dc',
                      padding: index === matchedInvoiceDataRef.current.length - 1 ? '4px 16px 14px 16px' : '4px 16px 4px 16px',
                      borderBottomLeftRadius: index === matchedInvoiceDataRef.current.length - 1 ? '8px' : '0px',
                      borderBottomRightRadius: index === matchedInvoiceDataRef.current.length - 1 ? '8px' : '0px'
                    }}
                  >
                    <Grid item xs={3} lg={3} className={classes.logdata_detail}>
                      <Typography className={classes.logdata_detail_txt}>{value.date ? moment(value.date).format('DD/MM/YYYY') : null}</Typography>
                    </Grid>
                    <Grid item xs={3} lg={3} className={classes.logdata_detail}>
                      <Typography className={classes.logdata_detail_txt}>{value.number}</Typography>
                    </Grid>
                    <Grid item xs={3} lg={3} className={classes.logdata_detail}>
                      <Typography className={classes.logdata_detail_txt}>
                        {isNaN(value.invoice_amount) ? 0 : '$' + formatNumber((Math.round(value.invoice_amount * 100) / 100).toFixed(2))}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} lg={2} className={classes.logdata_detail}>
                      <Typography className={classes.logdata_detail_txt}>
                        {value.date_matched ? moment(value.date_matched).format('DD/MM/YYYY') : null}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.logdata_detail} style={{ textAlign: 'end' }}>
                      <RouterLink to={`/invoice/${value.id}`} onClick={() => navigateToOtherInvoice(value.id)}>
                        <IconButton size="medium" className={classes.iconbuttonarea2}>
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                      </RouterLink>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          ) : null
        ) : null}

        {showChangeLog ? (
          <Grid
            container
            spacing={0}
            justifyContent="space-between"
            style={{ background: '#FFFCD2', borderRadius: '8px', padding: '12px 16px', marginTop: '5px' }}
          >
            <Grid container>
              <Grid item xs={3} lg={3} className={classes.logdata}>
                <Typography className={classes.orderitem_header_left}>Date & Time</Typography>
              </Grid>
              <Grid item xs={3} lg={3} className={classes.logdata}>
                <Typography className={classes.orderitem_header_left}>Status Updates</Typography>
              </Grid>
              <Grid item xs={6} lg={6} className={classes.logdata}>
                <Typography className={classes.orderitem_header_left}>User</Typography>
              </Grid>
            </Grid>
            {logData.length > 0
              ? logData.map((dataLog, index) => {
                return (
                  <Grid container key={index}>
                    <Grid item xs={3} lg={3} className={classes.logdata_detail}>
                      <Typography className={classes.logdata_detail_txt}>
                        {dataLog.created_on ? moment(dataLog.created_on).format('DD/MM/YYYY, hh:mm a') : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} lg={3} className={classes.logdata_detail}>
                      <Typography className={classes.logdata_detail_txt}>{orderEventType ? orderEventType[dataLog.type] : dataLog.type}</Typography>
                    </Grid>
                    <Grid item xs={6} lg={6} className={classes.logdata_detail}>
                      <Typography className={classes.logdata_detail_txt}>
                        {dataLog.owner ? dataLog.owner.first_name : ''} {dataLog.owner ? dataLog.owner.last_name : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
              : null}
          </Grid>
        ) : null}
      </Paper>

      <Dialog isopen={`${openGenerateInvoiceDialog}`} open={openGenerateInvoiceDialog} fullWidth={true} onClose={handleCloseGenerateInvoiceDialog}>
        <DialogTitle disableTypography={true} id="form-dialog-title">
          <Typography component="div" variant="h6">
            <h2>Generate Invoice</h2>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">Are you sure you want to generate a new invoice record from this attachment?</Typography>
        </DialogContent>
        <DialogActions style={{ height: '60px' }}>
          {loadingGenerateInvoice ? (
            <CircularProgress className={classes.circular} style={{ marginRight: '10px' }} />
          ) : (
            <div>
              <CancelButton id="cancel_btn" size="small" variant="contained" onClick={handleCloseGenerateInvoiceDialog}>
                Cancel
              </CancelButton>
              <DraftButton
                style={{ marginLeft: '10px', width: '125px' }}
                id="save_btn"
                size="small"
                variant="contained"
                onClick={() => generateInvoice()}
              >
                Generate
              </DraftButton>
              <CompletedButton
                style={{ marginLeft: '10px', width: '225px' }}
                id="save_btn"
                size="small"
                variant="contained"
                onClick={() => generateInvoice(true)}
              >
                Generate and Match PO
              </CompletedButton>
            </div>
          )}
        </DialogActions>
      </Dialog>
      <div style={{ height: 12 + 'px' }}></div>
    </div>
  );
}

const DataStatusSent = props => {
  return (
    <div id={`order_sent_${props.id}`} key={props.id}>
      <OrderStatusSent
        data={props.data}
        items={props.data.order_items}
        loadOrders={props.loadOrders}
        totalOrderItem={props.totalOrder}
        props={props}
        orderEventType={props.orderEventType}
        editMode={props.editMode}
        goToFirstPage={props.goToFirstPage}
        updateStatusToDraft={props.updateStatusToDraft}
        showInvoiceTab={props.showInvoiceTab}
      />
    </div>
  );
};
export default DataStatusSent;
