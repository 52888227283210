import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import './style.css';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import OrderStatusDraft from 'src/views/Order/OrderStatusDraft';
import OrderStatusSent from 'src/views/Order/OrderStatusSent';
import OrderStatusCompleted from 'src/views/Order/OrderStatusCompleted';
import OrderStatusCancelled from 'src/views/Order/OrderStatusCancelled';
import useStyles2 from 'src/views/_styles/style1';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useNavigate } from 'react-router-dom';
import useStateRef from 'react-usestateref';
import EventEmitter from 'src/utils/EventEmitter';
import linq from 'linq';
import { OverflowTip } from 'src/components/OverflowTip';

import { CustomTooltip } from '../setting/style';
import InfoIcon from '@material-ui/icons/Info';


const useStyles = makeStyles(theme => ({
  orderslist: {
    borderRadius: '8px',
    height: '48px',
    padding: '6px 6px 6px 22px'
  },
  iconbuttonarea: {
    borderRadius: '10px',
    width: '36px',
    height: '36px',
    marginLeft: '6px',
    marginTop: '-6px'
  }
}));

function Row({
  items,
  fallback,
  loadOrders,
  categoryType,
  collection,
  collectionData,
  categoryTypeLoading,
  orderEventType,
  goToFirstPage,
  updateStatusToDraft,
  employeeID,
  showInvoiceTab,
  props
}) {
  /* eslint-disable no-unused-vars*/
  const classes = useStyles();
  const classes2 = useStyles2();
  const [isEdit, setIsEdit, isEditRef] = useStateRef(false);
  const navigate = useNavigate();
  /* eslint-enable no-unused-vars*/

  React.useEffect(() => {
    const onSetIsEdit = async eventData => {
      setIsEdit(eventData.value);
    };
    const listener1 = EventEmitter.addListener('setIsEdit', onSetIsEdit);

    return () => {
      listener1.remove();
    };
  }, []);

  if (!items || items.length === 0) {
    return fallback;
  } else {
    return items.map((data, index) => {
      let status;
      let totalOrder = data.total_order;

      if (data.status === 'draft') {
        status = { backgroundColor: '#D4E3F2' };
      } else if (data.status === 'sent') {
        status = { backgroundColor: '#FFF0DD' };
      } else if (data.status === 'cancelled') {
        status = { backgroundColor: '#FFDBD9' };
      } else if (data.status === 'completed') {
        status = { backgroundColor: '#DAF0DC' };
      } else {
        status = { backgroundColor: '#FFFFFF' };
      }

      function editMode(orderID, value) {
        setIsEdit({ [orderID]: value });
      }

      const formatNumber = num => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      };

      const navigateToInvoice = value => {
        EventEmitter.emit('hideFilter', { value: true });
        EventEmitter.emit('reload_item', { value: `/invoice/${value.id}` });
        navigate(`/invoice/${value.id}`, { state: { id: '', arrow: '', prevLocation: window.location.pathname } });
      };

      if (data.order_items.length > 0) {
        var order_items = data.order_items;
        var new_order_items = [];

        order_items.map(value => {
          if (value.order_item) {
            new_order_items.push(value);
          }
        });

        new_order_items = linq
          .from(new_order_items)
          .orderBy(o => o.order_item.sort)
          .toArray();
        data.order_items = new_order_items;
      }

      const getMatchInvoiceIconColor = (invoice_values) => {
        var color = '#13E000';
        if (invoice_values.length > 0) {
          var invoice_status_list = [];
          invoice_values.map((invoice_value) => {
            if (invoice_value.invoices_id) {
              if (invoice_value.invoices_id.invoice_status) {
                invoice_status_list.push(invoice_value.invoices_id.invoice_status);
              }
            }
          })

          if (invoice_status_list.length > 0) {
            if (invoice_status_list.includes("unmatched")) color = '#FFB251';
            else if (invoice_status_list.includes("disputed")) color = '#FF453B';
            else if (invoice_status_list.includes("matched")) color = '#188BFF';
            else if (invoice_status_list.includes("awaiting_approval_level_2")) color = '#188BFF';
            else if (invoice_status_list.includes("approved")) color = '#13E000';
          }

        }
        return color;
      }

      const orderID = data.id;

      return (
        <div key={orderID} id={`order_detail_${orderID}`}>
          {isEditRef.current[orderID] ? (
            data.status === 'sent' ? (
              <OrderStatusSent
                loadOrders={loadOrders}
                data={data}
                totalOrder={totalOrder}
                id={orderID}
                editMode={editMode}
                categoryType={categoryType}
                collection={collection}
                collectionData={collectionData}
                categoryTypeLoading={categoryTypeLoading}
                orderEventType={orderEventType}
                goToFirstPage={goToFirstPage}
                updateStatusToDraft={updateStatusToDraft}
                employeeID={employeeID}
                showInvoiceTab={showInvoiceTab}
              />
            ) : data.status === 'completed' ? (
              <OrderStatusCompleted
                loadOrders={loadOrders}
                data={data}
                totalOrder={totalOrder}
                id={orderID}
                editMode={editMode}
                categoryType={categoryType}
                collection={collection}
                collectionData={collectionData}
                categoryTypeLoading={categoryTypeLoading}
                orderEventType={orderEventType}
                goToFirstPage={goToFirstPage}
                employeeID={employeeID}
                showInvoiceTab={showInvoiceTab}
              />
            ) : data.status === 'cancelled' ? (
              <OrderStatusCancelled
                loadOrders={loadOrders}
                data={data}
                totalOrder={totalOrder}
                id={orderID}
                editMode={editMode}
                categoryType={categoryType}
                collection={collection}
                collectionData={collectionData}
                categoryTypeLoading={categoryTypeLoading}
                orderEventType={orderEventType}
                goToFirstPage={goToFirstPage}
                employeeID={employeeID}
                showInvoiceTab={showInvoiceTab}
              />
            ) : window.location.pathname === '/orders' || window.location.pathname === '/' ? (
              data.status === 'draft' ? (
                <OrderStatusDraft
                  loadOrders={loadOrders}
                  data={data}
                  totalOrder={totalOrder}
                  id={orderID}
                  editMode={editMode}
                  setIsEdit={setIsEdit}
                  categoryType={categoryType}
                  collection={collection}
                  collectionData={collectionData}
                  categoryTypeLoading={categoryTypeLoading}
                  orderEventType={orderEventType}
                  goToFirstPage={goToFirstPage}
                  employeeID={employeeID}
                  showInvoiceTab={showInvoiceTab}
                />
              ) : (
                ''
              )
            ) : (
              ''
            )
          ) : (
            <div onClick={() => editMode(orderID, true)} style={{ cursor: 'pointer' }}>
              <Paper elevation={0} className={classes.orderslist}>
                <Grid container spacing={0} justifyContent="space-around" style={{ paddingTop: 'inherit' }}>
                  <Grid item xs={1} sm={1} md={1} lg={1}>
                    <Typography
                      id={`order_number_${index}`}
                      style={{ fontWeight: 'bold', overflowX: 'hidden', textOverflow: 'ellipsis', paddingTop: '2px' }}
                      component="div"
                    >
                      {/* {data.status === 'draft' ? 'Draft' : data.order_number} */}
                      <OverflowTip>{data.order_number}</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1}>
                    <Typography
                      id={`order_date_${index}`}
                      style={{ paddingTop: '2px', overflowX: 'hidden', textOverflow: 'ellipsis' }}
                      component="div"
                    >
                      <OverflowTip>{data.order_date ? moment(data.order_date).format('DD/MM/YYYY') : ''}</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Typography id={`order_supplier_${index}`} noWrap style={{ paddingRight: '20px', paddingTop: '2px' }} component="div">
                      <OverflowTip>{data.supplier !== null ? data.supplier.name : ''}</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <div style={{ display: "flex" }}>
                      <Typography id={`order_customer_${index}`} noWrap style={{ paddingRight: '20px', paddingTop: '2px' }} component="div">
                        <OverflowTip>{data.farm_code !== null ? data.farm_code.name : ''}</OverflowTip>
                      </Typography>

                      {data.farm_code ? data.farm_code.legal_name ?
                        <CustomTooltip title={<Typography variant="h6"> {data.farm_code.legal_name}</Typography>}>
                          <InfoIcon
                            fontSize="small"
                            style={{ cursor: 'pointer', height: '13px', marginRight: "20px", marginTop: "5px", marginLeft: "-20px" }}
                          />
                        </CustomTooltip>
                        : '' : ''}
                    </div>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Paper id={`order_status_${index}`} elevation={0} style={status} className={classes2.statusarea2}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: '80%' }}>
                          <Typography component="div">
                            <OverflowTip>
                              <span style={{ textTransform: 'capitalize' }}>{data.status}</span>
                            </OverflowTip>
                          </Typography>
                        </div>
                        <div style={{ width: '20%' }}>
                          {data.invoices ? (
                            data.invoices.length > 0 ? (
                              <CheckCircleRoundedIcon style={{
                                color: getMatchInvoiceIconColor(data.invoices),
                                fontSize: '20px', cursor: 'pointer'
                              }} />
                            ) : null
                          ) : null}
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} style={{ display: 'inline-flex' }}>
                    <Paper elevation={0} style={status} className={classes2.amountarea2}>
                      <Paper elevation={0} className={classes2.amountdetail}>
                        <Typography noWrap id={`order_amount_${index}`} style={{ color: '#333', fontWeight: '600' }} component="div">
                          <OverflowTip>{isNaN(totalOrder) ? 0 : '$' + formatNumber(totalOrder.toFixed(2))}</OverflowTip>
                        </Typography>
                      </Paper>
                    </Paper>
                    <IconButton id={`order_edit_btn_${index}`} size="medium" className={classes.iconbuttonarea}>
                      <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
              <div style={{ height: 10 + 'px' }}></div>
            </div>
          )}
        </div>
      );
    });
  }
}

const OrderDetail = props => {
  return (
    <div>
      <Row
        loadOrders={props.loadOrders}
        items={props.orderData}
        fallback={''}
        categoryType={props.categoryType}
        collection={props.collection}
        collectionData={props.collectionData}
        categoryTypeLoading={props.categoryTypeLoading}
        orderEventType={props.orderEventType}
        goToFirstPage={props.goToFirstPage}
        updateStatusToDraft={props.updateStatusToDraft}
        employeeID={props.employeeID}
        showInvoiceTab={props.showInvoiceTab}
      />
    </div>
  );
};

export default OrderDetail;
