import React, { useState, useEffect } from 'react';
import {
  withStyles,
  CircularProgress,
  LinearProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Typography,
  Grid,
  Checkbox,
  Menu,
  MenuItem,
  Switch,
  Chip,
  InputAdornment
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  CustomTooltip
} from './style.js';
import EditIcon from '@material-ui/icons/Edit';
import directus from '../../services/directus';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
// import EventEmitter from 'src/utils/EventEmitter';
import { useStylesEmployees, AddButton } from './style.js';
import useStateRef from "react-usestateref";
import moment from 'moment-timezone';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { pageOptions } from 'src/_utils/pageOptions';
import { useSnackbar } from 'notistack';
import ErrorMessage from 'src/views/Components/ErrorMessage';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import linq from "linq";

import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { removeCache } from "../../utils/KeyValueCache";
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const CustomAccordion = withStyles({
  root: {
    borderRadius: '10px',
  },

})(Accordion);


const SubmitButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
})(Button);

const CancelButton = withStyles({
  root: {
    borderRadius: '24px',
    marginRight: '4px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

let page = 1;
let integration = '';

var searchTimeout;
var react_app_url = process.env.REACT_APP_URL;
if (window.location.hostname.search('interax') !== -1) {
  react_app_url = process.env.REACT_APP_URL_INTERAX;
}

const Employees = (props) => {
  /* eslint-disable no-unused-vars*/
  const mountedRef = React.useRef(true)
  const classes = useStylesEmployees();
  const [initialized, setInitialized] = useState(false);

  const [employees, setEmployees, employeesRef] = useStateRef(null);
  const [customers, setCustomers, customersRef] = useStateRef('');
  const [userRoles, setUserRoles, userRolesRef] = useStateRef(null);
  const [userRole, setUserRole] = useState('');

  const [approversLevel1, setApproversLevel1, approversLevel1Ref] = useStateRef([]);
  const [approversLevel2, setApproversLevel2, approversLevel2Ref] = useStateRef([]);

  const [usersFilterChosen, setUsersFilterChosen, usersFilterChosenRef] = useStateRef('');
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState(null);

  const [employee, setEmployee] = useState(null);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [totalPage, setTotalPage, totalPageRef] = useStateRef("");
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(100);

  const [loadingPagination, setLoadingPagination] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filter, setFilter, filterRef] = useStateRef(3);

  const [employeeRole, setEmployeeRole, employeeRoleRef] = useStateRef('');

  const [emailNotificationStatus, setEmailNotificationStatus, emailNotificationStatusRef] = useStateRef(false);
  const [addInvoiceMobileStatus, setAddInvoiceMobileStatus, addInvoiceMobileStatusRef] = useStateRef(false);
  const [expanded, setExpanded] = React.useState(false);
  const [mode, setMode] = useState('');

  const [code, setCode, codeRef] = useStateRef('');
  const [firstName, setFirstName, firstNameRef] = useStateRef('');
  const [lastName, setLastName, lastNameRef] = useStateRef('');
  const [notSynced, setNotSynced, notSyncedRef] = useStateRef(false);

  const [email, setEmail, emailRef] = useStateRef('');
  const [contactEmail, setContactEmail, contactEmailRef] = useStateRef('');
  const [employmentType, setEmploymentType] = React.useState('');
  const [userLoading, setUserLoading] = React.useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const [mobileNumber, setMobileNumber, mobileNumberRef] = useStateRef('');
  const [userID, setUserID] = React.useState('');
  const [employeeData, setEmployeeData] = useState(null);

  const [search, setSearch] = React.useState('');
  const [searchingLoading, setSearchingLoading] = useState(false);

  const [sortField, setSortField, sortFieldRef] = useStateRef({
    "code": { type: "desc", value: false },
    "name": { type: "desc", value: false },
    "login_email": { type: "desc", value: false },
    "contact_email": { type: "desc", value: false },
    "phone": { type: "desc", value: false }
  });

  const [sortLoading, setSortLoading, sortLoadingRef] = useStateRef(false);

  const [anchorElRole, setAnchorElRole] = React.useState(null);
  const [filterRole, setFilterRole, filterRoleRef] = useStateRef(5);

  const [loadingExport, setLoadingExport] = React.useState(false);

  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadUserRoles();
      loadFirst();
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadUserRoles = async () => {
    try {
      var allUserRoles = await directus.api.get('/custom/settings/user_roles');
      setUserRoles(allUserRoles);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
  };

  const loadFirst = () => {
    var sort_data = localStorage.sort_data;
    if (sort_data) {
      sort_data = JSON.parse(sort_data);
      if (sort_data.employees) {
        setSortField(sort_data.employees)
      }
    }
    loadEmployees();
  }

  /* eslint-disable array-callback-return*/
  const loadEmployees = async (data) => {
    var code_sort = sortFieldRef.current.code;
    var name_sort = sortFieldRef.current.name;
    var login_email_sort = sortFieldRef.current.login_email;
    var contact_email_sort = sortFieldRef.current.contact_email;
    var phone_sort = sortFieldRef.current.phone;

    var orderByFieldCustom = 'employees.code';

    if (code_sort.value) {
      if (code_sort.type === "asc") { orderByFieldCustom = "employees.code"; }
      else if (code_sort.type === "desc") { orderByFieldCustom = "-employees.code"; }
    }

    if (name_sort.value) {
      if (name_sort.type === "asc") { orderByFieldCustom = "directus_users.first_name"; }
      else if (name_sort.type === "desc") { orderByFieldCustom = "-directus_users.first_name"; }
    }

    if (login_email_sort.value) {
      if (login_email_sort.type === "asc") { orderByFieldCustom = "directus_users.email"; }
      else if (login_email_sort.type === "desc") { orderByFieldCustom = "-directus_users.email"; }
    }

    if (contact_email_sort.value) {
      if (contact_email_sort.type === "asc") { orderByFieldCustom = "employees.email"; }
      else if (contact_email_sort.type === "desc") { orderByFieldCustom = "-employees.email"; }
    }

    if (phone_sort.value) {
      if (phone_sort.type === "asc") { orderByFieldCustom = "employees.mobile_number"; }
      else if (phone_sort.type === "desc") { orderByFieldCustom = "-employees.mobile_number"; }
    }

    var EmployeesResults = null;
    // ============== Search ==============//
    let filterData = {};
    filterData['type'] = { "neq": null }

    if (filterRef.current === 1) {
      filterData['type'] = "Full-Time";
    }
    else if (filterRef.current === 2) {
      filterData['type'] = "Casual";
    }

    if (filterRoleRef.current === 1) {
      filterData['user_role'] = "Super Admin";
    }
    else if (filterRoleRef.current === 2) {
      filterData['user_role'] = "Invoice Admin";
    }
    else if (filterRoleRef.current === 3) {
      filterData['user_role'] = "PO Approver";
    }
    else if (filterRoleRef.current === 4) {
      filterData['user_role'] = "PO Creator";
    }


    if (search) {
      EmployeesResults = await directus.api.get('/custom/settings/employees', {
        fields: "approvers_level1_customer.customer.id,approvers_level1_customer.customer.code,approvers_level1_customer.customer.name,approvers_level1_customer.*,approvers_level2_customer.customer.id,approvers_level2_customer.customer.code,approvers_level2_customer.customer.name,approvers_level2_customer.*,user.*,user.role.id,user.role.name,*",
        filter: filterData,
        q: search,
        meta: "filter_count",
        limit: pageNumberRef.current,
        page: page,
        sort: orderByFieldCustom
      });
    } else {
      EmployeesResults = await directus.api.get('/custom/settings/employees', {
        fields: "approvers_level1_customer.customer.id,approvers_level1_customer.customer.code,approvers_level1_customer.customer.name,approvers_level1_customer.*,approvers_level2_customer.customer.id,approvers_level2_customer.customer.code,approvers_level2_customer.customer.name,approvers_level2_customer.*,user.*,user.role.id,user.role.name,*",
        filter: filterData,
        q: search,
        meta: "filter_count",
        limit: pageNumberRef.current,
        page: page,
        sort: orderByFieldCustom
      });

    }


    EmployeesResults.data.map((employeeResult, x) => {
      employeeResult.approvers_level1_customer.map((approversLevel1Result, y) => {
        if (approversLevel1Result.customer === null) {
          EmployeesResults.data[x].approvers_level1_customer.splice(y, 1);
        }
      })

      employeeResult.approvers_level2_customer.map((approversLevel2Result, y) => {
        if (approversLevel2Result.customer === null) {
          EmployeesResults.data[x].approvers_level2_customer.splice(y, 1);
        }
      })
    })

    setEmployees(EmployeesResults.data);
    setTotalPage(EmployeesResults.meta.filter_count);

    let SortOrder;
    let sort_type = '';
    let sort_by_data = '';
    let changeUpperCase = false;

    if (code_sort.value) {
      sort_by_data = 'code';
      sort_type = code_sort.type;
      changeUpperCase = true;
    } else if (name_sort.value) {
      sort_by_data = 'user_name';
      sort_type = name_sort.type;
      changeUpperCase = true;
    } else if (login_email_sort.value) {
      sort_by_data = 'user_email';
      sort_type = login_email_sort.type;
      changeUpperCase = true;
    } else if (contact_email_sort.value) {
      sort_by_data = 'email';
      sort_type = contact_email_sort.type;
      changeUpperCase = true;
    } else if (phone_sort.value) {
      sort_by_data = 'mobile_number';
      sort_type = phone_sort.type;
    } else {
      sort_by_data = 'code';
      sort_type = 'asc';
      changeUpperCase = true;
    }

    SortOrder = EmployeesResults.data;
    SortOrder.map((value, index) => {
      var user_email = "";
      var user_name = "";
      if (value.user !== null) {
        if (value.user.email) {
          user_email = value.user.email;
        }

        user_name = value.user.first_name + " " + value.user.last_name;
      }
      SortOrder[index].user_email = user_email;
      SortOrder[index].user_name = user_name;
    })

    SortOrder = linq.from(SortOrder)
      .orderBy(o => o.code)
      .toArray();

    if (sort_by_data) {
      if (sort_type === "desc") {
        SortOrder.sort(function (a, b) {
          let nameA = a[sort_by_data] ? a[sort_by_data] : '';
          let nameB = b[sort_by_data] ? b[sort_by_data] : '';
          if (changeUpperCase) {
            nameA = nameA ? nameA.toUpperCase() : nameA; // ignore upper and lowercase
            nameB = nameB ? nameB.toUpperCase() : nameB; // ignore upper and lowercase
          }

          if (nameA > nameB) { return -1; }
          if (nameA < nameB) { return 1; }
          return 0; // names must be equal
        });
      } else {
        SortOrder.sort(function (a, b) {
          let nameA = a[sort_by_data] ? a[sort_by_data] : '';
          let nameB = b[sort_by_data] ? b[sort_by_data] : '';
          if (changeUpperCase) {
            nameA = nameA ? nameA.toUpperCase() : nameA; // ignore upper and lowercase
            nameB = nameB ? nameB.toUpperCase() : nameB; // ignore upper and lowercase
          }

          if (nameA < nameB) { return -1; }
          if (nameA > nameB) { return 1; }
          return 0; // names must be equal
        });
      }
    }

    if (SortOrder) {
      setEmployees(SortOrder);
    }

    loadCustomers();
    setLoadingPagination(false);
    setSearchingLoading(false);
    setSortLoading(false);
  };


  const loadCustomers = async () => {
    var CustomersResults = await directus.getItems('customers', {
      filter: { status: { eq: 'published' } },
      fields: '*',
      sort: 'code',
      limit: -1
    });

    setCustomers(CustomersResults.data);
  };

  const getUser = async (id) => {
    setUserLoading(true);
    var User = await directus.getUser(id);
    setUserID(id);
    setFirstName(User.data.first_name);
    setLastName(User.data.last_name);
    setEmail(User.data.email);

    setUserLoading(false);
  };

  const handleClickOpen = async (employee, mode) => {
    setOpen(true);
    if (mode === 'edit') {
      setMode('edit');
      await getUser(employee.user.id);
      setCode(employee.code);
      setEmailNotificationStatus(employee.enable_email_notifications);
      setAddInvoiceMobileStatus(employee.enable_add_invoice);
      setEmploymentType(employee.type);
      setContactEmail(employee.email);
      setNotSynced(employee.not_synced);

      if (employee.approvers_level1_customer.length > 0) {
        let result = [];
        employee.approvers_level1_customer.map(data => {
          result = [...result, data.customer];
        })

        setApproversLevel1(result);
      }
      else { setApproversLevel1([]); }

      if (employee.approvers_level2_customer.length > 0) {
        let result = [];
        employee.approvers_level2_customer.map(data => {
          result = [...result, data.customer];
        })

        setApproversLevel2(result);
      }
      else { setApproversLevel2([]); }

      setEmployee(employee);
      setTitle(employee.user.first_name + "'s Profile")
      setMobileNumber(employee.mobile_number);
      setUserRole(employee.user.role ? employee.user.role.id ?? '' : '');
    }
    else if (mode === 'add') {
      setMode('add');
      setCode('');
      setEmailNotificationStatus('');
      setAddInvoiceMobileStatus('');
      setCode('');
      setEmployee('');
      setTitle('Add Employee');
      setApproversLevel1([]);
      setApproversLevel2([]);
      setEmployeeData('');
      setContactEmail('');
      setUserRole(null);
    }
    else {
      setMode('delete');
      getUser(employee.user.id);
      setTitle('Delete ' + employee.user.first_name + ' ' + employee.user.last_name);
      setEmployee(employee);
      setEmployeeData(employee);
    }

  };


  const handleClose = () => {
    setOpen(false);
    setPassword(null);
    setEmployee(null);
    setFirstName('');
    setLastName('');
    setCode('');
    setEmail('');
    setContactEmail('');
    setEmploymentType(null);
    setEmailNotificationStatus('');
    setAddInvoiceMobileStatus('');
    setApproversLevel1([]);
    setApproversLevel2([]);
    setMobileNumber('');
    setUserRole(null);
  };

  const pageChange = (value) => {
    setLoadingPagination(true);
    page = page + value;
    loadEmployees();
  }

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    page = 1;
    loadEmployees();
  };

  const adminHandleChange = (event) => {
    setEmployeeRole(event.target.checked);
    loadEmployees();
  };

  const emailNotificationHandleChange = (event) => {
    setEmailNotificationStatus(event.target.checked);
    loadEmployees();
  };

  const enableAddInvoiceMobileHandleChange = (event) => {
    setAddInvoiceMobileStatus(event.target.checked);
  };

  const accordionHandleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function filterStatus(input) {
    setFilter(input);
    setSortLoading(true);
    loadEmployees();
    handleStatusClose();
    handleClose();
  }


  const handleStatusClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusClose = () => {
    setAnchorEl(null);
  };


  function handleFilterRole(input) {
    setFilterRole(input);
    setSortLoading(true);
    loadEmployees();
    handleCloseRole();
  }

  const handleRoleClick = (event) => {
    setAnchorElRole(event.currentTarget);
  };

  const handleCloseRole = () => {
    setAnchorElRole(null);
  }

  const handleChangeInput = (e, type) => {
    const { name, value } = e.target;
    if (name === 'password') {
      setPassword(value);
    } else if (name === "firstname") {
      setFirstName(value);
    } else if (name === "lastname") {
      setLastName(value);
    } else if (name === "code") {
      setCode(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "contact_email") {
      setContactEmail(value);
    } else if (name === "employment_type") {
      setEmploymentType(value);
    } else if (name === 'mobile_number') {
      setMobileNumber(value);
    } else if (name === "user_role") {
      setUserRole(value);
    }
  }

  const SaveEmployee = async () => {
    setLoading(true);

    var isSuccess = true;
    if (mode === 'edit') {
      //================================== Edit Users ===================================//
      if (firstNameRef.current !== '' || lastNameRef.current !== '' || emailRef.current !== '') {
        try {
          await directus.updateUser(userID, {
            first_name: firstNameRef.current,
            last_name: lastNameRef.current,
            email: emailRef.current,
            role: userRole
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }

      if (password !== null && password !== "" && password !== 'null') {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + directus.api.auth.config.token },
          body: JSON.stringify({ password: password })
        };

        fetch(`${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/auth/setpassword/` + employee.user.id, requestOptions)
          .then(response => response.text())
          .then(result => {
            // console.log(result);
          });
      }

      //================================== Edit Employee ===================================//
      var employee_data = { type: employmentType, mobile_number: mobileNumberRef.current, code: codeRef.current, email: contactEmailRef.current, enable_email_notifications: emailNotificationStatusRef.current ? true : false };
      
      var employee_data = {
        type: employmentType,
        mobile_number: mobileNumberRef.current,
        code: codeRef.current,
        email: contactEmailRef.current,
        enable_email_notifications: emailNotificationStatusRef.current ? true : false,
        enable_add_invoice: addInvoiceMobileStatusRef.current ? true : false,
      };

      if (employmentType !== '') { employee_data['type'] = employmentType; }

      try {
        await directus.updateItem('employees', employee.id, employee_data);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }

      //================================== Add Approvers Level 1 ===================================//
      let createData = [];
      approversLevel1Ref.current.map(data => {
        const filterInput = linq.from(employee.approvers_level1_customer)
          .where(x => x.customer.id === data.id).toArray();
        if (filterInput.length === 0) {
          createData = [...createData, { employee: employee.id, customer: data.id, status: 'published' }];
        }
      })

      if (createData.length > 0) {
        try {
          await directus.createItems('customers_approvers_level1', createData);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }

      // Delete Approvers Level 1 Customer ===============================================================================================================

      let deleteApproversLevel1Data = '';

      employee.approvers_level1_customer.map(customer => {
        const filterInput = linq.from(approversLevel1Ref.current)
          .where(x => x.id === customer.customer.id).toArray();
        if (filterInput.length === 0) {
          if (deleteApproversLevel1Data === '') {
            deleteApproversLevel1Data = customer.customer.id;
          }
          else {
            deleteApproversLevel1Data = customer.customer.id + ',' + deleteApproversLevel1Data;
          }
        }
      })

      if (deleteApproversLevel1Data !== '') {
        var CustomerApproversLevel1 = await directus.getItems('customers_approvers_level1', {
          fields: '*.*',
          filter: {
            customer: { in: deleteApproversLevel1Data },
            employee: { eq: employee.id }
          }
        });

        let deleteApproversLevel1Input = [];

        CustomerApproversLevel1.data.map(data => {
          deleteApproversLevel1Input = [...deleteApproversLevel1Input, data.id]
        })

        try {
          await directus.deleteItems('customers_approvers_level1', deleteApproversLevel1Input);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }


      //================================== Add Approvers Level 2 ===================================//
      createData = [];
      approversLevel2Ref.current.map(data => {
        const filterInput = linq.from(employee.approvers_level2_customer)
          .where(x => x.customer.id === data.id).toArray();
        if (filterInput.length === 0) {
          createData = [...createData, { employee: employee.id, customer: data.id, status: 'published' }];
        }
      })

      if (createData.length > 0) {
        try {
          await directus.createItems('customers_approvers_level2', createData);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }

      // Delete Approvers Level 2 Customer ===============================================================================================================

      let deleteApproversLevel2Data = '';

      employee.approvers_level2_customer.map(customer => {
        const filterInput = linq.from(approversLevel2Ref.current)
          .where(x => x.id === customer.customer.id).toArray();
        if (filterInput.length === 0) {
          if (deleteApproversLevel2Data === '') {
            deleteApproversLevel2Data = customer.customer.id;
          }
          else {
            deleteApproversLevel2Data = customer.customer.id + ',' + deleteApproversLevel2Data;
          }
        }
      })

      if (deleteApproversLevel2Data !== '') {
        var CustomerApproversLevel2 = await directus.getItems('customers_approvers_level2', {
          fields: '*.*',
          filter: {
            customer: { in: deleteApproversLevel2Data },
            employee: { eq: employee.id }
          }
        });

        let deleteApproversLevel2Input = [];

        CustomerApproversLevel2.data.map(data => {
          deleteApproversLevel2Input = [...deleteApproversLevel2Input, data.id]
        })

        try {
          await directus.deleteItems('customers_approvers_level2', deleteApproversLevel2Input);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }

      //ADD
    } else if (mode === 'add') {
      try {
        var createUser = await directus.api.get("/custom/users/create", {
          first_name: firstNameRef.current,
          last_name: lastNameRef.current,
          email: emailRef.current,
          password: password,
          role: userRole,
        });

        var user_id = null;

        //================================== Add user ===================================//

        if (!createUser) {
          try {
            var addUser = await directus.createUser({
              first_name: firstNameRef.current,
              last_name: lastNameRef.current,
              email: emailRef.current,
              password: password,
              role: userRole,
              status: "active"
            });

            user_id = addUser.data.id;
          }
          catch (e) {
            isSuccess = false;
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
          }
        } else {
          user_id = createUser.id;
        }

        if (user_id) {

          //================================== Add Employee ===================================//
          try {
            var addEmployees = await directus.createItems('employees', {
              code: code,
              type: employmentType,
              mobile_number: mobileNumber,
              user: user_id,
              email: contactEmailRef.current,
              not_synced: true,
              enable_email_notifications: emailNotificationStatusRef.current ? true : false,
              enable_add_invoice: addInvoiceMobileStatusRef.current ? true : false,
            });
          }
          catch (e) {
            isSuccess = false;
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
          }


          //================================== Add Customers Approvers Level 1 Relations ===================================//
          let createDataApproversLevel1 = [];
          if (approversLevel1Ref.current.length > 0) {
            approversLevel1Ref.current.map(data => {
              const filterInput = linq.from(employee.approvers_level1_customer)
                .where(x => x.customer.id === data.id).toArray();
              if (filterInput.length === 0) {
                createDataApproversLevel1 = [...createDataApproversLevel1, { employee: addEmployees.data.id, customer: data.id, status: 'published' }];
              }
            })

            if (createDataApproversLevel1.length > 0) {
              try {
                await directus.createItems('customers_approvers_level1', createDataApproversLevel1);
              }
              catch (e) {
                isSuccess = false;
                enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
              }
            }
          }

          //================================== Add Customers Approvers Level 2 Relations ===================================//
          let createDataApproversLevel2 = [];
          if (approversLevel2Ref.current.length > 0) {
            approversLevel2Ref.current.map(data => {
              const filterInput = linq.from(employee.approvers_level2_customer)
                .where(x => x.customer.id === data.id).toArray();
              if (filterInput.length === 0) {
                createDataApproversLevel2 = [...createDataApproversLevel2, { employee: addEmployees.data.id, customer: data.id, status: 'published' }];
              }
            })

            if (createDataApproversLevel2.length > 0) {
              try {
                await directus.createItems('customers_approvers_level2', createDataApproversLevel2);
              }
              catch (e) {
                isSuccess = false;
                enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
              }
            }
          }
        }


      } catch (e) {
        isSuccess = false;
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }




    }

    //DELETE
    else {
      //================================== Delete Approvers Level 1 ===================================//
      let deleteData = '';

      employee.approvers_level1_customer.map(customer => {
        const filterInput = linq.from(approversLevel1Ref.current)
          .where(x => x.id === customer.customer.id).toArray();
        if (filterInput.length === 0) {
          if (deleteData === '') {
            deleteData = customer.customer.id;
          }
          else {
            deleteData = customer.customer.id + ',' + deleteData;
          }
        }
      })

      if (deleteData !== '') {
        try {
          //eslint-disable-next-line no-redeclare
          var CustomerEmployeeResult = await directus.getItems('customers_approvers_level1', {
            fields: '*.*',
            filter: {
              customer: { in: deleteData },
              employee: { eq: employee.id }
            }
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }

        let deleteInput = [];

        CustomerEmployeeResult.data.map(data => {
          deleteInput = [...deleteInput, data.id];
        })
        try {
          await directus.deleteItems('customers_approvers_level1', deleteInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }

      //================================== Delete Approvers Level 2 ===================================//
      deleteData = '';

      employee.approvers_level2_customer.map(customer => {
        const filterInput = linq.from(approversLevel2Ref.current)
          .where(x => x.id === customer.customer.id).toArray();
        if (filterInput.length === 0) {
          if (deleteData === '') {
            deleteData = customer.customer.id;
          }
          else {
            deleteData = customer.customer.id + ',' + deleteData;
          }
        }
      })

      if (deleteData !== '') {
        try {
          //eslint-disable-next-line no-redeclare
          var CustomerEmployeeResult = await directus.getItems('customers_approvers_level2', {
            fields: '*.*',
            filter: {
              customer: { in: deleteData },
              employee: { eq: employee.id }
            }
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }

        let deleteInput = [];

        CustomerEmployeeResult.data.map(data => {
          deleteInput = [...deleteInput, data.id];
        })
        try {
          await directus.deleteItems('customers_approvers_level2', deleteInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }

      //================================== Delete Employee ===================================//
      try {
        await directus.deleteItem('employees', employeeData.id);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }

      //================================== Delete Users ===================================//
      let company = JSON.parse(window.localStorage.getItem('company'));
      let token = JSON.parse(window.localStorage.getItem('directus_data'));

      const requestOptions = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token.token
        },
        body: JSON.stringify({
          'status': 'deleted'
        })
      };

      fetch(`${react_app_url}${company}/users/${employee.user.id}`, requestOptions);
    }

    // Remove employees from cache
    removeCache('employees');

    if (isSuccess) {
      loadEmployees();
    }

    setLoading(false);

    if (isSuccess) {
      handleClose();
      var message = 'Data updated successfully';
      enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
  }

  const sendEmail = () => {
    setLoading(true);
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    fetch(`${react_app_url}/console/custom/passwordreset/${employee.user.email}`, requestOptions);

    setLoading(false);
    handleClose();
  }

  const diffTime = (data) => {
    var date = moment(data).format('DD/MM/YYYY h:mm:ss A');
    var m = moment.utc(date, "DD-MM-YYYY h:mm:ss A"); // parse input as UTC
    var result = m.clone().local().format("YYYY-MM-DD HH:mm:ss");

    return moment().diff(result, 'hours', true);
  }

  let disable = firstName === '' || lastName === '' || email === '';
  /* eslint-enable array-callback-return*/

  const handleChangeSearching = (e) => {
    setSearch(e.target.value);
  }

  const searchEmployee = (event) => {
    setSearchingLoading(true);
    if (event.key === "Enter") {
      loadEmployees();
    }
    else {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        page = 1;
        loadEmployees();
      }, 500)
    }
  }

  const sortData = (val) => {
    setSortLoading(true);
    var sort_field_data = {
      "code": { type: "desc", value: false },
      "name": { type: "desc", value: false },
      "login_email": { type: "desc", value: false },
      "contact_email": { type: "desc", value: false },
      "phone": { type: "desc", value: false },
    }

    var sort_data = sortFieldRef.current[val];
    if (sort_data) {
      var value = sort_data.value;
      if (sort_data.value === false) {
        value = true;
      }

      if (value === true) {

        var sort_type = "";
        if (sort_data.type === "desc") {
          sort_type = "asc";
        } else {
          sort_type = "desc";
        }

        sort_field_data[val].value = true;
        sort_field_data[val].type = sort_type;

        var sort_data = localStorage.sort_data;
        if (sort_data) {
          sort_data = JSON.parse(sort_data);
          sort_data.employees = sort_field_data;
        } else {
          sort_data = { employees: sort_field_data };
        }
        localStorage.setItem("sort_data", JSON.stringify(sort_data))
        setSortField(sort_field_data);
      }
    }

    loadEmployees();
  }

  const getPOUserRoleName = (role_value) => {
    if (role_value === "super_admin") {
      return "Super Admin";
    } else if (role_value === "invoice_admin") {
      return "Invoice Admin";
    } else if (role_value === "po_approver") {
      return "PO Approver";
    } else if (role_value === "po_creator") {
      return "PO Creator";
    } else {
      return ""
    }
  }

  const exportData = async () => {
    setLoadingExport(true);
    setSortLoading(true);
    let dataURL = '';

    let sort_type = '';
    let sort_by_data = '';

    var code_sort = sortFieldRef.current.code;
    var name_sort = sortFieldRef.current.name;
    var login_email_sort = sortFieldRef.current.login_email;
    var contact_email_sort = sortFieldRef.current.contact_email;
    var phone_sort = sortFieldRef.current.phone;

    if (code_sort.value) {
      sort_by_data = 'code';
      sort_type = code_sort.type;
    } else if (name_sort.value) {
      sort_by_data = 'user_name';
      sort_type = name_sort.type;
    } else if (login_email_sort.value) {
      sort_by_data = 'user_email';
      sort_type = login_email_sort.type;
    } else if (contact_email_sort.value) {
      sort_by_data = 'email';
      sort_type = contact_email_sort.type;
    } else if (phone_sort.value) {
      sort_by_data = 'mobile_number';
      sort_type = phone_sort.type;
    } else {
      sort_by_data = 'code';
      sort_type = 'asc';
    }

    var employment_type = "";
    var user_role = "";


    if (filterRef.current === 1) {
      employment_type = "Full-Time";
    }
    else if (filterRef.current === 2) {
      employment_type = "Casual";
    }

    if (filterRoleRef.current === 1) {
      user_role = "Super Admin";
    }
    else if (filterRoleRef.current === 2) {
      user_role = "Invoice Admin";
    }
    else if (filterRoleRef.current === 3) {
      user_role = "PO Approver";
    }
    else if (filterRoleRef.current === 4) {
      user_role = "PO Creator";
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + directus.api.auth.config.token },
      body: JSON.stringify({
        custom_sort: { sort_by_data: sort_by_data, sort_type: sort_type },
        employment_type: employment_type,
        user_role: user_role,
        search_by_text: search,
      })
    };

    dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/export_settings/employees`;
    await fetch(dataURL, requestOptions)
      .then(resp => resp.blob())
      .then(async blob => {
        var file = await blob.text();
        if (file.includes("error")) {
          alert('Connection error. Please try again.');
        } else if (file.includes("no_template")) {
          alert('No Template');
        } else {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          var file_name = 'Employees-All-Export';
          file_name += '.xlsx';
          a.download = file_name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      })
    setLoadingExport(false)
    setSortLoading(false);
  }

  return (
    <div >
      <div style={{ width: "1200px", display: "flex", alignItems: "center", height: "60px", marginTop: "10px", marginBottom: "10px" }}>
        <div style={{ padding: "0px 20px 0 22px", lineHeight: "60px", background: "white", height: "100%", borderRadius: "8px 0 0 8px" }}>
          <Typography style={{ lineHeight: "60px" }}><b>Filter:</b></Typography>
        </div>
        <div style={{ lineHeight: "60px", width: "310px", background: "white", height: "100%", paddingRight: "10px", borderRadius: "0 8px 8px 0" }}>
          <InputBase
            id="search_order"
            name="searchOrder"
            variant="outlined"
            placeholder="Search"
            size="small"
            onChange={handleChangeSearching}
            onKeyUp={(e) => searchEmployee(e)}
            className={classes.searchbox}
            value={search}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon className={classes.IconSearch} />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <div>{searchingLoading ? <CircularProgress style={{ width: "20px", height: "20px", marginTop: "4px" }} className={classes.circular2} /> : null}</div>
              </InputAdornment>
            }
          />
        </div>
        <div style={{ lineHeight: "60px", width: "150px", background: "white", height: "100%", paddingRight: "20px", paddingLeft: "10px", borderRadius: "0 8px 8px 0" }}>
          {loadingExport ?
            <span><CircularProgress style={{ marginLeft: "7px", color: "#5bfa17" }} size={20} /></span>
            :
            <IconButton className={classes.export} TouchRippleProps={{ style: { width: "115px" } }} style={{ width: "115px", borderRadius: "10px", backgroundColor: "#82cb43", height: "36px", boxShadow: "0 0 1px 0 rgb(0 0 0 / 31%), 0 2px 2px -2px rgb(0 0 0 / 25%)", padding: "7px", marginTop: "-7px", color: "white" }}
              onClick={(e) => exportData()}
            >
              <FileUploadOutlinedIcon style={{ borderRadius: "14px", marginLeft: "2px" }}
                fontSize="10px"
              />
              <span style={{ fontSize: "15px", fontWeight: "600", marginLeft: "3px", marginRight: "8px" }}>EXPORT</span>
            </IconButton>
          }
        </div>
      </div>


      {employees ?
        <div>
          <Grid container
            spacing={0}
            style={{ paddingTop: "16px", paddingBottom: "8px" }}
          >
            <Grid item xs={1} md={1} lg={1} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
              <div className={classes.headerSort} onClick={() => sortData("code")}>
                <Typography noWrap variant="h5" style={{ paddingLeft: '16px' }}>Code</Typography>
                <div style={{ position: "relative" }}>
                  {sortFieldRef.current['code'].value === true ?
                    sortFieldRef.current['code'].type === "asc" ?
                      <SortRoundedIcon className={classes.ascSort} />
                      :
                      <SortRoundedIcon className={classes.descSort} />
                    : ''
                  }
                </div>
              </div>
            </Grid>
            <Grid item xs={2} md={2} lg={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
              <div className={classes.headerSort} onClick={() => sortData("name")}>
                <Typography noWrap variant="h5" style={{ paddingLeft: '10px' }}>Name</Typography>
                <div style={{ position: "relative" }}>
                  {sortFieldRef.current['name'].value === true ?
                    sortFieldRef.current['name'].type === "asc" ?
                      <SortRoundedIcon className={classes.ascSort} />
                      :
                      <SortRoundedIcon className={classes.descSort} />
                    : ''
                  }
                </div>
              </div>
            </Grid>
            <Grid item xs={2} md={2} lg={2} style={{ marginRight: '-24px' }}>
              <div className={classes.headerSort} onClick={() => sortData("login_email")}>
                <Typography noWrap variant="h5">Login Email</Typography>
                <div style={{ position: "relative" }}>
                  {sortFieldRef.current['login_email'].value === true ?
                    sortFieldRef.current['login_email'].type === "asc" ?
                      <SortRoundedIcon className={classes.ascSort} />
                      :
                      <SortRoundedIcon className={classes.descSort} />
                    : ''
                  }
                </div>
              </div>
            </Grid>
            <Grid item xs={2} md={2} lg={2} style={{ marginRight: '-24px' }}>
              <div className={classes.headerSort} onClick={() => sortData("contact_email")}>
                <Typography noWrap variant="h5" style={{ paddingLeft: '14px' }}>Contact Email</Typography>
                <div style={{ position: "relative" }}>
                  {sortFieldRef.current['contact_email'].value === true ?
                    sortFieldRef.current['contact_email'].type === "asc" ?
                      <SortRoundedIcon className={classes.ascSort} />
                      :
                      <SortRoundedIcon className={classes.descSort} />
                    : ''
                  }
                </div>
              </div>
            </Grid>
            <Grid item xs={1} md={1} lg={1} >
              <div className={classes.headerSort} onClick={() => sortData("phone")}>
                <Typography noWrap variant="h5" style={{ paddingLeft: '28px' }}>Phone</Typography>
                <div style={{ position: "relative" }}>
                  {sortFieldRef.current['phone'].value === true ?
                    sortFieldRef.current['phone'].type === "asc" ?
                      <SortRoundedIcon className={classes.ascSort} />
                      :
                      <SortRoundedIcon className={classes.descSort} />
                    : ''
                  }
                </div>
              </div>
            </Grid>
            <Grid item xs={2} md={2} lg={2} >
              <Button
                size="small"
                onClick={handleRoleClick}
                style={{ paddingLeft: '10px' }}
              >
                <Typography variant="h5" style={{ textTransform: 'capitalize' }} noWrap >User Role</Typography><i className="fa fa-filter fa-lg"></i>
              </Button>
              <Menu
                anchorEl={anchorElRole}
                keepMounted
                open={Boolean(anchorElRole)}
                onClose={handleCloseRole}
                className={classes.filter}
              >
                <MenuItem onClick={() => handleFilterRole(1)} selected={1 === filterRoleRef.current} >Super Admin</MenuItem>
                <MenuItem onClick={() => handleFilterRole(2)} selected={2 === filterRoleRef.current} >Invoice Admin</MenuItem>
                <MenuItem onClick={() => handleFilterRole(3)} selected={3 === filterRoleRef.current} >PO Approver</MenuItem>
                <MenuItem onClick={() => handleFilterRole(4)} selected={4 === filterRoleRef.current} >PO Creator</MenuItem>
                <MenuItem onClick={() => handleFilterRole(5)} selected={5 === filterRoleRef.current} >All</MenuItem>
              </Menu>
            </Grid>
            <Grid item xs={1} md={1} lg={1} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
              <Button
                size="small"
                onClick={handleStatusClick}
                style={{ paddingLeft: '0px' }}
              >
                <Typography variant="h5" style={{ textTransform: 'capitalize' }} noWrap >Employment Type</Typography><i className="fa fa-filter fa-lg"></i>
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleStatusClose}
                className={classes.filter}
              >
                <MenuItem onClick={() => filterStatus(1)} selected={1 === filter} >Full Time</MenuItem>
                <MenuItem onClick={() => filterStatus(2)} selected={2 === filter} >Casual</MenuItem>
                <MenuItem onClick={() => filterStatus(3)} selected={3 === filter} >All</MenuItem>
              </Menu>
            </Grid>

            <Grid item xs={1} md={1} lg={1} style={{ textAlign: 'right' }}>
              {/* <Button
                size="small"
                onClick={handleFilterClick}
              >
                <Typography variant="h5">Filter</Typography><i className="fa fa-filter fa-lg"></i>
              </Button> */}

              {/* {props.employee_settings === true ? */}
              <AddButton size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                <AddIcon />
              </AddButton>
              {/* : ''} */}

            </Grid>
          </Grid>
          {sortLoadingRef.current ?
            <Grid item xs={12}>
              <div className={classes.linearProgress} style={{ marginLeft: "7px", marginRight: "7px" }}><LinearProgress style={{ height: "2px" }} /></div>
            </Grid>
            : ''}
          {/* eslint-disable-next-line array-callback-return */}
          {employees.map((employee, index) => {
            if (employee.user) {
              return (
                <CustomAccordion className={classes.MuiAccordionroot} key={index}>
                  <AccordionSummary
                    onClick={() => accordionHandleChange('panel' + index)}
                    expanded={expanded === 'panel' + index ? "true" : "false"}
                    expandIcon={<ExpandMoreIcon />}
                    style={{ userSelect: 'auto', cursor: 'auto', alignItems: 'flex-start', paddingTop: "10px" }}
                  >
                    <Grid container onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                      <Grid item xs={1} md={1} lg={1} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                        <Typography variant="h6">{employee.code ? employee.code : ''}</Typography>
                      </Grid>
                      <Grid item xs={2} md={2} lg={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                        <Typography variant="h6">{employee.user.first_name ? employee.user.first_name : ''} {employee.user.last_name ? employee.user.last_name : ''}</Typography>
                      </Grid>
                      <Grid item xs={2} md={2} lg={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                        <Typography variant="h6">{employee.user.email}</Typography>
                      </Grid>
                      <Grid item xs={2} md={2} lg={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                        <Typography variant="h6">{employee.email}</Typography>
                      </Grid>
                      <Grid item xs={1} md={1} lg={1} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                        <Typography variant="h6">{employee.mobile_number}</Typography>
                      </Grid>
                      <Grid item xs={2} md={2} lg={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                        <Typography variant="h6">{employee.user.role ? employee.user.role.name ?? '' : ''}</Typography>
                      </Grid>
                      <Grid item xs={1} md={1} lg={1} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                        <Typography variant="h6">{employee.type}</Typography>
                      </Grid>
                      <Grid item xs={1} md={1} lg={1} style={{ textAlign: 'right' }} onClick={(event) => event.stopPropagation()}>

                        {props.employee_settings === true || employee.not_synced ?
                          <>
                            <IconButton style={{ marginTop: "-12px" }} aria-label="edit" onClick={() => handleClickOpen(employee, 'edit')} >
                              <EditIcon fontSize="small" />
                            </IconButton>

                            <IconButton style={{ marginTop: "-12px" }} aria-label="delete" onClick={() => handleClickOpen(employee, 'delete')} >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </>
                          :

                          <IconButton style={{ marginTop: "-12px" }} aria-label="edit" onClick={() => handleClickOpen(employee, 'edit')} >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        }

                      </Grid>
                      <Grid item xs={12} style={{ textAlign: 'left' }}>
                        {employee.approvers_level1_customer.length > 0 ?
                          <div>
                            <Grid container key={index}>
                              <Grid item xs={2} style={{ maxWidth: '8.333333% !important' }}> <Typography variant="h6"> Approver Level 1 for customer: &nbsp;</Typography></Grid>
                              <Grid item xs={10}>
                                {employee.approvers_level1_customer.map((customer, index) => {
                                  return customer.customer ?
                                    <CustomTooltip key={index} title={<Typography variant="h6">{customer.customer.name}</Typography>}>
                                      <Chip key={index} variant="outlined" size="small" label={<Typography variant="h6">{customer.customer.code ? customer.customer.code : customer.customer.name}</Typography>} color="primary" />
                                    </CustomTooltip>
                                    : null;
                                })}
                              </Grid>
                            </Grid>
                          </div>
                          : ''}
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: 'left' }}>
                        {employee.approvers_level2_customer.length > 0 ?
                          <div>
                            <Grid container key={index}>
                              <Grid item xs={2} style={{ maxWidth: '8.333333% !important' }}> <Typography variant="h6"> Approver Level 2 for customer: &nbsp;</Typography></Grid>
                              <Grid item xs={10}>
                                {employee.approvers_level2_customer.map((customer, index) => {
                                  return customer.customer ?
                                    <CustomTooltip key={index} title={<Typography variant="h6">{customer.customer.name}</Typography>}>
                                      <Chip key={index} variant="outlined" size="small" label={<Typography variant="h6">{customer.customer.code ? customer.customer.code : customer.customer.name}</Typography>} color="primary" />
                                    </CustomTooltip>
                                    : null;
                                })}
                              </Grid>
                            </Grid>
                          </div>
                          : ''}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <Grid container key={index} style={{ paddingBottom: '1px', paddingTop: '7px' }}>
                        <Grid item xs={12}>

                          {employee.user.last_access_on_mobile === null && employee.user.last_mobile_user_agent === null ?
                            <i style={{ fontSize: '13px' }}>last accessed on mobile : - </i>
                            :
                            <i style={{ fontSize: '13px' }}>
                              last accessed on mobile is {employee.user.last_access_on_mobile}
                              &nbsp;
                              by
                              &nbsp;
                              {employee.user.last_mobile_user_agent}
                            </i>
                          }

                        </Grid>
                        <Grid item xs={12}>
                          {employee.user.last_access_on_desktop === null && employee.user.last_desktop_user_agent === null ?
                            <i style={{ fontSize: '13px' }}>last accessed on desktop : - </i>
                            :
                            <i style={{ fontSize: '13px' }}>
                              last accessed on desktop is {employee.user.last_access_on_desktop}
                              &nbsp;
                              by
                              &nbsp;
                              {employee.user.last_desktop_user_agent}
                            </i>
                          }
                        </Grid>
                      </Grid>
                    </div>
                  </AccordionDetails>
                </CustomAccordion>
              );
            }
          })}
          <Dialog
            open={open}
            fullWidth={true}
            onClose={handleClose}
            disableEnforceFocus
          >
            <DialogTitle disableTypography={true} id="form-dialog-title"><Typography component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
            <DialogContent>
              {mode === 'add' || (mode === 'edit' && notSyncedRef.current) ?
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {userLoading ?
                      <LinearProgress className={classes.linear} />
                      :
                      <TextField
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        name="firstname"
                        value={firstNameRef.current}
                        fullWidth={true}
                        autoComplete="off"
                        onChange={handleChangeInput}
                      />
                    }
                    <br />
                  </Grid>
                  <Grid item xs={6}>
                    {userLoading ?
                      <LinearProgress className={classes.linear} />
                      :
                      <TextField
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        name="lastname"
                        value={lastNameRef.current}
                        fullWidth={true}
                        autoComplete="off"
                        onChange={handleChangeInput}
                      />
                    }
                  </Grid>
                  <Grid item xs={6}>
                    {userLoading ?
                      <LinearProgress className={classes.linear} />
                      :
                      <TextField
                        id="outlined-basic"
                        label="Code"
                        variant="outlined"
                        name="code"
                        value={code}
                        fullWidth={true}
                        autoComplete="off"
                        onChange={handleChangeInput}
                      />
                    }
                    <br />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Employment Type</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={employmentType ? employmentType : ''}
                        label="Employment Type"
                        name="employment_type"
                        onChange={(e) => handleChangeInput(e, "employment_type")}
                      >
                        <MenuItem value={'Full-Time'}>Full-Time</MenuItem>
                        <MenuItem value={'Part-Time'}>Part-Time</MenuItem>
                      </Select>
                    </FormControl>
                    <br />
                  </Grid>

                  <Grid item xs={12}>
                    {userLoading ?
                      <LinearProgress className={classes.linear} />
                      :
                      <TextField
                        id="outlined-basic"
                        label="Login E-mail"
                        variant="outlined"
                        name="email"
                        value={emailRef.current}
                        fullWidth={true}
                        autoComplete="off"
                        onChange={(e) => handleChangeInput(e)}
                      />
                    }
                  </Grid>
                  <Grid item xs={12}>
                    {userLoading ?
                      <LinearProgress className={classes.linear} />
                      :
                      <TextField
                        id="outlined-basic"
                        label="Contact E-mail"
                        variant="outlined"
                        name="contact_email"
                        value={contactEmailRef.current}
                        fullWidth={true}
                        autoComplete="off"
                        onChange={(e) => handleChangeInput(e)}
                      />
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>User Role</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={userRole ? userRole : ''}
                        label="User Role"
                        name="user_role"
                        onChange={(e) => handleChangeInput(e, "user_role")}
                      >
                        {userRolesRef.current ?
                          userRolesRef.current.map((data, index) => <MenuItem value={data.id} key={index} selected={data.id === userRole}>{data.name}</MenuItem>)
                        : ''}
                      </Select>
                    </FormControl>
                    <br />
                  </Grid>
                  <Grid item xs={12}>
                    {userLoading ?
                      <LinearProgress className={classes.linear} />
                      :
                      <>
                        <TextField
                          id="outlined-basic"
                          label="Mobile Number"
                          variant="outlined"
                          name="mobile_number"
                          value={mobileNumberRef.current ? mobileNumberRef.current : ''}
                          fullWidth={true}
                          autoComplete="off"
                          onChange={handleChangeInput}
                        />
                        <br /> <br />
                      </>
                    }
                  </Grid>
                  <br />
                </Grid>
                : mode !== 'delete' ?
                  <Grid container spacing={2} style={{ marginBottom: "16px" }}>
                    <Grid item xs={12}>
                      {userLoading ?
                        <LinearProgress className={classes.linear} />
                        :
                        <TextField
                          id="outlined-basic"
                          label="Contact E-mail"
                          variant="outlined"
                          name="contact_email"
                          value={contactEmailRef.current}
                          fullWidth={true}
                          autoComplete="off"
                          onChange={(e) => handleChangeInput(e)}
                        />
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>User Role</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={userRole ? userRole : ''}
                          label="User Role"
                          name="user_role"
                          onChange={(e) => handleChangeInput(e, "user_role")}
                        >
                          {userRolesRef.current ?
                            userRolesRef.current.map((data, index) => <MenuItem value={data.id} key={index} selected={data.id === userRole}>{data.name}</MenuItem>)
                          : ''}
                        </Select>
                      </FormControl>
                      <br />
                    </Grid>
                  </Grid>
                  :
                  ''
              }



              {mode !== 'delete' ?
                <Grid container spacing={2}>
                  {customers ?
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-customers"
                        options={customers}
                        disableCloseOnSelect
                        fullWidth={true}
                        getOptionLabel={(option) => option.code ? option.code : option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        onChange={(event, newValue) => {
                          setApproversLevel1(newValue)
                        }}
                        value={approversLevel1Ref.current}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.code} - {option.name}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label="Select customers to approve level 1" placeholder="Select..." />
                        )}
                      />
                      <br />
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-customers"
                        options={customers}
                        disableCloseOnSelect
                        fullWidth={true}
                        getOptionLabel={(option) => option.code ? option.code : option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        onChange={(event, newValue) => {
                          setApproversLevel2(newValue)
                        }}
                        value={approversLevel2Ref.current}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.code} - {option.name}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label="Select customers to approve level 2" placeholder="Select..." />
                        )}
                      />
                      <br />
                    </Grid>
                    :
                    <CircularProgress className={classes.circular} size={20} />
                  }</Grid>
                : ''
              }

              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                name="password"
                fullWidth={true}
                autoComplete="off"
                style={{ marginBottom: '10px' }}
                onChange={handleChangeInput}
              />

              {mode !== 'add' ?
                <Button onClick={() => sendEmail()} color="primary">
                  Send Reset Password Email
                </Button>
                : ''
              }

              <br />
              <FormControlLabel
                control={
                  <Switch
                    name="Email Notifications Enabled"
                    checked={emailNotificationStatusRef.current}
                    onClick={emailNotificationHandleChange}
                  />
                }
                label="Email Notifications Enabled"
              />
              <br />
              <FormControlLabel
                control={
                  <Switch
                    name="Enable Add Invoice (Mobile)"
                    checked={addInvoiceMobileStatusRef.current}
                    onClick={enableAddInvoiceMobileHandleChange}
                  />
                }
                label="Enable Add Invoice (Mobile)"
              />
              <br />
            </DialogContent>
            <DialogActions style={{ lineHeight: "60px", height: "60px" }}>
              {loading ?
                <CircularProgress className={classes.circular} />
                :
                <div>
                  {/* {usersFilterChosenRef.current === 7 ?
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          defaultChecked
                          onChange={adminHandleChange}
                          name="checked"
                        />
                      }
                      label="Admin"
                    />
                    :
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={adminHandleChange}
                          name="checked"
                        />
                      }
                      label="Admin"
                    />
                  } */}

                  <CancelButton
                    size="small"
                    variant="contained"
                    onClick={handleClose}
                  >
                    Cancel
                  </CancelButton>
                  <SubmitButton
                    disabled={mode === 'delete' ? false : mode === 'edit' ? (firstName === '' || lastName === '') : disable}
                    size="small"
                    variant="contained"
                    onClick={() => SaveEmployee()}
                  >
                    {mode === 'delete' ? 'Delete' : 'Save'}
                  </SubmitButton>
                </div>
              }

            </DialogActions>
          </Dialog>
          {/* </List> */}
          {/* <Typography variant="h6" style={diffTime(integration.data[0].employees_last_sync)>1?{color:'red',textAlign:'right'}:{color: '#333942',textAlign:'right'}}>
            Master Data last synced: {TimezoneDate(integration.data[0].employees_last_sync)}
          </Typography>
          <br/> */}
        </div>
        :
        <CircularProgress className={classes.circular} />
      }



      {employees ?
        <Grid container>
          <Grid
            item xs={12}
            style={{ textAlign: 'right', marginTop: '10px', marginBottom: '10px', fontFamily: 'Manrope' }}
          >
            <span style={{ paddingRight: '20px' }}>
              Rows per page:
            </span>
            <span style={{ paddingRight: '20px' }}>
              <TextField
                id="standard-select-currency"
                select
                value={pageNumber}
                onChange={handleChange}
              >
                {pageOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </span>

            <span style={{ paddingRight: '20px' }}>
              {(pageNumberRef.current * (page - 1)) + 1}- {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
            </span>

            <Button
              variant="outlined"
              size="small"
              disabled={page === 1 || loadingPagination}
              className={classes.buttonLeft}
              onClick={() => pageChange(-1)}
            >
              <ArrowBackIosRoundedIcon />
            </Button>

            <Button
              variant="outlined"
              size="small"
              className={classes.buttonRight}
              disabled={page >= Math.ceil(totalPage / pageNumberRef.current) || loadingPagination}
              onClick={() => pageChange(1)}
            >
              <ArrowForwardIosRoundedIcon />
            </Button>
          </Grid>
          <Grid item xs={6}>
            {integration ? integration.data[0] ?
              <Typography variant="h6" style={diffTime(integration.data[0].employees_last_sync) > 1 ? { color: 'red', textAlign: 'right' } : { color: '#333942', textAlign: 'right' }}>
                Master Data last synced: {(integration.data[0].employees_last_sync)}
              </Typography>
              : ''
              : ''}
          </Grid>
        </Grid>
        :
        " "}
    </div>
  );
}

export default Employees;
